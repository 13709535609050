/**
 * Utils implementation for CORE Casual
 * author <a href="mailto:shikerya@me.com">Jacky Shikerya</a>
 * version 1.0.0
 */
!(() => {
    const ua = navigator.userAgent;
    const vendor = navigator.vendor;

    if (/AppleWebKit/.test(ua) && /Google/.test(vendor)) {
        core.isWebKit = true;
        $('html').addClass('webkit');
    }

    if (/Safari/i.test(ua) && /Apple/i.test(vendor)) {
        $('html').addClass('safari');
        core.isSafari = true;
    }

    if ((/Trident/.test(ua) || /Edge/.test(ua)) && /Microsoft/.test(vendor)) {
        core.isIE = true;
    }

    if (/Macintosh|Apple/.test(ua) && !/Windows/i.test(ua)) {
        core.isApple = true;
    }

    if (/ipad|iphone/i.test(ua)) {
        core.isIOS = true;
        $('html').addClass('ios');
    }

    if (/Firefox/i.test(ua)) {
        core.isFirefox = true;
        $('html').addClass('firefox');
    }
})();

/** @namespace */
const core_utils = {};

core_utils.noop = () => {};

/** @type {Object.<string, function>} */
const core_exp = {};

core_utils.intExport = (name, func, scope) => {
    switch (scope) {
        case undefined:
            core_exp[name] = func;
            break;
        case false:
            core_exp[name] = function(...args) {
                return func.apply(this, args);
            };
            break;
        default:
            core_exp[name] = function(...args) {
                return func.apply(scope, args);
            };
            break;
    }
    return core_exp[name];
};

/**
 * export dataManager function hasFeature
 * @param {string} featureName
 * @return {boolean}
 */
core_utils.hasFeature = featureName => core_DO.hasFeature(featureName);

/**
 * export settings getter for templates
 * @param key
 */
core_utils.settings = key => {
    const v = core_DO.settings(key);
    if (v === undefined) {
        return false;
    } else if (typeof v == 'string') {
        if (/true|false/i.test(v)) {
            return v.toLowerCase() == 'true';
        } else if (['0', '1'].includes(v)) {
            return v == '1';
        }
    }
    return v;
};

/**
 * pack local date to utc
 * @param {number} d
 * @return {number}
 */
core_utils.packDate = d => {
    if (!d) {
        return d;
    }
    const oo = new Date(d);
    return Date.UTC(oo.getFullYear(), oo.getMonth(), oo.getDate());
};

/**
 * unpack date from utc
 * @param {number} d
 * @return {number}
 */
core_utils.unpackDate = d => {
    if (!d) {
        return d;
    }
    const oo = new Date(d);
    return new Date(oo.getUTCFullYear(), oo.getUTCMonth(), oo.getUTCDate()).getTime();
};

/**
 * Zeroing time in date object if present
 * @param  {Moment?} d
 * @return {Moment}
 */
core_utils.zeroTime = d => {
    if (!d) {
        d = moment();
    }
    return d
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
};

/**
 * Convert string date to timestamp
 * @param {string} s
 * @return {number}
 */
core_utils.dateStr2Timestamp = s => {
    const d = new Date(0);
    const m = s.match(/^(\d{4})\-?(\d{2})\-(\d{2})$/);
    d.setUTCFullYear(m[1]);
    d.setUTCMonth(m[2] - 1);
    d.setUTCDate(m[3]);
    return d.getTime();
};

/**
 * add lead zeros to string
 * @param {string} s
 * @param {number} len
 * @return {string}
 */
function lead(s, len) {
    return '0'.repeat(len - s.length) + s;
}

/**
 * Convert timestamp to date string
 * @param {number} d
 * @return {string}
 */
core_utils.timestamp2DateString = d => {
    const o = new Date(d);
    return `${o.getUTCFullYear()}-${lead(`${o.getUTCMonth() + 1}`, 2)}-${lead(`${o.getUTCDate()}`, 2)}`;
};

/**
 * Async/sync function call
 * @param {function|Array} event function to call, or array like [obj, obj.method]
 * @param {(Array.<*>|*)=} args arguments for function
 * @param {_UNTIE=} call type cpecification (default: async)
 */
core_utils.untie = (event, args, async) => {
    const to = 0;
    async = __ASYNC ? (async == undefined && true) || async : false;

    if (Object.isArray(event)) {
        event = event[1].bind(event[0]);
    }

    if (args && !Object.isArray(args) && !(Object.isObject(args) == false && args.length)) {
        args = [args];
    }

    const func = () => {
        switch (true) {
            case event instanceof Function:
                event(...(args || []));
                break;
            case event instanceof Array:
                event[1].apply(event[0], args || []);
                break;
        }
    };

    if (async === true) {
        setTimeout(func, to);
    } else {
        func();
    }

    return true;
};

/**
 * Create namespaced object from string
 * @param {string|Array} ns name
 * @param {?Object} obj target object
 * @param {?*} value assign value to NS
 * @param {?*} def default value of NS if it doenst exists
 * @param {?Function} dproc post process for NS value
 * @return {Object} pointer to NS
 */
core_utils.mkNS = (ns, obj, value, def, dproc) => {
    const n = (Object.isString(ns) && ns.split('.')) || (Object.isArray(ns) && ns) || (Object.isEmpty(ns) && []);
    let to = (!obj && (obj = {})) || obj;
    let prev;
    let nl;

    n.forEach(v => {
        !to[v] && (to[v] = {});
        prev = to;
        to = to[v];
    });

    nl = n.last();

    value != undefined && (prev[nl] = value);

    if (def && value === undefined && Object.isEmpty(prev[nl])) {
        prev[nl] = def;
    }

    if (dproc && dproc instanceof Function) {
        dproc.call(null, prev[nl]);
    }

    return prev[nl];
};

/**
 * UUID generate function
 * @return {String}
 */
core_utils.uuid =
    typeof window.crypto != 'undefined' && typeof window.crypto.getRandomValues != 'undefined' && !core.isIE
        ? protectDeadlock => {
              const buf = new Uint16Array(10);
              window.crypto.getRandomValues(buf);
              const r = Array.prototype.reduce
                  .call(buf, (a, b) => (a *= b))
                  .toString(36)
                  .replace(/^\d+\.|\(e\+\d+\)/g, '');
              const r1 = r.replace(/0+$/, '');
              if (r1.length < 20) {
                  if (protectDeadlock) {
                      return r1;
                  } else {
                      return r1 + core_utils.uuid(true);
                  }
              }
              return r.replace(/(\W|\.)+/g, '');
          }
        : () => (Math.random().toString(36) + Math.random().toString(36)).replace(/(\W|\.)+/g, '');

/**
 * format string/Number to size in Kb, Mb or bytes
 * @param {String|Number} size string to format
 * @param {boolean} binary mode
 * @return {String}
 */
core_utils.fmtFileSize = (size, binary) => {
    typeof size != 'number' && (size = parseInt(size, 10));

    const /** @const */ kb = (binary && 1024) || 1000;
    const /** @const */ mb = kb * ((binary && 1024) || 1000);
    const /** @const */ gb = mb * ((binary && 1024) || 1000);
    let ret;
    let suf;

    !size && (size = 0);

    switch (true) {
        case size < kb:
            ret = size || 0;
            suf = 'b';
            break;
        case size > kb && size < mb + kb:
            ret = Math.round(size / kb);
            suf = 'Kb';
            break;
        case size >= mb && size < gb: // + mb:
            ret = (size / mb).toFixed(1);
            suf = 'Mb';
            break;
        case size >= gb:
            ret = (size / gb).toFixed(2);
            suf = 'Gb';
            break;
    }
    ret = ret.toString();
    /\.0+$/.test(ret) && (ret = ret.replace(/\.0+$/, ''));

    return ret + suf;
};

/**
 * Convert value to array
 * @param {*} value
 * @return {Array}
 */
core_utils.any2array = value =>
    (Object.isArray(value) && value) || (value == undefined || value == null || value == '' ? [] : [value]);

/**
 * Convert form.serializeArray to object
 * @param {Array} data
 * @return {Object}
 */
core_utils.serialized2Object = data => {
    const obj = {};
    for (let i = data.length; --i >= 0; ) {
        if (obj[data[i]['name']]) {
            if (obj[data[i]['name']] instanceof Array == false) {
                obj[data[i]['name']] = [obj[data[i]['name']]];
            }
            obj[data[i]['name']].push(data[i]['value']);
        } else {
            obj[data[i]['name']] = data[i]['value'];
        }
    }
    return obj;
};

/**
 * Serialize form
 * @param {jQuery} $form
 * @returns {Object<string, string|string[]>}
 */
core_utils.serializeForm = $form => {
    return $form.serializeArray().reduce((acc, v) => {
        if (acc[v.name]) {
            if (typeof acc[v.name] == 'string') {
                acc[v.name] = [acc[v.name]];
            }
            acc[v.name].push(v.value);
        } else {
            acc[v['name']] = v['value'];
        }
        return acc;
    }, {});
};

/**
 * Make hash object
 * @param {string} key
 * @param {*} data
 * @return {Object}
 */
core_utils.makeHashObj = (key, data) => {
    const obj = {};
    obj[key] = data;
    return obj;
};

/**
 * Array to hash object
 * @param {Array|Object} src
 * @param {string} key
 * @param {Object=} target
 * @param {Function=} callback for record preprocessing
 * @return {Object}
 */
core_utils.array2HashObj = (src, key, target, callback) => {
    target = target || {};

    if (target instanceof Function) {
        callback = target;
        target = {};
    }

    if (src instanceof Array !== true) {
        src = [src];
    }

    for (let r, i = src.length; --i >= 0; ) {
        r = callback ? callback(src[i], src[i][key]) : src[i];
        target[r[key]] = r;
    }

    return target;
};

/**
 * Convert array to quick search map
 * @param {Array.<string>} $src
 * @param {*=} val
 * @param {string=} field
 * @return {Object.<*>}
 */
core_utils.makeQHash = ($src, val, field) => {
    const r = Object.create(null);
    $src.forEach(v => {
        r[field ? v[field] : v] = val;
    });
    return r;
};

/**
 * Makes object hashed by array values
 * @param {Array.<string>} src
 * @return {Object.<string, number>}
 */
core_utils.array2HashedByValues = src => {
    const res = {};

    src.forEach((v, i) => {
        res[v] = i;
    });

    return res;
};

/**
 * Bind map to object
 * @param {Object} map
 * @param {Object} pointer
 */
core_utils.bindMap = (map, pointer) => {
    for (const k in map) {
        map[k] = (f =>
            function(...args) {
                return f.apply(pointer, args);
            })(map[k]);
    }
    return map;
};

/**
 * Filter object by hash
 * @param {Object} src
 * @param {Array} keys
 * @return {Object}
 */
core_utils.getItemsFromObjectByHash = (src, keys) => {
    const res = {};

    for (const i in src) {
        if (keys.indexOf(i) > -1) {
            res[i] = src[i];
        }
    }

    return res;
};

/**
 * Get items from array by hash field
 * @param {Object} src
 * @param {Array} keys
 * @param {string} hashField
 * @param {string} idField
 * @return {Object}
 */
core_utils.getItemsFromArrayByHash = (src, keys, hashField, idField) => {
    const res = {};

    // FIXME: it is wrong, have to be refactored
    $.each(src, (i, v) => {
        keys.indexOf(v[hashField]) > -1 && (res[idField] = v);
    });

    return res;
};

/**
 * Get Items from hash object by criteria
 * @param {Object} src
 * @param {Object} criteria hash object
 * @return {Object}
 */
core_utils.getItemsByCriteria = (src, criteria) => {
    const res = {};

    for (const i in src) {
        let b = true;
        const r = src[i];
        for (const c in criteria) {
            b = b && r[c] == criteria[c];
            if (!b) break;
        }
        b && (res[i] = r);
    }

    return res;
};

/**
 * Export only listed properties from the source object
 * @param {Object} source
 * @param {Array} p properties list for the export
 * @return {Object}
 */
core_utils.exportListed = (source, p) => {
    const obj = {};
    for (let i = p.length; --i >= 0; ) {
        obj[p[i]] = source[p[i]];
    }
    return obj;
};

/**
 * Namespace
 * @param {Object} src
 * @param {String} path
 * @return {*}
 */
core_utils.ns = (obj, path) => {
    const nsa = path && Object.isArray(path) ? path : path.split('.');
    let f = false;
    let i = 0;

    do {
        f = !!(obj = obj[nsa[i]]);
    } while (++i < nsa.length && f);

    return obj;
};

/**
 * Retrieve info from object by given path, if it exists
 * @param {Object} obj
 * @param {string} path
 * @param {*=} fallbackResult
 * @return {*}
 */
core_utils.getFromObj = (obj, path, fallbackResult) => {
    if (obj) {
        const nsa = path && Object.isArray(path) ? path : path.split('.');
        let f = false;
        let i = 0;

        do {
            f = !!(obj = obj[nsa[i]]);
        } while (++i < nsa.length && f);

        return obj != undefined ? obj : fallbackResult;
    } else {
        return fallbackResult;
    }
};

/**
 * Mix objects
 * @param {Object} target
 * @param {...Object} sources list of sorce objects to mix
 * @param {?Array} filter list of properties to mix
 * @return {Object}
 */
core_utils.mixin = function(...args) {
    const sources = $.makeArray(args);
    let target = sources.splice(0, 1)[0];
    let filter = sources.splice(-1, 1)[0];

    if (target === false) {
        target = {};
    }

    switch (true) {
        case !sources.length && filter instanceof Array:
            console.error('no source objects to mix');
            break;
        case !(filter instanceof Array):
            sources.push(filter);
            filter = undefined;
            break;
    }

    for (let i = 0; i < sources.length; i++) {
        $.each(sources, (i, source) => {
            if (!filter) {
                $.each(source, (i, m) => {
                    if (/\./.test(i)) {
                        m = i.namespace(source);
                        i = i.split('.').slice(-1)[0];
                    }
                    target[i] = m;
                });
            } else {
                $.each(filter, (i, m) => {
                    let v;
                    if (/\./.test(m)) {
                        v = m.namespace(source);
                        m = m.split('.').slice(-1)[0];
                    } else {
                        v = source[m];
                    }
                    if (v != undefined) {
                        // if (target[m] && typeof(target[m]) == 'object' && typeof(v) == 'object') {
                        //     target[m] = $.extend(true, target[m], v);
                        // } else {
                        target[m] = v;
                        // }
                    }
                });
            }
        });
    }

    return target;
};

/**
 * Make array from array-like
 * @param {*} ar
 * @return {Array}
 */
core_utils.cloneArray = ar => Array.prototype.slice.apply(ar || []);

/**
 * Find min element of array
 * @param {Array} ar
 * @param {function} cb
 * @return {Array}
 */
core_utils.findMinInArray = (ar, cb) => {
    const ma = ar.map(cb),
        miv = ma.min();
    return (miv !== undefined && [[ar[ma.findIndex(miv)], miv]]) || [];
};

/**
 * Process class names string|array
 * @param {string|Array<string>} src
 * @return {string}
 */
core_utils.pClassName = src => {
    if (Object.isArray(src)) {
        return src
            .flatten()
            .filter(p => !!p)
            .unique()
            .join(' ');
    } else {
        return src;
    }
};

/** @namespace */
core_utils.string = {};
/**
 * output a string produced according to the formatting string format
 * @param {String} format string
 * @param {String|Number} parameter to substitute
 * @return {String}
 */
(core_utils.string.sprintf = function(...args) {
    let i = 0;
    let a;
    let f = args[i++];
    const o = [];
    let m;
    let p;
    let c;
    let x;
    while (f) {
        if ((m = /^[^\x25]+/.exec(f))) o.push(m[0]);
        else if ((m = /^\x25{2}/.exec(f))) o.push('%');
        else if ((m = /^\x25(?:(\d+)\$)?(\+)?(0|'[^$])?(-)?(\d+)?(?:[\.](\d+))?([b-ftosuxX])/.exec(f))) {
            if ((a = args[m[1] || i++]) == null || a == undefined) throw 'Too few arguments.';
            if (/[^s]/.test(m[7]) && typeof a != 'number') throw `Expecting number but found ${typeof a}`;
            switch (m[7]) {
                case 'b':
                    a = a.toString(2);
                    break;
                case 'c':
                    a = String.fromCharCode(a);
                    break;
                case 'd':
                    a = parseInt(a);
                    break;
                case 'e':
                    a = m[6] ? a.toExponential(m[6]) : a.toExponential();
                    break;
                case 'f':
                    a = m[6] ? parseFloat(a).toFixed(m[6]) : parseFloat(a);
                    break;
                case 't':
                    const _f = parseInt(a, 10),
                        _b = (parseFloat(a) - _f).toFixed(m[6]) * 100;
                    a = `${zlead(_f, m[3])}:${zlead(_b, m[6])}`;
                    break;
                case 'o':
                    a = a.toString(8);
                    break;
                case 's':
                    a = (a = String(a)) && m[6] ? a.substring(0, m[6]) : a;
                    break;
                case 'u':
                    a = Math.abs(a);
                    break;
                case 'x':
                    a = a.toString(16);
                    break;
                case 'X':
                    a = a.toString(16).toUpperCase();
                    break;
            }
            a = /[def]/.test(m[7]) && m[2] && a > 0 ? `+${a}` : a;
            c = m[3] ? (m[3] == '0' ? '0' : m[3].charAt(1)) : ' ';
            x = m[5] - String(a).length;
            p = m[5] ? str_repeat(c, x) : '';
            o.push(m[4] ? a + p : p + a);
        } else throw 'Huh ?!';
        f = f.substring(m[0].length);
    }
    return o.join('');
}),
    /**
     * Accurately cut the string.
     * @param {string} string to be cutted
     * @param {number} max length
     * @param {boolean=} add tree dts
     * @return {string} resulting string
     */
    (core_utils.string.accurateCut = (str, len, cut) => {
        if (str.length > len) {
            let rw;
            let lw;
            const cl = str.length;
            rw = lw = len;
            while (/\w/.test(str.charAt(rw)) && ++rw < cl) {}
            while (/\w/.test(str.charAt(lw)) && --lw > 0) {}
            switch (true) {
                case rw < str.length || lw > 0:
                    return (rw - len > len - lw ? str.substr(0, lw) : str.substr(0, rw)) + ((cut && '…') || '');
                    break;
                case rw == str.length && lw == 0:
                    return str.substr(str, 0, len);
                    break;
                case rw == str.length && lw > 0:
                    return `${str.substr(0, lw)}…`;
                    break;
                case rw < str.length && lw == 0:
                    return `${str.substr(0, rw)}…`;
                    break;
            }
        } else return str;
    }),
    /**
     * Stringify to value of attribute
     *    like: core_utils.string.toAttr('value', {id: '12345'}) -> data-value='{"id":"12345"}'
     * @param {string} name Data set attribute name
     * @param {*} obj Data to be stringified
     * @return {string}
     */
    (core_utils.string.toAttr = (name, obj) => {
        const v = JSON.stringify(obj);
        let f = `data-${name}=`;

        switch (true) {
            case Object.isArray(obj):
            case Object.isObject(obj):
                f += `'${v}'`;
                break;
            case Object.isString(obj):
                f += v;
                break;
            default:
                f += `"${v}"`;
                break;
        }

        return f;
    });

/**
 * Process html entities
 * @param {string} str
 * @return {string}
 */
core_utils.string.proc_entity = function proc_entity(str) {
    return (str || '')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&#60;')
        .replace(/>/g, '&#62;')
        .replace(/\$/g, '&#36;');
};

/**
 * @param {string} template
 * @param {Object.<string, *>} data
 * @return {string}
 */
core_utils.string.substitute = (template, data) =>
    template.replace(/\{\$(.*?)\}/g, (full, match) => (data[match] != null ? data[match] : full));

/**
 * Get abbriviation from string
 * @param {string} src
 * @param {number} limit
 * @return {string}
 */
core_utils.string.getAbbr = (src, limit) => {
    limit == undefined && (limit = 2);

    const r = [];
    let i = 0;
    const m = src.replace(/&nbsp;/g, ' ').match(/(^|\s)./g);

    if (m && m.length) {
        switch (m.length) {
            case 1:
                r.push(src.slice(0, 1).toUpperCase());
                break;
            default:
                do {
                    r.push(m[i++].trim().toUpperCase());
                } while (i < m.length && i < limit);
                break;
        }
    }

    return r.join('');
};

/**
 * generate calendar data
 * @param  {number} timestamp  timestamp for current date
 * @param  {boolean} selectable mark selected date
 * @param  {cWorkspaceSettingsInfo} settings
 * @return {Array<string>}
 */
core_utils.calendar = (timestamp, selectable, settings) => {
    const gDate = dobj => dobj.getDate();
    const gDay = dobj => dobj.getDay();
    const gMonth = dobj => dobj.getMonth();
    const gYear = dobj => dobj.getFullYear();
    const isSame = (dobj1, dobj2) =>
        gDate(dobj1) === gDate(dobj2) && gMonth(dobj1) === gMonth(dobj2) && gYear(dobj1) === gYear(dobj2);
    const sDate = (year, month, date) => {
        const d = new Date(year, month, date);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    };
    const incDate = dobj => {
        dobj.setDate(gDate(dobj) + 1);
    };
    const daysInMonth = obj => {
        const d = sDate(gYear(obj), gMonth(obj) + 1, 0);
        return gDate(d);
    };

    const tmp = moment();
    const date = (timestamp && new Date(timestamp)) || sDate(tmp.year(), tmp.month(), tmp.date());
    const day = (timestamp && gDate(date)) || 0;
    const year = gYear(date);
    const month = gMonth(date);
    const dObj = sDate(year, month, 1);
    const isSundayBeginsTheWeek = settings.firstDayOfWeek == 7;
    const lead = (gDay(dObj) - (isSundayBeginsTheWeek ? 0 : 1) + 7) % 7;
    const rows = Math.ceil((lead + daysInMonth(dObj)) / 7);
    const pDay = sDate(year, month, 1 - lead);
    const cDay = sDate(tmp.year(), tmp.month(), tmp.date());
    // debugger;
    const dayOffs =
        (settings.schedule &&
            (isSundayBeginsTheWeek ? [0, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 7]).subtract(
                core_utils.compileWorkWeekInfo(settings.schedule)
            )) ||
        (isSundayBeginsTheWeek ? [0, 6] : [6, 7]);
    const r = [];

    for (let i = rows; --i >= 0; ) {
        const rr = [];
        for (let dow = 0; dow < 7; dow++) {
            const cls = [];

            gMonth(pDay) != month && cls.push('outer');
            selectable && gDate(pDay) == day && gMonth(pDay) == month && gYear(pDay) == year && cls.push('selected');
            isSame(pDay, cDay) && cls.push('current-date');
            dayOffs.includes(dow) && cls.push('dayoff');

            rr.push({
                date: gDate(pDay),
                stamp: pDay.getTime(),
                cls: cls.join(' ')
            });

            incDate(pDay);
        }
        r.push(rr);
    }
    return r;
};

/**
 * generate week days for calendar
 * @param  {cWorkspaceSettingsInfo} settings
 * @return {Array<string>}
 */
core_utils.calendar.days = settings => {
    const a = [];
    const w = new Date();
    const days = [1, 2, 3, 4, 5, 6];

    settings.firstDayOfWeek == 7 ? days.unshift(0) : days.push(0);

    days.forEach(d => {
        a.push(
            moment()
                .day(d)
                .format('ddd')
        );
    });

    return a;
};

/**
 * Compile week work data string into sutable format
 * @param  {string} data 1-09:00-17:00;2-09:00-17:00;3-09:00-17:00
 * @return {Object<string, [string, string]>}>
 */
core_utils.compileWorkWeekInfo = function compileWorkWeekInfo(data) {
    const r = (data || '')
        .split(';')
        .map(v => {
            const t = v.split('-');
            return parseInt(t[0], 10);
        })
        .unique();

    // hack for sunday
    r.includes(7) && r.push(0);
    return r;
};

function getFmt(d, needTime, utc) {
    const cd = moment();
    const td = moment(d);
    let r;

    switch (true) {
        case cd.get('year') == td.get('year'):
        case cd.get('year') == td.get('year') + 1 && moment().diff(d, 'months') < 2:
            r = (needTime && core.getMsg(_msgShortDateTime)) || core.getMsg(_msgShortDate);
            break;
        case cd.get('year') != td.get('year'):
            r = (needTime && core.getMsg(_msgFullDateTime)) || core.getMsg(_msgFullDate);
            break;
    }
    // var t = moment(d);
    // if (utc) { t = t.utc(); }
    return moment(d).format(r);
}

core_utils.getFmt = getFmt;

core_utils.fmtTplDate = d => getFmt(d);
core_utils.fmtUnpuckTplDate = d => getFmt(core_utils.unpackDate(d));

core_utils.fmtUTCDate = d => getFmt(d, false, true);

core_utils.fmtTplDateTime = d => getFmt(d, true);

core_utils.fmtFullDateTime = d => moment(d).format('llll');

core_utils.fmtRelativeTime = d => moment(d + (core.timeOffset || 0)).fromNow();

core_utils.completeBar = (data, output) => {
    const wq = (data.open || 0) + (data.done || 0) + (data.blocked || 0),
        s = tpls.components.completeBar(
            $.extend(data, {
                donep: wq && data.done && Math.round((data.done * 100) / wq),
                openp: wq && data.open && Math.round((data.open * 100) / wq),
                blockedp: wq && data.blocked && Math.round((data.blocked * 100) / wq),
                wq
            })
        );
    return s;
};

core_utils.encData = (data, pack) => {
    const s = [];
    for (const k in data) {
        s.push(`${k}:${pack ? data[k].toString(36) : data[k]}`);
    }
    return s.join(',');
};
core_utils.decData = (str, pack) => {
    const tmp = str.split(','),
        obj = {};

    tmp.forEach(d => {
        const k = d.split(':');
        obj[k[0]] = pack ? parseInt(k[1], 36) : k[1] * 1;
    });

    return obj;
};

core_utils.banner = str => {
    let $el = $('#banner');
    switch (!!str) {
        case true:
            if ($el.length) {
                $el.html(str);
            } else {
                $el = $(tpls.components.banner({ caption: str }))
                    .attr('id', 'banner')
                    .appendTo('body');

                setTimeout(() => {
                    $el.addClass('fadein');
                }, 0);
            }
            break;
        case false:
            if ($el.length) {
                $el.on(_gcTransitionEnd, function() {
                    $(this).remove();
                }).removeClass('fadein');
                setTimeout(() => {
                    $('#banner').remove();
                }, 300);
            }
            break;
    }
};

core_utils.mapArray2Obj = (keys, values) => {
    const res = {};
    (keys || []).forEach((f, i) => {
        f && values[i] && (res[f] = values[i]);
    });

    return res;
};

core_utils.isPointInside = function(p, x, y, w, h) {
    const pp = (p instanceof Array && p) || [p.x, p.y];
    if (arguments.length == 2) {
        switch (true) {
            case x instanceof Array:
                h = x[3];
                w = x[2];
                y = x[1];
                x = x[0];
                break;
            case x instanceof Object:
                h = x.height;
                w = x.width;
                y = x.top;
                x = x.left;
                break;
        }
    }
    return pp[0] > x && pp[0] < x + w && pp[1] > y && pp[1] < y + h;
};

core_utils.getDefined = function(...args) {
    if (args.length) {
        for (var i = 0; i < args.length && args[i] === undefined; i++) {}
        return args[i];
    }
};

/** @const */ const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

core_utils.encode64 = input => {
    input = escape(input);
    let output = '';
    let chr1,
        chr2,
        chr3 = '';
    let enc1,
        enc2,
        enc3,
        enc4 = '';
    let i = 0;

    do {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }

        output = [output, keyStr.charAt(enc1), keyStr.charAt(enc2), keyStr.charAt(enc3), keyStr.charAt(enc4)].join('');
        chr1 = chr2 = chr3 = '';
        enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return output;
};

core_utils.decode64 = input => {
    let output = '';
    let chr1,
        chr2,
        chr3 = '';
    let enc1,
        enc2,
        enc3,
        enc4 = '';
    let i = 0;

    // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
    const base64test = /[^A-Za-z0-9\+\/\=]/g;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

    do {
        enc1 = keyStr.indexOf(input.charAt(i++));
        enc2 = keyStr.indexOf(input.charAt(i++));
        enc3 = keyStr.indexOf(input.charAt(i++));
        enc4 = keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
        }

        chr1 = chr2 = chr3 = '';
        enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return unescape(output);
};

core_utils.timers = (() => {
    const pool = {};
    let base = 0;

    // clean up the holder
    function _remove(id) {
        delete pool[id];
    }

    // internal action for done timer
    function _done(id) {
        if (!pool[id]) return;
        core_utils.untie(pool[id].func, pool[id].arg);
        _remove(id);
    }

    return {
        // creating timer
        // timeout - idle time in milliseconds
        // func - callback function
        // arg - arguments for callback function
        // loop - should timer be looped
        // return timer ID
        add(timeout, func, arg, pid, run_on_define) {
            const id = pid || base++;
            pool[id] = {
                id: setTimeout(() => {
                    _done(id);
                }, timeout),
                func,
                arg,
                // loop: loop || false,
                timeout
            };
            run_on_define && _done(id);
            return id;
        },
        restart(id, new_callback, new_arg) {
            if (pool[id]) {
                clearTimeout(pool[id].id);
                pool[id].id = setTimeout(
                    id => {
                        _done(id);
                    },
                    pool[id].timeout,
                    id
                );
                // if false - we going to remove existing callback so lets clear the existing one by null
                // null as argument will doesnt touch the function
                if (new_callback === false || new_callback) {
                    pool[id].func = new_callback || null;
                }
                pool[id].arg = new_arg || null;
                return id;
            }
            return false;
        },
        // kill timer
        stop(id) {
            if (pool[id]) {
                clearTimeout(pool[id].id);
            }
            _remove(id);
        }
    };
})();

/**
 * Fast arrays concatenate
 * @param {Array} array1
 * @param {Array} array2
 * @return {Array}
 */
core_utils.concatArrays = (array1, array2) => {
    const r = new Array(array1.length + array2.length);
    let idx = 0;

    [array1, array2].forEach(src => {
        for (let l = src.length, i = 0; i < l; i++) {
            r[idx++] = src[i];
        }
    });

    return r;
};

/**
 * Extend object
 * @param {...Object} args
 * @return {Object}
 */
core_utils.extendObj = function(...args) {
    const r = {};
    Array.prototype.slice.call(args).forEach(o => {
        Object.keys(o).forEach(f => {
            r[f] = o[f];
        });
    });
    return r;
};

/**
 * Draw rounded rect on canvas
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} x
 * @param {number} y
 * @param {number} $width
 * @param {number} $height
 * @param {number} $radius
 * @param {string=} $fill
 * @param {string=} $stroke
 */
core_utils.canvasRoundRect = (ctx, x, y, $width, $height, $radius, $fill, $stroke, $lineWidth) => {
    ctx.beginPath();
    ctx.moveTo(x, y + $radius);
    ctx.lineTo(x, y + $height - $radius);
    ctx.quadraticCurveTo(x, y + $height, x + $radius, y + $height);
    ctx.lineTo(x + $width - $radius, y + $height);
    ctx.quadraticCurveTo(x + $width, y + $height, x + $width, y + $height - $radius);
    ctx.lineTo(x + $width, y + $radius);
    ctx.quadraticCurveTo(x + $width, y, x + $width - $radius, y);
    ctx.lineTo(x + $radius, y);
    ctx.quadraticCurveTo(x, y, x, y + $radius);
    $fill && (ctx.fillStyle = $fill);
    ctx.fill();
    $stroke && (ctx.strokeStyle = $stroke);
    $lineWidth && (ctx.lineWidth = $lineWidth);
    ctx.stroke();
};

// function now (d) {
//     return moment(d).utc().valueOf();
// }

/**
 * convert color from tag to ui color
 * @param {string} n
 * @nosideeffect
 * @return {string}
 */
core_utils.tagColor = n =>{
    if(n && (n.startsWith('#') || n.startsWith('_'))) {
        const color = _gcModernTagColors[n];
        if(color !== undefined) return color;
        if(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(n)) return n;
    }
    return '';
};

/**
 * convert tag id to color for label
 * @param {string} id
 * @nosideeffect
 * @return {string}
 */
core_utils.tagToColor = id => {
    const item = $scope[_bndProjectTags].$item && $scope[_bndProjectTags].$item(id);
    if (item) {
        const color = core_utils.tagColor(item.color);
        // var hsv = colorLib.RGB2HSV(colorLib.HEX2RGB(item.color));
        // hsv.hue = colorLib.HUEShift(hsv.hue, 180.0);
        return `background-color:${color};color:${core_utils.makeFColorVisible(color)}`;
        //rgb(' + Object.values(colorLib.HSV2RGB(hsv)) + ')';
    } else {
        return '';
    }
};

core_utils.makeFColorVisible = color => {
    //     debugger;
    // var hsv = colorLib.RGB2HSV(colorLib.HEX2RGB(color));
    // hsv.hue = colorLib.HUEShift(hsv.hue, 80.0);
    // return 'rgb(' + Object.values(colorLib.HSV2RGB(hsv)) + ')';
    const rgb = colorLib.HEX2RGB(color);
    let avg = Math.round((rgb.r + rgb.g + rgb.b) / 3);
    // var r = (avg ^ (128 - 32)).toString(16);
    if (avg > 150) {
        avg -= 128;
    } else {
        avg += 128;
    }
    if (avg > 255) {
        avg = 255;
    } else if (avg < 0) {
        avg = 0;
    }
    const r = avg.toString(16);
    return `#${r}${r}${r}`;
    //('000000' + ((('0x' + color.slice(1)) ^ 0xFFFFFF).toString(16))).slice(-6);
};

/**
 * convert tag id to tag name for label
 * @param {string} id
 * @nosideeffect
 * @return {string}
 */
core_utils.tagToName = id => {
    const item = $scope[_bndProjectTags].$item && $scope[_bndProjectTags].$item(id);
    return (item && item.name) || '';
};

/**
 * Validates project tag
 * @param  {string} tag tag Id
 * @return {boolean}
 */
core_utils.validateProjectTag = function validateProjectTag(tag) {
    return !!$scope[_bndProjectTags].$item(tag);
};

core_utils.getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

core_utils.mkProjectUri = id =>
    `//${window.location.host}${_CONF_APPROOTURL}/${core.uriHandler.mkLink({
        id: _umProject,
        prId: id
    })}`;

core_utils.mkTaskUri = task =>
    `//${window.location.host}${_CONF_APPROOTURL}/${core.uriHandler.mkLink({
        id: _umProject,
        prId: task.projectId,
        tid: task.id,
        gid: task.groupId
    })}`;

core_utils.duration2Values = data =>
    ((data || '').match(/((\d+)(\w))/g) || []).reduce((obj, m) => {
        obj[m.split(/\d+/)[1]] = parseInt(m, 10);
        return obj;
    }, {});

// for users with ru/uk langs we show option to select them
// !((() => {
//     let blangs;

//     const clangs = Object.clone(
//         (blangs = window['browserLanguages'][0]
//             .split(',')
//             .map(d => d.substr(0, 2).toLowerCase())
//             .unique())
//     );

//     let lang;

//     do {
//         lang = clangs.shift();
//     } while (_gcSupportedLocales.indexOf(lang) == -1 && clangs.length);

//     if (!lang) {
//         lang = window['browserLanguages'][1];
//     }

//     for (let i = _gcHiddenLangs.length; --i >= 0; ) {
//         if (blangs.indexOf(_gcHiddenLangs[i]) > -1) {
//             core.showLangs = true;
//         }
//     }
// }))();

!window.console.time && (window.console.time = () => {});
!window.console.timeEnd && (window.console.timeEnd = () => {});

__DEV__ &&
    (() => {
        window['d3export'] = (obj, pref, res) => {
            let r;
            !res && ((res = {}), (r = []));
            for (const k in obj) {
                switch (true) {
                    case obj[k] instanceof Function:
                        const a = obj[k].toString().match(/function\s([\w\s]+)?\([^)]+\)/);
                        res[`${pref}.${k}`] = true; //a && a[0] || 'function ()';
                        break;
                    case obj[k] instanceof Object:
                        res[`${pref}.${k}`] = {};
                        window['d3export'](obj[k], `${pref}.${k}`, res);
                        break;
                }
            }

            if (r) {
                const nss = Object.keys(res).filter(k => Object.isObject(res[k])),
                    exp = Object.keys(res).subtract(nss),
                    rr = ['var d3'];

                Array.prototype.push.apply(
                    rr,
                    nss.map(k => `var ${k}`)
                );

                Array.prototype.push.apply(
                    rr,
                    exp.map(k => `${k} = function () {}`)
                );

                return rr.join(';\n');
                // return Object.keys(res).map(function (k) { return k + ' = ' + res[k] + ' {}' }).join('\n');
            }
        };
    })();

if (DEBUG) {
    $(document)
        .on('click', `.${_clsCenterBox}`, ev => {
            if (ev.altKey) {
                $('html').toggleClass('debug');
            }
        })
        .on('click', `.${_clsGlobalHeader}`, ev => {
            if (ev.altKey) {
                $('html').toggleClass('debug');
            }
        });
}

core_utils.loadScript = uri => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.addEventListener('load', () => {
            resolve();
        });
        script.addEventListener('error', () => {
            reject();
        });
        script.setAttribute('async', 'true');
        script.setAttribute('src', uri);
        document.head.appendChild(script);
    });
};

/**
 * pack LTA number of milliseconds to number of minutes since 2020.01.01 and convert to 36 bit string
 * @param {number} date
 * @return {string}
 */
core_utils.packLTA = date => (~~(date / 1000 / 60)).toString(36);
/**
 * unpack LTA
 * @param {string} data
 * @return {number}
 */
core_utils.unpackLTA = data => parseInt(data, 36) * 1000 * 60;
