var PROTOTYPING = true, stringify = JSON.stringify;
class cAbstractMessage extends cBasePrototype {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cAbstractMessage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAbstractMessage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAbstractMessage, resetDefaults);
        return this;
    }
}
$classes[_cAbstractMessage] = cAbstractMessage;

class cMessage extends cAbstractMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cMessage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cMessage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cMessage, resetDefaults);
        return this;
    }
}
$classes[_cMessage] = cMessage;

class cProtocolMessage extends cMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cProtocolMessage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProtocolMessage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProtocolMessage, resetDefaults);
        return this;
    }
}
$classes[_cProtocolMessage] = cProtocolMessage;

class cSessionMessage extends cProtocolMessage {
    static __fieldsDef = {
        sessionKey: [_dtpID,null,() => core.sessionId()]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.sessionKey = '';
        updateFields.call(this, obj, _cSessionMessage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSessionMessage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSessionMessage, resetDefaults);
        return this;
    }
}
$classes[_cSessionMessage] = cSessionMessage;

class cClientConfig extends cBasePrototype {
    static __fieldsDef = {
        config: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object} */
        this.config = {};
        updateFields.call(this, obj, _cClientConfig, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cClientConfig, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cClientConfig, resetDefaults);
        return this;
    }
}
$classes[_cClientConfig] = cClientConfig;

class cClientInfo extends cBasePrototype {
    static __fieldsDef = {
        clientKey: [_dtpString,null,null],
        clientName: [_dtpString,null,null],
        clientVersion: [_dtpString,null,null],
        clientId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.clientKey = '';
        /** @type {!string} */
        this.clientName = '';
        /** @type {!string} */
        this.clientVersion = '';
        /** @type {!string} */
        this.clientId = '';
        updateFields.call(this, obj, _cClientInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cClientInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cClientInfo, resetDefaults);
        return this;
    }
}
$classes[_cClientInfo] = cClientInfo;

class cLoginRequest extends cProtocolMessage {
    static __fieldsDef = {
        email: [_dtpString,null,null],
        password: [_dtpString,null,null],
        client: [_dtpClass | _dtpOptional,_cClientInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.email = '';
        /** @type {!string} */
        this.password = '';
        /** @type {?cClientInfo} */
        this.client = undefined;
        updateFields.call(this, obj, _cLoginRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cLoginRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cLoginRequest, resetDefaults);
        return this;
    }
}
$classes[_cLoginRequest] = cLoginRequest;

class cLoginResponse extends cSessionMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmLoginStatus,null],
        message: [_dtpString,null,null],
        language: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmLoginStatus} */
        this.status = undefined;
        /** @type {!string} */
        this.message = '';
        /** @type {?string} */
        this.language = '';
        updateFields.call(this, obj, _cLoginResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cLoginResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cLoginResponse, resetDefaults);
        return this;
    }
}
$classes[_cLoginResponse] = cLoginResponse;

class cLogoutRequest extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cLogoutRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cLogoutRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cLogoutRequest, resetDefaults);
        return this;
    }
}
$classes[_cLogoutRequest] = cLogoutRequest;

class cLogoutResponse extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cLogoutResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cLogoutResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cLogoutResponse, resetDefaults);
        return this;
    }
}
$classes[_cLogoutResponse] = cLogoutResponse;

class cErrorMessage extends cSessionMessage {
    static __fieldsDef = {
        error: [_dtpString,null,() => "OK"],
        message: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.error = '';
        /** @type {?string} */
        this.message = '';
        updateFields.call(this, obj, _cErrorMessage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cErrorMessage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cErrorMessage, resetDefaults);
        return this;
    }
}
$classes[_cErrorMessage] = cErrorMessage;

class cCheckSessionRequest extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cCheckSessionRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCheckSessionRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCheckSessionRequest, resetDefaults);
        return this;
    }
}
$classes[_cCheckSessionRequest] = cCheckSessionRequest;

class cInvalidateSessionsRequest extends cSessionMessage {
    static __fieldsDef = {
        keepCurrentSession: [_dtpBoolean | _dtpOptional,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {?boolean} */
        this.keepCurrentSession = false;
        updateFields.call(this, obj, _cInvalidateSessionsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cInvalidateSessionsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cInvalidateSessionsRequest, resetDefaults);
        return this;
    }
}
$classes[_cInvalidateSessionsRequest] = cInvalidateSessionsRequest;

class cDirectRegistrationRequest extends cProtocolMessage {
    static __fieldsDef = {
        email: [_dtpString,null,null],
        subscriptionPlan: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.email = '';
        /** @type {?string} */
        this.subscriptionPlan = '';
        updateFields.call(this, obj, _cDirectRegistrationRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cDirectRegistrationRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cDirectRegistrationRequest, resetDefaults);
        return this;
    }
}
$classes[_cDirectRegistrationRequest] = cDirectRegistrationRequest;

class cContinueRegistrationRequest extends cProtocolMessage {
    static __fieldsDef = {
        registrationId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.registrationId = '';
        updateFields.call(this, obj, _cContinueRegistrationRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cContinueRegistrationRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cContinueRegistrationRequest, resetDefaults);
        return this;
    }
}
$classes[_cContinueRegistrationRequest] = cContinueRegistrationRequest;

class cCompleteRegistrationRequest extends cProtocolMessage {
    static __fieldsDef = {
        registrationId: [_dtpID,null,null],
        password: [_dtpString,null,null],
        firstName: [_dtpString,null,null],
        middleName: [_dtpString | _dtpOptional,null,null],
        lastName: [_dtpString,null,null],
        companyName: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.registrationId = '';
        /** @type {!string} */
        this.password = '';
        /** @type {!string} */
        this.firstName = '';
        /** @type {?string} */
        this.middleName = '';
        /** @type {!string} */
        this.lastName = '';
        /** @type {?string} */
        this.companyName = '';
        updateFields.call(this, obj, _cCompleteRegistrationRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCompleteRegistrationRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCompleteRegistrationRequest, resetDefaults);
        return this;
    }
}
$classes[_cCompleteRegistrationRequest] = cCompleteRegistrationRequest;

class cRegistrationResponse extends cSessionMessage {
    static __fieldsDef = {
        source: [_dtpEnum,enmRegistrationResponseSource,null],
        status: [_dtpEnum,enmRegistrationResponseStatus,null],
        userId: [_dtpID,null,null],
        userInfo: [_dtpClass,_cUserInfo,null],
        registrationId: [_dtpID,null,null],
        message: [_dtpString,null,null],
        destination: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmRegistrationResponseSource} */
        this.source = undefined;
        /** @type {!enmRegistrationResponseStatus} */
        this.status = undefined;
        /** @type {!string} */
        this.userId = '';
        /** @type {!cUserInfo} */
        this.userInfo = undefined;
        /** @type {!string} */
        this.registrationId = '';
        /** @type {!string} */
        this.message = '';
        /** @type {!string} */
        this.destination = '';
        updateFields.call(this, obj, _cRegistrationResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRegistrationResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRegistrationResponse, resetDefaults);
        return this;
    }
}
$classes[_cRegistrationResponse] = cRegistrationResponse;

class cVerifyEmailTokenRequest extends cSessionMessage {
    static __fieldsDef = {
        token: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.token = '';
        updateFields.call(this, obj, _cVerifyEmailTokenRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cVerifyEmailTokenRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cVerifyEmailTokenRequest, resetDefaults);
        return this;
    }
}
$classes[_cVerifyEmailTokenRequest] = cVerifyEmailTokenRequest;

class cVerifyEmailTokenResponse extends cSessionMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmVerifyEmailTokenResponseStatus,null],
        cause: [_dtpEnum,enmVerifyEmailTokenResponseCause,null],
        registrationResponse: [_dtpClass,_cRegistrationResponse,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmVerifyEmailTokenResponseStatus} */
        this.status = undefined;
        /** @type {!enmVerifyEmailTokenResponseCause} */
        this.cause = undefined;
        /** @type {!cRegistrationResponse} */
        this.registrationResponse = undefined;
        updateFields.call(this, obj, _cVerifyEmailTokenResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cVerifyEmailTokenResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cVerifyEmailTokenResponse, resetDefaults);
        return this;
    }
}
$classes[_cVerifyEmailTokenResponse] = cVerifyEmailTokenResponse;

class cResendEmailVerificationRequest extends cProtocolMessage {
    static __fieldsDef = {
        email: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.email = '';
        updateFields.call(this, obj, _cResendEmailVerificationRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cResendEmailVerificationRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cResendEmailVerificationRequest, resetDefaults);
        return this;
    }
}
$classes[_cResendEmailVerificationRequest] = cResendEmailVerificationRequest;

class cResendEmailVerificationResponse extends cProtocolMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmResendEmailVerificationResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmResendEmailVerificationResponseStatus} */
        this.status = undefined;
        updateFields.call(this, obj, _cResendEmailVerificationResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cResendEmailVerificationResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cResendEmailVerificationResponse, resetDefaults);
        return this;
    }
}
$classes[_cResendEmailVerificationResponse] = cResendEmailVerificationResponse;

class cChangePasswordRequest extends cSessionMessage {
    static __fieldsDef = {
        oldPassword: [_dtpString,null,null],
        newPassword: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.oldPassword = '';
        /** @type {!string} */
        this.newPassword = '';
        updateFields.call(this, obj, _cChangePasswordRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cChangePasswordRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cChangePasswordRequest, resetDefaults);
        return this;
    }
}
$classes[_cChangePasswordRequest] = cChangePasswordRequest;

class cValidatePasswordRequest extends cSessionMessage {
    static __fieldsDef = {
        password: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.password = '';
        updateFields.call(this, obj, _cValidatePasswordRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cValidatePasswordRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cValidatePasswordRequest, resetDefaults);
        return this;
    }
}
$classes[_cValidatePasswordRequest] = cValidatePasswordRequest;

class cRemoveUserAccountRequest extends cSessionMessage {
    static __fieldsDef = {
        password: [_dtpString,null,null],
        feedbackMessage: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.password = '';
        /** @type {?string} */
        this.feedbackMessage = '';
        updateFields.call(this, obj, _cRemoveUserAccountRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveUserAccountRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveUserAccountRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveUserAccountRequest] = cRemoveUserAccountRequest;

class cPasswordRecoveryRequest extends cProtocolMessage {
    static __fieldsDef = {
        email: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.email = '';
        updateFields.call(this, obj, _cPasswordRecoveryRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cPasswordRecoveryRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cPasswordRecoveryRequest, resetDefaults);
        return this;
    }
}
$classes[_cPasswordRecoveryRequest] = cPasswordRecoveryRequest;

class cPasswordRecoveryStep2Request extends cProtocolMessage {
    static __fieldsDef = {
        password: [_dtpString,null,null],
        token: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.password = '';
        /** @type {!string} */
        this.token = '';
        updateFields.call(this, obj, _cPasswordRecoveryStep2Request, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cPasswordRecoveryStep2Request, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cPasswordRecoveryStep2Request, resetDefaults);
        return this;
    }
}
$classes[_cPasswordRecoveryStep2Request] = cPasswordRecoveryStep2Request;

class cPasswordRecoveryResponse extends cProtocolMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmPasswordRecoveryResponseStatus,null],
        message: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmPasswordRecoveryResponseStatus} */
        this.status = undefined;
        /** @type {!string} */
        this.message = '';
        updateFields.call(this, obj, _cPasswordRecoveryResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cPasswordRecoveryResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cPasswordRecoveryResponse, resetDefaults);
        return this;
    }
}
$classes[_cPasswordRecoveryResponse] = cPasswordRecoveryResponse;

class cUserOriginInfo extends cMessage {
    static __fieldsDef = {
        source: [_dtpString,null,null],
        invitedToWorkspaceId: [_dtpID,null,null],
        invitedByWorkspaceUserId: [_dtpID,null,null],
        invitedByUserId: [_dtpID,null,null],
        timeCreated: [_dtpTimestamp,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.source = '';
        /** @type {!string} */
        this.invitedToWorkspaceId = '';
        /** @type {!string} */
        this.invitedByWorkspaceUserId = '';
        /** @type {!string} */
        this.invitedByUserId = '';
        /** @type {!number} */
        this.timeCreated = 0;
        updateFields.call(this, obj, _cUserOriginInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserOriginInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserOriginInfo, resetDefaults);
        return this;
    }
}
$classes[_cUserOriginInfo] = cUserOriginInfo;

class cUserInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        firstName: [_dtpString,null,null],
        middleName: [_dtpString,null,null],
        lastName: [_dtpString,null,null],
        language: [_dtpString,null,null],
        primaryContactId: [_dtpID,null,null],
        contacts: [_dtpArray | _dtpClass,_cContactInfoInfo,null],
        imageId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.firstName = '';
        /** @type {!string} */
        this.middleName = '';
        /** @type {!string} */
        this.lastName = '';
        /** @type {!string} */
        this.language = '';
        /** @type {!string} */
        this.primaryContactId = '';
        /** @type {!Array.<cContactInfoInfo>} */
        this.contacts = [];
        /** @type {!string} */
        this.imageId = '';
        updateFields.call(this, obj, _cUserInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserInfo, resetDefaults);
        return this;
    }
}
$classes[_cUserInfo] = cUserInfo;

class cUserInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        details: [_dtpArray,null,() => ['ContactInfo','WorkspaceUsers']]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array} */
        this.details = [];
        updateFields.call(this, obj, _cUserInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cUserInfoRequest] = cUserInfoRequest;

class cUserInfoResponse extends cSessionMessage {
    static __fieldsDef = {
        user: [_dtpClass,_cUserInfo,null],
        timeCreated: [_dtpTimestamp,null,null],
        dailyDigest: [_dtpBoolean,null,null],
        intercomHash: [_dtpString | _dtpOptional,null,null],
        ownedWorkspaces: [_dtpNumber,null,null],
        userOrigin: [_dtpClass | _dtpOptional,_cUserOriginInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cUserInfo} */
        this.user = undefined;
        /** @type {!number} */
        this.timeCreated = 0;
        /** @type {!boolean} */
        this.dailyDigest = false;
        /** @type {?string} */
        this.intercomHash = '';
        /** @type {!number} */
        this.ownedWorkspaces = 0;
        /** @type {?cUserOriginInfo} */
        this.userOrigin = undefined;
        updateFields.call(this, obj, _cUserInfoResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserInfoResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserInfoResponse, resetDefaults);
        return this;
    }
}
$classes[_cUserInfoResponse] = cUserInfoResponse;

class cListUserPropertiesRequest extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cListUserPropertiesRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListUserPropertiesRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListUserPropertiesRequest, resetDefaults);
        return this;
    }
}
$classes[_cListUserPropertiesRequest] = cListUserPropertiesRequest;

class cUserPropertiesResponse extends cSessionMessage {
    static __fieldsDef = {
        properties: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object} */
        this.properties = {};
        updateFields.call(this, obj, _cUserPropertiesResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserPropertiesResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserPropertiesResponse, resetDefaults);
        return this;
    }
}
$classes[_cUserPropertiesResponse] = cUserPropertiesResponse;

class cSetUserPropertiesRequest extends cSessionMessage {
    static __fieldsDef = {
        properties: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object} */
        this.properties = {};
        updateFields.call(this, obj, _cSetUserPropertiesRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSetUserPropertiesRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSetUserPropertiesRequest, resetDefaults);
        return this;
    }
}
$classes[_cSetUserPropertiesRequest] = cSetUserPropertiesRequest;

class cRemoveUserPropertiesRequest extends cSessionMessage {
    static __fieldsDef = {
        keys: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array} */
        this.keys = [];
        updateFields.call(this, obj, _cRemoveUserPropertiesRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveUserPropertiesRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveUserPropertiesRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveUserPropertiesRequest] = cRemoveUserPropertiesRequest;

class cUserPropertiesDoneResponse extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cUserPropertiesDoneResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserPropertiesDoneResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserPropertiesDoneResponse, resetDefaults);
        return this;
    }
}
$classes[_cUserPropertiesDoneResponse] = cUserPropertiesDoneResponse;

class cUpdateUserInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        userId: [_dtpID,null,null],
        firstName: [_dtpString | _dtpOptional,null,null],
        middleName: [_dtpString | _dtpOptional,null,null],
        lastName: [_dtpString | _dtpOptional,null,null],
        language: [_dtpString | _dtpOptional,null,null],
        updateWorkspaceUsers: [_dtpArray | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.userId = '';
        /** @type {?string} */
        this.firstName = '';
        /** @type {?string} */
        this.middleName = '';
        /** @type {?string} */
        this.lastName = '';
        /** @type {?string} */
        this.language = '';
        /** @type {?Array} */
        this.updateWorkspaceUsers = [];
        updateFields.call(this, obj, _cUpdateUserInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateUserInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateUserInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateUserInfoRequest] = cUpdateUserInfoRequest;

class cProcessUploadedPictureRequest extends cSessionMessage {
    static __fieldsDef = {
        fileId: [_dtpID,null,null],
        target: [_dtpString,null,null],
        targetId: [_dtpID,null,null],
        x: [_dtpNumber,null,null],
        y: [_dtpNumber,null,null],
        w: [_dtpNumber,null,null],
        h: [_dtpNumber,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.fileId = '';
        /** @type {!string} */
        this.target = '';
        /** @type {!string} */
        this.targetId = '';
        /** @type {!number} */
        this.x = 0;
        /** @type {!number} */
        this.y = 0;
        /** @type {!number} */
        this.w = 0;
        /** @type {!number} */
        this.h = 0;
        updateFields.call(this, obj, _cProcessUploadedPictureRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProcessUploadedPictureRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProcessUploadedPictureRequest, resetDefaults);
        return this;
    }
}
$classes[_cProcessUploadedPictureRequest] = cProcessUploadedPictureRequest;

class cRemovePictureRequest extends cSessionMessage {
    static __fieldsDef = {
        fileId: [_dtpID,null,null],
        target: [_dtpString,null,null],
        targetId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.fileId = '';
        /** @type {!string} */
        this.target = '';
        /** @type {!string} */
        this.targetId = '';
        updateFields.call(this, obj, _cRemovePictureRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemovePictureRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemovePictureRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemovePictureRequest] = cRemovePictureRequest;

class cSetPrimaryContactInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        contactInfoId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.contactInfoId = '';
        updateFields.call(this, obj, _cSetPrimaryContactInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSetPrimaryContactInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSetPrimaryContactInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cSetPrimaryContactInfoRequest] = cSetPrimaryContactInfoRequest;

class cGetUserAlertsSettingsRequest extends cSessionMessage {
    static __fieldsDef = {
        mailId: [_dtpID | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {?string} */
        this.mailId = '';
        updateFields.call(this, obj, _cGetUserAlertsSettingsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cGetUserAlertsSettingsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cGetUserAlertsSettingsRequest, resetDefaults);
        return this;
    }
}
$classes[_cGetUserAlertsSettingsRequest] = cGetUserAlertsSettingsRequest;

class cGetUserAlertsSettingsResponse extends cSessionMessage {
    static __fieldsDef = {
        settings: [_dtpClass,_cAlertsSettings,null],
        defaults: [_dtpClass,_cAlertsSettings,null],
        email: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cAlertsSettings} */
        this.settings = undefined;
        /** @type {!cAlertsSettings} */
        this.defaults = undefined;
        /** @type {?string} */
        this.email = '';
        updateFields.call(this, obj, _cGetUserAlertsSettingsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cGetUserAlertsSettingsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cGetUserAlertsSettingsResponse, resetDefaults);
        return this;
    }
}
$classes[_cGetUserAlertsSettingsResponse] = cGetUserAlertsSettingsResponse;

class cUpdateUserAlertsSettingsRequest extends cSessionMessage {
    static __fieldsDef = {
        mailId: [_dtpID,null,null],
        settings: [_dtpClass,_cAlertsSettings,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.mailId = '';
        /** @type {!cAlertsSettings} */
        this.settings = undefined;
        updateFields.call(this, obj, _cUpdateUserAlertsSettingsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateUserAlertsSettingsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateUserAlertsSettingsRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateUserAlertsSettingsRequest] = cUpdateUserAlertsSettingsRequest;

class cAlertsSettings extends cAbstractMessage {
    static __fieldsDef = {
        sendDigest: [_dtpBoolean,null,null],
        all: [_dtpArray | _dtpClass,_cAlertSetting,null],
        values: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!boolean} */
        this.sendDigest = false;
        /** @type {!Array.<cAlertSetting>} */
        this.all = [];
        /** @type {!Object} */
        this.values = {};
        updateFields.call(this, obj, _cAlertsSettings, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAlertsSettings, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAlertsSettings, resetDefaults);
        return this;
    }
}
$classes[_cAlertsSettings] = cAlertsSettings;

class cAlertSetting extends cAbstractMessage {
    static __fieldsDef = {
        name: [_dtpString,null,null],
        options: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        /** @type {!Array} */
        this.options = [];
        updateFields.call(this, obj, _cAlertSetting, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAlertSetting, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAlertSetting, resetDefaults);
        return this;
    }
}
$classes[_cAlertSetting] = cAlertSetting;

class cContactInfoInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        ownerId: [_dtpID,null,null],
        type: [_dtpString,null,null],
        value: [_dtpString,null,null],
        status: [_dtpEnum,enmContactInfoInfoStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.ownerId = '';
        /** @type {!string} */
        this.type = '';
        /** @type {!string} */
        this.value = '';
        /** @type {!enmContactInfoInfoStatus} */
        this.status = undefined;
        updateFields.call(this, obj, _cContactInfoInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cContactInfoInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cContactInfoInfo, resetDefaults);
        return this;
    }
}
$classes[_cContactInfoInfo] = cContactInfoInfo;

class cWorkspaceInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        subscriptionId_: [_dtpID | _dtpInternal,null,null],
        workspaceUser: [_dtpClass | _dtpOptional,_cWorkspaceUserInfo,null],
        workspaceOwner: [_dtpClass,_cWorkspaceUserInfo,null],
        projects: [_dtpArray | _dtpClass | _dtpOptional,_cProjectInfo,null],
        standardTeams: [_dtpArray | _dtpClass | _dtpOptional,_cStandardTeamInfo,null],
        projectStats: [_dtpMap | _dtpAnyType | _dtpOptional,null,null],
        imageId: [_dtpID,null,null],
        workspaceLimits: [_dtpClass | _dtpOptional,_cWorkspaceLimitsInfo,null],
        settings: [_dtpClass | _dtpOptional,_cWorkspaceSettingsInfo,null],
        resourceUsage: [_dtpClass | _dtpOptional,_cWorkspaceUsageInfo,null],
        writable: [_dtpBoolean | _dtpOptional,null,() => true],
        createdAt: [_dtpTimestamp | _dtpOptional,null,null],
        categories: [_dtpArray | _dtpClass | _dtpOptional,_cProjectTagInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.subscriptionId_ = '';
        /** @type {?cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceOwner = undefined;
        /** @type {?Array.<cProjectInfo>} */
        this.projects = [];
        /** @type {?Array.<cStandardTeamInfo>} */
        this.standardTeams = [];
        /** @type {?Object.<string, *>} */
        this.projectStats = {};
        /** @type {!string} */
        this.imageId = '';
        /** @type {?cWorkspaceLimitsInfo} */
        this.workspaceLimits = undefined;
        /** @type {?cWorkspaceSettingsInfo} */
        this.settings = undefined;
        /** @type {?cWorkspaceUsageInfo} */
        this.resourceUsage = undefined;
        /** @type {?boolean} */
        this.writable = false;
        /** @type {?number} */
        this.createdAt = 0;
        /** @type {?Array.<cProjectTagInfo>} */
        this.categories = [];
        updateFields.call(this, obj, _cWorkspaceInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceInfo, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceInfo] = cWorkspaceInfo;

class cWorkspaceLimitsInfo extends cProtocolMessage {
    static __fieldsDef = {
        enabled: [_dtpBoolean,null,null],
        limits: [_dtpObject,null,null],
        features: [_dtpArray,null,null],
        subscriptionId: [_dtpString | _dtpOptional,null,null],
        inTrial: [_dtpBoolean | _dtpOptional,null,null],
        trialEnds: [_dtpTimestamp | _dtpOptional,null,null],
        currentTermEnds: [_dtpTimestamp | _dtpOptional,null,null],
        cardPresent: [_dtpBoolean | _dtpOptional,null,null],
        productCode: [_dtpString | _dtpOptional,null,null],
        productTitle: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!boolean} */
        this.enabled = false;
        /** @type {!Object} */
        this.limits = {};
        /** @type {!Array} */
        this.features = [];
        /** @type {?string} */
        this.subscriptionId = '';
        /** @type {?boolean} */
        this.inTrial = false;
        /** @type {?number} */
        this.trialEnds = 0;
        /** @type {?number} */
        this.currentTermEnds = 0;
        /** @type {?boolean} */
        this.cardPresent = false;
        /** @type {?string} */
        this.productCode = '';
        /** @type {?string} */
        this.productTitle = '';
        updateFields.call(this, obj, _cWorkspaceLimitsInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceLimitsInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceLimitsInfo, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceLimitsInfo] = cWorkspaceLimitsInfo;

class cWorkspaceSettingsInfo extends cProtocolMessage {
    static __fieldsDef = {
        timeZone: [_dtpString,null,null],
        digestHour: [_dtpNumber,null,null],
        firstDayOfWeek: [_dtpNumber,null,null],
        timeFormat: [_dtpString,null,null],
        shortDateFormat: [_dtpString,null,null],
        fullDateFormat: [_dtpString,null,null],
        schedule: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.timeZone = '';
        /** @type {!number} */
        this.digestHour = 0;
        /** @type {!number} */
        this.firstDayOfWeek = 0;
        /** @type {!string} */
        this.timeFormat = '';
        /** @type {!string} */
        this.shortDateFormat = '';
        /** @type {!string} */
        this.fullDateFormat = '';
        /** @type {!string} */
        this.schedule = '';
        updateFields.call(this, obj, _cWorkspaceSettingsInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceSettingsInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceSettingsInfo, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceSettingsInfo] = cWorkspaceSettingsInfo;

class cWorkspaceUsageInfo extends cProtocolMessage {
    static __fieldsDef = {
        count: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object} */
        this.count = {};
        updateFields.call(this, obj, _cWorkspaceUsageInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceUsageInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceUsageInfo, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceUsageInfo] = cWorkspaceUsageInfo;

class cWorkspaceInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        details: [_dtpArray | _dtpOptional,null,() => enmWorkspaceInfoRequestDetails.values()]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?Array} */
        this.details = [];
        updateFields.call(this, obj, _cWorkspaceInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceInfoRequest] = cWorkspaceInfoRequest;

class cWorkspaceUserInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        workspaceId: [_dtpID,null,null],
        firstName: [_dtpString,null,null],
        middleName: [_dtpString,null,null],
        lastName: [_dtpString,null,null],
        imageId: [_dtpID,null,null],
        role: [_dtpEnum,enmWorkspaceUserRole,null],
        status: [_dtpEnum,enmWorkspaceUserStatus,() => enmWorkspaceUserStatus.Confirmed],
        contactInfoId: [_dtpID | _dtpOptional,null,null],
        image_: [_dtpString | _dtpInternal,null,null],
        wsrole: [_dtpEnum | _dtpInternal,enmWorkspaceUserRole,null],
        projectId: [_dtpID | _dtpInternal,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.firstName = '';
        /** @type {!string} */
        this.middleName = '';
        /** @type {!string} */
        this.lastName = '';
        /** @type {!string} */
        this.imageId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.role = undefined;
        /** @type {!enmWorkspaceUserStatus} */
        this.status = undefined;
        /** @type {?string} */
        this.contactInfoId = '';
        /** @type {!string} */
        this.image_ = '';
        /** @type {!enmWorkspaceUserRole} */
        this.wsrole = undefined;
        /** @type {!string} */
        this.projectId = '';
        updateFields.call(this, obj, _cWorkspaceUserInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceUserInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceUserInfo, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceUserInfo] = cWorkspaceUserInfo;

class cCreateWorkspaceRequest extends cSessionMessage {
    static __fieldsDef = {
        name: [_dtpString,null,null],
        contactInfoId: [_dtpID | _dtpOptional,null,null],
        description: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        /** @type {?string} */
        this.contactInfoId = '';
        /** @type {!string} */
        this.description = '';
        updateFields.call(this, obj, _cCreateWorkspaceRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateWorkspaceRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateWorkspaceRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateWorkspaceRequest] = cCreateWorkspaceRequest;

class cCreateWorkspaceResponse extends cSessionMessage {
    static __fieldsDef = {
        workspace: [_dtpClass,_cWorkspaceInfo,null],
        billingStatus: [_dtpEnum,enmBillingResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cWorkspaceInfo} */
        this.workspace = undefined;
        /** @type {!enmBillingResponseStatus} */
        this.billingStatus = undefined;
        updateFields.call(this, obj, _cCreateWorkspaceResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateWorkspaceResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateWorkspaceResponse, resetDefaults);
        return this;
    }
}
$classes[_cCreateWorkspaceResponse] = cCreateWorkspaceResponse;

class cUpdateWorkspaceRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        updateFields.call(this, obj, _cUpdateWorkspaceRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateWorkspaceRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateWorkspaceRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateWorkspaceRequest] = cUpdateWorkspaceRequest;

class cUpdateWorkspaceSettingsRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        settings: [_dtpClass,_cWorkspaceSettingsInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!cWorkspaceSettingsInfo} */
        this.settings = undefined;
        updateFields.call(this, obj, _cUpdateWorkspaceSettingsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateWorkspaceSettingsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateWorkspaceSettingsRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateWorkspaceSettingsRequest] = cUpdateWorkspaceSettingsRequest;

class cRemoveWorkspaceRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        password: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.password = '';
        updateFields.call(this, obj, _cRemoveWorkspaceRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveWorkspaceRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveWorkspaceRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveWorkspaceRequest] = cRemoveWorkspaceRequest;

class cWorkspaceStatusResponse extends cSessionMessage {
    static __fieldsDef = {
        workspace: [_dtpClass,_cWorkspaceInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cWorkspaceInfo} */
        this.workspace = undefined;
        updateFields.call(this, obj, _cWorkspaceStatusResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceStatusResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceStatusResponse, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceStatusResponse] = cWorkspaceStatusResponse;

class cListWorkspacesRequest extends cSessionMessage {
    static __fieldsDef = {
        contents: [_dtpEnum,enmListWorkspacesContents,() => enmListWorkspacesContents.All],
        details: [_dtpArray | _dtpAnyType,null,() => enmWorkspaceInfoRequestDetails.values()]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmListWorkspacesContents} */
        this.contents = undefined;
        /** @type {!Array.<*>} */
        this.details = [];
        updateFields.call(this, obj, _cListWorkspacesRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListWorkspacesRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListWorkspacesRequest, resetDefaults);
        return this;
    }
}
$classes[_cListWorkspacesRequest] = cListWorkspacesRequest;

class cListOfWorkspacesResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaces: [_dtpArray | _dtpClass,_cWorkspaceInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cWorkspaceInfo>} */
        this.workspaces = [];
        updateFields.call(this, obj, _cListOfWorkspacesResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfWorkspacesResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfWorkspacesResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfWorkspacesResponse] = cListOfWorkspacesResponse;

class cTransferOwnershipRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        newOwnerId: [_dtpID,null,null],
        removeCard: [_dtpBoolean | _dtpOptional,null,null],
        password: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.newOwnerId = '';
        /** @type {?boolean} */
        this.removeCard = false;
        /** @type {!string} */
        this.password = '';
        updateFields.call(this, obj, _cTransferOwnershipRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTransferOwnershipRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTransferOwnershipRequest, resetDefaults);
        return this;
    }
}
$classes[_cTransferOwnershipRequest] = cTransferOwnershipRequest;

class cWorkspaceUserInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUserId: [_dtpID | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cWorkspaceUserInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceUserInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceUserInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceUserInfoRequest] = cWorkspaceUserInfoRequest;

class cWorkspaceUserInfoResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceUser: [_dtpClass,_cWorkspaceUserInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        updateFields.call(this, obj, _cWorkspaceUserInfoResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceUserInfoResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceUserInfoResponse, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceUserInfoResponse] = cWorkspaceUserInfoResponse;

class cWorkspaceUserProfileRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cWorkspaceUserProfileRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceUserProfileRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceUserProfileRequest, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceUserProfileRequest] = cWorkspaceUserProfileRequest;

class cWorkspaceUserProfileResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceUser: [_dtpAnyType,null,null],
        email: [_dtpString,null,null],
        projects: [_dtpArray | _dtpClass,_cProjectInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!*} */
        this.workspaceUser = undefined;
        /** @type {!string} */
        this.email = '';
        /** @type {!Array.<cProjectInfo>} */
        this.projects = [];
        updateFields.call(this, obj, _cWorkspaceUserProfileResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceUserProfileResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceUserProfileResponse, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceUserProfileResponse] = cWorkspaceUserProfileResponse;

class cUpdateWorkspaceUserInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUser: [_dtpClass,_cWorkspaceUserInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        updateFields.call(this, obj, _cUpdateWorkspaceUserInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateWorkspaceUserInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateWorkspaceUserInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateWorkspaceUserInfoRequest] = cUpdateWorkspaceUserInfoRequest;

class cUpdateWorkspaceUserInfoResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceUser: [_dtpClass,_cWorkspaceUserInfo,null],
        billingStatus: [_dtpEnum,enmBillingResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        /** @type {!enmBillingResponseStatus} */
        this.billingStatus = undefined;
        updateFields.call(this, obj, _cUpdateWorkspaceUserInfoResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateWorkspaceUserInfoResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateWorkspaceUserInfoResponse, resetDefaults);
        return this;
    }
}
$classes[_cUpdateWorkspaceUserInfoResponse] = cUpdateWorkspaceUserInfoResponse;

class cWorkspaceMembersMessage extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cWorkspaceMembersMessage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceMembersMessage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceMembersMessage, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceMembersMessage] = cWorkspaceMembersMessage;

class cResendWorkspaceInvitationRequest extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cResendWorkspaceInvitationRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cResendWorkspaceInvitationRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cResendWorkspaceInvitationRequest, resetDefaults);
        return this;
    }
}
$classes[_cResendWorkspaceInvitationRequest] = cResendWorkspaceInvitationRequest;

class cResendWorkspaceInvitationResponse extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        result: [_dtpEnum,enmResendWorkspaceInvitationResult,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmResendWorkspaceInvitationResult} */
        this.result = undefined;
        updateFields.call(this, obj, _cResendWorkspaceInvitationResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cResendWorkspaceInvitationResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cResendWorkspaceInvitationResponse, resetDefaults);
        return this;
    }
}
$classes[_cResendWorkspaceInvitationResponse] = cResendWorkspaceInvitationResponse;

class cMessageToProject extends cSessionMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,() => $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        updateFields.call(this, obj, _cMessageToProject, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cMessageToProject, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cMessageToProject, resetDefaults);
        return this;
    }
}
$classes[_cMessageToProject] = cMessageToProject;

class cProjectInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        workspaceId: [_dtpID,null,null],
        callerRole: [_dtpEnum | _dtpOptional,enmWorkspaceUserRole,null],
        imageId: [_dtpString | _dtpOptional,null,null],
        status: [_dtpEnum,enmProjectStatus,null],
        writable: [_dtpBoolean,null,null],
        startDate: [_dtpTimestamp | _dtpOptional,null,null],
        tagId: [_dtpID | _dtpOptional,null,null],
        tag2Id: [_dtpID | _dtpOptional,null,null],
        stats_: [_dtpObject | _dtpInternal,null,() => {}],
        lta_: [_dtpTimestamp | _dtpInternal,null,() => 0],
        createdAt: [_dtpTimestamp,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?enmWorkspaceUserRole} */
        this.callerRole = undefined;
        /** @type {?string} */
        this.imageId = '';
        /** @type {!enmProjectStatus} */
        this.status = undefined;
        /** @type {!boolean} */
        this.writable = false;
        /** @type {?number} */
        this.startDate = 0;
        /** @type {?string} */
        this.tagId = '';
        /** @type {?string} */
        this.tag2Id = '';
        /** @type {!Object} */
        this.stats_ = {};
        /** @type {!number} */
        this.lta_ = 0;
        /** @type {!number} */
        this.createdAt = 0;
        updateFields.call(this, obj, _cProjectInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectInfo, resetDefaults);
        return this;
    }
}
$classes[_cProjectInfo] = cProjectInfo;

class cProjectInfoBundleRequest extends cMessageToProject {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cProjectInfoBundleRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectInfoBundleRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectInfoBundleRequest, resetDefaults);
        return this;
    }
}
$classes[_cProjectInfoBundleRequest] = cProjectInfoBundleRequest;

class cProjectInfoBundle extends cSessionMessage {
    static __fieldsDef = {
        project: [_dtpClass,_cProjectInfo,null],
        teamsAndMembers: [_dtpClass,_cProjectTeamsAndMembers,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cProjectInfo} */
        this.project = undefined;
        /** @type {!cProjectTeamsAndMembers} */
        this.teamsAndMembers = undefined;
        updateFields.call(this, obj, _cProjectInfoBundle, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectInfoBundle, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectInfoBundle, resetDefaults);
        return this;
    }
}
$classes[_cProjectInfoBundle] = cProjectInfoBundle;

class cCreateProjectRequest extends cSessionMessage {
    static __fieldsDef = {
        name: [_dtpString,null,null],
        description: [_dtpString | _dtpOptional,null,null],
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        startDate: [_dtpTimestamp | _dtpOptional,null,null],
        tagId: [_dtpID | _dtpOptional,null,null],
        tag2Id: [_dtpID | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        /** @type {?string} */
        this.description = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?number} */
        this.startDate = 0;
        /** @type {?string} */
        this.tagId = '';
        /** @type {?string} */
        this.tag2Id = '';
        updateFields.call(this, obj, _cCreateProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateProjectRequest] = cCreateProjectRequest;

class cCreateProjectResponse extends cSessionMessage {
    static __fieldsDef = {
        project: [_dtpClass,_cProjectInfo,null],
        billingStatus: [_dtpEnum,enmBillingResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cProjectInfo} */
        this.project = undefined;
        /** @type {!enmBillingResponseStatus} */
        this.billingStatus = undefined;
        updateFields.call(this, obj, _cCreateProjectResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateProjectResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateProjectResponse, resetDefaults);
        return this;
    }
}
$classes[_cCreateProjectResponse] = cCreateProjectResponse;

class cUpdateProjectRequest extends cSessionMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString | _dtpOptional,null,null],
        startDate: [_dtpTimestamp | _dtpOptional,null,null],
        tagId: [_dtpID,null,null],
        tag2Id: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {?string} */
        this.description = '';
        /** @type {?number} */
        this.startDate = 0;
        /** @type {!string} */
        this.tagId = '';
        /** @type {!string} */
        this.tag2Id = '';
        updateFields.call(this, obj, _cUpdateProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateProjectRequest] = cUpdateProjectRequest;

class cProjectStateRequest extends cMessageToProject {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cProjectStateRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectStateRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectStateRequest, resetDefaults);
        return this;
    }
}
$classes[_cProjectStateRequest] = cProjectStateRequest;

class cChangeProjectStateRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projectId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        updateFields.call(this, obj, _cChangeProjectStateRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cChangeProjectStateRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cChangeProjectStateRequest, resetDefaults);
        return this;
    }
}
$classes[_cChangeProjectStateRequest] = cChangeProjectStateRequest;

class cRemoveProjectRequest extends cChangeProjectStateRequest {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cRemoveProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveProjectRequest] = cRemoveProjectRequest;

class cArchiveProjectRequest extends cChangeProjectStateRequest {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cArchiveProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cArchiveProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cArchiveProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cArchiveProjectRequest] = cArchiveProjectRequest;

class cReactivateProjectRequest extends cChangeProjectStateRequest {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cReactivateProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cReactivateProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cReactivateProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cReactivateProjectRequest] = cReactivateProjectRequest;

class cPurgeRemovedProjectsRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectIds: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!Array} */
        this.projectIds = [];
        updateFields.call(this, obj, _cPurgeRemovedProjectsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cPurgeRemovedProjectsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cPurgeRemovedProjectsRequest, resetDefaults);
        return this;
    }
}
$classes[_cPurgeRemovedProjectsRequest] = cPurgeRemovedProjectsRequest;

class cListProjectsPagingRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        withStatus: [_dtpAnyType | _dtpOptional,null,null],
        sendStats: [_dtpBoolean | _dtpOptional,null,null],
        sort: [_dtpAnyType,null,() => enmProjectsSort.Name],
        desc: [_dtpBoolean,null,() => false],
        limit: [_dtpNumber,null,() => 10],
        offset: [_dtpNumber,null,() => 0]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?*} */
        this.withStatus = undefined;
        /** @type {?boolean} */
        this.sendStats = false;
        /** @type {!*} */
        this.sort = undefined;
        /** @type {!boolean} */
        this.desc = false;
        /** @type {!number} */
        this.limit = 0;
        /** @type {!number} */
        this.offset = 0;
        updateFields.call(this, obj, _cListProjectsPagingRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListProjectsPagingRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListProjectsPagingRequest, resetDefaults);
        return this;
    }
}
$classes[_cListProjectsPagingRequest] = cListProjectsPagingRequest;

class cListOfProjectsPagingResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projects: [_dtpArray | _dtpClass,_cProjectInfo,null],
        offset: [_dtpNumber,null,null],
        total: [_dtpNumber,null,() => 0]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!Array.<cProjectInfo>} */
        this.projects = [];
        /** @type {!number} */
        this.offset = 0;
        /** @type {!number} */
        this.total = 0;
        updateFields.call(this, obj, _cListOfProjectsPagingResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfProjectsPagingResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfProjectsPagingResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfProjectsPagingResponse] = cListOfProjectsPagingResponse;

class cCloneProjectRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projectId: [_dtpID,null,null],
        newName: [_dtpString,null,null],
        newStartDate: [_dtpTimestamp | _dtpOptional,null,null],
        newDescription: [_dtpString | _dtpOptional,null,null],
        newTag: [_dtpID | _dtpOptional,null,null],
        newTag2: [_dtpID | _dtpOptional,null,null],
        options: [_dtpArray | _dtpEnum,enmCloneProjectRequestsOption,null],
        ownerWorkspaceUserId: [_dtpID | _dtpOptional,null,null],
        targetWorkspaceId: [_dtpID | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.newName = '';
        /** @type {?number} */
        this.newStartDate = 0;
        /** @type {?string} */
        this.newDescription = '';
        /** @type {?string} */
        this.newTag = '';
        /** @type {?string} */
        this.newTag2 = '';
        /** @type {!Array.<enmCloneProjectRequestsOption>} */
        this.options = [];
        /** @type {?string} */
        this.ownerWorkspaceUserId = '';
        /** @type {?string} */
        this.targetWorkspaceId = '';
        updateFields.call(this, obj, _cCloneProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCloneProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCloneProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cCloneProjectRequest] = cCloneProjectRequest;

class cTutorialProjectRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        newName: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.newName = '';
        updateFields.call(this, obj, _cTutorialProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTutorialProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTutorialProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cTutorialProjectRequest] = cTutorialProjectRequest;

class cListProjectsRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        withStatus: [_dtpEnum | _dtpOptional,enmProjectStatus,() => enmProjectStatus.Active],
        sendStats: [_dtpBoolean | _dtpOptional,null,() => true]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?enmProjectStatus} */
        this.withStatus = undefined;
        /** @type {?boolean} */
        this.sendStats = false;
        updateFields.call(this, obj, _cListProjectsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListProjectsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListProjectsRequest, resetDefaults);
        return this;
    }
}
$classes[_cListProjectsRequest] = cListProjectsRequest;

class cListOfProjectsResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projects: [_dtpArray | _dtpClass,_cProjectInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!Array.<cProjectInfo>} */
        this.projects = [];
        updateFields.call(this, obj, _cListOfProjectsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfProjectsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfProjectsResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfProjectsResponse] = cListOfProjectsResponse;

class cProjectInfoPackage extends cSessionMessage {
    static __fieldsDef = {
        projectInfo: [_dtpClass,_cProjectInfo,null],
        teams: [_dtpArray | _dtpClass,_cTeamInfo,null],
        tasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        transitions: [_dtpArray | _dtpClass,_cTransitionInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cProjectInfo} */
        this.projectInfo = undefined;
        /** @type {!Array.<cTeamInfo>} */
        this.teams = [];
        /** @type {!Array.<cTaskInfo>} */
        this.tasks = [];
        /** @type {!Array.<cTransitionInfo>} */
        this.transitions = [];
        updateFields.call(this, obj, _cProjectInfoPackage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectInfoPackage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectInfoPackage, resetDefaults);
        return this;
    }
}
$classes[_cProjectInfoPackage] = cProjectInfoPackage;

class cProjectMembershipInfo extends cMessageToProject {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null],
        role: [_dtpEnum,enmWorkspaceUserRole,null],
        roleTitle: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.role = undefined;
        /** @type {?string} */
        this.roleTitle = '';
        updateFields.call(this, obj, _cProjectMembershipInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMembershipInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMembershipInfo, resetDefaults);
        return this;
    }
}
$classes[_cProjectMembershipInfo] = cProjectMembershipInfo;

class cProjectMembershipRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        become: [_dtpEnum,enmWorkspaceUserRole,null],
        roleTitle: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.become = undefined;
        /** @type {?string} */
        this.roleTitle = '';
        updateFields.call(this, obj, _cProjectMembershipRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMembershipRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMembershipRequest, resetDefaults);
        return this;
    }
}
$classes[_cProjectMembershipRequest] = cProjectMembershipRequest;

class cProjectMembershipResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        role: [_dtpEnum,enmWorkspaceUserRole,null],
        roleTitle: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.role = undefined;
        /** @type {?string} */
        this.roleTitle = '';
        updateFields.call(this, obj, _cProjectMembershipResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMembershipResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMembershipResponse, resetDefaults);
        return this;
    }
}
$classes[_cProjectMembershipResponse] = cProjectMembershipResponse;

class cListProjectMembershipsRequest extends cMessageToProject {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cListProjectMembershipsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListProjectMembershipsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListProjectMembershipsRequest, resetDefaults);
        return this;
    }
}
$classes[_cListProjectMembershipsRequest] = cListProjectMembershipsRequest;

class cListOfProjectMembershipsResponse extends cSessionMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        list: [_dtpArray | _dtpClass,_cWorkspaceUserInfo,null],
        listOfIds: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!Array.<cWorkspaceUserInfo>} */
        this.list = [];
        /** @type {!Array} */
        this.listOfIds = [];
        updateFields.call(this, obj, _cListOfProjectMembershipsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfProjectMembershipsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfProjectMembershipsResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfProjectMembershipsResponse] = cListOfProjectMembershipsResponse;

class cTaskInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        type: [_dtpEnum,enmTaskInfoTypes,() => enmTaskInfoTypes.Task],
        name: [_dtpString,null,null],
        description: [_dtpString | _dtpOptional,null,null],
        deadline: [_dtpTimestamp | _dtpOptional,null,null],
        duration: [_dtpNumber | _dtpOptional,null,null],
        durationUnit: [_dtpEnum | _dtpOptional,enmTaskInfoDurations,null],
        state: [_dtpEnum,enmTaskInfoStates,() => enmTaskInfoStates.Unblocked],
        groupId: [_dtpID | _dtpOptional,null,null],
        rowId: [_dtpNumber,null,null],
        colId: [_dtpNumber,null,null],
        teamId: [_dtpID | _dtpOptional,null,null],
        execs: [_dtpArray | _dtpOptional,null,() => []],
        timeCreated: [_dtpTimestamp | _dtpOptional,null,null],
        timeUnblocked: [_dtpTimestamp | _dtpOptional,null,null],
        timeStarted: [_dtpTimestamp | _dtpOptional,null,null],
        timeCompleted: [_dtpTimestamp | _dtpOptional,null,null],
        creatorId: [_dtpID | _dtpOptional,null,null],
        counters: [_dtpJSON | _dtpOptional,null,null],
        startDate: [_dtpTimestamp | _dtpOptional,null,null],
        durationDef: [_dtpString | _dtpOptional,null,null],
        values: [_dtpObject | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!enmTaskInfoTypes} */
        this.type = undefined;
        /** @type {!string} */
        this.name = '';
        /** @type {?string} */
        this.description = '';
        /** @type {?number} */
        this.deadline = 0;
        /** @type {?number} */
        this.duration = 0;
        /** @type {?enmTaskInfoDurations} */
        this.durationUnit = undefined;
        /** @type {!enmTaskInfoStates} */
        this.state = undefined;
        /** @type {?string} */
        this.groupId = '';
        /** @type {!number} */
        this.rowId = 0;
        /** @type {!number} */
        this.colId = 0;
        /** @type {?string} */
        this.teamId = '';
        /** @type {?Array} */
        this.execs = [];
        /** @type {?number} */
        this.timeCreated = 0;
        /** @type {?number} */
        this.timeUnblocked = 0;
        /** @type {?number} */
        this.timeStarted = 0;
        /** @type {?number} */
        this.timeCompleted = 0;
        /** @type {?string} */
        this.creatorId = '';
        /** @type {?string} */
        this.counters = '';
        /** @type {?number} */
        this.startDate = 0;
        /** @type {?string} */
        this.durationDef = '';
        /** @type {?Object} */
        this.values = {};
        updateFields.call(this, obj, _cTaskInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTaskInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTaskInfo, resetDefaults);
        return this;
    }
}
$classes[_cTaskInfo] = cTaskInfo;

class cTransitionInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        fromId: [_dtpID,null,null],
        toId: [_dtpID,null,null],
        groupId: [_dtpID | _dtpInternal,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.fromId = '';
        /** @type {!string} */
        this.toId = '';
        /** @type {!string} */
        this.groupId = '';
        updateFields.call(this, obj, _cTransitionInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTransitionInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTransitionInfo, resetDefaults);
        return this;
    }
}
$classes[_cTransitionInfo] = cTransitionInfo;

class cStateChangesEnvelope extends cSessionMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        changes: [_dtpArray | _dtpClass,_cStateChange,null],
        time: [_dtpTimestamp,null,() => Date.now()]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!Array.<cStateChange>} */
        this.changes = [];
        /** @type {!number} */
        this.time = 0;
        updateFields.call(this, obj, _cStateChangesEnvelope, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStateChangesEnvelope, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStateChangesEnvelope, resetDefaults);
        return this;
    }
}
$classes[_cStateChangesEnvelope] = cStateChangesEnvelope;

class cStateChangeAccepted extends cSessionMessage {
    static __fieldsDef = {
        accepted: [_dtpBoolean,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!boolean} */
        this.accepted = false;
        updateFields.call(this, obj, _cStateChangeAccepted, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStateChangeAccepted, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStateChangeAccepted, resetDefaults);
        return this;
    }
}
$classes[_cStateChangeAccepted] = cStateChangeAccepted;

class cStateChange extends cMessage {
    static __fieldsDef = {
        type: [_dtpEnum,enmStateChangeTypes,null],
        id: [_dtpID,null,null],
        data: [_dtpObject | _dtpOptional,null,null],
        object: [_dtpObject | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmStateChangeTypes} */
        this.type = undefined;
        /** @type {!string} */
        this.id = '';
        /** @type {?Object} */
        this.data = {};
        /** @type {?Object} */
        this.object = {};
        updateFields.call(this, obj, _cStateChange, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStateChange, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStateChange, resetDefaults);
        return this;
    }
}
$classes[_cStateChange] = cStateChange;

class cNotification extends cMessage {
    static __fieldsDef = {
        type: [_dtpString,null,null],
        targetIds: [_dtpArray,null,null],
        targets: [_dtpArray,null,null],
        values: [_dtpObject | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.type = '';
        /** @type {!Array} */
        this.targetIds = [];
        /** @type {!Array} */
        this.targets = [];
        /** @type {?Object} */
        this.values = {};
        updateFields.call(this, obj, _cNotification, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cNotification, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cNotification, resetDefaults);
        return this;
    }
}
$classes[_cNotification] = cNotification;

class cNotificationsEnvelope extends cSessionMessage {
    static __fieldsDef = {
        time: [_dtpTimestamp,null,null],
        notifications: [_dtpArray | _dtpClass,_cNotification,null],
        sn: [_dtpNumber,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!number} */
        this.time = 0;
        /** @type {!Array.<cNotification>} */
        this.notifications = [];
        /** @type {!number} */
        this.sn = 0;
        updateFields.call(this, obj, _cNotificationsEnvelope, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cNotificationsEnvelope, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cNotificationsEnvelope, resetDefaults);
        return this;
    }
}
$classes[_cNotificationsEnvelope] = cNotificationsEnvelope;

class cGetUpdates extends cSessionMessage {
    static __fieldsDef = {
        since: [_dtpTimestamp,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!number} */
        this.since = 0;
        updateFields.call(this, obj, _cGetUpdates, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cGetUpdates, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cGetUpdates, resetDefaults);
        return this;
    }
}
$classes[_cGetUpdates] = cGetUpdates;

class cStandardTeamInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        workspaceId: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        color: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.color = '';
        updateFields.call(this, obj, _cStandardTeamInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStandardTeamInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStandardTeamInfo, resetDefaults);
        return this;
    }
}
$classes[_cStandardTeamInfo] = cStandardTeamInfo;

class cStandardTeamMembershipInfo extends cMessage {
    static __fieldsDef = {
        standardTeamId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.standardTeamId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cStandardTeamMembershipInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStandardTeamMembershipInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStandardTeamMembershipInfo, resetDefaults);
        return this;
    }
}
$classes[_cStandardTeamMembershipInfo] = cStandardTeamMembershipInfo;

class cStandardTeamMessages extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        updateFields.call(this, obj, _cStandardTeamMessages, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStandardTeamMessages, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStandardTeamMessages, resetDefaults);
        return this;
    }
}
$classes[_cStandardTeamMessages] = cStandardTeamMessages;

class cStandardTeamStatusResponse extends cStandardTeamMessages {
    static __fieldsDef = {
        standardTeam: [_dtpClass,_cStandardTeamInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cStandardTeamInfo} */
        this.standardTeam = undefined;
        updateFields.call(this, obj, _cStandardTeamStatusResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStandardTeamStatusResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStandardTeamStatusResponse, resetDefaults);
        return this;
    }
}
$classes[_cStandardTeamStatusResponse] = cStandardTeamStatusResponse;

class cAddToStandardTeamRequest extends cStandardTeamMessages {
    static __fieldsDef = {
        teamId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cAddToStandardTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddToStandardTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddToStandardTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cAddToStandardTeamRequest] = cAddToStandardTeamRequest;

class cAddedToStandardTeamResponse extends cStandardTeamMessages {
    static __fieldsDef = {
        standardTeamMembership: [_dtpClass,_cStandardTeamMembershipInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cStandardTeamMembershipInfo} */
        this.standardTeamMembership = undefined;
        updateFields.call(this, obj, _cAddedToStandardTeamResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddedToStandardTeamResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddedToStandardTeamResponse, resetDefaults);
        return this;
    }
}
$classes[_cAddedToStandardTeamResponse] = cAddedToStandardTeamResponse;

class cCreateStandardTeamRequest extends cStandardTeamMessages {
    static __fieldsDef = {
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        color: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.color = '';
        updateFields.call(this, obj, _cCreateStandardTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateStandardTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateStandardTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateStandardTeamRequest] = cCreateStandardTeamRequest;

class cListStandardTeamsRequest extends cStandardTeamMessages {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cListStandardTeamsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListStandardTeamsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListStandardTeamsRequest, resetDefaults);
        return this;
    }
}
$classes[_cListStandardTeamsRequest] = cListStandardTeamsRequest;

class cListOfStandardTeamsResponse extends cStandardTeamMessages {
    static __fieldsDef = {
        standardTeams: [_dtpArray | _dtpClass,_cStandardTeamInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cStandardTeamInfo>} */
        this.standardTeams = [];
        updateFields.call(this, obj, _cListOfStandardTeamsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfStandardTeamsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfStandardTeamsResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfStandardTeamsResponse] = cListOfStandardTeamsResponse;

class cRemoveFromStandardTeamRequest extends cStandardTeamMessages {
    static __fieldsDef = {
        teamId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cRemoveFromStandardTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveFromStandardTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveFromStandardTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveFromStandardTeamRequest] = cRemoveFromStandardTeamRequest;

class cRemoveStandardTeamRequest extends cStandardTeamMessages {
    static __fieldsDef = {
        teamId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        updateFields.call(this, obj, _cRemoveStandardTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveStandardTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveStandardTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveStandardTeamRequest] = cRemoveStandardTeamRequest;

class cStandardTeamDoneResponse extends cStandardTeamMessages {
    static __fieldsDef = {
        done: [_dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!*} */
        this.done = undefined;
        updateFields.call(this, obj, _cStandardTeamDoneResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStandardTeamDoneResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStandardTeamDoneResponse, resetDefaults);
        return this;
    }
}
$classes[_cStandardTeamDoneResponse] = cStandardTeamDoneResponse;

class cUpdateStandardTeamRequest extends cStandardTeamMessages {
    static __fieldsDef = {
        teamId: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        color: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.color = '';
        updateFields.call(this, obj, _cUpdateStandardTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateStandardTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateStandardTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateStandardTeamRequest] = cUpdateStandardTeamRequest;

class cAddUserToWorkspaceRequest extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        firstName: [_dtpString | _dtpOptional,null,null],
        lastName: [_dtpString | _dtpOptional,null,null],
        email: [_dtpString | _dtpOptional,null,null],
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        role: [_dtpEnum,enmWorkspaceUserRole,() => enmWorkspaceUserRole.Participant],
        projectId: [_dtpID | _dtpOptional,null,null],
        projectRole: [_dtpEnum | _dtpOptional,enmWorkspaceUserRole,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {?string} */
        this.firstName = '';
        /** @type {?string} */
        this.lastName = '';
        /** @type {?string} */
        this.email = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.role = undefined;
        /** @type {?string} */
        this.projectId = '';
        /** @type {?enmWorkspaceUserRole} */
        this.projectRole = undefined;
        updateFields.call(this, obj, _cAddUserToWorkspaceRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddUserToWorkspaceRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddUserToWorkspaceRequest, resetDefaults);
        return this;
    }
}
$classes[_cAddUserToWorkspaceRequest] = cAddUserToWorkspaceRequest;

class cAddUserToWorkspaceResponse extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        result: [_dtpEnum,enmAddUserToWorkspaceResponseStatus,null],
        workspaceUser: [_dtpClass,_cWorkspaceUserInfo,null],
        billingStatus: [_dtpEnum,enmBillingResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmAddUserToWorkspaceResponseStatus} */
        this.result = undefined;
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        /** @type {!enmBillingResponseStatus} */
        this.billingStatus = undefined;
        updateFields.call(this, obj, _cAddUserToWorkspaceResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddUserToWorkspaceResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddUserToWorkspaceResponse, resetDefaults);
        return this;
    }
}
$classes[_cAddUserToWorkspaceResponse] = cAddUserToWorkspaceResponse;

class cAttachUserToWorkspaceUserRequest extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        email: [_dtpString,null,null],
        workspaceUserId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        role: [_dtpEnum,enmWorkspaceUserRole,null],
        firstName: [_dtpString | _dtpOptional,null,null],
        lastName: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.email = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.role = undefined;
        /** @type {?string} */
        this.firstName = '';
        /** @type {?string} */
        this.lastName = '';
        updateFields.call(this, obj, _cAttachUserToWorkspaceUserRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAttachUserToWorkspaceUserRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAttachUserToWorkspaceUserRequest, resetDefaults);
        return this;
    }
}
$classes[_cAttachUserToWorkspaceUserRequest] = cAttachUserToWorkspaceUserRequest;

class cAttachUserToWorkspaceUserResponse extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        result: [_dtpEnum,enmAttachUserToWorkspaceUserStatus,null],
        workspaceUser: [_dtpClass,_cWorkspaceUserInfo,null],
        billingStatus: [_dtpEnum,enmBillingResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmAttachUserToWorkspaceUserStatus} */
        this.result = undefined;
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        /** @type {!enmBillingResponseStatus} */
        this.billingStatus = undefined;
        updateFields.call(this, obj, _cAttachUserToWorkspaceUserResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAttachUserToWorkspaceUserResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAttachUserToWorkspaceUserResponse, resetDefaults);
        return this;
    }
}
$classes[_cAttachUserToWorkspaceUserResponse] = cAttachUserToWorkspaceUserResponse;

class cDetachUserFromWorksaceUserRequest extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        workspaceUserId: [_dtpID,null,null],
        firstName: [_dtpString | _dtpOptional,null,null],
        lastName: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {?string} */
        this.firstName = '';
        /** @type {?string} */
        this.lastName = '';
        updateFields.call(this, obj, _cDetachUserFromWorksaceUserRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cDetachUserFromWorksaceUserRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cDetachUserFromWorksaceUserRequest, resetDefaults);
        return this;
    }
}
$classes[_cDetachUserFromWorksaceUserRequest] = cDetachUserFromWorksaceUserRequest;

class cDetachUserFromWorkspaceUserResponse extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        result: [_dtpEnum,enmDetachUserFromWorkspaceUserStatus,null],
        workspaceUser: [_dtpClass,_cWorkspaceUserInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmDetachUserFromWorkspaceUserStatus} */
        this.result = undefined;
        /** @type {!cWorkspaceUserInfo} */
        this.workspaceUser = undefined;
        updateFields.call(this, obj, _cDetachUserFromWorkspaceUserResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cDetachUserFromWorkspaceUserResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cDetachUserFromWorkspaceUserResponse, resetDefaults);
        return this;
    }
}
$classes[_cDetachUserFromWorkspaceUserResponse] = cDetachUserFromWorkspaceUserResponse;

class cRemoveUserFromWorkspaceRequest extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cRemoveUserFromWorkspaceRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveUserFromWorkspaceRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveUserFromWorkspaceRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveUserFromWorkspaceRequest] = cRemoveUserFromWorkspaceRequest;

class cRemoveUserFromWorkspaceResponse extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        result: [_dtpEnum,enmRemoveUserFromWorkspaceResult,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmRemoveUserFromWorkspaceResult} */
        this.result = undefined;
        updateFields.call(this, obj, _cRemoveUserFromWorkspaceResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveUserFromWorkspaceResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveUserFromWorkspaceResponse, resetDefaults);
        return this;
    }
}
$classes[_cRemoveUserFromWorkspaceResponse] = cRemoveUserFromWorkspaceResponse;

class cListWorkspaceUsersRequest extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        updateFields.call(this, obj, _cListWorkspaceUsersRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListWorkspaceUsersRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListWorkspaceUsersRequest, resetDefaults);
        return this;
    }
}
$classes[_cListWorkspaceUsersRequest] = cListWorkspaceUsersRequest;

class cListOfWorkspaceUsersResponse extends cWorkspaceMembersMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUsers: [_dtpArray | _dtpClass,_cWorkspaceUserInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!Array.<cWorkspaceUserInfo>} */
        this.workspaceUsers = [];
        updateFields.call(this, obj, _cListOfWorkspaceUsersResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfWorkspaceUsersResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfWorkspaceUsersResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfWorkspaceUsersResponse] = cListOfWorkspaceUsersResponse;

class cProjectMapRequest extends cMessageToProject {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cProjectMapRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMapRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMapRequest, resetDefaults);
        return this;
    }
}
$classes[_cProjectMapRequest] = cProjectMapRequest;

class cProjectMapResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null],
        tasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        transitions: [_dtpArray | _dtpClass,_cTransitionInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!Array.<cTaskInfo>} */
        this.tasks = [];
        /** @type {!Array.<cTransitionInfo>} */
        this.transitions = [];
        updateFields.call(this, obj, _cProjectMapResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMapResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMapResponse, resetDefaults);
        return this;
    }
}
$classes[_cProjectMapResponse] = cProjectMapResponse;

class cProjectExecutionBundleRequest extends cMessageToProject {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cProjectExecutionBundleRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectExecutionBundleRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectExecutionBundleRequest, resetDefaults);
        return this;
    }
}
$classes[_cProjectExecutionBundleRequest] = cProjectExecutionBundleRequest;

class cProjectExecutionBundle extends cSessionMessage {
    static __fieldsDef = {
        project: [_dtpClass,_cProjectInfo,null],
        teamsAndMembers: [_dtpClass,_cProjectTeamsAndMembers,null],
        projectMemberPack: [_dtpClass,_cProjectMemberPack,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cProjectInfo} */
        this.project = undefined;
        /** @type {!cProjectTeamsAndMembers} */
        this.teamsAndMembers = undefined;
        /** @type {!cProjectMemberPack} */
        this.projectMemberPack = undefined;
        updateFields.call(this, obj, _cProjectExecutionBundle, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectExecutionBundle, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectExecutionBundle, resetDefaults);
        return this;
    }
}
$classes[_cProjectExecutionBundle] = cProjectExecutionBundle;

class cProjectTeamsAndMembers extends cMessage {
    static __fieldsDef = {
        members: [_dtpArray | _dtpClass,_cWorkspaceUserInfo,null],
        projectMemberships: [_dtpArray | _dtpClass,_cProjectMembershipInfo,null],
        teams: [_dtpArray | _dtpClass,_cTeamInfo,null],
        teamMemberships: [_dtpArray | _dtpClass,_cTeamMembershipInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cWorkspaceUserInfo>} */
        this.members = [];
        /** @type {!Array.<cProjectMembershipInfo>} */
        this.projectMemberships = [];
        /** @type {!Array.<cTeamInfo>} */
        this.teams = [];
        /** @type {!Array.<cTeamMembershipInfo>} */
        this.teamMemberships = [];
        updateFields.call(this, obj, _cProjectTeamsAndMembers, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectTeamsAndMembers, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectTeamsAndMembers, resetDefaults);
        return this;
    }
}
$classes[_cProjectTeamsAndMembers] = cProjectTeamsAndMembers;

class cProjectMemberPack extends cMessage {
    static __fieldsDef = {
        projectRole: [_dtpEnum,enmWorkspaceUserRole,null],
        currentTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        historyTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        futureTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        transitions: [_dtpArray | _dtpClass,_cTransitionInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmWorkspaceUserRole} */
        this.projectRole = undefined;
        /** @type {!Array.<cTaskInfo>} */
        this.currentTasks = [];
        /** @type {!Array.<cTaskInfo>} */
        this.historyTasks = [];
        /** @type {!Array.<cTaskInfo>} */
        this.futureTasks = [];
        /** @type {!Array.<cTransitionInfo>} */
        this.transitions = [];
        updateFields.call(this, obj, _cProjectMemberPack, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMemberPack, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMemberPack, resetDefaults);
        return this;
    }
}
$classes[_cProjectMemberPack] = cProjectMemberPack;

class cProjectMemberPackRequest extends cMessageToProject {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cProjectMemberPackRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMemberPackRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMemberPackRequest, resetDefaults);
        return this;
    }
}
$classes[_cProjectMemberPackRequest] = cProjectMemberPackRequest;

class cProjectMemberPackResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null],
        projectMemberPack: [_dtpArray | _dtpClass,_cProjectMemberPack,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!Array.<cProjectMemberPack>} */
        this.projectMemberPack = [];
        updateFields.call(this, obj, _cProjectMemberPackResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectMemberPackResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectMemberPackResponse, resetDefaults);
        return this;
    }
}
$classes[_cProjectMemberPackResponse] = cProjectMemberPackResponse;

class cTeamPack extends cMessage {
    static __fieldsDef = {
        currentTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        historyTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        futureTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        transitions: [_dtpArray | _dtpClass,_cTransitionInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cTaskInfo>} */
        this.currentTasks = [];
        /** @type {!Array.<cTaskInfo>} */
        this.historyTasks = [];
        /** @type {!Array.<cTaskInfo>} */
        this.futureTasks = [];
        /** @type {!Array.<cTransitionInfo>} */
        this.transitions = [];
        updateFields.call(this, obj, _cTeamPack, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamPack, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamPack, resetDefaults);
        return this;
    }
}
$classes[_cTeamPack] = cTeamPack;

class cTeamPackRequest extends cMessageToProject {
    static __fieldsDef = {
        teamId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        updateFields.call(this, obj, _cTeamPackRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamPackRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamPackRequest, resetDefaults);
        return this;
    }
}
$classes[_cTeamPackRequest] = cTeamPackRequest;

class cTeamPackResponse extends cSessionMessage {
    static __fieldsDef = {
        teamId: [_dtpID,null,null],
        teamPack: [_dtpClass,_cTeamPack,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        /** @type {!cTeamPack} */
        this.teamPack = undefined;
        updateFields.call(this, obj, _cTeamPackResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamPackResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamPackResponse, resetDefaults);
        return this;
    }
}
$classes[_cTeamPackResponse] = cTeamPackResponse;

class cMultipleTaskInfosRequest extends cMessageToProject {
    static __fieldsDef = {
        taskIds: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array} */
        this.taskIds = [];
        updateFields.call(this, obj, _cMultipleTaskInfosRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cMultipleTaskInfosRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cMultipleTaskInfosRequest, resetDefaults);
        return this;
    }
}
$classes[_cMultipleTaskInfosRequest] = cMultipleTaskInfosRequest;

class cMutlipleTaskInfosResponse extends cSessionMessage {
    static __fieldsDef = {
        tasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        transitions: [_dtpArray | _dtpClass,_cTransitionInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cTaskInfo>} */
        this.tasks = [];
        /** @type {!Array.<cTransitionInfo>} */
        this.transitions = [];
        updateFields.call(this, obj, _cMutlipleTaskInfosResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cMutlipleTaskInfosResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cMutlipleTaskInfosResponse, resetDefaults);
        return this;
    }
}
$classes[_cMutlipleTaskInfosResponse] = cMutlipleTaskInfosResponse;

class cTeamInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        color: [_dtpColor,null,null],
        symbol: [_dtpString,null,null],
        projectId: [_dtpID,null,() => $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id],
        isNew_: [_dtpBoolean | _dtpInternal,null,() => false],
        isEdit_: [_dtpBoolean | _dtpInternal,null,() => false],
        oldValue_: [_dtpObject | _dtpInternal,null,null],
        members_: [_dtpArray | _dtpClass | _dtpInternal,_cTeamMembershipInfo,() => []]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.color = '';
        /** @type {!string} */
        this.symbol = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!boolean} */
        this.isNew_ = false;
        /** @type {!boolean} */
        this.isEdit_ = false;
        /** @type {!Object} */
        this.oldValue_ = {};
        /** @type {!Array.<cTeamMembershipInfo>} */
        this.members_ = [];
        updateFields.call(this, obj, _cTeamInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamInfo, resetDefaults);
        return this;
    }
}
$classes[_cTeamInfo] = cTeamInfo;

class cTeamMembershipInfo extends cMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        teamId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null],
        role: [_dtpEnum,enmWorkspaceUserRole,() => enmWorkspaceUserRole.Leader]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.teamId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.role = undefined;
        updateFields.call(this, obj, _cTeamMembershipInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamMembershipInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamMembershipInfo, resetDefaults);
        return this;
    }
}
$classes[_cTeamMembershipInfo] = cTeamMembershipInfo;

class cTeamStatusResponse extends cSessionMessage {
    static __fieldsDef = {
        team: [_dtpClass,_cTeamInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cTeamInfo} */
        this.team = undefined;
        updateFields.call(this, obj, _cTeamStatusResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamStatusResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamStatusResponse, resetDefaults);
        return this;
    }
}
$classes[_cTeamStatusResponse] = cTeamStatusResponse;

class cMessageToTeam extends cMessageToProject {
    static __fieldsDef = {
        teamId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.teamId = '';
        updateFields.call(this, obj, _cMessageToTeam, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cMessageToTeam, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cMessageToTeam, resetDefaults);
        return this;
    }
}
$classes[_cMessageToTeam] = cMessageToTeam;

class cCreateTeamRequest extends cMessageToTeam {
    static __fieldsDef = {
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        color: [_dtpString,null,null],
        symbol: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.color = '';
        /** @type {!string} */
        this.symbol = '';
        updateFields.call(this, obj, _cCreateTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateTeamRequest] = cCreateTeamRequest;

class cUpdateTeamRequest extends cMessageToTeam {
    static __fieldsDef = {
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        color: [_dtpString,null,null],
        symbol: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.color = '';
        /** @type {!string} */
        this.symbol = '';
        updateFields.call(this, obj, _cUpdateTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateTeamRequest] = cUpdateTeamRequest;

class cRemoveTeamRequest extends cMessageToTeam {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cRemoveTeamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveTeamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveTeamRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveTeamRequest] = cRemoveTeamRequest;

class cTeamMembershipRequest extends cMessageToTeam {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null],
        become: [_dtpEnum,enmWorkspaceUserRole,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!enmWorkspaceUserRole} */
        this.become = undefined;
        updateFields.call(this, obj, _cTeamMembershipRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamMembershipRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamMembershipRequest, resetDefaults);
        return this;
    }
}
$classes[_cTeamMembershipRequest] = cTeamMembershipRequest;

class cTeamMembershipResponse extends cSessionMessage {
    static __fieldsDef = {
        membership: [_dtpClass,_cTeamMembershipInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cTeamMembershipInfo} */
        this.membership = undefined;
        updateFields.call(this, obj, _cTeamMembershipResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTeamMembershipResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTeamMembershipResponse, resetDefaults);
        return this;
    }
}
$classes[_cTeamMembershipResponse] = cTeamMembershipResponse;

class cAddUserFeedback extends cSessionMessage {
    static __fieldsDef = {
        userId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null],
        type: [_dtpEnum,enmFeedbackTypes,null],
        title: [_dtpString,null,null],
        description: [_dtpString,null,null],
        userAgent: [_dtpString,null,null],
        uiPath: [_dtpString,null,null],
        logs: [_dtpString,null,null],
        data: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.userId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!enmFeedbackTypes} */
        this.type = undefined;
        /** @type {!string} */
        this.title = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.userAgent = '';
        /** @type {!string} */
        this.uiPath = '';
        /** @type {!string} */
        this.logs = '';
        /** @type {!string} */
        this.data = '';
        updateFields.call(this, obj, _cAddUserFeedback, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddUserFeedback, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddUserFeedback, resetDefaults);
        return this;
    }
}
$classes[_cAddUserFeedback] = cAddUserFeedback;

class cGetMessageOfTheDay extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cGetMessageOfTheDay, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cGetMessageOfTheDay, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cGetMessageOfTheDay, resetDefaults);
        return this;
    }
}
$classes[_cGetMessageOfTheDay] = cGetMessageOfTheDay;

class cMessageOfTheDayResponse extends cSessionMessage {
    static __fieldsDef = {
        description: [_dtpString,null,null],
        creationTime: [_dtpTimestamp,null,null],
        version: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.description = '';
        /** @type {!number} */
        this.creationTime = 0;
        /** @type {!string} */
        this.version = '';
        updateFields.call(this, obj, _cMessageOfTheDayResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cMessageOfTheDayResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cMessageOfTheDayResponse, resetDefaults);
        return this;
    }
}
$classes[_cMessageOfTheDayResponse] = cMessageOfTheDayResponse;

class cHistoryEntryInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID | _dtpOptional,null,null],
        subjectId: [_dtpID,null,null],
        stream: [_dtpEnum,enmStreamTypes,() => enmStreamTypes.Task],
        objectId: [_dtpID,null,null],
        type: [_dtpEnum,enmHistoryEntryType,null],
        event: [_dtpEnum | _dtpOptional,enmHistoryEventType,null],
        content: [_dtpJSON | _dtpString,null,null],
        eventTime: [_dtpTimestamp,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?string} */
        this.projectId = '';
        /** @type {!string} */
        this.subjectId = '';
        /** @type {!enmStreamTypes} */
        this.stream = undefined;
        /** @type {!string} */
        this.objectId = '';
        /** @type {!enmHistoryEntryType} */
        this.type = undefined;
        /** @type {?enmHistoryEventType} */
        this.event = undefined;
        /** @type {!string} */
        this.content = '';
        /** @type {!number} */
        this.eventTime = 0;
        updateFields.call(this, obj, _cHistoryEntryInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cHistoryEntryInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cHistoryEntryInfo, resetDefaults);
        return this;
    }
}
$classes[_cHistoryEntryInfo] = cHistoryEntryInfo;

class cUserAlertEntryInfo extends cHistoryEntryInfo {
    static __fieldsDef = {
        event: [_dtpEnum,enmAlertEvents,null],
        read_: [_dtpBoolean | _dtpInternal,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmAlertEvents} */
        this.event = undefined;
        /** @type {!boolean} */
        this.read_ = false;
        updateFields.call(this, obj, _cUserAlertEntryInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserAlertEntryInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserAlertEntryInfo, resetDefaults);
        return this;
    }
}
$classes[_cUserAlertEntryInfo] = cUserAlertEntryInfo;

class cHistoryStreamRequest extends cMessageToProject {
    static __fieldsDef = {
        serialTag: [_dtpString | _dtpOptional,null,null],
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID | _dtpOptional,null,null],
        stream: [_dtpEnum,enmStreamTypes,() => enmStreamTypes.Task],
        objectId: [_dtpID | _dtpOptional,null,null],
        type: [_dtpEnum | _dtpOptional,enmHistoryEntryType,null],
        before: [_dtpTimestamp | _dtpOptional,null,null],
        beforeOrAt: [_dtpTimestamp | _dtpOptional,null,null],
        after: [_dtpTimestamp | _dtpOptional,null,null],
        afterOrAt: [_dtpTimestamp | _dtpOptional,null,null],
        skip: [_dtpNumber | _dtpOptional,null,null],
        limit: [_dtpNumber | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {?string} */
        this.serialTag = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?string} */
        this.projectId = '';
        /** @type {!enmStreamTypes} */
        this.stream = undefined;
        /** @type {?string} */
        this.objectId = '';
        /** @type {?enmHistoryEntryType} */
        this.type = undefined;
        /** @type {?number} */
        this.before = 0;
        /** @type {?number} */
        this.beforeOrAt = 0;
        /** @type {?number} */
        this.after = 0;
        /** @type {?number} */
        this.afterOrAt = 0;
        /** @type {?number} */
        this.skip = 0;
        /** @type {?number} */
        this.limit = 0;
        updateFields.call(this, obj, _cHistoryStreamRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cHistoryStreamRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cHistoryStreamRequest, resetDefaults);
        return this;
    }
}
$classes[_cHistoryStreamRequest] = cHistoryStreamRequest;

class cHistoryStreamPackage extends cProtocolMessage {
    static __fieldsDef = {
        serialTag: [_dtpString | _dtpOptional,null,null],
        workspaceId: [_dtpID,null,null],
        stream: [_dtpEnum,enmStreamTypes,() => enmStreamTypes.Task],
        objectId: [_dtpID,null,null],
        entries: [_dtpArray | _dtpClass,_cHistoryEntryInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {?string} */
        this.serialTag = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!enmStreamTypes} */
        this.stream = undefined;
        /** @type {!string} */
        this.objectId = '';
        /** @type {!Array.<cHistoryEntryInfo>} */
        this.entries = [];
        updateFields.call(this, obj, _cHistoryStreamPackage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cHistoryStreamPackage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cHistoryStreamPackage, resetDefaults);
        return this;
    }
}
$classes[_cHistoryStreamPackage] = cHistoryStreamPackage;

class cPostCommentRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        stream: [_dtpEnum,enmStreamTypes,() => enmStreamTypes.Task],
        objectId: [_dtpID,null,null],
        message: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!enmStreamTypes} */
        this.stream = undefined;
        /** @type {!string} */
        this.objectId = '';
        /** @type {!string} */
        this.message = '';
        updateFields.call(this, obj, _cPostCommentRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cPostCommentRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cPostCommentRequest, resetDefaults);
        return this;
    }
}
$classes[_cPostCommentRequest] = cPostCommentRequest;

class cRemoveCommentRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projectId: [_dtpID,null,() => $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id],
        historyEntryId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.historyEntryId = '';
        updateFields.call(this, obj, _cRemoveCommentRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveCommentRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveCommentRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveCommentRequest] = cRemoveCommentRequest;

class cAddNewEmailRequest extends cSessionMessage {
    static __fieldsDef = {
        email: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.email = '';
        updateFields.call(this, obj, _cAddNewEmailRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddNewEmailRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddNewEmailRequest, resetDefaults);
        return this;
    }
}
$classes[_cAddNewEmailRequest] = cAddNewEmailRequest;

class cAddNewEmailResponse extends cSessionMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmAddNewEmailResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmAddNewEmailResponseStatus} */
        this.status = undefined;
        updateFields.call(this, obj, _cAddNewEmailResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAddNewEmailResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAddNewEmailResponse, resetDefaults);
        return this;
    }
}
$classes[_cAddNewEmailResponse] = cAddNewEmailResponse;

class cRemoveContactInfoRequest extends cSessionMessage {
    static __fieldsDef = {
        contactInfoId: [_dtpID,null,null],
        reassignToPrimary: [_dtpBoolean | _dtpOptional,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.contactInfoId = '';
        /** @type {?boolean} */
        this.reassignToPrimary = false;
        updateFields.call(this, obj, _cRemoveContactInfoRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveContactInfoRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveContactInfoRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveContactInfoRequest] = cRemoveContactInfoRequest;

class cAssignContactInfoToWorkspaceUser extends cSessionMessage {
    static __fieldsDef = {
        contactInfoId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.contactInfoId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        updateFields.call(this, obj, _cAssignContactInfoToWorkspaceUser, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAssignContactInfoToWorkspaceUser, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAssignContactInfoToWorkspaceUser, resetDefaults);
        return this;
    }
}
$classes[_cAssignContactInfoToWorkspaceUser] = cAssignContactInfoToWorkspaceUser;

class cFileInfo extends cProtocolMessage {
    static __fieldsDef = {
        id: [_dtpID | _dtpOptional,null,null],
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        stream: [_dtpEnum,enmFileStreams,null],
        objectId: [_dtpID,null,null],
        sid: [_dtpString | _dtpOptional,null,null],
        version: [_dtpString | _dtpOptional,null,null],
        ownerId: [_dtpID | _dtpOptional,null,null],
        type: [_dtpEnum,enmFileType,null],
        location: [_dtpString | _dtpOptional,null,null],
        preview: [_dtpString | _dtpOptional,null,null],
        thumbnail: [_dtpString | _dtpOptional,null,null],
        fileName: [_dtpString,null,null],
        contentType: [_dtpString,null,null],
        fileSize: [_dtpNumber,null,null],
        timeUploaded: [_dtpTimestamp | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {?string} */
        this.id = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!enmFileStreams} */
        this.stream = undefined;
        /** @type {!string} */
        this.objectId = '';
        /** @type {?string} */
        this.sid = '';
        /** @type {?string} */
        this.version = '';
        /** @type {?string} */
        this.ownerId = '';
        /** @type {!enmFileType} */
        this.type = undefined;
        /** @type {?string} */
        this.location = '';
        /** @type {?string} */
        this.preview = '';
        /** @type {?string} */
        this.thumbnail = '';
        /** @type {!string} */
        this.fileName = '';
        /** @type {!string} */
        this.contentType = '';
        /** @type {!number} */
        this.fileSize = 0;
        /** @type {?number} */
        this.timeUploaded = 0;
        updateFields.call(this, obj, _cFileInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFileInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFileInfo, resetDefaults);
        return this;
    }
}
$classes[_cFileInfo] = cFileInfo;

class cNewFileRequest extends cSessionMessage {
    static __fieldsDef = {
        file: [_dtpClass,_cFileInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cFileInfo} */
        this.file = undefined;
        updateFields.call(this, obj, _cNewFileRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cNewFileRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cNewFileRequest, resetDefaults);
        return this;
    }
}
$classes[_cNewFileRequest] = cNewFileRequest;

class cListFilesRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        stream: [_dtpEnum | _dtpOptional,enmFileStreams,null],
        objectId: [_dtpID | _dtpOptional,null,null],
        ownerId: [_dtpID | _dtpOptional,null,null],
        serialTag: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {?enmFileStreams} */
        this.stream = undefined;
        /** @type {?string} */
        this.objectId = '';
        /** @type {?string} */
        this.ownerId = '';
        /** @type {?string} */
        this.serialTag = '';
        updateFields.call(this, obj, _cListFilesRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListFilesRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListFilesRequest, resetDefaults);
        return this;
    }
}
$classes[_cListFilesRequest] = cListFilesRequest;

class cRemoveFileRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        stream: [_dtpEnum,enmFileStreams,null],
        objectId: [_dtpID,null,null],
        fileId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!enmFileStreams} */
        this.stream = undefined;
        /** @type {!string} */
        this.objectId = '';
        /** @type {!string} */
        this.fileId = '';
        updateFields.call(this, obj, _cRemoveFileRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveFileRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveFileRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveFileRequest] = cRemoveFileRequest;

class cListFilesResponse extends cSessionMessage {
    static __fieldsDef = {
        files: [_dtpArray | _dtpClass,_cFileInfo,null],
        serialTag: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cFileInfo>} */
        this.files = [];
        /** @type {?string} */
        this.serialTag = '';
        updateFields.call(this, obj, _cListFilesResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListFilesResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListFilesResponse, resetDefaults);
        return this;
    }
}
$classes[_cListFilesResponse] = cListFilesResponse;

class cUploadTicketRequest extends cSessionMessage {
    static __fieldsDef = {
        file: [_dtpClass,_cFileInfo,null],
        method: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cFileInfo} */
        this.file = undefined;
        /** @type {?string} */
        this.method = '';
        updateFields.call(this, obj, _cUploadTicketRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUploadTicketRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUploadTicketRequest, resetDefaults);
        return this;
    }
}
$classes[_cUploadTicketRequest] = cUploadTicketRequest;

class cUploadTicketResponse extends cSessionMessage {
    static __fieldsDef = {
        billingStatus: [_dtpEnum,enmBillingResponseStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmBillingResponseStatus} */
        this.billingStatus = undefined;
        updateFields.call(this, obj, _cUploadTicketResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUploadTicketResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUploadTicketResponse, resetDefaults);
        return this;
    }
}
$classes[_cUploadTicketResponse] = cUploadTicketResponse;

class cUploadTicketInfo extends cProtocolMessage {
    static __fieldsDef = {
        file: [_dtpClass,_cFileInfo,null],
        url: [_dtpString | _dtpOptional,null,null],
        accessKey: [_dtpString | _dtpOptional,null,null],
        acl: [_dtpString | _dtpOptional,null,null],
        policy: [_dtpString | _dtpOptional,null,null],
        signature: [_dtpString | _dtpOptional,null,null],
        key: [_dtpString | _dtpOptional,null,null],
        postPath: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cFileInfo} */
        this.file = undefined;
        /** @type {?string} */
        this.url = '';
        /** @type {?string} */
        this.accessKey = '';
        /** @type {?string} */
        this.acl = '';
        /** @type {?string} */
        this.policy = '';
        /** @type {?string} */
        this.signature = '';
        /** @type {?string} */
        this.key = '';
        /** @type {?string} */
        this.postPath = '';
        updateFields.call(this, obj, _cUploadTicketInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUploadTicketInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUploadTicketInfo, resetDefaults);
        return this;
    }
}
$classes[_cUploadTicketInfo] = cUploadTicketInfo;

class cClipboardEntryInfo extends cProtocolMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        type: [_dtpEnum,enmClipboardEntryInfoType,() => enmClipboardEntryInfoType.StateSnapshot],
        info: [_dtpClass,_cSnapshotStats,null],
        workspaceId: [_dtpID,null,null],
        preview: [_dtpBoolean | _dtpOptional,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!enmClipboardEntryInfoType} */
        this.type = undefined;
        /** @type {!cSnapshotStats} */
        this.info = undefined;
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {?boolean} */
        this.preview = false;
        updateFields.call(this, obj, _cClipboardEntryInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cClipboardEntryInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cClipboardEntryInfo, resetDefaults);
        return this;
    }
}
$classes[_cClipboardEntryInfo] = cClipboardEntryInfo;

class cClearClipboardRequest extends cSessionMessage {
    static __fieldsDef = {
        ids: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array} */
        this.ids = [];
        updateFields.call(this, obj, _cClearClipboardRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cClearClipboardRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cClearClipboardRequest, resetDefaults);
        return this;
    }
}
$classes[_cClearClipboardRequest] = cClearClipboardRequest;

class cClipboardPreview extends cSessionMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        type: [_dtpString,null,() => enmClipboardPreviewType.metaData],
        data: [_dtpString,null,null],
        base64: [_dtpBoolean,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.type = '';
        /** @type {!string} */
        this.data = '';
        /** @type {!boolean} */
        this.base64 = false;
        updateFields.call(this, obj, _cClipboardPreview, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cClipboardPreview, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cClipboardPreview, resetDefaults);
        return this;
    }
}
$classes[_cClipboardPreview] = cClipboardPreview;

class cSnapshotStats extends cProtocolMessage {
    static __fieldsDef = {
        tasks: [_dtpNumber,null,null],
        groups: [_dtpNumber,null,null],
        contents: [_dtpArray | _dtpClass,_cSnapshotStats,null],
        positions: [_dtpArray | _dtpClass,_cTaskPastePosition,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!number} */
        this.tasks = 0;
        /** @type {!number} */
        this.groups = 0;
        /** @type {!Array.<cSnapshotStats>} */
        this.contents = [];
        /** @type {!Array.<cTaskPastePosition>} */
        this.positions = [];
        updateFields.call(this, obj, _cSnapshotStats, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSnapshotStats, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSnapshotStats, resetDefaults);
        return this;
    }
}
$classes[_cSnapshotStats] = cSnapshotStats;

class cCopyToClipboardRequest extends cSessionMessage {
    static __fieldsDef = {
        entryId: [_dtpID,null,null],
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projectId: [_dtpID,null,() => $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id],
        tasks: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.entryId = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!Array} */
        this.tasks = [];
        updateFields.call(this, obj, _cCopyToClipboardRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCopyToClipboardRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCopyToClipboardRequest, resetDefaults);
        return this;
    }
}
$classes[_cCopyToClipboardRequest] = cCopyToClipboardRequest;

class cCopyToClipboardResponse extends cSessionMessage {
    static __fieldsDef = {
        snapshotId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.snapshotId = '';
        updateFields.call(this, obj, _cCopyToClipboardResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCopyToClipboardResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCopyToClipboardResponse, resetDefaults);
        return this;
    }
}
$classes[_cCopyToClipboardResponse] = cCopyToClipboardResponse;

class cPasteFromClipboardRequest extends cSessionMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projectId: [_dtpID,null,() => $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id],
        groupId: [_dtpID | _dtpOptional,null,null],
        tasks: [_dtpArray | _dtpClass,_cTaskPastePosition,null],
        copyExecutors: [_dtpBoolean,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {?string} */
        this.groupId = '';
        /** @type {!Array.<cTaskPastePosition>} */
        this.tasks = [];
        /** @type {!boolean} */
        this.copyExecutors = false;
        updateFields.call(this, obj, _cPasteFromClipboardRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cPasteFromClipboardRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cPasteFromClipboardRequest, resetDefaults);
        return this;
    }
}
$classes[_cPasteFromClipboardRequest] = cPasteFromClipboardRequest;

class cTaskPastePosition extends cProtocolMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        colId: [_dtpNumber,null,null],
        rowId: [_dtpNumber,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!number} */
        this.colId = 0;
        /** @type {!number} */
        this.rowId = 0;
        updateFields.call(this, obj, _cTaskPastePosition, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTaskPastePosition, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTaskPastePosition, resetDefaults);
        return this;
    }
}
$classes[_cTaskPastePosition] = cTaskPastePosition;

class cCreateShareRequest extends cSessionMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        workspaceId: [_dtpID,null,null],
        type: [_dtpEnum,enmSharedContentType,null],
        contents: [_dtpArray | _dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!enmSharedContentType} */
        this.type = undefined;
        /** @type {!Array.<*>} */
        this.contents = [];
        updateFields.call(this, obj, _cCreateShareRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateShareRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateShareRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateShareRequest] = cCreateShareRequest;

class cCreateShareResponse extends cSessionMessage {
    static __fieldsDef = {
        created: [_dtpClass,_cSharedContentInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cSharedContentInfo} */
        this.created = undefined;
        updateFields.call(this, obj, _cCreateShareResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateShareResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateShareResponse, resetDefaults);
        return this;
    }
}
$classes[_cCreateShareResponse] = cCreateShareResponse;

class cSharedContentInfo extends cProtocolMessage {
    static __fieldsDef = {
        key: [_dtpString,null,null],
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null],
        type: [_dtpEnum,enmSharedContentType,null],
        location: [_dtpString,null,null],
        timeUploaded: [_dtpTimestamp,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.key = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!enmSharedContentType} */
        this.type = undefined;
        /** @type {!string} */
        this.location = '';
        /** @type {!number} */
        this.timeUploaded = 0;
        updateFields.call(this, obj, _cSharedContentInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSharedContentInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSharedContentInfo, resetDefaults);
        return this;
    }
}
$classes[_cSharedContentInfo] = cSharedContentInfo;

class cListSharesRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        updateFields.call(this, obj, _cListSharesRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListSharesRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListSharesRequest, resetDefaults);
        return this;
    }
}
$classes[_cListSharesRequest] = cListSharesRequest;

class cListSharesResponse extends cSessionMessage {
    static __fieldsDef = {
        shares: [_dtpArray | _dtpClass,_cSharedContentInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cSharedContentInfo>} */
        this.shares = [];
        updateFields.call(this, obj, _cListSharesResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListSharesResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListSharesResponse, resetDefaults);
        return this;
    }
}
$classes[_cListSharesResponse] = cListSharesResponse;

class cRemoveShareRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        shareKey: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.shareKey = '';
        updateFields.call(this, obj, _cRemoveShareRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveShareRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveShareRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveShareRequest] = cRemoveShareRequest;

class cSharedSnapshotData extends cProtocolMessage {
    static __fieldsDef = {
        shareKey: [_dtpString,null,null],
        name: [_dtpString,null,null],
        description: [_dtpString,null,null],
        tasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        transitions: [_dtpArray | _dtpClass,_cTransitionInfo,null],
        users: [_dtpArray | _dtpClass | _dtpOptional,_cWorkspaceUserInfo,null],
        teams: [_dtpArray | _dtpClass | _dtpOptional,_cTeamInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.shareKey = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!Array.<cTaskInfo>} */
        this.tasks = [];
        /** @type {!Array.<cTransitionInfo>} */
        this.transitions = [];
        /** @type {?Array.<cWorkspaceUserInfo>} */
        this.users = [];
        /** @type {?Array.<cTeamInfo>} */
        this.teams = [];
        updateFields.call(this, obj, _cSharedSnapshotData, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSharedSnapshotData, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSharedSnapshotData, resetDefaults);
        return this;
    }
}
$classes[_cSharedSnapshotData] = cSharedSnapshotData;

class cWorkspaceOverviewForUserRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        workspaceUserId: [_dtpID,null,null],
        projectIds: [_dtpArray | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {?Array} */
        this.projectIds = [];
        updateFields.call(this, obj, _cWorkspaceOverviewForUserRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceOverviewForUserRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceOverviewForUserRequest, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceOverviewForUserRequest] = cWorkspaceOverviewForUserRequest;

class cWorkspaceOverviewForUserResponse extends cSessionMessage {
    static __fieldsDef = {
        overview: [_dtpClass,_cOverviewForUserInWorkspace,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cOverviewForUserInWorkspace} */
        this.overview = undefined;
        updateFields.call(this, obj, _cWorkspaceOverviewForUserResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceOverviewForUserResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceOverviewForUserResponse, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceOverviewForUserResponse] = cWorkspaceOverviewForUserResponse;

class cOverviewForUserInWorkspace extends cProtocolMessage {
    static __fieldsDef = {
        projectUserDigests: [_dtpArray | _dtpClass,_cOverviewForUserInProject,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cOverviewForUserInProject>} */
        this.projectUserDigests = [];
        updateFields.call(this, obj, _cOverviewForUserInWorkspace, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cOverviewForUserInWorkspace, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cOverviewForUserInWorkspace, resetDefaults);
        return this;
    }
}
$classes[_cOverviewForUserInWorkspace] = cOverviewForUserInWorkspace;

class cOverviewForUserInProject extends cProtocolMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        currentTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        nextTasks: [_dtpArray | _dtpClass,_cTaskInfo,null],
        blocks: [_dtpMap | _dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!Array.<cTaskInfo>} */
        this.currentTasks = [];
        /** @type {!Array.<cTaskInfo>} */
        this.nextTasks = [];
        /** @type {!Object.<string, *>} */
        this.blocks = {};
        updateFields.call(this, obj, _cOverviewForUserInProject, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cOverviewForUserInProject, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cOverviewForUserInProject, resetDefaults);
        return this;
    }
}
$classes[_cOverviewForUserInProject] = cOverviewForUserInProject;

class cListOfBlocks extends cProtocolMessage {
    static __fieldsDef = {
        blocks: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array} */
        this.blocks = [];
        updateFields.call(this, obj, _cListOfBlocks, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfBlocks, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfBlocks, resetDefaults);
        return this;
    }
}
$classes[_cListOfBlocks] = cListOfBlocks;

class cWorkspaceOverviewRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        updateFields.call(this, obj, _cWorkspaceOverviewRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceOverviewRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceOverviewRequest, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceOverviewRequest] = cWorkspaceOverviewRequest;

class cWorkspaceOverviewResponse extends cSessionMessage {
    static __fieldsDef = {
        overview: [_dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!*} */
        this.overview = undefined;
        updateFields.call(this, obj, _cWorkspaceOverviewResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cWorkspaceOverviewResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cWorkspaceOverviewResponse, resetDefaults);
        return this;
    }
}
$classes[_cWorkspaceOverviewResponse] = cWorkspaceOverviewResponse;

class cDigestsForProjects extends cProtocolMessage {
    static __fieldsDef = {
        projectDigests: [_dtpMap | _dtpAnyType,null,null],
        issuedAt: [_dtpTimestamp,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object.<string, *>} */
        this.projectDigests = {};
        /** @type {!number} */
        this.issuedAt = 0;
        updateFields.call(this, obj, _cDigestsForProjects, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cDigestsForProjects, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cDigestsForProjects, resetDefaults);
        return this;
    }
}
$classes[_cDigestsForProjects] = cDigestsForProjects;

class cOverviewForWorkspace extends cDigestsForProjects {
    static __fieldsDef = {
        projects: [_dtpMap | _dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object.<string, *>} */
        this.projects = {};
        updateFields.call(this, obj, _cOverviewForWorkspace, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cOverviewForWorkspace, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cOverviewForWorkspace, resetDefaults);
        return this;
    }
}
$classes[_cOverviewForWorkspace] = cOverviewForWorkspace;

class cDigestForProject extends cProtocolMessage {
    static __fieldsDef = {
        projectId: [_dtpID,null,null],
        version: [_dtpNumber,null,null],
        tasks: [_dtpMap | _dtpAnyType,null,null],
        blocks: [_dtpMap | _dtpAnyType,null,null],
        users: [_dtpMap | _dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.projectId = '';
        /** @type {!number} */
        this.version = 0;
        /** @type {!Object.<string, *>} */
        this.tasks = {};
        /** @type {!Object.<string, *>} */
        this.blocks = {};
        /** @type {!Object.<string, *>} */
        this.users = {};
        updateFields.call(this, obj, _cDigestForProject, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cDigestForProject, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cDigestForProject, resetDefaults);
        return this;
    }
}
$classes[_cDigestForProject] = cDigestForProject;

class cDigestForUserInProject extends cProtocolMessage {
    static __fieldsDef = {
        workspaceUserId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        currentTasks: [_dtpArray,null,null],
        nextTasks: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceUserId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!Array} */
        this.currentTasks = [];
        /** @type {!Array} */
        this.nextTasks = [];
        updateFields.call(this, obj, _cDigestForUserInProject, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cDigestForUserInProject, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cDigestForUserInProject, resetDefaults);
        return this;
    }
}
$classes[_cDigestForUserInProject] = cDigestForUserInProject;

class cProductInfo extends cProtocolMessage {
    static __fieldsDef = {
        code: [_dtpString,null,null],
        title: [_dtpString,null,null],
        description: [_dtpString,null,null],
        productType: [_dtpString,null,null],
        definition: [_dtpString,null,null],
        active: [_dtpBoolean,null,null],
        hasTrial: [_dtpBoolean,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.code = '';
        /** @type {!string} */
        this.title = '';
        /** @type {!string} */
        this.description = '';
        /** @type {!string} */
        this.productType = '';
        /** @type {!string} */
        this.definition = '';
        /** @type {!boolean} */
        this.active = false;
        /** @type {!boolean} */
        this.hasTrial = false;
        updateFields.call(this, obj, _cProductInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProductInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProductInfo, resetDefaults);
        return this;
    }
}
$classes[_cProductInfo] = cProductInfo;

class cLimitsDefinition extends cBasePrototype {
    static __fieldsDef = {
        limits: [_dtpObject,null,null],
        features: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object} */
        this.limits = {};
        /** @type {!Array} */
        this.features = [];
        updateFields.call(this, obj, _cLimitsDefinition, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cLimitsDefinition, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cLimitsDefinition, resetDefaults);
        return this;
    }
}
$classes[_cLimitsDefinition] = cLimitsDefinition;

class cProductDefinition extends cLimitsDefinition {
    static __fieldsDef = {
        priceInCents: [_dtpNumber,null,null],
        currency: [_dtpEnum,enmCurrencies,null],
        priceUnit: [_dtpEnum,enmPriceUnit,null],
        timePeriod: [_dtpEnum,enmTimePeriod,null],
        fallbackTo: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!number} */
        this.priceInCents = 0;
        /** @type {!enmCurrencies} */
        this.currency = undefined;
        /** @type {!enmPriceUnit} */
        this.priceUnit = undefined;
        /** @type {!enmTimePeriod} */
        this.timePeriod = undefined;
        /** @type {?string} */
        this.fallbackTo = '';
        updateFields.call(this, obj, _cProductDefinition, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProductDefinition, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProductDefinition, resetDefaults);
        return this;
    }
}
$classes[_cProductDefinition] = cProductDefinition;

class cProductsListRequest extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cProductsListRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProductsListRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProductsListRequest, resetDefaults);
        return this;
    }
}
$classes[_cProductsListRequest] = cProductsListRequest;

class cProductsListResponse extends cSessionMessage {
    static __fieldsDef = {
        products: [_dtpArray | _dtpClass,_cProductInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cProductInfo>} */
        this.products = [];
        updateFields.call(this, obj, _cProductsListResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProductsListResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProductsListResponse, resetDefaults);
        return this;
    }
}
$classes[_cProductsListResponse] = cProductsListResponse;

class cBillableResource extends cBasePrototype {
    static __fieldsDef = {
        name: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        updateFields.call(this, obj, _cBillableResource, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cBillableResource, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cBillableResource, resetDefaults);
        return this;
    }
}
$classes[_cBillableResource] = cBillableResource;

class cProductFeature extends cBasePrototype {
    static __fieldsDef = {
        name: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.name = '';
        updateFields.call(this, obj, _cProductFeature, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProductFeature, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProductFeature, resetDefaults);
        return this;
    }
}
$classes[_cProductFeature] = cProductFeature;

class cBillableResourceLimit extends cBasePrototype {
    static __fieldsDef = {
        resource: [_dtpClass,_cBillableResource,null],
        parent: [_dtpClass,_cBillableResourceLimit,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cBillableResource} */
        this.resource = undefined;
        /** @type {!cBillableResourceLimit} */
        this.parent = undefined;
        updateFields.call(this, obj, _cBillableResourceLimit, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cBillableResourceLimit, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cBillableResourceLimit, resetDefaults);
        return this;
    }
}
$classes[_cBillableResourceLimit] = cBillableResourceLimit;

class cSubscriptionInfo extends cProtocolMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        userId: [_dtpID,null,null],
        productCode: [_dtpID,null,null],
        productTitle: [_dtpString,null,null],
        enabled: [_dtpBoolean,null,null],
        status: [_dtpEnum,enmSubscriptionStatus,null],
        cancelReason: [_dtpEnum | _dtpOptional,enmSubscriptionCancelReason,null],
        createdAt: [_dtpTimestamp,null,null],
        startedAt: [_dtpTimestamp | _dtpOptional,null,null],
        trialSince: [_dtpTimestamp | _dtpOptional,null,null],
        trialUntil: [_dtpTimestamp | _dtpOptional,null,null],
        activeSince: [_dtpTimestamp | _dtpOptional,null,null],
        activeUntil: [_dtpTimestamp | _dtpOptional,null,null],
        currentTermSince: [_dtpTimestamp | _dtpOptional,null,null],
        currentTermUntil: [_dtpTimestamp | _dtpOptional,null,null],
        cancelledAt: [_dtpTimestamp | _dtpOptional,null,null],
        cardPresent: [_dtpBoolean,null,null],
        contents: [_dtpArray | _dtpClass,_cSubscriptionContentInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.userId = '';
        /** @type {!string} */
        this.productCode = '';
        /** @type {!string} */
        this.productTitle = '';
        /** @type {!boolean} */
        this.enabled = false;
        /** @type {!enmSubscriptionStatus} */
        this.status = undefined;
        /** @type {?enmSubscriptionCancelReason} */
        this.cancelReason = undefined;
        /** @type {!number} */
        this.createdAt = 0;
        /** @type {?number} */
        this.startedAt = 0;
        /** @type {?number} */
        this.trialSince = 0;
        /** @type {?number} */
        this.trialUntil = 0;
        /** @type {?number} */
        this.activeSince = 0;
        /** @type {?number} */
        this.activeUntil = 0;
        /** @type {?number} */
        this.currentTermSince = 0;
        /** @type {?number} */
        this.currentTermUntil = 0;
        /** @type {?number} */
        this.cancelledAt = 0;
        /** @type {!boolean} */
        this.cardPresent = false;
        /** @type {!Array.<cSubscriptionContentInfo>} */
        this.contents = [];
        updateFields.call(this, obj, _cSubscriptionInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSubscriptionInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSubscriptionInfo, resetDefaults);
        return this;
    }
}
$classes[_cSubscriptionInfo] = cSubscriptionInfo;

class cSubscriptionContentInfo extends cProtocolMessage {
    static __fieldsDef = {
        objectType: [_dtpString,null,null],
        objectId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.objectType = '';
        /** @type {!string} */
        this.objectId = '';
        updateFields.call(this, obj, _cSubscriptionContentInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cSubscriptionContentInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cSubscriptionContentInfo, resetDefaults);
        return this;
    }
}
$classes[_cSubscriptionContentInfo] = cSubscriptionContentInfo;

class cListActiveSubscriptionsRequest extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cListActiveSubscriptionsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListActiveSubscriptionsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListActiveSubscriptionsRequest, resetDefaults);
        return this;
    }
}
$classes[_cListActiveSubscriptionsRequest] = cListActiveSubscriptionsRequest;

class cListOfActiveSubscriptionsResponse extends cSessionMessage {
    static __fieldsDef = {
        subscriptions: [_dtpArray | _dtpClass,_cSubscriptionInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cSubscriptionInfo>} */
        this.subscriptions = [];
        updateFields.call(this, obj, _cListOfActiveSubscriptionsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfActiveSubscriptionsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfActiveSubscriptionsResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfActiveSubscriptionsResponse] = cListOfActiveSubscriptionsResponse;

class cUserBillingManagementResponse extends cSessionMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmBillingResponseStatus,null],
        payload: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmBillingResponseStatus} */
        this.status = undefined;
        /** @type {!Object} */
        this.payload = {};
        updateFields.call(this, obj, _cUserBillingManagementResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUserBillingManagementResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUserBillingManagementResponse, resetDefaults);
        return this;
    }
}
$classes[_cUserBillingManagementResponse] = cUserBillingManagementResponse;

class cCreateSubscriptionRequest extends cSessionMessage {
    static __fieldsDef = {
        productCode: [_dtpString,null,null],
        workspaceIds: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.productCode = '';
        /** @type {!Array} */
        this.workspaceIds = [];
        updateFields.call(this, obj, _cCreateSubscriptionRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateSubscriptionRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateSubscriptionRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateSubscriptionRequest] = cCreateSubscriptionRequest;

class cChangeSubscriptionPlanRequest extends cSessionMessage {
    static __fieldsDef = {
        subscriptionId: [_dtpID,null,null],
        productCode: [_dtpString,null,null],
        password: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.subscriptionId = '';
        /** @type {!string} */
        this.productCode = '';
        /** @type {!string} */
        this.password = '';
        updateFields.call(this, obj, _cChangeSubscriptionPlanRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cChangeSubscriptionPlanRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cChangeSubscriptionPlanRequest, resetDefaults);
        return this;
    }
}
$classes[_cChangeSubscriptionPlanRequest] = cChangeSubscriptionPlanRequest;

class cUpdateSubscriptionCardRequest extends cSessionMessage {
    static __fieldsDef = {
        subscriptionId: [_dtpID,null,null],
        cardToken: [_dtpString,null,null],
        cardTokenSource: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.subscriptionId = '';
        /** @type {!string} */
        this.cardToken = '';
        /** @type {!string} */
        this.cardTokenSource = '';
        updateFields.call(this, obj, _cUpdateSubscriptionCardRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateSubscriptionCardRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateSubscriptionCardRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateSubscriptionCardRequest] = cUpdateSubscriptionCardRequest;

class cCancelSubscriptionRequest extends cSessionMessage {
    static __fieldsDef = {
        subscriptionId: [_dtpID,null,null],
        password: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.subscriptionId = '';
        /** @type {!string} */
        this.password = '';
        updateFields.call(this, obj, _cCancelSubscriptionRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCancelSubscriptionRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCancelSubscriptionRequest, resetDefaults);
        return this;
    }
}
$classes[_cCancelSubscriptionRequest] = cCancelSubscriptionRequest;

class cRemoveContentFromSubscriptionRequest extends cSessionMessage {
    static __fieldsDef = {
        subscriptionId: [_dtpID,null,null],
        workspaceIds: [_dtpArray,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.subscriptionId = '';
        /** @type {!Array} */
        this.workspaceIds = [];
        updateFields.call(this, obj, _cRemoveContentFromSubscriptionRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveContentFromSubscriptionRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveContentFromSubscriptionRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveContentFromSubscriptionRequest] = cRemoveContentFromSubscriptionRequest;

class cFeatureAuthRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        authEvent: [_dtpClass,_cFeatureAuthData,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!cFeatureAuthData} */
        this.authEvent = undefined;
        updateFields.call(this, obj, _cFeatureAuthRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureAuthRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureAuthRequest, resetDefaults);
        return this;
    }
}
$classes[_cFeatureAuthRequest] = cFeatureAuthRequest;

class cFeatureAuthData extends cProtocolMessage {
    static __fieldsDef = {
        featureName: [_dtpString,null,null],
        providerId: [_dtpID,null,null],
        scopes: [_dtpArray,null,null],
        token: [_dtpString,null,null],
        token2: [_dtpString | _dtpOptional,null,null],
        extId: [_dtpString | _dtpOptional,null,null],
        expires: [_dtpTimestamp | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.featureName = '';
        /** @type {!string} */
        this.providerId = '';
        /** @type {!Array} */
        this.scopes = [];
        /** @type {!string} */
        this.token = '';
        /** @type {?string} */
        this.token2 = '';
        /** @type {?string} */
        this.extId = '';
        /** @type {?number} */
        this.expires = 0;
        updateFields.call(this, obj, _cFeatureAuthData, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureAuthData, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureAuthData, resetDefaults);
        return this;
    }
}
$classes[_cFeatureAuthData] = cFeatureAuthData;

class cFeatureDataRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        data: [_dtpClass,_cFeatureData,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!cFeatureData} */
        this.data = undefined;
        updateFields.call(this, obj, _cFeatureDataRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureDataRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureDataRequest, resetDefaults);
        return this;
    }
}
$classes[_cFeatureDataRequest] = cFeatureDataRequest;

class cFeatureData extends cProtocolMessage {
    static __fieldsDef = {
        featureName: [_dtpString,null,null],
        providerId: [_dtpID | _dtpOptional,null,null],
        event: [_dtpString,null,null],
        data: [_dtpString | _dtpOptional,null,null],
        values: [_dtpObject | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.featureName = '';
        /** @type {?string} */
        this.providerId = '';
        /** @type {!string} */
        this.event = '';
        /** @type {?string} */
        this.data = '';
        /** @type {?Object} */
        this.values = {};
        updateFields.call(this, obj, _cFeatureData, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureData, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureData, resetDefaults);
        return this;
    }
}
$classes[_cFeatureData] = cFeatureData;

class cFeatureAuthChallenge extends cProtocolMessage {
    static __fieldsDef = {
        featureName: [_dtpString,null,null],
        providerId: [_dtpString | _dtpOptional,null,null],
        scopes: [_dtpArray | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.featureName = '';
        /** @type {?string} */
        this.providerId = '';
        /** @type {?Array} */
        this.scopes = [];
        updateFields.call(this, obj, _cFeatureAuthChallenge, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureAuthChallenge, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureAuthChallenge, resetDefaults);
        return this;
    }
}
$classes[_cFeatureAuthChallenge] = cFeatureAuthChallenge;

class cFeatureQuestionRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        question: [_dtpClass,_cFeatureQuestion,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!cFeatureQuestion} */
        this.question = undefined;
        updateFields.call(this, obj, _cFeatureQuestionRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureQuestionRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureQuestionRequest, resetDefaults);
        return this;
    }
}
$classes[_cFeatureQuestionRequest] = cFeatureQuestionRequest;

class cFeatureQuestion extends cProtocolMessage {
    static __fieldsDef = {
        featureName: [_dtpString,null,null],
        providerId: [_dtpID | _dtpOptional,null,null],
        question: [_dtpString,null,null],
        values: [_dtpObject | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.featureName = '';
        /** @type {?string} */
        this.providerId = '';
        /** @type {!string} */
        this.question = '';
        /** @type {?Object} */
        this.values = {};
        updateFields.call(this, obj, _cFeatureQuestion, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureQuestion, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureQuestion, resetDefaults);
        return this;
    }
}
$classes[_cFeatureQuestion] = cFeatureQuestion;

class cFeatureQuestionResponse extends cSessionMessage {
    static __fieldsDef = {
        response: [_dtpObject,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object} */
        this.response = {};
        updateFields.call(this, obj, _cFeatureQuestionResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cFeatureQuestionResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cFeatureQuestionResponse, resetDefaults);
        return this;
    }
}
$classes[_cFeatureQuestionResponse] = cFeatureQuestionResponse;

class cEvernoteError extends cProtocolMessage {
    static __fieldsDef = {
        error: [_dtpEnum,enmEvernoteErrorType,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmEvernoteErrorType} */
        this.error = undefined;
        updateFields.call(this, obj, _cEvernoteError, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cEvernoteError, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cEvernoteError, resetDefaults);
        return this;
    }
}
$classes[_cEvernoteError] = cEvernoteError;

class cEvernoteNotebookInfo extends cProtocolMessage {
    static __fieldsDef = {
        guid: [_dtpString,null,null],
        name: [_dtpString,null,null],
        defaultNotebook: [_dtpBoolean,null,() => true],
        serviceCreated: [_dtpTimestamp,null,null],
        serviceUpdated: [_dtpTimestamp,null,null],
        published: [_dtpBoolean,null,() => false],
        stack: [_dtpString | _dtpOptional,null,null],
        restrictions: [_dtpClass,_cEvernoteNotebookRestrictions,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.guid = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!boolean} */
        this.defaultNotebook = false;
        /** @type {!number} */
        this.serviceCreated = 0;
        /** @type {!number} */
        this.serviceUpdated = 0;
        /** @type {!boolean} */
        this.published = false;
        /** @type {?string} */
        this.stack = '';
        /** @type {!cEvernoteNotebookRestrictions} */
        this.restrictions = undefined;
        updateFields.call(this, obj, _cEvernoteNotebookInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cEvernoteNotebookInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cEvernoteNotebookInfo, resetDefaults);
        return this;
    }
}
$classes[_cEvernoteNotebookInfo] = cEvernoteNotebookInfo;

class cEvernoteNotebookRestrictions extends cProtocolMessage {
    static __fieldsDef = {
        noReadNotes: [_dtpBoolean,null,() => false],
        noCreateNotes: [_dtpBoolean,null,() => false],
        noUpdateNotes: [_dtpBoolean,null,() => false],
        noUpdateNotebook: [_dtpBoolean,null,() => false]
    }
    constructor(obj) {
        super(obj);
        /** @type {!boolean} */
        this.noReadNotes = false;
        /** @type {!boolean} */
        this.noCreateNotes = false;
        /** @type {!boolean} */
        this.noUpdateNotes = false;
        /** @type {!boolean} */
        this.noUpdateNotebook = false;
        updateFields.call(this, obj, _cEvernoteNotebookRestrictions, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cEvernoteNotebookRestrictions, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cEvernoteNotebookRestrictions, resetDefaults);
        return this;
    }
}
$classes[_cEvernoteNotebookRestrictions] = cEvernoteNotebookRestrictions;

class cListOfNotebooks extends cProtocolMessage {
    static __fieldsDef = {
        notebooks: [_dtpArray | _dtpClass,_cEvernoteNotebookInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cEvernoteNotebookInfo>} */
        this.notebooks = [];
        updateFields.call(this, obj, _cListOfNotebooks, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfNotebooks, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfNotebooks, resetDefaults);
        return this;
    }
}
$classes[_cListOfNotebooks] = cListOfNotebooks;

class cListOfLinkedNotebooks extends cProtocolMessage {
    static __fieldsDef = {
        linkedNotebooks: [_dtpArray | _dtpClass,_cEvernoteLinkedNotebookInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Array.<cEvernoteLinkedNotebookInfo>} */
        this.linkedNotebooks = [];
        updateFields.call(this, obj, _cListOfLinkedNotebooks, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfLinkedNotebooks, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfLinkedNotebooks, resetDefaults);
        return this;
    }
}
$classes[_cListOfLinkedNotebooks] = cListOfLinkedNotebooks;

class cEvernoteUserInfo extends cProtocolMessage {
    static __fieldsDef = {
        id: [_dtpString,null,null],
        username: [_dtpString,null,null],
        email: [_dtpString,null,null],
        name: [_dtpString,null,null],
        timezone: [_dtpAnyType,null,null],
        shardId: [_dtpAnyType,null,null],
        businessUserInfo: [_dtpClass,_cEvernoteBusinessUserInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.username = '';
        /** @type {!string} */
        this.email = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!*} */
        this.timezone = undefined;
        /** @type {!*} */
        this.shardId = undefined;
        /** @type {!cEvernoteBusinessUserInfo} */
        this.businessUserInfo = undefined;
        updateFields.call(this, obj, _cEvernoteUserInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cEvernoteUserInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cEvernoteUserInfo, resetDefaults);
        return this;
    }
}
$classes[_cEvernoteUserInfo] = cEvernoteUserInfo;

class cEvernoteBusinessUserInfo extends cProtocolMessage {
    static __fieldsDef = {
        businessId: [_dtpAnyType,null,null],
        businessName: [_dtpString,null,null],
        email: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!*} */
        this.businessId = undefined;
        /** @type {!string} */
        this.businessName = '';
        /** @type {!string} */
        this.email = '';
        updateFields.call(this, obj, _cEvernoteBusinessUserInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cEvernoteBusinessUserInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cEvernoteBusinessUserInfo, resetDefaults);
        return this;
    }
}
$classes[_cEvernoteBusinessUserInfo] = cEvernoteBusinessUserInfo;

class cEvernoteLinkedNotebookInfo extends cProtocolMessage {
    static __fieldsDef = {
        guid: [_dtpString,null,null],
        shareName: [_dtpString,null,null],
        username: [_dtpString,null,null],
        stack: [_dtpString | _dtpOptional,null,null],
        shardId: [_dtpString | _dtpOptional,null,null],
        uri: [_dtpString | _dtpOptional,null,null],
        businessId: [_dtpNumber | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.guid = '';
        /** @type {!string} */
        this.shareName = '';
        /** @type {!string} */
        this.username = '';
        /** @type {?string} */
        this.stack = '';
        /** @type {?string} */
        this.shardId = '';
        /** @type {?string} */
        this.uri = '';
        /** @type {?number} */
        this.businessId = 0;
        updateFields.call(this, obj, _cEvernoteLinkedNotebookInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cEvernoteLinkedNotebookInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cEvernoteLinkedNotebookInfo, resetDefaults);
        return this;
    }
}
$classes[_cEvernoteLinkedNotebookInfo] = cEvernoteLinkedNotebookInfo;

class cProjectTagInfo extends cMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        name: [_dtpString,null,null],
        color: [_dtpColor,null,() => '#fff'],
        order: [_dtpNumber,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.color = '';
        /** @type {!number} */
        this.order = 0;
        updateFields.call(this, obj, _cProjectTagInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectTagInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectTagInfo, resetDefaults);
        return this;
    }
}
$classes[_cProjectTagInfo] = cProjectTagInfo;

class cListProjectTagsRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        updateFields.call(this, obj, _cListProjectTagsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListProjectTagsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListProjectTagsRequest, resetDefaults);
        return this;
    }
}
$classes[_cListProjectTagsRequest] = cListProjectTagsRequest;

class cListOfProjectTagsResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectTags: [_dtpArray | _dtpClass,_cProjectTagInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!Array.<cProjectTagInfo>} */
        this.projectTags = [];
        updateFields.call(this, obj, _cListOfProjectTagsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cListOfProjectTagsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cListOfProjectTagsResponse, resetDefaults);
        return this;
    }
}
$classes[_cListOfProjectTagsResponse] = cListOfProjectTagsResponse;

class cCreateProjectTagRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        name: [_dtpString,null,null],
        color: [_dtpColor,null,null],
        order: [_dtpNumber,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.color = '';
        /** @type {!number} */
        this.order = 0;
        updateFields.call(this, obj, _cCreateProjectTagRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCreateProjectTagRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCreateProjectTagRequest, resetDefaults);
        return this;
    }
}
$classes[_cCreateProjectTagRequest] = cCreateProjectTagRequest;

class cUpdateProjectTagRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        tagId: [_dtpID,null,null],
        name: [_dtpString,null,null],
        color: [_dtpColor,null,null],
        order: [_dtpNumber,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.tagId = '';
        /** @type {!string} */
        this.name = '';
        /** @type {!string} */
        this.color = '';
        /** @type {!number} */
        this.order = 0;
        updateFields.call(this, obj, _cUpdateProjectTagRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cUpdateProjectTagRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cUpdateProjectTagRequest, resetDefaults);
        return this;
    }
}
$classes[_cUpdateProjectTagRequest] = cUpdateProjectTagRequest;

class cRemoveProjectTagRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        tagId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.tagId = '';
        updateFields.call(this, obj, _cRemoveProjectTagRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRemoveProjectTagRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRemoveProjectTagRequest, resetDefaults);
        return this;
    }
}
$classes[_cRemoveProjectTagRequest] = cRemoveProjectTagRequest;

class cProjectTagStatusResponse extends cSessionMessage {
    static __fieldsDef = {
        projectTag: [_dtpClass,_cProjectTagInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cProjectTagInfo} */
        this.projectTag = undefined;
        updateFields.call(this, obj, _cProjectTagStatusResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cProjectTagStatusResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cProjectTagStatusResponse, resetDefaults);
        return this;
    }
}
$classes[_cProjectTagStatusResponse] = cProjectTagStatusResponse;

class cTagProjectRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,() => $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id],
        projectId: [_dtpID,null,null],
        tagId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.tagId = '';
        updateFields.call(this, obj, _cTagProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cTagProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cTagProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cTagProjectRequest] = cTagProjectRequest;

class cAnswer extends cProtocolMessage {
    static __fieldsDef = {
        status: [_dtpEnum,enmAnswerStatus,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmAnswerStatus} */
        this.status = undefined;
        updateFields.call(this, obj, _cAnswer, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cAnswer, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cAnswer, resetDefaults);
        return this;
    }
}
$classes[_cAnswer] = cAnswer;

class cStoryPageSettings extends cProtocolMessage {
    static __fieldsDef = {
        id: [_dtpID,null,null],
        pageInfo: [_dtpClass,_cStoryPageInfo,null],
        pageSocial: [_dtpClass | _dtpOptional,_cStoryPageSocial,null],
        previewContent: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.id = '';
        /** @type {!cStoryPageInfo} */
        this.pageInfo = undefined;
        /** @type {?cStoryPageSocial} */
        this.pageSocial = undefined;
        /** @type {!string} */
        this.previewContent = '';
        updateFields.call(this, obj, _cStoryPageSettings, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStoryPageSettings, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStoryPageSettings, resetDefaults);
        return this;
    }
}
$classes[_cStoryPageSettings] = cStoryPageSettings;

class cStoryPage extends cStoryPageSettings {
    static __fieldsDef = {
        contentParts: [_dtpMap | _dtpAnyType,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!Object.<string, *>} */
        this.contentParts = {};
        updateFields.call(this, obj, _cStoryPage, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStoryPage, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStoryPage, resetDefaults);
        return this;
    }
}
$classes[_cStoryPage] = cStoryPage;

class cStoryPageSocial extends cProtocolMessage {
    static __fieldsDef = {
        tweet: [_dtpString | _dtpOptional,null,null],
        ogTitle: [_dtpString | _dtpOptional,null,null],
        ogDescription: [_dtpString | _dtpOptional,null,null],
        ogImage: [_dtpString | _dtpOptional,null,null],
        seoTitle: [_dtpString | _dtpOptional,null,null],
        seoDescription: [_dtpString | _dtpOptional,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {?string} */
        this.tweet = '';
        /** @type {?string} */
        this.ogTitle = '';
        /** @type {?string} */
        this.ogDescription = '';
        /** @type {?string} */
        this.ogImage = '';
        /** @type {?string} */
        this.seoTitle = '';
        /** @type {?string} */
        this.seoDescription = '';
        updateFields.call(this, obj, _cStoryPageSocial, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStoryPageSocial, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStoryPageSocial, resetDefaults);
        return this;
    }
}
$classes[_cStoryPageSocial] = cStoryPageSocial;

class cStoryPageInfo extends cProtocolMessage {
    static __fieldsDef = {
        templateId: [_dtpEnum,enmStoryPageTemplate,() => enmStoryPageTemplate.story],
        coverImage: [_dtpString,null,null],
        title: [_dtpString,null,null],
        authorName: [_dtpString,null,null],
        authorTitle: [_dtpString,null,null],
        authorBio: [_dtpString,null,null],
        authorImage: [_dtpString,null,null],
        source: [_dtpString,null,null],
        sourceLink: [_dtpString,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!enmStoryPageTemplate} */
        this.templateId = undefined;
        /** @type {!string} */
        this.coverImage = '';
        /** @type {!string} */
        this.title = '';
        /** @type {!string} */
        this.authorName = '';
        /** @type {!string} */
        this.authorTitle = '';
        /** @type {!string} */
        this.authorBio = '';
        /** @type {!string} */
        this.authorImage = '';
        /** @type {!string} */
        this.source = '';
        /** @type {!string} */
        this.sourceLink = '';
        updateFields.call(this, obj, _cStoryPageInfo, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStoryPageInfo, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStoryPageInfo, resetDefaults);
        return this;
    }
}
$classes[_cStoryPageInfo] = cStoryPageInfo;

class cStoryPart extends cProtocolMessage {
    static __fieldsDef = {
        taskId: [_dtpID,null,null],
        title: [_dtpString,null,null],
        content: [_dtpString,null,null],
        partType: [_dtpEnum,enmStoryPartType,() => enmStoryPartType.Section],
        flow: [_dtpEnum,enmStoryPartFlow,() => enmStoryPartFlow.Normal]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.taskId = '';
        /** @type {!string} */
        this.title = '';
        /** @type {!string} */
        this.content = '';
        /** @type {!enmStoryPartType} */
        this.partType = undefined;
        /** @type {!enmStoryPartFlow} */
        this.flow = undefined;
        updateFields.call(this, obj, _cStoryPart, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStoryPart, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStoryPart, resetDefaults);
        return this;
    }
}
$classes[_cStoryPart] = cStoryPart;

class cStoryMedia extends cProtocolMessage {
    static __fieldsDef = {
        fileId: [_dtpID,null,null],
        title: [_dtpString,null,null],
        mediaType: [_dtpEnum,enmStoryMediaType,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.fileId = '';
        /** @type {!string} */
        this.title = '';
        /** @type {!enmStoryMediaType} */
        this.mediaType = undefined;
        updateFields.call(this, obj, _cStoryMedia, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cStoryMedia, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cStoryMedia, resetDefaults);
        return this;
    }
}
$classes[_cStoryMedia] = cStoryMedia;

class cReportProjectsAndStatsRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        scope: [_dtpEnum,enmReportProjectsScope,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!enmReportProjectsScope} */
        this.scope = undefined;
        updateFields.call(this, obj, _cReportProjectsAndStatsRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cReportProjectsAndStatsRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cReportProjectsAndStatsRequest, resetDefaults);
        return this;
    }
}
$classes[_cReportProjectsAndStatsRequest] = cReportProjectsAndStatsRequest;

class cReportProjectsAndStatsResponse extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        entries: [_dtpArray | _dtpClass,_cReportEntry,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!Array.<cReportEntry>} */
        this.entries = [];
        updateFields.call(this, obj, _cReportProjectsAndStatsResponse, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cReportProjectsAndStatsResponse, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cReportProjectsAndStatsResponse, resetDefaults);
        return this;
    }
}
$classes[_cReportProjectsAndStatsResponse] = cReportProjectsAndStatsResponse;

class cReportEntry extends cProtocolMessage {
    static __fieldsDef = {
        project: [_dtpClass,_cProjectInfo,null],
        stats: [_dtpClass,_cHistoryEntryInfo,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!cProjectInfo} */
        this.project = undefined;
        /** @type {!cHistoryEntryInfo} */
        this.stats = undefined;
        updateFields.call(this, obj, _cReportEntry, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cReportEntry, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cReportEntry, resetDefaults);
        return this;
    }
}
$classes[_cReportEntry] = cReportEntry;

class cCheckTrialEligibilityRequest extends cSessionMessage {
    static __fieldsDef = {

    }
    constructor(obj) {
        super(obj);
;
        updateFields.call(this, obj, _cCheckTrialEligibilityRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cCheckTrialEligibilityRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cCheckTrialEligibilityRequest, resetDefaults);
        return this;
    }
}
$classes[_cCheckTrialEligibilityRequest] = cCheckTrialEligibilityRequest;

class cRedeemTrialOfferRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        updateFields.call(this, obj, _cRedeemTrialOfferRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cRedeemTrialOfferRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cRedeemTrialOfferRequest, resetDefaults);
        return this;
    }
}
$classes[_cRedeemTrialOfferRequest] = cRedeemTrialOfferRequest;

class cConsultantCloneProjectRequest extends cSessionMessage {
    static __fieldsDef = {
        workspaceId: [_dtpID,null,null],
        projectId: [_dtpID,null,null],
        newName: [_dtpString,null,null],
        newDescription: [_dtpString | _dtpOptional,null,null],
        targetWorkspaceId: [_dtpID,null,null]
    }
    constructor(obj) {
        super(obj);
        /** @type {!string} */
        this.workspaceId = '';
        /** @type {!string} */
        this.projectId = '';
        /** @type {!string} */
        this.newName = '';
        /** @type {?string} */
        this.newDescription = '';
        /** @type {!string} */
        this.targetWorkspaceId = '';
        updateFields.call(this, obj, _cConsultantCloneProjectRequest, true);
    }
    toString(r) {
        !r && (r = {});
        return stringify(this.$export(r, true));
    }
    $export(r, setOnly = false) {
        !r && (r = {});
        super.$export(r, setOnly);
        exportFields.call(this, r, _cConsultantCloneProjectRequest, setOnly);
        return r;
    }
    $update(obj, resetDefaults = false) {
        super.$update(obj);
        updateFields.call(this, obj, _cConsultantCloneProjectRequest, resetDefaults);
        return this;
    }
}
$classes[_cConsultantCloneProjectRequest] = cConsultantCloneProjectRequest;

PROTOTYPING = false;
if (typeof exports === "object") { exports = $classes; }