/**
 * Queues implementation
 * @author Jacky Shikerya
 */

$classes.DelayedQueue = function(ninterval) {
    const container = {},
        interval = ninterval || 1000;

    this.add = (id, callback, args) => {
        const poll = () => {
            const d = container[id];
            delete container[id];
            core_utils.untie(d.callback, d.args);
        };

        if (container[id]) {
            clearTimeout(container[id].timer);
            container[id].timer = setTimeout(poll, interval);
        } else {
            container[id] = {
                timer: setTimeout(poll, interval),
                callback,
                args
            };
        }
    };
};

$classes.WatchQueue = function() {
    const list = {};

    function processKeys(item, events) {
        const p = item.events;

        switch (true) {
            case Object.isArray(events):
                item.events = p.concat(events);
                break;
            case Object.isObject(events):
                for (const i in events) {
                    p.push(i);
                }
                break;
            case Object.isString(events):
                if (/,/.test(events)) {
                    item.events = p.concat(events.split(','));
                } else {
                    p.push(events);
                }
                break;
        }
    }

    return {
        queue(events, id, callback) {
            let p;
            list[id || (id = new Date().getTime())] = {
                callback,
                events: []
            };
            processKeys(list[id], events);
            return id;
        },
        isComplete(id) {
            return list[id] === undefined;
        },
        append(id, events) {
            processKeys(list[id], events);
        },
        cancel(id) {
            if (list[id]) delete list[id];
        },
        fire(event, data) {
            // console.log('WatchQueue, processing event', event);
            for (const i in list) {
                const p = list[i],
                    e = list[i].events,
                    k = e.indexOf(event);

                if (k > -1) {
                    e.splice(k, 1);

                    if (e.length == 0) {
                        DEBUG && console.log(`WatchQueue::queueFinished(${i})`);
                        if (p.callback) {
                            switch (typeof p.callback) {
                                case 'string':
                                    core.moses.announce(p.callback, data);
                                    break;
                                case 'function':
                                    core_utils.untie(p.callback, data);
                                    break;
                            }
                        }

                        delete list[i];
                    }
                }
            }
        }
    };
};
