/**
 * URI handler object
 * @author Jacky Shikerya
 */

core.uriHandler = (() => {
    const handlers = {};
    let defPage;
    let handler;

    // if (__USE_HASH) {
    //     const getHash = () => window.location.hash.replace(/^#/, '');

    //     const setUri = function(id) {
    //         // !uri && (uri = '') == '' || Object.isArray(uri) && (uri = uri.join('/'));
    //         const uri = this.mkLink(id);
    //         DEBUG && console.log(`uriHandler::setUri(${uri})`);
    //         if (window.location.hash != uri) {
    //             window.location.hash = uri;
    //         } else {
    //             handler();
    //         }
    //     };

    //     var repUri = null;

    //     const /** FIXME: complete replace uri for hash support */
    //         setUp = () => {
    //             window.onhashchange = handler;
    //         };
    // } else {
    const root = new RegExp(__BUILD_APP ? _CONF_APPROOTURL : _CONF_VISITORS_ROOTURL);
    const getHash = () => {
        let u = decodeURIComponent(window.location.pathname)
            .replace(root, '')
            .replace(/^\//, '');
        const h = decodeURIComponent(window.location.hash).replace(/^#/, '');

        if (h && (u == '/' || u == '')) {
            u = h;
        }

        return u;
    };
    const setUp = () => {
        $(window).on('popstate', handler);
    };
    const repUri = function(id) {
        const uri = this.mkLink(id);
        DEBUG && console.log(`uriHandler::repUri(${uri})`);
        history.replaceState(null, null, uri);
    };
    const setUri = function(id, justUri, navigate, noHistory) {
        if (id === undefined) {
            // history.pushState(null, null, window.location.pathname);
            handler(true);
            return;
        }

        const uri = this.mkLink(id);

        navigate === undefined && (navigate = true);

        DEBUG && console.log(`uriHandler::setUri(${uri})`);
        if (getHash() != uri) {
            const mthd = (noHistory && history.replaceState) || history.pushState;
            mthd.call(history, null, null, `${__BUILD_APP ? _CONF_APPROOTURL : _CONF_VISITORS_ROOTURL}/${uri}`);
            !justUri && handler(navigate);
        } else {
            !justUri && handler(navigate);
        }
    };
    // }

    handler = navigate => {
        const hash = getHash();
        DEBUG && console.log('hashChange: ', hash || '(empty)');

        if (hash == '' && defPage) {
            core_utils.untie(defPage, [!!navigate, hash || []]);
            return;
        }

        let found = false;

        $.each(handlers, (i, v) => {
            let m;
            const p = _UM[i];
            m = p.pcre.exec(hash);
            if (m) {
                core_utils.untie(v, [!!navigate, (m.length > 1 && m.slice(1)) || m || []]);
                found = true;
                return false;
            }
        });

        if (!found) {
            debugger;
            // FIXME: handle page404
        }
    };

    return {
        init() {
            DEBUG && console.log('uriHandler::init');
            setUp();
            core.moses.announce(_MOSES_URI_READY);
            DEBUG && console.log('handling default page through hashChange event');
            handler();
            return this;
        },
        registerDefault(handler, alias) {
            defPage = handler;
            if (alias) {
                handlers[alias] = handler;
            }
            return this;
        },
        registerHandler(id, handler) {
            handlers[id] = handler;
            return this;
        },
        setUri(id, justUri, navigate, noHistory) {
            setUri.call(this, id, justUri, navigate, noHistory);
        },
        repUri(id) {
            repUri.call(this, id);
        },
        mkLink(id, output) {
            const p = _UM[id.id || id || ''];
            let r;

            if (p == undefined && typeof id == 'string' && id.length != 0) {
                r = id;
            } else if (p == undefined) {
                r = '';
            } else if (typeof id == 'object') {
                if (p.make) {
                    const o = {
                        CPR: $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id,
                        CWS: $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id,
                        CTID: core_DO.getCTID(),
                        uri: p.handle
                    };
                    r = (p.make instanceof Function ? p.make(id, o) : p.make)
                        .format({ ...o, ...id })
                        .replace(/\{\w+\}/, '');
                } else {
                    r = p.handle;
                }
            } else {
                r = p.handle;
            }
            if (__USE_HASH) {
                r = (r && ((!/^#/.test(r) && `#${r}`) || r)) || '';
            }
            if (output) {
                output.append(r);
            }
            return r;
        },
        getActual() {
            return getHash();
        },
        force() {
            handler();
        }
    };
})();

core_utils.intExport(_ienUriMkLink, core.uriHandler.mkLink.bind(core.uriHandler));
core_utils.intExport(_ienAllProjectsLink, () =>
    core.uriHandler.mkLink({ id: _umWorkspace, wsId: $scope[_bndCurrentWorkspace].id, link: 'projects' })
);

core.uri = {};

core.uri.mkTaskLink = id =>
    core.uriHandler.mkLink({
        id: _umProject,
        prId: $scope[_bndCurrentProject].id,
        add: `/task:${id}:${core_DO.getCTID()}`
    });

core.uri.mkProjLink = obj => {
    obj['id'] = _umProject;
    !obj['prId'] && (obj['prId'] = $scope[_bndCurrentProject].id);
    return core.uriHandler.mkLink(obj);
};

// core.uri.mkTaskLink = function (obj, output) {
//     var r = core.uriHandler.mkLink({
//         id: _umProject,
//         'mode': obj['mode'] || _upmExecutor,
//         'gid': obj['gid'],
//         'tid': obj['tid']
//     })
//     !obj['prId'] && ($scope[_bndCurrentProject].id);
// }
