/**
 * Observer implementation
 * @author Jacky Shikerya <shikerya@me.com>
 */

class Observer {
    _list = [];
    _suspended = false;
    _touched = false;
    _asyncAnnounceType;
    debouncedPublish;
    _suspendedArgs;

    constructor(asyncAnnounceType) {
        this._asyncAnnounceType = asyncAnnounceType === false ? false : true;
        this.debouncedPublish = this.publish.debounce(25).bind(this);
    }

    subscribe(callback, scope) {
        const cnt = this._list.length;

        scope && (callback = callback.bind(scope));

        if (this._list.indexOf(callback) == -1) {
            const a = this._list;
            (a && a.push(callback)) || (DEBUG && console.log(a));
        }
        return cnt;
    }

    unsubscribe(callback) {
        if (callback == undefined) {
            this._list = [];
        } else {
            if (!this._list) {
                return;
            }
            const i = this._list.indexOf(callback);
            if (i === -1) {
                return false;
            }
            this._list.splice(i, 1);
        }

        return true;
    }
    publish() {
        if (this._suspended) {
            this._touched = true;
            this._suspendedArgs && this._suspendedArgs.push(Array.prototype.slice.call(arguments));
            return;
        }
        for (var i = this._list.length; --i >= 0; ) {
            this._asyncAnnounceType
                ? core_utils.untie(this._list[i], arguments, true)
                : this._list[i].apply(null, arguments);
        }
    }

    publishWithScope(scope, args) {
        for (const i = this._list.length; --i >= 0; ) {
            this._list[i].apply(scope || null, args);
        }
    }

    count() {
        return this._list.length;
    }

    suspend(recordArgs) {
        this._suspended = true;
        if (recordArgs) {
            this.suspendedArgs_ = [];
        }
    }

    resume() {
        this._suspended = false;
        if (this._touched) {
            this.publish(this._suspendedArgs);
            delete this._suspendedArgs;
        }
    }
}

$classes.Observer = Observer;
