/**
 * @fileoverview Constants definition
 * @author Jacky Shikerya
 */
'use strict';
/**
 * Global Constants definition
 */
const /** @const */ _gcSESSION_ID_NAME = 'sessID';
const /** @const */ _gcSESSION_FIELD = 'sessionKey';
const /** @const */ _gcPROJECT_STATE_CHANGES_PUSH_DELAY = 600;
// interval for watchdog in ms
const /** @const */ _gcWatchDogTimeout = 5e3;
// how long package have to wait for response before it would be resend
const /** @const */ _gcPackageResponseTimeout = 3e4;
// how much quants to wait for response for request (package timeout is 1sec)
const /** @const */ _gcSocketReconnectTimeout = 1e4;
const /** @const */ _gcSocketStringDelimiter = ' ';
const /** @const X */ _PackageStatusQueued  = 'a9';
const /** @const X */ _PackageStatusInRetry  = 'aa';
const /** @const X */ _PackageStatusAwaiting  = 'ab';
const /** @const X */ _PackageStatusProcessing  = 'ac';
const /** @const X */ _PackageStatusDone  = 'ad';
const /** @const */ _gcSubscribePrefix = '@subscribe';
const /** @const */ _gcNotificationPrefix = '@notification';
const /** @const */ _gcSessionWatchDogInterval = 6e4; //__DEV__ ? 6e4 : 6e4*5;
const _gcMobileBanner = 'mobile-banner';
const /** @const */ _gcProjectTitle = 'Casual';
const /** @const */ _gcTitleSeparator = ' – ';
const /** @const */ _gcDefaultLocale = 'en';
const /** @const */ _gcSupportedLocales = ['en']; //@supported.locales@;
const /** @const XC */ _gcMosesWatchClass  = 'am';
const /** @const */ _gcNameMaxLength = 100;
const /** @const */ _gcDescrMaxLength = 150;
const /** @const */ _gcAvatarMaxFilesize = 5e6;
const /** @const */ _gcAvatarFileTypes = /(png)|(jpe?g)|(gif)$/i;
const /** @const */ _gcImageLoadTimeout = 5e3;
const /** @const */ _gcImageLoadErrorRetry = 4;
const /** @const */ _gcImageLoadErrorDelay = 5e3;
const /** @const */ _gcClipboardId = 'application/json';
const /** @const */ _gcLimitBBTasks = 3;
const /** @const */ _gcLanguageCookie = 'lang';
const /** @const */ _gcHiddenLangs = ['uk'];
const /** @const */ _gcNotificationsPopupLimit = 5;
const /** @const */ _gcNotificationsDialogLimit = 7;
const /** @const */ _gcVideoGuideURL = 'https://www.youtube.com/embed/s4oTIrLhB5Q';
const /** @const */ _gcDblClickDebounceRate = 300;
const /** @const */ _gcMaxLogLength = 1e3;
const /** @const */ _gcAlertifyTimeout = 10000;
const /** @const */ _gcHistoryLimit = 15;
const /** @const */ _gcSharesPerProjectLimit = 3;
const /** @const X */ _gcEmbedProjectId  = 'b6';
const /** @const */ _gcConsoleLogLimit = 500;
const /** @const */ _gcSelectedWorkspace = 'pws';
const /** @const */ _gcTaskFadeTimeout = 10000; // how long changed task state will wait to rollback state before it would be removed from WS Overview pane
const /** @const */ _gcTransitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd';
const /** @const C */ _gcProjectOverviewWidth = 350;
const /** @const */ _gcOverviewHorizontValues = [1, 3, 5, 10, 15];
const /** @const */ _skeyOverviewHorizontKey = 'OverviewHorizontKey';
const /** @const */ _gcOverviewHorizontDefaultValue = 3;
const /** @const */ _gcTreeMarkerInc = 300;
const /** @const X */ _gcOnSvgPanEvent  = 'bg';
const /** @const */ _gcDropBoxScript = '//www.dropbox.com/static/api/2/dropins.js';
const /** @const */ _gcBoxScript = '//app.box.com/js/static/select.js';
const /** @const X */ _gcGoogleDriveCallbackFunction  = 'bj';
const /** @const */ _gcGoogleDriveClient = '//apis.google.com/js/client.js?onload=' + _gcGoogleDriveCallbackFunction;
const /** @const */ _gcGoogleApiScript = 'https://apis.google.com/js/api.js?onload=' + _gcGoogleDriveCallbackFunction;
const /** @const */ _gcReverseGeocodeUrl =
        'https://maps.googleapis.com/maps/api/geocode/json?latlng=%s,%s&result_type=street_address&key=%s';
const /** @const */ _gcSupportContact = 'mailto://support@casual.pm';
const /** @const X */ _gcGoogleAuthCallback  = 'bp';
const /** @const */ _gcOneDriveScript = 'https://js.live.net/v7.0/OneDrive.js';
const /** @const */ _gcOneDriveRedirect = '/s/code/onedrive.html';
const /** @const */ _gcSymbolLength = 2; // length of symbol for project team
const /** @const X */ _gcFeatureAuthSuccessQueueId  = 'bt';
const /** @const X */ _gcFeatureAuthFailQueueId  = 'bu';
const /** @const */ _gcLogLenghtForReporting = 150;
const /** @const */ _gcStoryEditorScript = '//cdnjs.cloudflare.com/ajax/libs/trix/0.9.8/trix.js';
const /** @const */ _gcStoryEditorCSS = '//cdnjs.cloudflare.com/ajax/libs/trix/0.9.8/trix.css';
const /** @const */ _gcStoryPreviewUri = 'dev/story/page';
const /** @const */ _gcStoryPublishUri = 'dev/content/publish';
const /** @const */ _gcMaxPaperSize = 14400;
const _gcFontData = '/s/svg-resource/open-sans-regular.ttf';
const /** @const X */ _MOSES_CORE_INIT  = 'c2';
const /** @const X */ _MOSES_CORE_START  = 'c3';
const /** @const X */ _MOSES_CORE_READY  = 'c4';
const /** @const X */ _MOSES_LOGIN  = 'c5';
const /** @const X */ _MOSES_LOGOUT  = 'c6';
const /** @const X */ _MOSES_URI_READY  = 'c7';
const /** @const X */ _MOSES_IFACE_READY  = 'c8';
const /** @const X */ _MOSES_PAGE_CHANGED  = 'c9';
const /** @const X */ _MOSES_TASKS_READY  = 'ca';
const /** @const X */ _MOSES_NO_SESSION  = 'cb';
const /** @const X */ _MOSES_WS_CURRENT  = 'cc';
const /** @const X */ _MOSES_PR_CURRENT  = 'cd';
const /** @const X */ _MOSES_USER_READY  = 'ce';
const /** @const X */ _MOSES_USER_PREF_READY  = 'cf';
const /** @const X */ _MOSES_INIT_DATA_READY  = 'cg';
const /** @const X */ _MOSES_DATA_READY  = 'ch';
const /** @const */ _ID_PCRE = /^\w{8}\-(\w{4}\-){3}\w{12}$/;
const /** @const */ _gcUserINIFont = '20pt "FreeSet W10 Demi", "Arial Bold", Arial';
const /** @const X */ _gcCoreDataReadyWatcherID  = 'ck';
const /** @const X */ _gcCoreReadyWatcherID  = 'cl';
const /** @const X */ _clAbstractWidget  = 'cm';
const /** @const X */ _clAbstractPageController  = 'cn';
const /** @const X */ _gcGroupInfoStorage  = 'co';
const /** @const X */ _gcGroupCanvasStorage  = 'cp';
const /** @const X */ _gcMixPanelAccount  = 'cq';
const /** @const */ _gcMixPanelDateFormat = 'YYYY-MM-DD';
const /** @const */ _gcMaxInteger = Number.MAX_SAFE_INTEGER;
/**
 * Notifications
 */
const /** @const */ _nFetchState = 'FetchState';
const /** @const */ _nChannelOpened = 'ChannelOpened';
const /** @const */ _nSessionLost = 'SessionLost';
const /** @const */ _nWorkspaceCreated = 'WorkspaceCreated';
const /** @const */ _nWorkspaceUpdated = 'WorkspaceUpdated';
const /** @const */ _nWorkspaceRemoved = 'WorkspaceRemoved';
const /** @const */ _nWorkspaceInfoDelivery = 'WorkspaceInfoDelivery';
const /** @const */ _nWorkspaceWritable = 'WorkspaceWritable';
const /** @const */ _nWorkspaceReadOnly = 'WorkspaceReadOnly';
const /** @const */ _nWorkspaceLimitsDelivery = 'WorkspaceLimitsDelivery';
const /** @const */ _nWorkspaceSettingsUpdated = 'WorkspaceSettingsUpdated';
const /** @const */ _nProjectCreated = 'ProjectCreated';
const /** @const */ _nProjectUpdated = 'ProjectUpdated';
const /** @const */ _nProjectRemoved = 'ProjectRemoved';
const /** @const */ _nProjectRestored = 'ProjectRestored';
const /** @const */ _nProjectArchived = 'ProjectArchived';
const /** @const */ _nProjectTagCreated = 'ProjectTagCreated';
const /** @const */ _nProjectTagUpdated = 'ProjectTagUpdated';
const /** @const */ _nProjectTagRemoved = 'ProjectTagRemoved';
const /** @const */ _nAddedToStandardTeam = 'AddedToStandardTeam';
const /** @const */ _nRemovedFromStandardTeam = 'RemovedFromStandardTeam';
const /** @const */ _nStandardTeamCreated = 'StandardTeamCreated';
const /** @const */ _nStandardTeamUpdated = 'StandardTeamUpdated';
const /** @const */ _nStandardTeamRemoved = 'StandardTeamRemoved';
const /** @const */ _nStateChanged = 'StateChanged';
const /** @const */ _nUserImageUpdated = 'UserImageUpdated';
const /** @const */ _nWorkspaceImageUpdated = 'WorkspaceImageUpdated';
const /** @const */ _nWorkspaceUserCreated = 'WorkspaceUserCreated';
const /** @const */ _nWorkspaceUserUpdated = 'WorkspaceUserUpdated';
const /** @const */ _nWorkspaceUserContactUpdated = 'WorkspaceUserContactUpdated';
const /** @const */ _nWorkspaceUserRemoved = 'WorkspaceUserRemoved';
const /** @const */ _nUserInfoUpdated = 'UserInfoUpdated';
const /** @const */ _nProjectMemberRemoved = 'ProjectMemberRemoved';
const /** @const */ _nProjectMemberAdded = 'ProjectMemberAdded';
const /** @const */ _nProjectMemberRoleChanged = 'ProjectMemberRoleChanged';
const /** @const */ _nAddTaskExecutor = 'AddTaskExecutor';
const /** @const */ _nRemoveTaskExecutor = 'RemoveTaskExecutor';
const /** @const */ _nHistoryDelivery = 'HistoryDelivery';
const /** @const */ _nCommentPosted = 'CommentPosted';
const /** @const */ _nCommentRemoved = 'CommentRemoved';
const /** @const */ _nHistoryEvent = 'HistoryEvent';
const /** @const */ _nUserAlert = 'UserAlert';
const /** @const */ _nFileCreated = 'FileCreated';
const /** @const */ _nFileRemoved = 'FileRemoved';
const /** @const */ _nFilesDelivery = 'FilesDelivery';
const /** @const */ _nFileUpdated = 'FileUpdated';
const /** @const */ _nDebugLogsRequest = 'DebugLogsRequest';
const /** @const */ _nClipboardDelivery = 'ClipboardDelivery';
const /** @const */ _nClipboardCleared = 'ClipboardCleared';
const /** @const */ _nClipboardPreview = 'ClipboardPreview';
const /** @const */ _nUploadTicketDelivery = 'UploadTicketDelivery';
const /** @const */ _nWorkspaceOwnershipChanged = 'WorkspaceOwnershipChanged';
const /** @const */ _nTeamCreated = 'TeamCreated';
const /** @const */ _nTeamRemoved = 'TeamRemoved';
const /** @const */ _nTeamUpdated = 'TeamUpdated';
const /** @const */ _nAddedToTeam = 'AddedToTeam';
const /** @const */ _nRemovedFromTeam = 'RemovedFromTeam';
const /** @const */ _nFeatureEvent = 'FeatureEvent';
const /** @const */ _nFeatureAuthResult = 'FeatureAuthResult';
const /** @const */ _nFeatureAuthSuccess = 'FeatureAuthSuccess';
const /** @const */ _nFeatureAuthFailure = 'FeatureAuthFailure';
/**
 * Deadline date selector mode
 * @enum */
const enmTaskDateMode = {
    deadline: 0,
    complete: 1,
    startDate: 2
};
/**
 * Dialog types
 */
const /** @const */ _dgtModal = 'modal';
const /** @const */ _dgtNotification = 'notification';
const /** @const */ _dgtWelcome = 'welcome';
/**
 * Stream names
 */
/** regular uploads @const */
const _avSNUploads = 'uploads';
/** user picture @const */
const _avSNUserPics = 'pic-u';
/** Workspace user picture @const*/
const _avSNWSUserPics = 'pic-wsu';
/** Workspace pictures @const */
const _avSNWSPics = 'pic-ws';
/** Project picture @const */
const _avSNPrPics = 'pic-p';
/**
 * Target names
 */
const /** @const */ _avTNUser = 'User';
const /** @const */ _avTNWorkspaceUser = 'WorkspaceUser';
const /** @const */ _avTNWorkspace = 'Workspace';
const /** @const */ _avTNProject = 'Project';
/**
 * Size Versions
 */
const /** @const C */ _avSVOriginal = 'o';
const /** @const XC */ _cssSVOriginal  = 'fn';
const /** @const C */ _avSVExtraSmall = 'xs'; // 20
const /** @const XC */ _cssSVExtraSmall  = 'fp';
const /** @const C */ _avSVSmall = 's'; // 40
const /** @const XC */ _cssSVSmall  = 'fr';
const /** @const C */ _avSVMedium = 'm'; // 80
const /** @const XC */ _cssSVMedium  = 'ft';
const /** @const C */ _avSVLarge = 'l';
const /** @const XC */ _cssSVLarge  = 'fv';
const /** @const C */ _avSVExtraLarge = 'xl';
const /** @const XC */ _cssSVExtraLarge  = 'fx';
/**
 * Image size to size-class map
 * @type Object.<string, string>
 */
const _sizeMap = {};
_sizeMap[_avSVOriginal] = _cssSVOriginal;
_sizeMap[_avSVExtraSmall] = _cssSVExtraSmall;
_sizeMap[_avSVSmall] = _cssSVSmall;
_sizeMap[_avSVMedium] = _cssSVMedium;
_sizeMap[_avSVLarge] = _cssSVLarge;
_sizeMap[_avSVExtraLarge] = _cssSVExtraLarge;
/**
 * Embed Story view mode
 */
const /** @const */ _vmEmbedStoryViewType_List = 'list';
const /** @const */ _vmEmbedStoryViewType_Expanded = 'expanded';
const /** @const */ _vmEmbedStoryViewType_Process = 'process';
/**
 * Moses Request
 */
const /** @const X */ _mrProfile  = 'gi';
const /** @const X */ _mrProfileDataReady  = 'gj';
/**
 * Page controllers
 */
const /** @const X */ _pcGlobalPageController  = 'gn';
const /** @const X */ _pcUserProfile  = 'go';
const /** @const X */ _pcWorkspaces  = 'gp';
const /** @const X */ _pcManageWorkspaces  = 'gq';
const /** @const X */ _pcWorkspace  = 'gr';
const /** @const X */ _pcProject  = 'gs';
const /** @const X */ _pcManageBillingWorkspaces  = 'gt';
/**
 * Page Ids
 */
const /** @const */ _pidWorkspace = 'workspace';
const /** @const */ _pidProject = 'project';
const /** @const */ _pidUserProfile = 'profile';
const /** @const */ _pidWorkspaces = 'workspaces';
const /** @const */ _pidManageWorkspaces = 'manage-workspaces';
const /** @const */ _pidManageBillingWorkspaces = 'manage-billing';
/**
 * Widget classes
 */
const /** @const XC */ _wtWsToolBar  = 'h6';
const /** @const XC */ _wtUserToolBar  = 'h7';
const /** @const X */ _wtPopupMenu  = 'h8';
const /** @const X */ _wtAvatar  = 'h9';
const /** @const X */ _wtWorkspaceSelect  = 'ha';
const /** @const X */ _wtProjectsList  = 'hb';
const /** @const X */ _wtWSPeopleList  = 'hc';
const /** @const X */ _wtModalDialog  = 'hd';
const /** @const XC */ _wtUserProfile  = 'he';
const /** @const X */ _wtProjectUsers  = 'hf';
const /** @const X */ _wtTasksList  = 'hg';
const /** @const X */ _wtTaskInfo  = 'hh';
const /** @const X */ _wtManageProjectUsers  = 'hi';
const /** @const XC */ _wtManageTeams  = 'hj';
const /** @const X */ _wtProjectFiles  = 'hk';
const /** @const X */ _wtTree  = 'hl';
const /** @const XC */ _wtHelp  = 'hm';
const /** @const X */ _wtContextMenu  = 'hn';
const /** @const XC */ _wtWorkspaceOverview  = 'ho';
const /** @const XC */ _wtTaskSchedule  = 'hp';
/**
 * Widget instances
 */
const /** @const X */ _wiToolbarWsMenu  = 'ht';
const /** @const XC */ _wiUsrMenu  = 'hu';
const /** @const X */ _wiUserEditDialog  = 'hv';
const /** @const X */ _wiUserPopup  = 'hw';
const /** @const X */ _wiUtilsPopup  = 'hx';
const /** @const X */ _wiDueDate  = 'hy';
const /** @const X */ _wiCreateWorkspace  = 'hz';
const /** @const XC */ _wiEditProject  = 'i0';
const /** @const X */ _wiAddUserToProjectMenu  = 'i1';
const /** @const X */ _wiAddUserToTaskMenu  = 'i2';
const /** @const X */ _wiFeedback  = 'i3';
const /** @const X */ _wiFeedbackMenu  = 'i4';
const /** @const XC */ _wiCloneProject  = 'i5';
const /** @const X */ _wiUserAction  = 'i6';
const /** @const XC */ _wiHelpMenu  = 'i7';
const /** @const X */ _wiUploadFileMenu  = 'i8';
const /** @const X */ _wiNotifications  = 'i9';
const /** @const XC */ _wiNotificationsDialog  = 'ia';
const /** @const XC */ _wiInviteUser  = 'ib';
const /** @const XC */ _wiUserEmailsAction  = 'ic';
const /** @const XC */ _wiShareProject  = 'id';
const /** @const X */ _wiTaskContextMenu  = 'ie';
const /** @const X */ _wiGroupContextMenu  = 'if';
const /** @const X */ _wiPaperContextMenu  = 'ig';
const /** @const X */ _wiExportAction  = 'ih';
const /** @const XC */ _wiDashboardProp  = 'ii';
const /** @const XC */ _wiProjectRecycleBin  = 'ij';
const /** @const XC */ _wiSubscriptionPlan  = 'ik';
const /** @const XC */ _wiCreateNewWorkspace  = 'il';
const /** @const XC */ _wiTransferOwnership  = 'im';
const /** @const XC */ _wiManageSubscription  = 'in';
const /** @const XC */ _wiAskPassword  = 'io';
const /** @const XC */ _wiTeamColorSelector  = 'ip';
const /** @const XC */ _wiAddMemberToTeam  = 'iq';
const /** @const XC */ _wiCalendarPicker  = 'ir';
const /** @const X */ _wiWorkspaceOverviewOpts  = 'is';
const /** @const XC */ _wiEvernoteNotebooks  = 'it';
const /** @const XC */ _wiProjectStartDate  = 'iu';
const /** @const XC */ _wiGetEmbedCode  = 'iv';
const /** @const XC */ _wiLabs  = 'iw';
const /** @const XC */ _wiStoriesEditorMenu  = 'ix';
const /** @const XC */ _wiProjectReport  = 'iy';
const /** @const XC */ _wiExport2File  = 'iz';
const /** @const XC */ _wiRemoveAccountWizard  = 'j0';
const /** @const XC */ _wiTaskTiming  = 'j1';
const /** @const XC */ _wiTaskSchedule  = 'j2';
const /** @const XC */ _wiReassignUserTasks  = 'j3';
const /** @const XC */ _wiConsultingTools  = 'j4';
/**
 * profile data roles
 */
const /** @const X */ _prfUpdateInfo  = 'j8';
const /** @const X */ _prfChangePassword  = 'j9';
const /** @const X */ _prfNotificationSetting  = 'ja';
/**
 * URL Map constants
 */
const /** @const X */ _umLogin  = 'je';
const /** @const X */ _umSignup  = 'jf';
const /** @const X */ _umGoogleRegister  = 'jg';
const /** @const X */ _umGoogleLogin  = 'jh';
const /** @const X */ _umGoogleAuth  = 'ji';
const /** @const X */ _umFeatureAuth  = 'jj';
const /** @const X */ _umForgotPassword  = 'jk';
const /** @const X */ _umReRequestVerification  = 'jl';
const /** @const X */ _umRecoverPassword  = 'jm';
const /** @const X */ _umConfirmEmail  = 'jn';
const /** @const X */ _umInvite  = 'jo';
const /** @const X */ _umNotifications  = 'jp';
const /** @const X */ _umWorkspaces  = 'jq';
const /** @const X */ _umManageWorkspaces  = 'jr';
const /** @const X */ _umWorkspace  = 'js';
const /** @const X */ _umProject  = 'jt';
const /** @const X */ _umManageBillingWorkspaces  = 'ju';
/**
 * Dialog buttons roles
 */
const /** @const XC */ _drbCancel  = 'jy';
const /** @const XC */ _drbSoftCancel  = 'jz';
const /** @const XC */ _drbOK  = 'k0';
const /** @const XC */ _drbDelete  = 'k1';
const /** @const XC */ _drbClose  = 'k2';
const /** @const XC */ _drbSave  = 'k3';
const /** @const XC */ _drbReset  = 'k4';
const /** @const XC */ _drbBack  = 'k5';
/**
 * Vertical align constants
 */
const _BOX_VALIGN_MIDDLE = 1;
const _BOX_VALIGN_TOP = 2;
const _BOX_VALIGN_TOP14 = 3;
const _BOX_VALIGN_TOP18 = 4;
const _BOX_VALIGN_TOP34 = 5
const _BOX_VALIGN_BOTTOM34 = 6;
const _BOX_VALIGN_BOTTOM = 7;
/**
 * Project constants
 */
const /** @const */ _skeyProjectSortBy = 'skeyProjectSortBy';
const /** @const */ _skeyProjectsAccess = 'skeyProjectsAccess';
const /** @const */ _skeyProjectGroupByCategories = 'skeyProjectGroupByCategories';
const /** @const */ _skeyProjectsAccessVersion = 'skeyProjectsAccessVersion';
const /** @const */ _skeyProjectIdPrefix = 'pid';
const /** @const */ _skeyPagingFeature = 'pagingFeature';
const /** @const */ _skeyMOTDVersion = 'motdversion';
const /** @const */ _skeyMOTDStatus = 'motdstatus';
const /** @const X */ _skeyTutorialParams  = 'kr';
const /** @const */ _skeyFutureTasksCollapse = 'skeyFTC';
const /** @const X */ _settingsVersion  = 'kt';
const /** @const */ _gcProjectTimeOffset = 1546293600;
const /** @const */ _gcProjectsPerPage = 30;
/** Sorting type */
const /** @const */ _prsbEnableListPaging = 'enableListPaging';
const /** @const */ _prsbDisableListPaging = 'disableListPaging';
const /** @const */ _prsbAlphabet = 'Alphabet';
const /** @const */ _prsbLTA = 'LTA';
const /** @const */ _prsbCreationTime = 'creationTime';
const /** @const */ _prsbTag = 'Tag';
const /** @const */ _skWelcomeIgnore = 'IgnoreWelcome2Project';
/**
 * Tutorial related consts
 */
const /** @const */ _skTutorialKey = 'onboarding';
const /** @const */ _skTutorialProjectIdKey = 'onboarding-%-pid';
const /** @const */ _skTutorialEnums_TDec15 = 't-dec15';
const /** @const */ _skTutorialEnums_TStories = 't-stories';
const /** @const */ _skTutorialEnums_SkipTDec15 = 'skip-t-dec15';
const /** @const */ _skTutorialBeenSkeeped = 't-skipped-show-later';
const /** @const */ _skTutorialEnums_New = 'new';
const /** @const */ _skTutorialEnums = [
        _skTutorialEnums_TDec15,
        _skTutorialEnums_SkipTDec15,
        _skTutorialEnums_TStories
    ];
const /** @const */ _skVideoBeenWatched = 'v-dec2015';
const /** @const */ _skFromStoriesFlag = 'origin.stories';
/**
 * Project PushState queue ID
 */
const /** @const X */ _prcPushChangesQueueId  = 'lo';
/**
 * Tree constants
 */
const /** @const */ _trINIT_ROW = 1e6;
const /** @const */ _trROW_INC = 100;
const /** @const */ _trINIT_COL = 1e6;
const /** @const */ _trCOL_INC = 100;
const /** @const */ _trROW_HEIGHT = 10;
const /** @const */ _trCOL_WIDTH = 10;
const /** @const */ _trGridGap = 20;
const /** @const */ _trGridSnap = 15;
const /** @const */ _trTaskHeightCor = 11;
const /** @const */ _trGroupWidthCor = 6;
const /** @const C */ _trTaskWidth = 240;
const /** @const */ _trTaskNameWidth = '170px';
const /** @const X */ _nsOnline  = 'm4';
const /** @const X */ _nsOffline  = 'm5';
const /** @const X */ _vm3Columns  = 'm6';
const /** @const X */ _vm2Columns  = 'm7';
const /** @const X */ _vmFullScreen  = 'm8';
const /** @const X */ _tcLinkStartHandleID  = 'm9';
const /** @const X */ _tcLinkEndHandleID  = 'ma';
const /** @const X */ _doEvTaskChanged  = 'mb';
const /** @const X */ _doEvUserChanged  = 'mc';
const /** @const */ _keyENTER = 13;
const /** @const */ _keyESC = 27;
const /** @const */ _keyDELETE = 46;
const /** @const */ _keyBACKSPACE = 8;
const /** @const */ _keyTab = 9;
const /** @const X */ _actRoleRemove  = 'mi';
const /** @const X */ _actRoleEdit  = 'mj';
const /** @const X */ _actRoleResource2User  = 'mk';
const /** @const X */ _actRoleUser2Resource  = 'ml';
// feedback menu items role
const /** @const X */ _rlSendComment  = 'mn';
const /** @const X */ _rlReportBug  = 'mo';
const /** @const X */ _rlViewMessages  = 'mp';
const /** @const X */ _rlLabs  = 'mq';
const /** @const */ _iScrollerOptions = {
        vScroll: true,
        hScroll: false,
        checkDOMChanges: true,
        zoom: false,
        handleClick: false,
        useTransition: true,
        useTransform: true
    };
/**
 * User account tab IDs
 */
const /** @const XC */ _acctabProfile  = 'n3';
const /** @const XC */ _acctabPassword  = 'n4';
const /** @const XC */ _acctabEmails  = 'n5';
const /** @const XC */ _acctabEmailNotify  = 'n6';
const /** @const */ _gcTaskStateAlerted = 'Overdue';
const /** @const X */ _dcNoGroup  = 'n8';
const /** @const X */ _dcNobodys  = 'n9';
const /** @const X */ _dcNoTeam  = 'na';
const /** @const */ _dupNameChanged = 0x01;
const /** @const */ _dupCoordChanged = 0x02;
const /** @const */ _dupDataChanged = 0x04;
const /** @const */ _dupRemoved = 0x08;
const /** @const */ _dupStateChanged = 0x10;
const /** @const */ _dupCreated = 0x20;
const /** @const */ _dupGroupChanged = 0x40;
const /** @const */ _dupDeadlineChanged = 0x80;
const /** @const */ _dupStatsUpdate = 0x100;
const /** @const */ _dupWritableState = 0x101;
const /** @const */ _dupDataLoaded = 0x102;
const /** @const */ _icUserPicIncSize = 1;
const /** @const */ _icWSUserPicIncSize = 10;
const /** @const */ _icProjectPicIncSize = 1;
const /** @const */ _icWorkspacePicIncSize = 1;
/**
 * Mixpanel constants
 */
const /** @const */ _mpcSyncTimeout = 300;
const /** @const X */ _mpctTrack  = 'nu';
const /** @const X */ _mpctInc  = 'nv';
const /** @const X */ _mpctIncAndTrack  = 'nw';
const /** @const */ _mpcUILoaded = 'UI loaded'; // track;
const /** @const */ _mpcUIinteracted = 'UI interacted'; //track
const /** @const */ _mpcProjectCreatedInc = 'projects created'; // inc
const /** @const */ _mpcProjectCreated = 'Project Created'; // track
const /** @const */ _mpcDraftCreated = 'draft tasks created'; // inc
const /** @const */ _mpcTaskCreated = 'tasks created'; // track + inc
const /** @const */ _mpcTaskCompleted = 'tasks completed'; // track + inc
const /** @const */ _mpcDraftRemoved = 'draft tasks deleted';
const /** @const */ _mpcTaskRemoved = 'tasks deleted'; // track + inc
const /** @const */ _mpcTransitionCreated = 'transitions created'; // inc
const /** @const */ _mpcTransitionRemoved = 'transitions deleted'; // inc
const /** @const */ _mpcGroupCreated = 'Group created'; // track
const /** @const */ _mpcUserInvited = 'User invited'; // track
const /** @const */ _mpcLoginPageVisit = 'Login page visited'; // track
const /** @const */ _mpcRemeberMeChecked = 'Login checked Remember me'; // track
const /** @const */ _mpcLoginSuccess = 'Login success'; // track
const /** @const */ _mpcLoginFailed = 'Login failed'; // track
const /** @const */ _mpcLoginWithGoogleClick = 'Login with Google clicked'; // track
const /** @const */ _mpcLoginWithGoogleSuccess = 'Login with Google success'; // track
const /** @const */ _mpcSignupPageVisit = 'Signup page visited'; // track
const /** @const */ _mpcSignupEMailSubmited = 'Signup email submited';
const /** @const */ _mpcSignupEMailConfirmed = 'Signup email confirmed'; // track
const /** @const */ _mpcSignupEMailConfirmFailed = 'Signup email confirm failed'; // track
const /** @const */ _mpcPasswordResetVisited = 'Password reset visited'; // track
const /** @const */ _mpcPasswordResetLinkVisited = 'Password reset link visited'; // track
const /** @const */ _mpcPasswordResetSubmited = 'Password reset submited'; // track
const /** @const */ _mpcPasswordResetCompleted = 'Password reset completed'; // track
const /** @const */ _mpcAppSignupStart = 'App signup start';
const /** @const */ _mpcSignup = 'Signup';
const /** @const */ _mpcInteractWithSignupForm = 'Interact with signup form';
const /** @const */ _mpcAppSignupCancel = 'App signup cancel';
const /** @const */ _mpcAppSignup = 'App signup';
const /** @const */ _mpcAdditionalWorkspaceCreated = 'additional workspaces created'; // inc
const /** @const */ _mpcFeedbackOpened = 'Feedback opened'; // track
const /** @const */ _mpcHelpButtonClicked = 'Help button clicked'; // track
const /** @const */ _mpcTutorialProjectCreated = 'Tutorial project created'; // track
const /** @const */ _mpcWelcomePopupVideoClicked = 'Welcome popup video clicked'; // track
const /** @const */ _mpcMyAccountVisited = 'My account visited'; // track
const /** @const */ _mpcMyWorkspacesVisited = 'My workspaces visited'; // track
const /** @const */ _mpcWorkspacePeopleVisited = 'Workspace people visited'; // track
const /** @const */ _mpcProjectPeopleVisited = 'Project people visited'; // track
const /** @const */ _mpcProjectModeExecutor = 'Project Mode switched to Executor'; // track
const /** @const */ _mpcProjectModeFullscreen = 'Project Mode Full Screen'; // track
const /** @const */ _mpcTaskExecutorAdded = 'Task executor added'; // track
const /** @const */ _mpcTaskExecutorRemoved = 'Task executor deleted'; // track
const /** @const */ _mpcTaskAssignedToTeam = 'Task assigned to team'; // track
const /** @const */ _mpcTaskUnassignedFromTeam = 'Task unassigned from team'; // track
const /** @const */ _mpcCameFromLanding = 'Came from landing page'; // track
const /** @const */ _mpcDetachedUserCreated = 'Detached user created'; // track
const /** @const */ _mpcDetachedUserRemoved = 'Detached user removed'; // track
const /** @const */ _mpcFilesTabOpened = 'files tab opened'; // track
const /** @const */ _mpcFileUploaded = 'file uploaded'; // track
const /** @const */ _mpcFileDropbox = 'file dropbox'; // track
const /** @const */ _mpcFileBox = 'file box'; // track
const /** @const */ _mpcShareSnapshot = 'share snapshot'; // track
const /** @const */ _mpcSharePdf = 'share pdf'; // track
const /** @const */ _mpcShareImage = 'share image'; // track
const /** @const */ _mpcProjectCloned = 'project cloned'; // track
const /** @const */ _mpcCommentAdded = 'comment added'; // track
const /** @const */ _mpcContentCopied = 'content copied'; // track
const /** @const */ _mpcContentPasted = 'content pasted'; // track
const /** @const */ _mpcWorkspaceButton = 'Workspace button'; // track
const /** @const */ _mpcCentralButton = 'Central button'; // track
const /** @const */ _mpcFeedbackButton = 'Feedback button'; // track
const /** @const */ _mpcRightTopMenu = 'Right top menu'; // track
const /** @const */ _mpcGettingStarted = 'Getting started'; // track
const /** @const */ _mpcSubscriptionMgmt = 'Subscription mgmt'; // track
const /** @const */ _mpcOpenNotifications = 'Open notifications';
const /** @const */ _mpcClickAvatar = 'Click avatar';
const /** @const */ _mpcOpenFeedbackForm = 'Open feedback form';
const /** @const */ _mpcGetHelp = 'Get help';
const /** @const */ _mpcOpenUserMenu = 'Open user menu';
const /** @const */ _mpcClickLogo = 'Click logo';
const /** @const */ _mpcCreateNewProject = 'Create new project';
const /** @const */ _mpcCreateTutorialProject = 'Create tutorial project';
// Tutorial statistic
const /** @const */ _mpcOnboardingVideoVisit = 'Onboarding Video Visit';
const /** @const */ _mpcOnboardingVideoPlay = 'Onboarding Video Play';
const /** @const */ _mpcOnboardingVideoEarlyClose = 'Onboarding Video Early Close';
const /** @const */ _mpcOnboardingVideoClose = 'Onboarding Video Close';
const /** @const */ _mpcOnboardingVideoExit = 'Onboarding Video Exit';
const /** @const */ _mpcOnboardingTutorialStart = 'Onboarding Tutorial Start';
const /** @const */ _mpcOnboardingTutorialNext = 'Onboarding Tutorial Next';
const /** @const */ _mpcOnboardingTutorialPrevious = 'Onboarding Tutorial Previous';
const /** @const */ _mpcOnboardingTutorialFinish = 'Onboarding Tutorial Finish';
const /** @const */ _mpcOnboardingTutorialRevisit = 'Onboarding Tutorial Revisit';
const /** @const */ _mpcOnboardingTutorialClose = 'Onboarding Tutorial Close';
const /** @const */ _mpcOnboardingVideoSkip = 'Onboarding Video Skip';
const /** @const */ _srRevert2Def = 'srRevert2Def';
/**
 * Project Planner CSS constants
 */
const /** @const XC */ _gcZoom50  = 'qh';
const /** @const XC */ _gcZoom75  = 'qi';
const /** @const XC */ _gcZoom100  = 'qj';
const /** @const */ _ZoomMap = {
        50: _gcZoom50,
        75: _gcZoom75,
        100: _gcZoom100
    };
const /** @const XC */ _ppReady  = 'qp';
const /** @const X */ _helpMainFeatures  = 'qq';
const /** @const X */ _helpPeople  = 'qr';
const /** @const X */ _helpGroups  = 'qs';
const /** @cpnst X */ _helpProjectTemplates = 'helpProjectTemplates';
const /** @cpnst X */ _helpProjectShare = 'helpProjectShare';
const /** @const X */ _helpMobileApp  = 'qv';
const /** @const X */ _helpGoogleCalendar  = 'qw';
const /** @const XC */ _mdcBlack  = 'qx';
const /** @const XC */ _mdcWhite  = 'qy';
const /** @const XC */ _mdcYellow  = 'qz';
const /** @const XC */ _mdcCyan  = 'r0';
const /** @const X */ _srcWorkspace  = 'r1';
const /** @const X */ _srcUser  = 'r2';
const /** @const C */ _srEmailConfirmation = 'srEmailConfirmation';
// export modes
const /** @const */ _expPNG = 'png';
const /** @const */ _expPDF = 'pdf';
const /** @const X */ _expGoogleCalendar  = 'r7';
const /** @const X */ _expExcelExport  = 'r8';
const /** @const X */ _expProjectFileIOExport  = 'r9';
const /** @const X */ _expProjectFileIOImport  = 'ra';
const /** @const X */ _expTaskList2Evernote  = 'rb';
const /** @const X */ _expProjectOverview2Evernote  = 'rc';
const /** @const X */ _expStoryEditor  = 'rd';
const /** @const X */ _expStoryEditorEmbed  = 're';
const /** @const C */ _clrActiveTColor = '#c9d0e1';
const /** @const C */ _clrInactiveTColor = '#fff';
const /** @const C */ _clrLinkTColor = '#63cae0';
const /** @const C */ _clrDoneBorder = '#71e294';
const /** @const C */ _clrDoneText = '#1e2c4f';
const /** @const C */ _clrDoneBG = '#e8ffed';
const /** @const C */ _clrOpenBorder = '#56c3da';
const /** @const C */ _clrOpenText = '#192e4d';
const /** @const C */ _clrOpenBG = '#fff';
const /** @const C */ _clrBlockedBorder = '#c7d2d6';
const /** @const C */ _clrBlockedText = '#6a6f82';
const /** @const C */ _clrBlockedBG = '#e4ecee';
const /** @const C */ _clrAlertBorder = '#ff5800';
const /** @const C */ _clrSelectedBorder = '#8678c7';
const /** @const C */ _clrBlueBorder = '#56BED2';
const /** @const C */ _clrGrayBorder = '#c6d2d4';
const /** @const C */ _clrActiveLink = '#0095B2';
const /** @const C */ _clrTransition = '#CCC';
const /** @const C */ _clrTaskDeadlineText = '#87989A';
const /** @const C */ _clrTaskName = '#87989a';
const /** @const C */ _clrPText = '#3d465d';
const /** @const C */ _clrCText = '#87989a';
/**
 * Data type definition
 */
const _dtpOptional = 0b1000000000000000;
const _dtpInternal = 0b0100000000000000;
const _dtpAnyType = 0b0000000000000001;
const _dtpString = 0b0000000000000010;
const _dtpID = 0b0000000000000100;
const _dtpNumber = 0b0000000000001000;
const _dtpBoolean = 0b0000000000010000;
const _dtpJSON = 0b0000000000100000;
const _dtpTimestamp = 0b0000000001000000;
const _dtpDate = 0b0000000010000000;
const _dtpColor = 0b0000000100000000;
const _dtpObject = 0b0000001000000000;
const _dtpEnum = 0b0000010000000000;
const _dtpMap = 0b0000100000000000;
const _dtpClass = 0b0001000000000000;
const _dtpArray = 0b0010000000000000;
/**
 * Colors define for project categories
 */
const _gcProjectTagsColors = ['#ffdc7c', '#ef7d16', '#f24d34', '#009e86', '#00c973', '#7cbaff', '#9d5eb4', '#ffffff'];
const _gcModernTagColors = {
    '_1':'#FFDC7C',
    '_2':'#BFF57F',
    '_3':'#82EB95',
    '_4':'#84E1D8',
    '_5':'#85A4D7',
    '_6':'#9F86CD',
    '_7':'#C385BE',
    '_8':'#B9B0B2',
    '#ffdc7c':'#ffdc7c',
    '#ef7d16':'#ef7d16',
    '#f24d34':'#f24d34',
    '#009e86':'#009e86',
    '#00c973':'#00c973',
    '#7cbaff':'#7cbaff',
    '#9d5eb4':'#9d5eb4',
    '#ffffff':'#ffffff'
};
/**
 * Feature names definition
 */
const /** @const */ _fndProjectTeams = 'ProjectTeams';
const /** @const */ _fndGoogleCalendar = 'GoogleCalendar';
const /** @const */ _fndCloudStorage = 'CloudStorage';
const /** @const */ _fndDropBox = 'Dropbox';
const /** @const */ _fndBox = 'Box';
const /** @const */ _fndGoogleDrive = 'GoogleDrive';
const /** @const */ _fndProjectSharing = 'ProjectSharing';
const /** @const */ _fndExcelExport = 'ExcelExport';
const /** @const */ _fndEvernote = 'Evernote';
const /** @const */ _fndCloneExecutors = 'CloneExecutors';
const /** @const */ _fndImprovedProjectDeadlines = 'ImprovedProjectDeadlines';
const /** @const */ _fndStoryEditor = 'StoryEditor';
const /** @const */ _fndOldStyleScroll = 'OldStyleScroll';
const /** @const */ _fndSidebarCollapse = 'SidebarCollapse';
const /** @const */ _fndProjectCategories = 'ProjectCategories';
const /** @const */ _fndSearch = 'Search';
const /** @const */ _fndFlexibleArrows = 'FlexibleArrows';
const /** @const */ _fndAdvancedCopyPaste = 'AdvancedCopyPaste';
const /** @const */ _fndAltTaskNumbers = 'AltTaskNumbers';
const /** @const */ _fndHideWhatsNew = 'HideWhatsNew';
const /** @const */ _fndReportingProjectStatuses = 'ReportingProjectStatuses';
const /** @const */ _fndWorkspaceWideOverview = 'WorkspaceWideOverview';
const /** @const */ _fndProjectFileIO = 'ProjectFileIO';
const /** @const */ _fndOneDrive = 'OneDrive';
const /** @const */ _fndHideTeamFromSpectators = 'HideTeamFromSpectators';
const /** @const */ _fndTaskDuration = 'TaskDuration';
const /** @const */ _fndTaskDebug = 'TaskDebug';
const /** @const */ _fndOverviewHorizont = 'OverviewHorizont';
const /** @const */ _fndConsultingTools = 'ConsultingTools';
const /** @const */ _fndProjectsListPaging = 'ProjectsListPaging';
const /** @const */ _fndProjectListScrollMemory = 'ProjectListScrollMemory';
const _bndCurrentWorkspace = 'u4';
const _bndCurrentProject = 'u5';
const _clsActive = 'u6';
const _bndCurrentWorkspaceUser = 'u7';
const _bndProjectTasks = 'u8';
const _clsLabel = 'u9';
const _clsTableCell = 'ua';
const _clsSVGIcon = 'ub';
const _clsIcon = 'uc';
const _clsUserPic = 'ud';
const _bndProjectTags = 'ue';
const _cSessionMessage = 'uf';
const _clsDisabled = 'ug';
const _idSymbolCircleXBlack = 'uh';
const _clsThrobber = 'ui';
const _cnstGroupTree = 'uj';
const _clsLink = 'uk';
const _bndStoryPageInfo = 'ul';
const _clsTaskName = 'um';
const _bndMOD = 'un';
const _bndHasFeature = 'uo';
const _clsScroller = 'up';
const _clsDivider = 'uq';
const _clsExpanded = 'ur';
const _clsDialogBody = 'us';
const _bndProjectTags_ManageMode = 'ut';
const _clsScrollWrapper = 'uu';
const _clsTree = 'uv';
const _clsProcessing = 'uw';
const _clsHeader = 'ux';
const _idSymbolDropdown = 'uy';
const _bndWorkspaceUsers = 'uz';
const _bndWorkspaceInfo = 'v0';
const _idSymbolMarkDone = 'v1';
const _bndProjectTeams = 'v2';
const _clsDeadline = 'v3';
const _clsCheckbox = 'v4';
const _clsLogoImage = 'v5';
const _clsTutorial_Frame = 'v6';
const _ienUriMkLink = 'v7';
const _clsGroupRects = 'v8';
const _clsTable = 'v9';
const _clsNote = 'va';
const _bndWorkspaces = 'vb';
const _bndProjects = 'vc';
const _clsDraftTask = 'vd';
const _msgUknownError = 've';
const _clsFIOInfo = 'vf';
const _rFeatureQuestion = 'vg';
const _bndProjectListViewMode = 'vh';
const _clsProjectItem = 'vi';
const _clsWhiteRoundWrapper = 'vj';
const _clsDialog = 'vk';
const _clsEditMode = 'vl';
const _clsPopupMenuHeader = 'vm';
const _bndStorySocialInfo = 'vn';
const _clsAlerted = 'vo';
const _ienCalcHeight = 'vp';
const _clsDialogButtons = 'vq';
const _mtdACL = 'vr';
const _ienGetDate = 'vs';
const _clsBackButton = 'vt';
const _bndProjectTags_ActiveFilter = 'vu';
const _bndProjectsList = 'vv';
const _flgMarkedForRemoval = 'vw';
const _bndWorkspaceOverviewActiveUser = 'vx';
const _clsEmpty = 'vy';
const _clsToggle = 'vz';
const _clsWorkspaceItem = 'w0';
const _msgAccessDenied = 'w1';
const _clsTreeToolbar = 'w2';
const _clsGroupNameHeader = 'w3';
const _clsNewItem = 'w4';
const _bndTaskDetails = 'w5';
const _clsIconCRemove = 'w6';
const _idSymbolProjectTrash = 'w7';
const _clsProjectPic = 'w8';
const _clsSVSmall = 'w9';
const _idSymbolCloseIcon = 'wa';
const _clsWorkspacePic = 'wb';
const _bndIsOwner = 'wc';
const _bndWorkspaceSettings = 'wd';
const _idSymbolDoneMark = 'we';
const _clsTutorial_Step3 = 'wf';
const _bndProjectSearchMode = 'wg';
const _bndProjectsRecycleBin = 'wh';
const _clsCategoriesPane = 'wi';
const _evEscPressed = 'wj';
const _clsSVMedium = 'wk';
const _clsEditable = 'wl';
const _clsSVGAvatar = 'wm';
const _bndViewMode = 'wn';
const _idSymbolDeadlineAlert = 'wo';
const _clsReleaseIcon = 'wp';
const _cTaskInfo = 'wq';
const _evCreateSubscription = 'wr';
const _clsProjectTagsPane_TagItem = 'ws';
const _msgServerError = 'wt';
const _idSymbolProjectMenuIcon = 'wu';
const _clsNotSelectableItem = 'wv';
const _evTaskNameEdit = 'ww';
const _cnstGroupHolderBinds = 'wx';
const _clsHandleLabel = 'wy';
const _clsIconAlert = 'wz';
const _idSymbolCalendar = 'x0';
const _clsForbidenLink = 'x1';
const _clsTutorial_Step2 = 'x2';
const _bndOpenPopup = 'x3';
const _clsResizer = 'x4';
const _clsAlignCenter = 'x5';
const _idSymbolCircleX = 'x6';
const _bndProjectShareList = 'x7';
const _msgUnnamedGroup = 'x8';
const _bndProjectUsers = 'x9';
const _evSetDeadline = 'xa';
const _clsDeadlineWrapper = 'xb';
const _clsTmpText = 'xc';
const _ienTmpValuesTexts = 'xd';
const _ienFmtTimestampLog = 'xe';
const _bndProjectStartDate = 'xf';
const _clsHovered = 'xg';
const _clsExpandable = 'xh';
const _clsShowButtons = 'xi';
const _clsBioCell = 'xj';
const _clsImgInTransition = 'xk';
const _clsWSOverviewPane = 'xl';
const _clsDragHandle = 'xm';
const _msgCheckInputField = 'xn';
const _msgWorkspaceIsReadOnly = 'xo';
const _clsCenterBox = 'xp';
const _clsFullBlock = 'xq';
const _idSymbolSearch = 'xr';
const _clsPagerButton = 'xs';
const _clsHidden = 'xt';
const _clsShowEditControls = 'xu';
const _bndRAActiveTab = 'xv';
const _clsIconClose = 'xw';
const _evOpenGroup = 'xx';
const _evCloseGroup = 'xy';
const _clsProjectHead = 'xz';
const _clsGroupWrapper = 'y0';
const _clsTreeFooter = 'y1';
const _clsInlineEdit = 'y2';
const _idSymbolAssignUser = 'y3';
const _idSymbolCasualLogo = 'y4';
const _clsTutorial_VideoFrame = 'y5';
const _clsTutorial_Step1 = 'y6';
const _clsTutorial_Label = 'y7';
const _bndAllowedToExtendTrial = 'y8';
const _clsLine = 'y9';
const _clsImageCell = 'ya';
const _bndProjectStats = 'yb';
const _cUserBillingManagementResponse = 'yc';
const _clsViewMode_Process = 'yd';
const _idSymbolBackIcon = 'ye';
const _clsButton = 'yf';
const _clsCategoryLabel = 'yg';
const _clsProjectsPane = 'yh';
const _bndIsNewProject = 'yi';
const _clsNewProject = 'yj';
const _clsWSPeoplePane = 'yk';
const _rCreateProject = 'yl';
const _clsHorizontalWrapper = 'ym';
const _bndProjectsListPages = 'yn';
const _clsItemEdit = 'yo';
const _clsWrapperHeader = 'yp';
const _cmpWorkspaceTrialBanner = 'v-yq';
const _clsProjectName = 'yr';
const _eUploadAvatar = 'ys';
const _evClosePopups = 'yt';
const _clsWorkArea = 'yu';
const _msgUnnamedTask = 'yv';
const _clsHead = 'yw';
const _rNewFile = 'yx';
const _clsSVGCanvas = 'yy';
const _clsPanelFrame = 'yz';
const _clsIconPanelHide = 'z0';
const _idGradientInlineEdit = 'z1';
const _clsGroupIsOpen = 'z2';
const _idSymbolDeadline = 'z3';
const _clsGroupResize = 'z4';
const _idSymbolMarkBlocked = 'z5';
const _clsSelectedNode = 'z6';
const _clsTutorial_Step4 = 'z7';
const _clsButton_White = 'z8';
const _clsSelected = 'z9';
const _clsPopupMenuItems = 'za';
const _clsQty = 'zb';
const _clsActionPanel_Start = 'zc';
const _clsActionPanel_Stop = 'zd';
const _clsEdit = 'ze';
const _clsTaskRow = 'zf';
const _cBasePrototype = 'zg';
const _clsCompleted = 'zh';
const _clsStoryBackButton = 'zi';
const _clsTask = 'zj';
const _clsViewMode_Details = 'zk';
const _bndTraslate = 'zl';
const _bndProjectSearchValue = 'zm';
const _clsWorkspaceWideOverview = 'zn';
const _ienSVGStatusLine = 'zo';
const _bndStartDate = 'zp';
const _idSymbolCogIcon = 'zq';
const _clsWrapperHeader_ButtonPanel = 'zr';
const _clsWrapperHeader_Label_Button = 'zs';
const _clsLeftStright = 'zt';
const _clsUserDetails = 'zu';
const _clsButtons = 'zv';
const _idSymbolProjectEdit = 'zw';
const _clsRemovable = 'zx';
const _clsFlex1 = 'zy';
const _bndProjectShareActiveTab = 'zz';
const _bndProjectShareCouldAddMore = 'g100';
const _evButtonClose = 'g101';
const _clsButtonWrapper = 'g102';
const _clsUploadBanner = 'g103';
const _clsHeadMenu_Container = 'g104';
const _clsHeadMenu = 'g105';
const _bndManageWorkspaceSettingsBlock = 'g106';
const _clsNewWorkspace = 'g107';
const _bndIsOnwer = 'g108';
const _evRemoveSelectedTasks = 'g109';
const _bndGroupCaption = 'g10a';
const _clsInlineEditShield = 'g10b';
const _bndTaskDebugMode = 'g10c';
const _cProjectInfo = 'g10d';
const _bndIAM = 'g10e';
const _clsMarkerArrow = 'g10f';
const _clsMarkerCircle = 'g10g';
const _clsWrapper = 'g10h';
const _clsAddUser = 'g10i';
const _idRoleRect = 'g10j';
const _ienDeadlineState = 'g10k';
const _clsTaskBuble = 'g10l';
const _clsLinkHandle = 'g10m';
const _clsTutorial_Video = 'g10n';
const _clsWorkspace = 'g10o';
const _clsBigText = 'g10p';
const _clsButton_Blue = 'g10q';
const _clsMemberItem = 'g10r';
const _bndNoteSaveNotification = 'g10s';
const _clsSection_Header_Value = 'g10t';
const _clsTab = 'g10u';
const _clsAddNewEmail = 'g10v';
const _clsMakePrimary = 'g10w';
const _clsFloatRight = 'g10x';
const _bndInviteMoreQty = 'g10y';
const _clsEditWSUserForm = 'g10z';
const _cLoginResponse = 'g110';
const _clsViewMode_Auto = 'g111';
const _clsImgNavigator = 'g112';
const _clsSVGStatusLine = 'g113';
const _bndProjectReports = 'g114';
const _clsProjectReports_stage1 = 'g115';
const _clsProjectReports_stage2 = 'g116';
const _clsPanelOpen = 'g117';
const _clsItemPanelClose = 'g118';
const _evShareProject = 'g119';
const _clsWorkspaceIsReadOnlyBanner = 'g11a';
const _bndExtendTrialProcessed = 'g11b';
const _clsProjectSearchPanel = 'g11c';
const _clsWrapperHeader_Panel__BigText = 'g11d';
const _clsWrapperHeader_Label = 'g11e';
const _bndIsTrial = 'g11f';
const _bndIsWorkspaceWritable = 'g11g';
const _clsProjects = 'g11h';
const _clsActivityPane = 'g11i';
const _ienN2br = 'g11j';
const _idSymbolInviteUser = 'g11k';
const _clsUserItem = 'g11l';
const _clsPagerCurrentButton = 'g11m';
const _clsFlexWrapper = 'g11n';
const _clsFlex0 = 'g11o';
const _clsStartDate = 'g11p';
const _clsColored = 'g11q';
const _evNetworkStatusChange = 'g11r';
const _clsWorkspaces = 'g11s';
const _clsWorkspaceMenuItem = 'g11t';
const _msgFeedbackSent = 'g11u';
const _bndRAErrors_WrongPassword = 'g11v';
const _rLogout = 'g11w';
const _clsManageWorkspaceSettingsBlock_EditView = 'g11x';
const _bndSubscriptions = 'g11y';
const _clsRoleLabel = 'g11z';
const _msgAddResourceMenuHeader = 'g120';
const _rProjectStatePush = 'g121';
const _clsShaded = 'g122';
const _rListFiles = 'g123';
const _clsColums3PanelHidden = 'g124';
const _clsTasksPool = 'g125';
const _bndProjectName = 'g126';
const _clsDatePicker_ActionPanel = 'g127';
const _evClearDeadline = 'g128';
const _evClearTaskStartDate = 'g129';
const _idSymbolBackArrow = 'g12a';
const _clsMaximized = 'g12b';
const _idSymbolProjectSideTabLeft = 'g12c';
const _idSymbolCollapseSidebarOff = 'g12d';
const _idSymbolCollapseSidebarOn = 'g12e';
const _urUpdateTask = 'g12f';
const _idUseInlineEdit = 'g12g';
const _clsPenImage = 'g12h';
const _clsTaskHighlight = 'g12i';
const _clsManageUsers = 'g12j';
const _clsManageTeams = 'g12k';
const _idRoleTmpText = 'g12l';
const _ienTextFlow = 'g12m';
const _clsExecuters = 'g12n';
const _clsNewLink = 'g12o';
const _clsCalendarList_Header4Remove = 'g12p';
const _idSymbolDoneMarkFrame = 'g12q';
const _clsMarkersLayer = 'g12r';
const _clsTutorial_Frame__Centered = 'g12s';
const _clsTutorial = 'g12t';
const _clsTutorial_StepConfirm = 'g12u';
const _clsTutorial_DecLine = 'g12v';
const _clsTutorial_StepOther = 'g12w';
const _bndProcessing = 'g12x';
const _bndProjectTeamMembers = 'g12y';
const _clsDialogHeader = 'g12z';
const _clsProjectUsers_TeamItem = 'g130';
const _clsProjectUsers = 'g131';
const _clsListHeader = 'g132';
const _clsIconSwitcher = 'g133';
const _bndHistory = 'g134';
const _bndMayIAddExecutor = 'g135';
const _clsBlocks = 'g136';
const _clsActionPanel = 'g137';
const _ienUserAvatarHref = 'g138';
const _cUserInfo = 'g139';
const _clsTrashcan = 'g13a';
const _clsPrimary = 'g13b';
const _clsUserInfoEdit = 'g13c';
const _ienSettingsCheckValue = 'g13d';
const _cUserInfoResponse = 'g13e';
const _bndOverviewList = 'g13f';
const _cRegistrationResponse = 'g13g';
const _clsTopResizer = 'g13h';
const _clsBottomResizer = 'g13i';
const _clsLeftResizer = 'g13j';
const _clsRightResizer = 'g13k';
const _clsTopRightResizer = 'g13l';
const _clsTopLeftResizer = 'g13m';
const _clsBottomRightResizer = 'g13n';
const _clsBottomLeftResizer = 'g13o';
const _clsScrollable = 'g13p';
const _cErrorMessage = 'g13q';
const _rSessionPing = 'g13r';
const _cWorkspaceUserInfo = 'g13s';
const _clsSelectBox = 'g13t';
const _clsEvernote_List_Item = 'g13u';
const _clsCategoriesSelector = 'g13v';
const _clsProjectStatusReport = 'g13w';
const _msgUserFLName = 'g13x';
const _clsProjectReports_stage3 = 'g13y';
const _eDashboardTabChange = 'g13z';
const _rListProject = 'g140';
const _clsPrjectRecycleBinItem = 'g141';
const _evOpenActiveProjects = 'g142';
const _clsProjectTagsPane_ColorSelector = 'g143';
const _cProjectTagInfo = 'g144';
const _evProjectCategorySelected = 'g145';
const _evPagerPageEvent = 'g146';
const _rUpdateProject = 'g147';
const _rGetMessageOfTheDay = 'g148';
const _clsWrapperHeader_Panel = 'g149';
const _clsNoWhatsNew = 'g14a';
const _clsPager_Wrapper = 'g14b';
const _clsPaperRoundWrapper = 'g14c';
const _idSymbolMotd = 'g14d';
const _clsRoleWrapper = 'g14e';
const _bndWSUsers = 'g14f';
const _ienLayoutProcess = 'g14g';
const _clsHorizontal = 'g14h';
const _clsCreateProjectShare = 'g14i';
const _clsProjectShareListItem = 'g14j';
const _evRemoveTag = 'g14k';
const _bndPopupAddClass = 'g14l';
const _clsWorkspaceMenuProjectItem = 'g14m';
const _rAddUserFeedback = 'g14n';
const _clsWSSelButton = 'g14o';
const _idSymbolToolbarHelp = 'g14p';
const _clsGlobalHeader = 'g14q';
const _clsWSOverview = 'g14r';
const _idSymbolToolbarPeople = 'g14s';
const _eProjectTabChange = 'g14t';
const _ienGetMsg = 'g14u';
const _msgCreateNewWorkspace = 'g14v';
const _bndInactiveWorkspaces = 'g14w';
const _clsGrayed = 'g14x';
const _bndOtherWorkspaces = 'g14y';
const _clsShowSubscriptionHeaders = 'g14z';
const _clsSubscriptionFrame = 'g150';
const _bndWorkspaceLimits = 'g151';
const _clsWsListBody = 'g152';
const _clsEditButton = 'g153';
const _clsManageButton = 'g154';
const _bndUpgradeLink = 'g155';
const _rWorkspaceInfo = 'g156';
const _msgSomeErrorOnServerWhileSavingChanges = 'g157';
const _clsDataPlaceHolder = 'g158';
const _ienFmtTimestamp = 'g159';
const _clsSubscriptionWrapper = 'g15a';
const _bndSubscriptionList = 'g15b';
const _msgAddUserMenuHeader = 'g15c';
const _evUserAccountTabChange = 'g15d';
const _msgLocationUknown = 'g15e';
const _msgDefaultTeamName = 'g15f';
const _clsProcess = 'g15g';
const _clsStoryEditorWindow_Header = 'g15h';
const _rAddUserToWorkspace = 'g15i';
const _clsShadedItem = 'g15j';
const _evMarkCompleted = 'g15k';
const _evConvertSelectionToGroup = 'g15l';
const _evCopySelectionToClipboard = 'g15m';
const _evPasteFromClipboard = 'g15n';
const _evRemoveTask = 'g15o';
const _bndSelectedCheckbox = 'g15p';
const _bndGroupState = 'g15q';
const _bndIsAlerted = 'g15r';
const _evHideTutorial = 'g15s';
const _evCancelTutorial = 'g15t';
const _evViewMode = 'g15u';
const _evAddNewTask = 'g15v';
const _cWorkspaceInfo = 'g15w';
const _clsZoom = 'g15x';
const _clsProjectStartDateGWrapper = 'g15y';
const _clsDeadlineBlock = 'g15z';
const _clsAddNewTask = 'g160';
const _clsSVGResources = 'g161';
const _evShowTaskDetails = 'g162';
const _clsAddNewTeam = 'g163';
const _idSymbolProjectSideTabRight = 'g164';
const _clsCornerBoth = 'g165';
const _urCreateTask = 'g166';
const _clsZoomDot = 'g167';
const _clsDeadline_Hover = 'g168';
const _clsCalendarList_Header = 'g169';
const _clsCalendarList_Item = 'g16a';
const _clsCalendarList_Item_Label = 'g16b';
const _clsStoryEditorWindow_Body = 'g16c';
const _clsViewTab = 'g16d';
const _idSymbolWorkspace = 'g16e';
const _clsTopMarker = 'g16f';
const _clsRightMarker = 'g16g';
const _clsBottomMarker = 'g16h';
const _clsLeftMarker = 'g16i';
const _clsVerticalGuides = 'g16j';
const _clsHorizontalGuides = 'g16k';
const _clsSelectedLink = 'g16l';
const _clsIncomeLinkHightlight = 'g16m';
const _clsOutcomeLinkHightlight = 'g16n';
const _bndPrevNextStep = 'g16o';
const _clsTutorial_Indicator_Dot = 'g16p';
const _clsTutorial_Indicator = 'g16q';
const _bndOwnerList = 'g16r';
const _bndParticipantList = 'g16s';
const _clsWSItem = 'g16t';
const _bndCurrentWorkspaceUsersFiltered = 'g16u';
const _evSelectorSelectUser = 'g16v';
const _clsOverviewUserSelector_List_Item = 'g16w';
const _clsPlaceHolder = 'g16x';
const _clsContextMenu = 'g16y';
const _clsContextMenuItem = 'g16z';
const _cTeamInfo = 'g170';
const _clsTeamItem_Abbr = 'g171';
const _clsPopupMenuIgnore = 'g172';
const _clsPopupMenuHandle = 'g173';
const _clsPopupMenu = 'g174';
const _cFileInfo = 'g175';
const _bndFiles = 'g176';
const _clsMyTask = 'g177';
const _clsIconHolder = 'g178';
const _clsTeamLogo = 'g179';
const _clsChilds = 'g17a';
const _bndBlockedBy = 'g17b';
const _bndBlocks = 'g17c';
const _bndIsDeadlineAlert = 'g17d';
const _rHistoryStream = 'g17e';
const _eProjectTaskActionRequired = 'g17f';
const _clsPopup = 'g17g';
const _clsCellAlignLeft = 'g17h';
const _clsDeadlineSection = 'g17i';
const _clsSection_Header = 'g17j';
const _clsTableRow = 'g17k';
const _clsDurationSelector = 'g17l';
const _clsSchedule_Value = 'g17m';
const _clsEmptyValue = 'g17n';
const _clsTaskSearchPanel = 'g17o';
const _clsProject = 'g17p';
const _bndFOWSetup = 'g17q';
const _clsCommand = 'g17r';
const _rResendEmailVerification = 'g17s';
const _clsProjectTasks = 'g17t';
const _clsTasksBlock = 'g17u';
const _clsTasksBlockPreserved = 'g17v';
const _clsUpcoming = 'g17w';
const _bndAddMore = 'g17x';
const _clsInviteUsersWrapper = 'g17y';
const _clsInviteProcess = 'g17z';
const _clsInviteFail = 'g180';
const _clsTaskDetails = 'g181';
const _cAbstractMessage = 'g182';
const _cPasswordRecoveryResponse = 'g183';
const _clsImageSelector_list = 'g184';
const _clsExecutorItem = 'g185';
const _clsPermanentShow = 'g186';
const _bndActiveUser = 'g187';
const _rSetUserProperties = 'g188';
const _cWorkspaceStatusResponse = 'g189';
const _clsStoryHeader = 'g18a';
const _clsActionPlanPlayForm = 'g18b';
const _clsFormWrapper = 'g18c';
const _clsViewMode_List = 'g18d';
const _clsViewMode_Expanded = 'g18e';
const _clsSelectBoxCaption = 'g18f';
const _clsAppStoreLink = 'g18g';
const _bndEvernoteBusinessNotebooks = 'g18h';
const _rFeatureData = 'g18i';
const _msgInviteUserButtonLabel = 'g18j';
const _msgPurgeProjectConfirm = 'g18k';
const _rPurgeRemovedProjects = 'g18l';
const _clsProjectReports_Pane1_items = 'g18m';
const _bndProjectReports_Pane2 = 'g18n';
const _rReactivateProject = 'g18o';
const _evPublishOverview2Evernote = 'g18p';
const _evProjectStatus2Evernote = 'g18q';
const _evTaskList2Evernote = 'g18r';
const _evProjectOverview2Evernote = 'g18s';
const _clsProjectTagsPane_TagsWrapper = 'g18t';
const _clsSortableChoosen = 'g18u';
const _evStoreProjectTags = 'g18v';
const _rUpdateProjectTag = 'g18w';
const _evResetProjectTags = 'g18x';
const _evProjectReports = 'g18y';
const _rRemoveProject = 'g18z';
const _rCloneProject = 'g190';
const _msgNoWorkspaceFound = 'g191';
const _bndOverviewHorizontSelect = 'g192';
const _clsOverviewHorizontSelect = 'g193';
const _clsMarkedForRemoval = 'g194';
const _clsColorSelector_Base = 'g195';
const _clsIconSearch = 'g196';
const _cmpWSOverviewUserSelector = 'v-197';
const _cmpProjectTags = 'v-198';
const _idSymbolCirclePlus = 'g199';
const _idSymbolCircleDone = 'g19a';
const _clsProjectsListWrapper = 'g19b';
const _clsTrialBannerBorder = 'g19c';
const _bndTrialEndsSoon = 'g19d';
const _ienScrollDetect = 'g19e';
const _evHideMOTD = 'g19f';
const _clsCloseMOTDButton = 'g19g';
const _clsMOTDShowIcon = 'g19h';
const _evShowMOTD = 'g19i';
const _clsRightStright = 'g19j';
const _clsNameWithPadding = 'g19k';
const _bndPager = 'g19l';
const _idSymbolSortByLta = 'g19m';
const _idSymbolPagingIcon = 'g19n';
const _idSymbolToolbarFiles = 'g19o';
const _clsRelativeDeadlines = 'g19p';
const _clsProjectSharesList = 'g19q';
const _clsListHolder = 'g19r';
const _ienProjectShareListItem = 'g19s';
const _bndProjectShareLink = 'g19t';
const _bndProjectShareCode = 'g19u';
const _clsEvernote_Header = 'g19v';
const _clsEvernote_List = 'g19w';
const _clsFilterActive = 'g19x';
const _clsProjectTagsPane_Label = 'g19y';
const _cmpProjectTagListItem = 'v-19z';
const _urRemoveProjectTag = 'g1a0';
const _clsModeTagRemove = 'g1a1';
const _clsIconTagColorEdit = 'g1a2';
const _clsTagRemoveMark = 'g1a3';
const _clsProjectReports_Pane2 = 'g1a4';
const _clsPrintMode = 'g1a5';
const _eRemoveAvatar = 'g1a6';
const _clsMobileMode = 'g1a7';
const _clsShowMore = 'g1a8';
const _cAddUserFeedback = 'g1a9';
const _clsNavWrapper = 'g1aa';
const _bndRAErrors_NoReasonProvided = 'g1ab';
const _bndRAPassword = 'g1ac';
const _bndRAReason = 'g1ad';
const _clsTrigger = 'g1ae';
const _clsManageBillingWorkspaces = 'g1af';
const _clsNotificationWidget = 'g1ag';
const _clsProjectActive = 'g1ah';
const _eDataReloadRequired = 'g1ai';
const _evSubscribeNowMenuItem = 'g1aj';
const _bndWorkspaceSettings_days = 'g1ak';
const _bndWorkspaceSettings_timeStart = 'g1al';
const _bndWorkspaceSettings_timeEnd = 'g1am';
const _clsWide = 'g1an';
const _bndWorkspaceUsage = 'g1ao';
const _bndIsSubscriptionActive = 'g1ap';
const _bndTrialInfo = 'g1aq';
const _bndReactivateSubscription = 'g1ar';
const _clsSpinner = 'g1as';
const _clsReactivateButton = 'g1at';
const _rListWorkspaceUsers = 'g1au';
const _msgNextPane = 'g1av';
const _rRemoveUserFromWorkspace = 'g1aw';
const _clsSubscribeButton = 'g1ax';
const _ienPostProcessWorkspaceItems = 'g1ay';
const _ienMngWSItemTPL = 'g1az';
const _ienTranslateRole = 'g1b0';
const _ienWorkspaceLink = 'g1b1';
const _clsEditSection = 'g1b2';
const _clsManageWorkspace = 'g1b3';
const _clsSubscriptionPlan = 'g1b4';
const _clsOwnerInfo = 'g1b5';
const _ienWSAvatarHref = 'g1b6';
const _urChangeWorkspaceImage = 'g1b7';
const _msgAddUser = 'g1b8';
const _msgInvitationSent = 'g1b9';
const _msgMyTasksHeader = 'g1ba';
const _clsHeaderMenu = 'g1bb';
const _msgNotificationsUpdated = 'g1bc';
const _msgLostAndFoundGroupName = 'g1bd';
const _msgContextMenuMarkComplete = 'g1be';
const _msgContextMenuConvertSelectionToGroup = 'g1bf';
const _msgPasteFromClipboard = 'g1bg';
const _clsMsg = 'g1bh';
const _msgProjectFileRemoveConfirm = 'g1bi';
const _msgAddUserToWorkspaceOutOfLimits = 'g1bj';
const _msgNoSuchSessionMessage = 'g1bk';
const _msgSomeErrorOnServerWhileRetrievingData = 'g1bl';
const _msgTutorialTextCreateTask = 'g1bm';
const _msgTutorialTextPlayWithArrows = 'g1bn';
const _msgTutorialTextAssignUsers = 'g1bo';
const _msgTutorialTextSelectTasks = 'g1bp';
const _msgTutorialTextMarkAsDone = 'g1bq';
const _msgTutorialTextCopyNPaste = 'g1br';
const _clsProgressBar = 'g1bs';
const _clsFileUploadBanner = 'g1bt';
const _clsProjectAddUserMenu = 'g1bu';
const _idSymbolAddUsers = 'g1bv';
const _rProjectMembership = 'g1bw';
const _cnstStoryMenu_Create = 'g1bx';
const _clsSettings = 'g1by';
const _clsChapter = 'g1bz';
const _bndStoryEditor_ChapterTitle = 'g1c0';
const _bndStoryChapterPartType = 'g1c1';
const _bndStoryWindowTitle = 'g1c2';
const _bndSaveButton = 'g1c3';
const _clsTaskSchedule_PopupWrapper = 'g1c4';
const _bndGeneratedCode = 'g1c5';
const _evMarkIncomplete = 'g1c6';
const _evConvert2Group = 'g1c7';
const _evGroup2Task = 'g1c8';
const _bndWrapperMode = 'g1c9';
const _clsZoomCtrl = 'g1ca';
const _evRestoreTutorial = 'g1cb';
const _clsFullscreen = 'g1cc';
const _evTabChangeRequest = 'g1cd';
const _evChangeLeftPanelVisibility = 'g1ce';
const _rProjectInfo = 'g1cf';
const _evFileUpload = 'g1cg';
const _evChangeCompleteDate = 'g1ch';
const _bndParentGroupId = 'g1ci';
const _evGetGroupPopupLocation = 'g1cj';
const _evShowContextMenu = 'g1ck';
const _clsRightFloatButton = 'g1cl';
const _clsHandleWrapper = 'g1cm';
const _idSymbolCanvasModeExec = 'g1cn';
const _idSymbolCanvasModeManage = 'g1co';
const _idSymbolCanvasModeFull = 'g1cp';
const _clsHeaderWrapper = 'g1cq';
const _fltSortUsers = 'g1cr';
const _cmpProjectTeam = 'v-1cs';
const _clsTaskStartedCircle = 'g1ct';
const _idRoleNewLink = 'g1cu';
const _idSymbolCanvasNewLink = 'g1cv';
const _ienPenByStatus = 'g1cw';
const _idRoleDeadlineValue = 'g1cx';
const _idRoleDeadlineHover = 'g1cy';
const _clsIndicatorValue = 'g1cz';
const _idRoleBottomBubble = 'g1d0';
const _clsGroupResizeRight = 'g1d1';
const _clsGroupResizeBottom = 'g1d2';
const _clsGroupContainer = 'g1d3';
const _idSymbolTaskFile = 'g1d4';
const _clsCalendar_Remove = 'g1d5';
const _clsCalendarList_CheckboxWrapper_Remove_Checkbox = 'g1d6';
const _idSymbolArrowDropDownCircle = 'g1d7';
const _idSymbolTaskListGroup = 'g1d8';
const _idSymbolToolbarNotifications = 'g1d9';
const _clsSelectionRect = 'g1da';
const _clsTaskNoName = 'g1db';
const _bndVimeoPlayer = 'g1dc';
const _clsPlayButton = 'g1dd';
const _bndStepConfirm = 'g1de';
const _bndCancelButton = 'g1df';
const _clsFakeVideo__Button = 'g1dg';
const _clsTutorial_LabelFrame = 'g1dh';
const _ienWSItemTPL = 'g1di';
const _fltAddLast = 'g1dj';
const _clsPopupVisible = 'g1dk';
const _cmpProjectTeamMember = 'v-1dl';
const _rTeamMembership = 'g1dm';
const _clsTrialBanner = 'g1dn';
const _clsTextColumn = 'g1do';
const _clsExtendTrialButton = 'g1dp';
const _rProcessUploadedPicture = 'g1dq';
const _rRemovePicture = 'g1dr';
const _clsUploadBannerShield_progressBar = 'g1ds';
const _clsUploadBannerShield_progressTitle = 'g1dt';
const _clsUserCard = 'g1du';
const _clsAddUserProject = 'g1dv';
const _clsTeamItem_ColorSelector = 'g1dw';
const _clsTeamItem_Name = 'g1dx';
const _clsTeamItem_Members = 'g1dy';
const _clsTeamItem_Icon = 'g1dz';
const _clsTeamMember_fio = 'g1e0';
const _clsDialogShield = 'g1e1';
const _clsDialogClose = 'g1e2';
const _bndEmpty = 'g1e3';
const _rRemoveFile = 'g1e4';
const _urAddFile = 'g1e5';
const _bndTeams = 'g1e6';
const _clsAllSelector = 'g1e7';
const _clsSVExtraSmall = 'g1e8';
const _bndHistoryButtonHide = 'g1e9';
const _clsNoteSaved = 'g1ea';
const _clsLoadMoreButton = 'g1eb';
const _clsValueWrapper = 'g1ec';
const _clsExpectations_Short = 'g1ed';
const _clsSection_SubHeader = 'g1ee';
const _clsTaskSchedule = 'g1ef';
const _clsValueWrapper_Alt = 'g1eg';
const _ienTaskTimingLegendHeader = 'g1eh';
const _urCompleteTask = 'g1ei';
const _clsStartedTaskLabel = 'g1ej';
const _clsStartTaskLabel = 'g1ek';
const _clsCellAlignRight = 'g1el';
const _urRemoveTask = 'g1em';
const _ienTaskNameCaption = 'g1en';
const _clsCompletedSection = 'g1eo';
const _clsSection_Body = 'g1ep';
const _clsSchedule_Editor = 'g1eq';
const _ienBlockTpl = 'g1er';
const _clsNoteProcessingValue = 'g1es';
const _clsNoteSavedValue = 'g1et';
const _bndCurrentWorkspaceSettings = 'g1eu';
const _clsCalendar_IsTooEarly = 'g1ev';
const _clsSchedule_Wrapper = 'g1ew';
const _clsSchedule_Item = 'g1ex';
const _clsSchedule_Label = 'g1ey';
const _bndTaskSearchValue = 'g1ez';
const _clsCollapsibleHeader = 'g1f0';
const _bndShowDropDown = 'g1f1';
const _clsContainer = 'g1f2';
const _ienUserEmails = 'g1f3';
const _clsPending = 'g1f4';
const _rAssignContactInfoToWorkspaceUser = 'g1f5';
const _bndWorkspacesList = 'g1f6';
const _clsUserInfo = 'g1f7';
const _clsButton_Red = 'g1f8';
const _clsWSUsersList = 'g1f9';
const _clsErrorHint = 'g1fa';
const _bndNotificationsQty = 'g1fb';
const _clsButton_Wide = 'g1fc';
const _clsNavButton = 'g1fd';
const _rWorkspaceOverviewForUser = 'g1fe';
const _ienProjectPath = 'g1ff';
const _clsThrobberShield = 'g1fg';
const _clsInviteUsersItem = 'g1fh';
const _clsInviteDone = 'g1fi';
const _clsSecond = 'g1fj';
const _clsFloatLeft = 'g1fk';
const _clsShow = 'g1fl';
const _rCheckSession = 'g1fm';
const _msgErrorMessage = 'g1fn';
const _msgWrongTokenMessage = 'g1fo';
const _cGetUserAlertsSettingsResponse = 'g1fp';
const _clsSVGResource = 'g1fq';
const _clsDateIsLate = 'g1fr';
const _clsHBox = 'g1fs';
const _cTransitionInfo = 'g1ft';
const _cUserAlertEntryInfo = 'g1fu';
const _cUserPropertiesResponse = 'g1fv';
const _cListOfWorkspacesResponse = 'g1fw';
const _cHistoryEntryInfo = 'g1fx';
const _cClipboardPreview = 'g1fy';
const _cUserPropertiesDoneResponse = 'g1fz';
const _cWorkspaceUserInfoRequest = 'g1g0';
const _cUpdateWorkspaceUserInfoResponse = 'g1g1';
const _cTeamStatusResponse = 'g1g2';
const _clsLoginMsg = 'g1g3';
const _clsGraphView = 'g1g4';
const _bndTasks = 'g1g5';
const _bndLinks = 'g1g6';
const _bndGetTranslate = 'g1g7';
const _bndCalculatePath = 'g1g8';
const _bndDblClick = 'g1g9';
const _clsRotate = 'g1ga';
const _idRoleStatusLine = 'g1gb';
const _eShowProjectBanner = 'g1gc';
const _clsFileUploaderForm = 'g1gd';
const _clsMobileMessage = 'g1ge';
const _clsIFrameContainer = 'g1gf';
const _clsIFrameContainer_Outer = 'g1gg';
const _bndIWantMobile = 'g1gh';
const _msgLoadEvernoteNotebooks = 'g1gi';
const _bndEvernoteUserInfo = 'g1gj';
const _bndEvernoteNotebooks = 'g1gk';
const _bndEvernoteBusinessUser = 'g1gl';
const _bndNotebookAction = 'g1gm';
const _clsEvernote_List_Bussiness = 'g1gn';
const _clsEvernoteWrapper = 'g1go';
const _msgEvernoteSubscribed2Sync = 'g1gp';
const _msgEvernoteSubscribeFailed = 'g1gq';
const _msgSetCategory = 'g1gr';
const _msgSetSecondCategory = 'g1gs';
const _bndColorMakeVisible = 'g1gt';
const _msgProjectsPurgeAllCaption = 'g1gu';
const _msgProjectsRestoreHeader = 'g1gv';
const _msgEvernoteNotebooksList = 'g1gw';
const _msgProjectReports_NextButton = 'g1gx';
const _rReportProjectsAndStats = 'g1gy';
const _msgProjectReport_Title = 'g1gz';
const _rWorkspaceOverview = 'g1h0';
const _msgOverviewWideReport_Title = 'g1h1';
const _evProjectRecycleBin = 'g1h2';
const _evProjectRecycleBinPurge = 'g1h3';
const _evProjectRecycleBinRestore = 'g1h4';
const _evOpenArchivedProjects = 'g1h5';
const _evAddCreditCard = 'g1h6';
const _evManageTags = 'g1h7';
const _clsSortableGhost = 'g1h8';
const _rRemoveProjectTag = 'g1h9';
const _msgTagRemoveErrorAccessDenied = 'g1ha';
const _msgTagUpdateErrorAccessDenied = 'g1hb';
const _rCreateProjectTag = 'g1hc';
const _msgTagCreateErrorAccessDenied = 'g1hd';
const _evCreateProjectTag = 'g1he';
const _idDashboardActiveFilter = 'g1hf';
const _evCreateProject = 'g1hg';
const _evDisablePagingFeature = 'g1hh';
const _evEnablePagingFeature = 'g1hi';
const _evCreateSpamProject = 'g1hj';
const _evDeleteAllProjects = 'g1hk';
const _evCloneInsane = 'g1hl';
const _msgProjectBillingStatusDenied = 'g1hm';
const _msgProjectBillingStatusOutOfLimits = 'g1hn';
const _msgProjectBillingStatusNoCard = 'g1ho';
const _msgYourAccessToWorkspaceRemoved = 'g1hp';
const _msgCloneProjectDialogHeader = 'g1hq';
const _msgRemoveProjectConfirm = 'g1hr';
const _evProjectEdit = 'g1hs';
const _evProjectClone = 'g1ht';
const _evProjectRemove = 'g1hu';
const _evProjectArchive = 'g1hv';
const _rArchiveProject = 'g1hw';
const _evProjectReactivate = 'g1hx';
const _bndSubscribeButton = 'g1hy';
const _bndExtendTrialButton = 'g1hz';
const _rRedeemTrialOffer = 'g1i0';
const _rTagProject = 'g1i1';
const _ienProjectsPager = 'g1i2';
const _ienProjectsGroupBy = 'g1i3';
const _ienCheckPagingFeatureStatus = 'g1i4';
const _urViewWorkspaceOverviewForOtherUsers = 'g1i5';
const _clsIconGear = 'g1i6';
const _urCreateProject = 'g1i7';
const _clsWrapperHeader_Label_Button__Back = 'g1i8';
const _clsAlignRight = 'g1i9';
const _clsProjectTagPane = 'g1ia';
const _urCreateProjectTag = 'g1ib';
const _clsProjectList_Wrapper = 'g1ic';
const _ienProjectItem = 'g1id';
const _ienSVGStatusLinePostProcessor = 'g1ie';
const _clsPager_WrapperBottom = 'g1if';
const _clsWSPeople = 'g1ig';
const _clsInviteUserForm = 'g1ih';
const _urAddWorkspaceUser = 'g1ii';
const _clsLeftPart = 'g1ij';
const _evAddResource = 'g1ik';
const _clsUsersBody = 'g1il';
const _ienWSUserItem = 'g1im';
const _clsNonInteractive = 'g1in';
const _clsPager = 'g1io';
const _idSymbolTags = 'g1ip';
const _idSymbolSortAlphabet = 'g1iq';
const _idSymbolSortByTagsOff = 'g1ir';
const _idSymbolSortByTagsOn = 'g1is';
const _idSymbolProjectReports = 'g1it';
const _ienProjectsRecycleBinItemTpl = 'g1iu';
const _clsNoImage = 'g1iv';
const _idSymbolProjectClone = 'g1iw';
const _idSymbolProjectArchive = 'g1ix';
const _idSymbolProjectRestore = 'g1iy';
const _clsProjectShareCode = 'g1iz';
const _clsAddNewShare = 'g1j0';
const _clsDirectLink = 'g1j1';
const _clsEvernote_UserInfo_User = 'g1j2';
const _clsProjectTagsPane_AllProjects = 'g1j3';
const _clsManageMode = 'g1j4';
const _urTagProject = 'g1j5';
const _clsIconTagRemove = 'g1j6';
const _clsIconTagRestore = 'g1j7';
const _clsModeTagRestore = 'g1j8';
const _cmpProjectTagColorSelector = 'v-1j9';
const _clsProjectReports_Pane1 = 'g1ja';
const _clsProjectReports_Pane2_Projects = 'g1jb';
const _clsProjectReports_Pane2_Workspaces = 'g1jc';
const _clsProjectReports_Pane3 = 'g1jd';
const _clsProjectsStatusReport = 'g1je';
const _clsProjectStatusVerbal = 'g1jf';
const _clsHaveAlerted = 'g1jg';
const _clsProjectStatusLine = 'g1jh';
const _msgNetworkGoesOnline = 'g1ji';
const _msgNetworkGoesOffline = 'g1jj';
const _clsWorkspaceMenu = 'g1jk';
const _msgWSnPRsHeader = 'g1jl';
const _bndActiveWorkspace = 'g1jm';
const _bndShowMore = 'g1jn';
const _bndShowMoreLink = 'g1jo';
const _msgHelpMenuTitle = 'g1jp';
const _msgNotificationsTitle = 'g1jq';
const _clsNotifications = 'g1jr';
const _clsUnreadNotification = 'g1js';
const _evNotificationsShowAll = 'g1jt';
const _msgNotificationsShowAll = 'g1ju';
const _msgNotificationsEmpty = 'g1jv';
const _msgUserMenuTitle = 'g1jw';
const _bndIsMyWorkspace = 'g1jx';
const _msgFeedbackDialogHeader = 'g1jy';
const _msgBugReportNextButtonLabel = 'g1jz';
const _msgBugReportCancelButtonLabel = 'g1k0';
const _msgSendingFeedback = 'g1k1';
const _msgBugReport = 'g1k2';
const _msgFeedbackFail = 'g1k3';
const _msgLabsDialogHeader = 'g1k4';
const _msgFeedbackMenuTitle = 'g1k5';
const _msgNotificationsDialogHeader = 'g1k6';
const _msgShareProjectHeader = 'g1k7';
const _rCreateShare = 'g1k8';
const _rRemoveShare = 'g1k9';
const _msgRemoveAccount = 'g1ka';
const _evButtonNext = 'g1kb';
const _rValidatePassword = 'g1kc';
const _evButtonBack = 'g1kd';
const _evRemoveAccountConfirm = 'g1ke';
const _rRemoveUserAccount = 'g1kf';
const _eShowUserProfile = 'g1kg';
const _evRemoveAccountWizard = 'g1kh';
const _rListShares = 'g1ki';
const _msgFirstUserProjectName = 'g1kj';
const _clsFeedback = 'g1kk';
const _bnd2DaysFromTrialStarts = 'g1kl';
const _clsWorkspaceActive = 'g1km';
const _idSymbolToolbarOverview = 'g1kn';
const _idSymbolToolbarProjects = 'g1ko';
const _idSymbolToolbarPlan = 'g1kp';
const _cmpUserToolbar = 'v-1kq';
const _clsPagesSpool = 'g1kr';
const _clsShield = 'g1ks';
const _clsItems = 'g1kt';
const _clsItem = 'g1ku';
const _clsFrame = 'g1kv';
const _clsTutorialGetout = 'g1kw';
const _clsTutorialGetout_Tail = 'g1kx';
const _clsTutorialGetout_Body = 'g1ky';
const _clsMessageValue = 'g1kz';
const _clsMessageButton = 'g1l0';
const _msgManageSubscription = 'g1l1';
const _evAddNewWorkspace = 'g1l2';
const _evEditWorkspaceLogo = 'g1l3';
const _evWorkspaceSettingsView = 'g1l4';
const _evWorkspaceSettingsView_Save = 'g1l5';
const _rUpdateWorkspaceSettings = 'g1l6';
const _evWorkspaceSettingsView_Cancel = 'g1l7';
const _evWorkspaceSettingsTimeslotAdd = 'g1l8';
const _evWorkspaceSettingsTimeslotRemove = 'g1l9';
const _msgSelectSubscriptionPlan = 'g1la';
const _msgTransferOwnershipHeader = 'g1lb';
const _rUpdateWorkspace = 'g1lc';
const _bndShowCreateWorkspaceButton = 'g1ld';
const _bndIsCardPresent = 'g1le';
const _bndProductCode = 'g1lf';
const _msgProductCodeAsNone = 'g1lg';
const _bndSubscriptionButton = 'g1lh';
const _rListActiveSubscriptions = 'g1li';
const _clsTransferOnwership = 'g1lj';
const _msgLoadUsers = 'g1lk';
const _msgNoUsersToTransferOwnership = 'g1ll';
const _msgWizardDoneCaption = 'g1lm';
const _clsRemoveWorkspace = 'g1ln';
const _msgConfirmWorkspaceRemove = 'g1lo';
const _msgConfirmWorkspaceRemoval = 'g1lp';
const _rRemoveWorkspace = 'g1lq';
const _msgWorkspaceRemoveError = 'g1lr';
const _clsLeaveWorkspace = 'g1ls';
const _msgLeaveWorkspaceConfirmation = 'g1lt';
const _msgWorkspaceUserRemovalNotAllowed = 'g1lu';
const _msgLeaveWorkspaceFail = 'g1lv';
const _clsTransferOwnershipThrobber = 'g1lw';
const _rTransferOwnership = 'g1lx';
const _ienSubstUsageItem = 'g1ly';
const _clsWorkspaceInfo = 'g1lz';
const _urChangeWorkspaceInfo = 'g1m0';
const _clsViewSection = 'g1m1';
const _clsGoToWorkspace = 'g1m2';
const _clsManageWorkspaceSettings = 'g1m3';
const _ienShouldSeeWorkspaceSettingsPane = 'g1m4';
const _clsMarginTop = 'g1m5';
const _ienWorkspaceSchedule = 'g1m6';
const _ienWorkspaceUsageItem = 'g1m7';
const _ienUserFL = 'g1m8';
const _clsActionButtons = 'g1m9';
const _clsProductList = 'g1ma';
const _msgAddTeamMenuHeader = 'g1mb';
const _msgAddSpectatorMenuHeader = 'g1mc';
const _msgWorkspacesTitle = 'g1md';
const _msgNotAllowed2AddUser = 'g1me';
const _msgNoSuchUser = 'g1mf';
const _msgAddDeadline = 'g1mg';
const _msgUserAdded = 'g1mh';
const _msgRemovedTask = 'g1mi';
const _msgTaskInfoHeaderFuture = 'g1mj';
const _msgTaskInfoHeaderStartTime = 'g1mk';
const _msgTaskInfoHeaderTaskCapt = 'g1ml';
const _msgTaskInfoHeaderCurrent = 'g1mm';
const _msgTaskInfoHeaderSkipped = 'g1mn';
const _msgTaskInfoHeaderCompleted = 'g1mo';
const _msgAllTasksHeader = 'g1mp';
const _msgUserTasksHeader = 'g1mq';
const _msgTeamUserTasksHeader = 'g1mr';
const _msgTeamTasksHeader = 'g1ms';
const _msgAllTasks = 'g1mt';
const _msgAllTeamTasks = 'g1mu';
const _msgUserAction = 'g1mv';
const _msgUserAccountDialogHeader = 'g1mw';
const _clsIconMan = 'g1mx';
const _msgInviteUser = 'g1my';
const _msgProjectLoadingBanner = 'g1mz';
const _msgPageReloadRequired = 'g1n0';
const _msgConvertTask2Group = 'g1n1';
const _msgConvertTasks2Group = 'g1n2';
const _msgUploadMenuHeader = 'g1n3';
const _msgSafariFullscreenWarn = 'g1n4';
const _msgConfirmMultiRemove = 'g1n5';
const _msgConfirmTasksRemove = 'g1n6';
const _msgConfirmTaskRemove = 'g1n7';
const _msgConfirmGroupRemove = 'g1n8';
const _msgConfirmEmptyGroupRemove = 'g1n9';
const _msgRemoveTaskAndStoryChapter = 'g1na';
const _msgCovertResource2UserEmailRequest = 'g1nb';
const _msgAddTeamMemberHeader = 'g1nc';
const _msgAddTeamMemberButtonLabel = 'g1nd';
const _msgInviteUserHeader = 'g1ne';
const _msgOopsGroupRemoved = 'g1nf';
const _msgConfirmUserRemoval = 'g1ng';
const _msgConfirmUser2ResourceConvert = 'g1nh';
const _msgYourAccessToProjectRemoved = 'g1ni';
const _msgNoProjectFound = 'g1nj';
const _msgLostGroupFound = 'g1nk';
const _msgUploadError = 'g1nl';
const _msgEmailVerificationSent = 'g1nm';
const _msgEmailAlreadyInSystem = 'g1nn';
const _msgEmailVerificationPending = 'g1no';
const _msgConfirmationEmailResend = 'g1np';
const _msgContactSetAsPrimary = 'g1nq';
const _msgContactRemoved = 'g1nr';
const _msgConfirmationError = 'g1ns';
const _msgContactSetAsPrimaryError = 'g1nt';
const _msgRemoveContactError = 'g1nu';
const _msgWorkspaceBeenRemoved = 'g1nv';
const _msgContextMenuTaskNameEdit = 'g1nw';
const _msgContextMenuGroupNameEdit = 'g1nx';
const _msgContextMenuSetDeadline = 'g1ny';
const _msgContextMenuMarkIncomplete = 'g1nz';
const _msgContextMenuConvertToGroup = 'g1o0';
const _msgContextMenuConvertToTask = 'g1o1';
const _msgContextMenuDeleteTask = 'g1o2';
const _msgContextMenuCreateTaskHere = 'g1o3';
const _msgContextMenuRemoveSelection = 'g1o4';
const _msgCopySelectionToClipboard = 'g1o5';
const _msgCopyToClipboard = 'g1o6';
const _msgPasteFromClipboardWithExecutors = 'g1o7';
const _msgRemoveSelectedLink = 'g1o8';
const _msgPasteFrameApply = 'g1o9';
const _msgPasteFrameCancel = 'g1oa';
const _msgContextMenuOpenGroup = 'g1ob';
const _msgExportMenuTitle = 'g1oc';
const _msgNoOverviewAndUpcoming = 'g1od';
const _msgMaxTasksLimitReached = 'g1oe';
const _msgMaxNestingLimitReached = 'g1of';
const _msgShortDate = 'g1og';
const _msgShortDateTime = 'g1oh';
const _msgFullDate = 'g1oi';
const _msgFullDateTime = 'g1oj';
const _clsRedText = 'g1ok';
const _msgFileUploadOutOfLimits = 'g1ol';
const _msgFileUploadErrorAccessDenied = 'g1om';
const _msgFileUploadErrorReadOnly = 'g1on';
const _msgFileUploadErrorNoRecipient = 'g1oo';
const _msgCannotChangeResourceToUserOutOfLimits = 'g1op';
const _msgRemoveCommentConfirmation = 'g1oq';
const _msgLocationLabel = 'g1or';
const _msgPasswordChanged = 'g1os';
const _msgPasswordChangeError = 'g1ot';
const _msgTeamColorPopup = 'g1ou';
const _msgConfirmTeamRemove = 'g1ov';
const _msgAddMemberToTeamTitle = 'g1ow';
const _msgCalendarPickerHeader = 'g1ox';
const _msgUnlinkCalendar = 'g1oy';
const _msgCalendarAddedMessage = 'g1oz';
const _msgCalendarAddedErrorMessage = 'g1p0';
const _msgCalendarUnlinkedMessage = 'g1p1';
const _msgGoogleCalendarLoadMessage = 'g1p2';
const _msgProjectStartDate = 'g1p3';
const _msgCloseWindowConfirm = 'g1p4';
const _msgMarkGroupAsCompleted = 'g1p5';
const _msgMarkSelectionAsCompleted = 'g1p6';
const _msgEmbedStoryHeader = 'g1p7';
const _msgGoogleCalendarLoadErrorMessage = 'g1p8';
const _msgUserAttached = 'g1p9';
const _msgUserBeenInvitedToTakeOver = 'g1pa';
const _msgUserIsAlreadyExists = 'g1pb';
const _msgUserAttachNotAllowed = 'g1pc';
const _msgUserAttachDenied = 'g1pd';
const _msgUserAttachNoCard = 'g1pe';
const _msgLogoutOtherSessionConfirmation = 'g1pf';
const _msgLogoutOtherSessionResult = 'g1pg';
const _msgLogoutOtherSessionFail = 'g1ph';
const _msgGetNS_ERROR_FILE_CORRUPTED = 'g1pi';
const _msgPutNS_ERROR_FILE_CORRUPTED = 'g1pj';
const _msgPutmsgPutQuotaExceededError = 'g1pk';
const _msgRemoveNS_ERROR_FILE_CORRUPTED = 'g1pl';
const _msgAccessDeniedOnWorkspaceOverview = 'g1pm';
const _msgStoryEditorMenuHeader = 'g1pn';
const _msgStoryEditorWindowHeader_Settings = 'g1po';
const _msgStoryEditorWindowHeader_Chapter = 'g1pp';
const _msgConfirmAvatarRemove = 'g1pq';
const _msgResendUserInviteConfirmation = 'g1pr';
const _msgTaskScheduleLabel = 'g1ps';
const _msgTaskScheduleLabelHover = 'g1pt';
const _rUploadTicket = 'g1pu';
const _cUploadTicketInfo = 'g1pv';
const _evAddMemberToTeamEvent = 'g1pw';
const _bndAddImageUrl = 'g1px';
const _bndAddEmbedBlock = 'g1py';
const _bndAddHTMLBlock = 'g1pz';
const _cnstStoryEmpty = 'g1q0';
const _cnstStoryCreated = 'g1q1';
const _cnstStoryMenu_Settings = 'g1q2';
const _bndStoryContentInput = 'g1q3';
const _bndStoryChapterInput = 'g1q4';
const _cStoryPart = 'g1q5';
const _clsStoryEditor_chapter_editor = 'g1q6';
const _clsStoryEditor_editor = 'g1q7';
const _clsCoverImage = 'g1q8';
const _cnstStoryMenu_Preview = 'g1q9';
const _cnstStoryMenu_Publish = 'g1qa';
const _rConsultantCloneProject = 'g1qb';
const _clsAddUserToTask = 'g1qc';
const _evReassignUserTask = 'g1qd';
const _evTreeExport = 'g1qe';
const _evMarkStarted = 'g1qf';
const _evMarkGroupAsCompleted = 'g1qg';
const _evMarkSelectionAsCompleted = 'g1qh';
const _evConvertToGroup = 'g1qi';
const _evConvertToTask = 'g1qj';
const _evCreateTaskHere = 'g1qk';
const _evPasteFromClipboardWithExecutors = 'g1ql';
const _evRemoveSelectedLink = 'g1qm';
const _evExportToImage = 'g1qn';
const _bndAddCalendar = 'g1qo';
const _bndCleanupCalendar = 'g1qp';
const _bndMentionProjectName = 'g1qq';
const _evProjectDateStartEdit = 'g1qr';
const _evRemoveDraftTask = 'g1qs';
const _eViewTaskInfo = 'g1qt';
const _evCancelTaskNameEdit = 'g1qu';
const _evConsultantClone = 'g1qv';
const _bndShowPanelHideButton = 'g1qw';
const _bndListMode = 'g1qx';
const _bndMakeGroup = 'g1qy';
const _bndGroupingMode = 'g1qz';
const _bndGroup2Task = 'g1r0';
const _bndAddNewMember = 'g1r1';
const _clsZoomLayer1 = 'g1r2';
const _rProjectMap = 'g1r3';
const _rProjectExecutionBundle = 'g1r4';
const _cStoryPageSettings = 'g1r5';
const _rCopyToClipboard = 'g1r6';
const _rPasteFromClipboard = 'g1r7';
const _clsExport = 'g1r8';
const _clsStoryEditorMenuButton = 'g1r9';
const _clsProjectHeadGroup = 'g1ra';
const _rRemoveComment = 'g1rb';
const _evTaskDurationDebug = 'g1rc';
const _clsProjectStartDate = 'g1rd';
const _evCreateLink = 'g1re';
const _evCreateTaskWithLink = 'g1rf';
const _evRemoveTransition = 'g1rg';
const _evSetExecuters = 'g1rh';
const _evZoom = 'g1ri';
const _evPositionSizeChanged = 'g1rj';
const _evShadowDragCreated = 'g1rk';
const _evShadowDragDrop = 'g1rl';
const _evApplyPaste = 'g1rm';
const _evCancelPaste = 'g1rn';
const _urCreateProjectTeam = 'g1ro';
const _idSymbolProjectMytasks = 'g1rp';
const _idSymbolCanvasShare = 'g1rq';
const _clsLeftCorner = 'g1rr';
const _clsRightCorner = 'g1rs';
const _clsHeadWrapper = 'g1rt';
const _clsZoomReact = 'g1ru';
const _clsZoomDot50 = 'g1rv';
const _clsZoomDot75 = 'g1rw';
const _clsZoomDot100 = 'g1rx';
const _idPatternCanvasFillVectr = 'g1ry';
const _idSymbolPaper = 'g1rz';
const _clsAltDrag = 'g1s0';
const _clsNoTeams = 'g1s1';
const _cmpProjectUserNew = 'v-1s2';
const _cmpProjectUser = 'v-1s3';
const _fltSortTeams = 'g1s4';
const _ienTaskExecs = 'g1s5';
const _idDeadlineBlock = 'g1s6';
const _clsIndicator = 'g1s7';
const _ienBublesIndicator = 'g1s8';
const _clsIndicatorIcon = 'g1s9';
const _idSymbolTaskIndicatorComments = 'g1sa';
const _idSymbolTaskIndicatorFiles = 'g1sb';
const _ienTaskBubbles = 'g1sc';
const _idRoleTopBubble = 'g1sd';
const _clsGroupResizeLeft = 'g1se';
const _clsGroupWrapperFO = 'g1sf';
const _clsProjectStartDateIcon = 'g1sg';
const _idSymbolProjectStartDate = 'g1sh';
const _clsProjectStartDateText = 'g1si';
const _clsFileName = 'g1sj';
const _clsCalendarList = 'g1sk';
const _clsCalendarList_Item_BoxWrapper = 'g1sl';
const _clsCalendarList_Item_ColorBox = 'g1sm';
const _clsCalendarList_Item_Label_Email = 'g1sn';
const _clsCalendarList_Item_Label_Description = 'g1so';
const _clsCalendarList_Item_Label_Description_Own = 'g1sp';
const _clsCalendarList_Or = 'g1sq';
const _clsFakeItemRemove = 'g1sr';
const _clsCalendarList_CheckboxWrapper = 'g1ss';
const _clsCalendar_Switch = 'g1st';
const _clsCalendar_NoChange = 'g1su';
const _clsCalendarList_CheckboxWrapper_Add_Checkbox = 'g1sv';
const _clsCalendarList_CheckboxWrapper_Mention_Checkbox = 'g1sw';
const _clsStoryEditorWindow = 'g1sx';
const _clsStoryEditorWindow_Header_Label = 'g1sy';
const _clsStoryEditor_StorySettingsView = 'g1sz';
const _clsStoryEditor_StorySettingsView_Header = 'g1t0';
const _clsStoryEditor_story_title = 'g1t1';
const _clsStoryEditor_seo_title = 'g1t2';
const _clsStoryEditor_seo_text = 'g1t3';
const _clsStoryEditor_StorySettingsView_Editor = 'g1t4';
const _clsStoryEditor_editor_input = 'g1t5';
const _clsStoryEditor_StorySettingsView_Author = 'g1t6';
const _clsStoryEditor_AuthorBlock = 'g1t7';
const _clsStoryEditor_StoryChapterView = 'g1t8';
const _clsStoryEditor_chapter_title = 'g1t9';
const _clsStoryEditor_chapter_editor_input = 'g1ta';
const _clsStoryEditor_ProcessView = 'g1tb';
const _idSymbolArrowUp = 'g1tc';
const _idSymbolDefaultProject = 'g1td';
const _idSymbolDefaultWorkspace = 'g1te';
const _idSymbolProjectPeopleEveryone = 'g1tf';
const _idSymbolStartTask = 'g1tg';
const _idSymbolStopTask = 'g1th';
const _idSymbolTaskBlockedBy = 'g1ti';
const _idSymbolTaskBlocks = 'g1tj';
const _clsRectangularSelection = 'g1tk';
const _idPaperLayer = 'g1tl';
const _idHelperLayer = 'g1tm';
const _clsEditing = 'g1tn';
const _clsDraftLink = 'g1to';
const _clsProjectPaper = 'g1tp';
const _clsPasteHelper = 'g1tq';
const _clsButtonText = 'g1tr';
const _clsButtonRect = 'g1ts';
const _clsEmptyGroup = 'g1tt';
const _clsAlertedLink = 'g1tu';
const _clsSubHeader = 'g1tv';
const _clsFooter = 'g1tw';
const _bndStep2Next = 'g1tx';
const _bndCloseTutorial = 'g1ty';
const _clsFakeVideo = 'g1tz';
const _clsTutorial_CreateProjectMessage = 'g1u0';
const _clsTutorialVideoShield = 'g1u1';
const _clsNoLine = 'g1u2';
const _clsImage = 'g1u3';
const _clsName = 'g1u4';
const _clsDescription = 'g1u5';
const _fltAddFirst = 'g1u6';
const _fltMapProjectMembers = 'g1u7';
const _evSelectorClick = 'g1u8';
const _bndShowClickCaptor = 'g1u9';
const _clsOverviewUserSelector = 'g1ua';
const _clsOverviewUserSelector_Active = 'g1ub';
const _clsOverviewUserSelector_List = 'g1uc';
const _evAbbrKeypress = 'g1ud';
const _evColorSelect = 'g1ue';
const _evOpenColorSelect = 'g1uf';
const _evNameKeypress = 'g1ug';
const _evNameChange = 'g1uh';
const _evEdit = 'g1ui';
const _evAddTeamMember = 'g1uj';
const _evOpenAddMemberPopup = 'g1uk';
const _evDragOver = 'g1ul';
const _cmpProjectTeamEditControls = 'v-1um';
const _evStore = 'g1un';
const _rCreateTeam = 'g1uo';
const _rUpdateTeam = 'g1up';
const _evCancel = 'g1uq';
const _evRemove = 'g1ur';
const _rRemoveTeam = 'g1us';
const _evRemoveMember = 'g1ut';
const _mtdUserRole = 'g1uu';
const _clsOwner = 'g1uv';
const _clsNoFlex = 'g1uw';
const _clsExtendTrial = 'g1ux';
const _clsNoResize = 'g1uy';
const _clsExtendTrialText = 'g1uz';
const _clsAvatarEditor = 'g1v0';
const _clsAvatarEditor_ButtonsPanel = 'g1v1';
const _clsUploadBannerShield = 'g1v2';
const _clsBold = 'g1v3';
const _urChangeMemberRole = 'g1v4';
const _evChangeMemberRole = 'g1v5';
const _bndMayIAddTeam = 'g1v6';
const _bndHasTeamsFeature = 'g1v7';
const _clsTeamItem = 'g1v8';
const _clsTeamItem_LogoWrapper = 'g1v9';
const _clsTeamItem_Logo = 'g1va';
const _clsItemEditGray = 'g1vb';
const _urUpdateProjectTeam = 'g1vc';
const _clsTeamItem_Members_Item = 'g1vd';
const _clsTeamItem_Member_New_Icon = 'g1ve';
const _urRemoveMemberFromTeam = 'g1vf';
const _clsTeamItem_EditWrapper = 'g1vg';
const _clsTeamItem_FakeBorder = 'g1vh';
const _urRemoveProjectTeam = 'g1vi';
const _clsManageTeams_ColorSelector_Square = 'g1vj';
const _clsNoBodyPadding = 'g1vk';
const _clsNoHeader = 'g1vl';
const _clsNoButtons = 'g1vm';
const _bndEmptySearch = 'g1vn';
const _ienProjectFileTpl = 'g1vo';
const _ienPFSpinner = 'g1vp';
const _ienUsersAvatarHref = 'g1vq';
const _clsUserName = 'g1vr';
const _clsTeamHolder = 'g1vs';
const _clsEveryone = 'g1vt';
const _bndShowDone = 'g1vu';
const _bndBecomeExecutor = 'g1vv';
const _clsBecomeExecutor = 'g1vw';
const _rPostComment = 'g1vx';
const _clsPartialLoad = 'g1vy';
const _ienShouldShowFileSection = 'g1vz';
const _ienShouldUserSeeExecutorPane = 'g1w0';
const _ienShouldUserSeeDeadline = 'g1w1';
const _ienTaskValues = 'g1w2';
const _clsTaskSchedule_HaveDuration = 'g1w3';
const _clsTaskSchedule_HaveStartDate = 'g1w4';
const _clsTaskSchedule_HaveDeadline = 'g1w5';
const _clsValueWrapper_Main = 'g1w6';
const _ienTaskTimingLegend = 'g1w7';
const _clsExpectations_Full = 'g1w8';
const _clsTaskNameFlexWrapper = 'g1w9';
const _clsNotEditable = 'g1wa';
const _clsTrashIconWrapper = 'g1wb';
const _clsIconGreenRMark = 'g1wc';
const _clsSchedule_Viewer = 'g1wd';
const _clsTaskSchedule_Details = 'g1we';
const _ienDuration2Text = 'g1wf';
const _clsTaskTimingLegend = 'g1wg';
const _clsValuesList = 'g1wh';
const _clsBlockedBy = 'g1wi';
const _ienUserCard = 'g1wj';
const _ienUpdateAutoexpand = 'g1wk';
const _ienUpdateRightsForTask = 'g1wl';
const _clsNoteSaveNotification = 'g1wm';
const _clsNoteProcessing = 'g1wn';
const _ienFileTpl = 'g1wo';
const _urPostComment = 'g1wp';
const _clsHistoryPlace = 'g1wq';
const _ienProjectCommentTpl = 'g1wr';
const _clsClickShield = 'g1ws';
const _clsCalendarMuted = 'g1wt';
const _clsCalendarSelected = 'g1wu';
const _clsCalendarDisabled = 'g1wv';
const _clsCalendarHighlighted = 'g1ww';
const _clsCalendarDayOff = 'g1wx';
const _clsCalendar_IsTooLate = 'g1wy';
const _ienDurationDef = 'g1wz';
const _evClearProjectStartDate = 'g1x0';
const _bndHasSearchFeature = 'g1x1';
const _clsTaskListItem_Started = 'g1x2';
const _bndShowMenu = 'g1x3';
const _bndTitle = 'g1x4';
const _clsNoProject = 'g1x5';
const _clsBanner = 'g1x6';
const _clsManageWorkspaces = 'g1x7';
const _clsWorkspacesList = 'g1x8';
const _ienWSItemPopupTpl = 'g1x9';
const _ienWorkspaceActiveDecorate = 'g1xa';
const _clsProjectsList = 'g1xb';
const _ienPRItemPopupTpl = 'g1xc';
const _evChangeUserAvatar = 'g1xd';
const _evResetTutorialFlag = 'g1xe';
const _evResetTutorialFlagAndTutPid = 'g1xf';
const _bndEmailsList = 'g1xg';
const _rGetUserAlertsSettings = 'g1xh';
const _clsChangePasswordButton = 'g1xi';
const _clsLogoutOtherSessions = 'g1xj';
const _rInvalidateSessions = 'g1xk';
const _clsEmailItem = 'g1xl';
const _cnstResendConfirmation = 'g1xm';
const _rSetPrimaryContactInfo = 'g1xn';
const _rRemoveContactInfo = 'g1xo';
const _clsDefEmailForAccountList = 'g1xp';
const _rAddNewEmail = 'g1xq';
const _clsSelectAll = 'g1xr';
const _clsWSUserInfoItem = 'g1xs';
const _rUpdateUserInfo = 'g1xt';
const _rChangePassword = 'g1xu';
const _rUpdateUserAlertsSettings = 'g1xv';
const _clsFIO = 'g1xw';
const _ienUserFLName = 'g1xx';
const _ienWSUserInfo = 'g1xy';
const _ienEmailItem = 'g1xz';
const _clsEmailPending = 'g1y0';
const _clsMark = 'g1y1';
const _clsDoneMarkWrapper = 'g1y2';
const _clsRemoveAccountWizard = 'g1y3';
const _clsRAActiveTab_Processing = 'g1y4';
const _clsRAActiveTab_Password = 'g1y5';
const _clsRAActiveTab_Reason = 'g1y6';
const _clsRAActiveTab_Confirm = 'g1y7';
const _clsRAActiveTab_Result = 'g1y8';
const _clsSubscribeNowButton = 'g1y9';
const _clsGreenBackground = 'g1ya';
const _clsNotificationBuble = 'g1yb';
const _clsUserNameWrapper = 'g1yc';
const _urChangeUserImage = 'g1yd';
const _clsMenuUserAreaWrapper = 'g1ye';
const _clsMenuUserAreaWrapper_UserData = 'g1yf';
const _clsUserFIO = 'g1yg';
const _clsButton_Green = 'g1yh';
const _clsMenuUserAreaWrapper_ButtonsPannel = 'g1yi';
const _clsButton_Align_Right = 'g1yj';
const _clsTimeLabel = 'g1yk';
const _clsNavButton_Back = 'g1yl';
const _clsNavButton_More = 'g1ym';
const _clsDataWrapper = 'g1yn';
const _ienNotificationsItem = 'g1yo';
const _ienNotificationsItemClass = 'g1yp';
const _clsRemoving = 'g1yq';
const _bndUpcoming = 'g1yr';
const _clsOverviewWrapper = 'g1ys';
const _clsWSOverview_Wrapper = 'g1yt';
const _ienProjectItemTemplate = 'g1yu';
const _ienTaskItemTemplate = 'g1yv';
const _ienOverviewUpcomingEmpty = 'g1yw';
const _clsColorLine = 'g1yx';
const _clsChecked = 'g1yy';
const _ienTaskPath = 'g1yz';
const _clsProjectNameSmall = 'g1z0';
const _clsTaskBlockedBy = 'g1z1';
const _clsIconGreenMark = 'g1z2';
const _evResendInviteRequested = 'g1z3';
const _rResendWorkspaceInvitation = 'g1z4';
const _rAttachUserToWorkspaceUser = 'g1z5';
const _rDetachUserFromWorksaceUser = 'g1z6';
const _bndSendNotificationsTo = 'g1z7';
const _rUpdateWorkspaceUserInfo = 'g1z8';
const _clsItemRoleWrapper = 'g1z9';
const _clsActiveUserRole = 'g1za';
const _clsDisplay = 'g1zb';
const _clsFirst = 'g1zc';
const _clsRemoveDetachPane = 'g1zd';
const _clsUserEmails = 'g1ze';
const _bndPopover = 'g1zf';
const _clsInviteUserPopover = 'g1zg';
const _clsAddResourceForm = 'g1zh';
const _clsMemberWrapper = 'g1zi';
const _evFullscreen = 'g1zj';
const _evZoomIn = 'g1zk';
const _evZoomOut = 'g1zl';
const _clsIconBack = 'g1zm';
const _clsTaskDescription = 'g1zn';
const _cAlertsSettings = 'g1zo';
const _msgEmailAlreadyRegistered = 'g1zp';
const _msgRegistrationDoneMessage = 'g1zq';
const _rVerifyEmailToken = 'g1zr';
const _rContinueRegistration = 'g1zs';
const _eReRequestVerification = 'g1zt';
const _eSignup4Waitlist = 'g1zu';
const _msgUAInfo = 'g1zv';
const _rLogin = 'g1zw';
const _rDirectUserRegistration = 'g1zx';
const _rCompleteRegistration = 'g1zy';
const _rPasswordRecovery = 'g1zz';
const _rPasswordRecoveryStep2 = 'g200';
const _clsImageSelector_container = 'g201';
const _clsImageSelector_selected = 'g202';
const _clsAddProject = 'g203';
const _clsZoomLayer2 = 'g204';
const _clsZoomLayer3 = 'g205';
const _clsIconUp = 'g206';
const _clsIconTagEditingDone = 'g207';
const _clsIconTagEditingCancel = 'g208';
const _clsVBox = 'g209';
const _clsModal = 'g20a';
const _clsManageButtons = 'g20b';
const _clsCardAction = 'g20c';
const _clsTeamItem_AddMember = 'g20d';
const _clsTaskSchedule_ShowEditor = 'g20e';
const _cNotificationsEnvelope = 'g20f';
const _bndPagerOnPage = 'g20g';
const _dmdTasks = 'g20h';
const _rRemoveUserProperties = 'g20i';
const _rCheckTrialEligibility = 'g20j';
const _cTeamMembershipInfo = 'g20k';
const _rUserInfo = 'g20l';
const _rListWorkspaces = 'g20m';
const _rListUserProperties = 'g20n';
const _cMessage = 'g20o';
const _cHistoryStreamPackage = 'g20p';
const _cListFilesResponse = 'g20q';
const _cClipboardEntryInfo = 'g20r';
const _rClipboardPreview = 'g20s';
const _cWorkspaceSettingsInfo = 'g20t';
const _cLoginRequest = 'g20u';
const _cLogoutRequest = 'g20v';
const _cLogoutResponse = 'g20w';
const _cCheckSessionRequest = 'g20x';
const _cInvalidateSessionsRequest = 'g20y';
const _cDirectRegistrationRequest = 'g20z';
const _cVerifyEmailTokenRequest = 'g210';
const _cVerifyEmailTokenResponse = 'g211';
const _cResendEmailVerificationRequest = 'g212';
const _cResendEmailVerificationResponse = 'g213';
const _cContinueRegistrationRequest = 'g214';
const _cCompleteRegistrationRequest = 'g215';
const _cPasswordRecoveryRequest = 'g216';
const _cPasswordRecoveryStep2Request = 'g217';
const _cChangePasswordRequest = 'g218';
const _cUserInfoRequest = 'g219';
const _cValidatePasswordRequest = 'g21a';
const _cRemoveUserAccountRequest = 'g21b';
const _cUpdateUserInfoRequest = 'g21c';
const _cListUserPropertiesRequest = 'g21d';
const _cSetUserPropertiesRequest = 'g21e';
const _cRemoveUserPropertiesRequest = 'g21f';
const _cRemovePictureRequest = 'g21g';
const _cListWorkspaceUsersRequest = 'g21h';
const _cListOfWorkspaceUsersResponse = 'g21i';
const _cAddUserToWorkspaceRequest = 'g21j';
const _cAddUserToWorkspaceResponse = 'g21k';
const _cRemoveUserFromWorkspaceRequest = 'g21l';
const _cRemoveUserFromWorkspaceResponse = 'g21m';
const _cAttachUserToWorkspaceUserRequest = 'g21n';
const _cAttachUserToWorkspaceUserResponse = 'g21o';
const _cDetachUserFromWorksaceUserRequest = 'g21p';
const _cDetachUserFromWorkspaceUserResponse = 'g21q';
const _cWorkspaceUserInfoResponse = 'g21r';
const _cUpdateWorkspaceUserInfoRequest = 'g21s';
const _cAddNewEmailRequest = 'g21t';
const _cAddNewEmailResponse = 'g21u';
const _cRemoveContactInfoRequest = 'g21v';
const _cAssignContactInfoToWorkspaceUser = 'g21w';
const _cSetPrimaryContactInfoRequest = 'g21x';
const _cListWorkspacesRequest = 'g21y';
const _cCreateWorkspaceRequest = 'g21z';
const _cCreateWorkspaceResponse = 'g220';
const _cUpdateWorkspaceRequest = 'g221';
const _cTransferOwnershipRequest = 'g222';
const _cRemoveWorkspaceRequest = 'g223';
const _cWorkspaceInfoRequest = 'g224';
const _cProcessUploadedPictureRequest = 'g225';
const _cListProjectsRequest = 'g226';
const _cListOfProjectsResponse = 'g227';
const _cCreateProjectRequest = 'g228';
const _cCreateProjectResponse = 'g229';
const _cUpdateProjectRequest = 'g22a';
const _cProjectInfoPackage = 'g22b';
const _cCloneProjectRequest = 'g22c';
const _cListProjectsPagingRequest = 'g22d';
const _cListOfProjectsPagingResponse = 'g22e';
const _cConsultantCloneProjectRequest = 'g22f';
const _cRemoveProjectRequest = 'g22g';
const _cArchiveProjectRequest = 'g22h';
const _cReactivateProjectRequest = 'g22i';
const _cTutorialProjectRequest = 'g22j';
const _cPurgeRemovedProjectsRequest = 'g22k';
const _cProjectInfoBundleRequest = 'g22l';
const _cProjectInfoBundle = 'g22m';
const _cProjectMapRequest = 'g22n';
const _cProjectMapResponse = 'g22o';
const _cProjectExecutionBundleRequest = 'g22p';
const _cProjectExecutionBundle = 'g22q';
const _cProjectMemberPackRequest = 'g22r';
const _cProjectMemberPackResponse = 'g22s';
const _cMultipleTaskInfosRequest = 'g22t';
const _cTeamPackRequest = 'g22u';
const _cTeamPackResponse = 'g22v';
const _cCreateTeamRequest = 'g22w';
const _cUpdateTeamRequest = 'g22x';
const _cRemoveTeamRequest = 'g22y';
const _cTeamMembershipRequest = 'g22z';
const _cTeamMembershipResponse = 'g230';
const _cProjectMembershipRequest = 'g231';
const _cProjectMembershipResponse = 'g232';
const _cStateChangesEnvelope = 'g233';
const _cStateChangeAccepted = 'g234';
const _cGetMessageOfTheDay = 'g235';
const _cMessageOfTheDayResponse = 'g236';
const _cHistoryStreamRequest = 'g237';
const _cPostCommentRequest = 'g238';
const _cRemoveCommentRequest = 'g239';
const _cGetUserAlertsSettingsRequest = 'g23a';
const _cUpdateUserAlertsSettingsRequest = 'g23b';
const _cNewFileRequest = 'g23c';
const _cListFilesRequest = 'g23d';
const _cRemoveFileRequest = 'g23e';
const _cUploadTicketRequest = 'g23f';
const _cUploadTicketResponse = 'g23g';
const _cCopyToClipboardRequest = 'g23h';
const _cCopyToClipboardResponse = 'g23i';
const _cClearClipboardRequest = 'g23j';
const _cPasteFromClipboardRequest = 'g23k';
const _cCreateShareRequest = 'g23l';
const _cCreateShareResponse = 'g23m';
const _cListSharesRequest = 'g23n';
const _cListSharesResponse = 'g23o';
const _cRemoveShareRequest = 'g23p';
const _cWorkspaceOverviewForUserRequest = 'g23q';
const _cWorkspaceOverviewForUserResponse = 'g23r';
const _cProductsListRequest = 'g23s';
const _cProductsListResponse = 'g23t';
const _cListActiveSubscriptionsRequest = 'g23u';
const _cListOfActiveSubscriptionsResponse = 'g23v';
const _cCreateSubscriptionRequest = 'g23w';
const _cUpdateSubscriptionCardRequest = 'g23x';
const _cChangeSubscriptionPlanRequest = 'g23y';
const _cCancelSubscriptionRequest = 'g23z';
const _cRemoveContentFromSubscriptionRequest = 'g240';
const _cFeatureAuthRequest = 'g241';
const _cFeatureDataRequest = 'g242';
const _cFeatureQuestionRequest = 'g243';
const _cFeatureQuestionResponse = 'g244';
const _cListProjectTagsRequest = 'g245';
const _cListOfProjectTagsResponse = 'g246';
const _cCreateProjectTagRequest = 'g247';
const _cUpdateProjectTagRequest = 'g248';
const _cRemoveProjectTagRequest = 'g249';
const _cTagProjectRequest = 'g24a';
const _cResendWorkspaceInvitationRequest = 'g24b';
const _cResendWorkspaceInvitationResponse = 'g24c';
const _cReportProjectsAndStatsRequest = 'g24d';
const _cReportProjectsAndStatsResponse = 'g24e';
const _cWorkspaceOverviewRequest = 'g24f';
const _cWorkspaceOverviewResponse = 'g24g';
const _cUpdateWorkspaceSettingsRequest = 'g24h';
const _cCheckTrialEligibilityRequest = 'g24i';
const _cRedeemTrialOfferRequest = 'g24j';
const _cWorkspaceMembersMessage = 'g24k';
const _clsInteractive = 'g24l';
const _evPrevMonth = 'g24m';
const _evNextMonth = 'g24n';
const _evShowHelp = 'g24o';
const _evVideoGuide = 'g24p';
const _bndHasEvernoteFeature = 'g24q';
const _evUpdateProjectsList = 'g24r';
const _evInviteUser = 'g24s';
const _clsProjectsListHolder = 'g24t';
const _ienLogoImage = 'g24u';
const _clsProjectReport_WorkArea = 'g24v';
const _clsButtonReplace = 'g24w';
const _eSubscribeNow = 'g24x';
const _eFeedbackModal = 'g24y';
const _evOpenProjects = 'g24z';
const _clsCreateProject = 'g250';
const _bndSubscriptionCanceled = 'g251';
const _clsManageWorkspaceSettingsBlock = 'g252';
const _clsManageWorkspaceWorkingHoursBlock = 'g253';
const _clsUsageItem = 'g254';
const _clsSubscriptionSpinner = 'g255';
const _msgNotAllowed2RemoveUser = 'g256';
const _msgDeadlineMenuTitle = 'g257';
const _msgResetDeadlineMenuTitle = 'g258';
const _msgUpdateCompleteDate = 'g259';
const _msgSetTaskStartDate = 'g25a';
const _msgUnsetDeadline = 'g25b';
const _msgEditUserDialogHeader = 'g25c';
const _msgWSUserRoleSpectator = 'g25d';
const _msgWSUserRoleParticipant = 'g25e';
const _msgWSUserRoleLeader = 'g25f';
const _msgWSUserRoleOwner = 'g25g';
const _msgTaskStateBlocked = 'g25h';
const _msgTaskStateUnblocked = 'g25i';
const _msgTaskStateStarted = 'g25j';
const _msgTaskStateCompleted = 'g25k';
const _msgTutorialProjectName = 'g25l';
const _msgWelcomePopupHeader = 'g25m';
const _msgWelcome2PPopupHeader = 'g25n';
const _msgUnassignedProjectFile = 'g25o';
const _msgHelpMainFeatures = 'g25p';
const _msgHelpPeople = 'g25q';
const _msgHelpTaskGroups = 'g25r';
const _msgHelpProjectTemplates = 'g25s';
const _msgHelpProjectShare = 'g25t';
const _msgHelpMobileApp = 'g25u';
const _msgHelpTaskDetails = 'g25v';
const _msgHelpGoogleCalendar = 'g25w';
const _msgEmailsActionPending = 'g25x';
const _msgEmailsActionMakePrimary = 'g25y';
const _msgEmailsActionRemove = 'g25z';
const _msgContextMenuStopTask = 'g260';
const _msgDashboardSetupMenuTitle = 'g261';
const _msgSubscriptionUpdatePasswordRequest = 'g262';
const _msgProcessingSubscriptionPlanChange = 'g263';
const _msgSubscriptionPlanChangeResponseDenied = 'g264';
const _msgSubscriptionPlanChangeResponseNoCard = 'g265';
const _msgSubscriptionPlanChangeResponseUknown = 'g266';
const _msgConfirmCardInfoRemove = 'g267';
const _msgCardInfoRemoved = 'g268';
const _msgConfirmCancelSubscriptionPlan = 'g269';
const _msgCancelSubscriptionPlanPasswordRequest = 'g26a';
const _msgSubscriptionCanceled = 'g26b';
const _msgProcessingSubscriptionPlanCreate = 'g26c';
const _msgCancelSubscriptionError = 'g26d';
const _msgCannotAddUserToProject = 'g26e';
const _msgLoadingSubscriptionPlans = 'g26f';
const _msgCheckoutName = 'g26g';
const _msgCheckoutDescription = 'g26h';
const _msgCheckoutPanelLabel = 'g26i';
const _msgFailedToGetStripeLib = 'g26j';
const _msgSetProjectStartDate = 'g26k';
const _msgClearProjectStartDate = 'g26l';
const _msgClearDeadline = 'g26m';
const _msgClearTaskStartDate = 'g26n';
const _msgUserHasBeenDetached = 'g26o';
const _msgErrorSVGExport = 'g26p';
const _msgResendUserInviteNotSent = 'g26q';
const _msgTaskTimingTitle = 'g26r';
const _msgTaskTiming_StartAfter = 'g26s';
const _msgTaskTiming_StartLate = 'g26t';
const _msgTaskTiming_LateFinish = 'g26u';
const _msgTaskTiming_CanStartAfter = 'g26v';
const _msgTaskTiming_MayStartLate = 'g26w';
const _msgTaskTiming_MayFinishLate = 'g26x';
const _ienProjectLink = 'g26y';
const _cnstStoryOpen = 'g26z';
const _evMarkStoped = 'g270';
const _evTeamColorSelectorAction = 'g271';
const _evSetCompletedDate = 'g272';
const _evCopyToClipboard = 'g273';
const _evPasteClipboard = 'g274';
const _bndPrevTask = 'g275';
const _bndHasTeamFeature = 'g276';
const _clsZoomLayer = 'g277';
const _evUserSelect = 'g278';
const _clsStartTaskDateSection = 'g279';
const _evGroupBack = 'g27a';
const _clsStartTaskDateWrapper = 'g27b';
const _evImportProjectData = 'g27c';
const _clsIconGrayPlus = 'g27d';
const _evStoryEditorMenu = 'g27e';
const _clsMakeGroup = 'g27f';
const _clsGroup2Task = 'g27g';
const _clsIconDeadline = 'g27h';
const _clsDeadlineIcon = 'g27i';
const _idFCIndicator = 'g27j';
const _clsCalendar_Add = 'g27k';
const _clsStoryEditor_StoryBackground_upload = 'g27l';
const _clsStoryEditor_SEO = 'g27m';
const _clsAuthorAvatar = 'g27n';
const _idSymbolArrowDropDown = 'g27o';
const _idSymbolArrowDropUp = 'g27p';
const _idSymbolComments = 'g27q';
const _idSymbolHistoryItemBullet = 'g27r';
const _idSymbolModePlan = 'g27s';
const _idSymbolMoreDots = 'g27t';
const _idSymbolPlus = 'g27u';
const _idSymbolProjectEnd = 'g27v';
const _idSymbolProjectRecycler = 'g27w';
const _idSymbolRefresh = 'g27x';
const _idSymbolRoundMarkDoneFrame = 'g27y';
const _idSymbolRoundMarkDoneMark = 'g27z';
const _idSymbolRoundMarkDone = 'g280';
const _idSymbolUserAvatar = 'g281';
const _idLinksLayer = 'g282';
const _idTasksLayer = 'g283';
const _idGroupLayer = 'g284';
const _clsDateNotSet = 'g285';
const _evCloneByAltDrag = 'g286';
const _clsTreeView = 'g287';
const _clsShadowDragNodes = 'g288';
const _evTreeLinkSelected = 'g289';
const _bndStep4 = 'g28a';
const _bndPlayIntroVideo = 'g28b';
const _bndStep1Next = 'g28c';
const _bndVideoShield = 'g28d';
const _clsTutorialVideoPopup = 'g28e';
const _clsNoData = 'g28f';
const _fltMapTasksQtyToUsers = 'g28g';
const _evDrag = 'g28h';
const _cmpProjectUsersTab = 'v-28i';
const _cmpProjectUsersTabItem = 'v-28j';
const _cmpProjectUsers_Teams = 'v-28k';
const _evClick = 'g28l';
const _evTeamFilter = 'g28m';
const _evExpand = 'g28n';
const _clsDialogModal = 'g28o';
const _ienShouldUserSeeHelperBar = 'g28p';
const _ienTaskScheduleWrapperMode = 'g28q';
const _clsStopTaskLabel = 'g28r';
const _clsTaskSchedule_Duration = 'g28s';
const _clsTaskSchedule_StartDate = 'g28t';
const _clsTaskSchedule_Deadline = 'g28u';
const _idRoleClearStartDate = 'g28v';
const _idRoleClearFinishDate = 'g28w';
const _evTreeTaskSelected = 'g28x';
const _idSymbolMessage = 'g28y';
const _idSymbolFiles = 'g28z';
const _evChangeWSAvatar = 'g290';
const _bndEmailsListFiltered = 'g291';
const _bndContactsList = 'g292';
const _clsRemoveAccount = 'g293';
const _clsEmailsList = 'g294';
const _clsDialogSubButton = 'g295';
const _clsEmailValue = 'g296';
const _clsEmailDisabled = 'g297';
const _clsRemoveAccountBackButton = 'g298';
const _clsRemoveAccountNextButton = 'g299';
const _clsLogoWrapper = 'g29a';
const _clsPad = 'g29b';
const _clsFirstName = 'g29c';
const _clsLastName = 'g29d';
const _clsOverviewIcon = 'g29e';
const _clsRemoveChrest = 'g29f';
const _clsIconProjectEdit = 'g29g';
const _clsIconProjectClone = 'g29h';
const _clsIconProjectRemove = 'g29i';
const _clsIconProjectShare = 'g29j';
const _clsIconProjectArchive = 'g29k';
const _clsIconProjectReactivate = 'g29l';
const _clsIconInvite = 'g29m';
const _clsIconArrow = 'g29n';
const _clsIconLeft = 'g29o';
const _clsIconLeftGray = 'g29p';
const _clsIconLeftSilver = 'g29q';
const _clsIconLeftBlack = 'g29r';
const _clsIconRight = 'g29s';
const _clsIconRightGray = 'g29t';
const _clsIconRightSilver = 'g29u';
const _clsIconRightBlack = 'g29v';
const _clsIconArrowDown = 'g29w';
const _clsIconDownGray = 'g29x';
const _clsIconDownSilver = 'g29y';
const _clsIconDownBlack = 'g29z';
const _clsIconUpGray = 'g2a0';
const _clsIconUpSilver = 'g2a1';
const _clsIconUpBlack = 'g2a2';
const _clsIconPen = 'g2a3';
const _clsIconExport = 'g2a4';
const _clsIconDown = 'g2a5';
const _clsEmptyIcon20 = 'g2a6';
const _clsEmptyIcon40 = 'g2a7';
const _clsBorder = 'g2a8';
const _clsIconTagAddNew = 'g2a9';
const _clsIconManageTags = 'g2aa';
const _clsIconGroupByCategories = 'g2ab';
const _clsIconDoNotGroupByCategories = 'g2ac';
const _clsIconArchive = 'g2ad';
const _clsIconRecycleBin = 'g2ae';
const _clsIconReports = 'g2af';
const _clsIconDropArrow = 'g2ag';
const _clsIconBackArrow = 'g2ah';
const _clsInvert = 'g2ai';
const _clsIconSortAlphabetic = 'g2aj';
const _clsIconSortLastAccessTime = 'g2ak';
const _clsUserIcon = 'g2al';
const _clsVertical = 'g2am';
const _clsFlex2 = 'g2an';
const _clsTableLayoutFixed = 'g2ao';
const _clsTableCaption = 'g2ap';
const _clsTableBody = 'g2aq';
const _clsTableHead = 'g2ar';
const _clsFloatNone = 'g2as';
const _clsBoxCenter = 'g2at';
const _clsActiveProjects = 'g2au';
const _clsArchivedProjects = 'g2av';
const _clsButtonPlace = 'g2aw';
const _clsButtonBack = 'g2ax';
const _clsProjectTagsPane_AddNewTag = 'g2ay';
const _clsButton_Align_Left = 'g2az';
const _clsActionLink = 'g2b0';
const _clsIconAddWorkspace = 'g2b1';
const _clsUsageSection = 'g2b2';
const _clsGroupName = 'g2b3';
const _clsStoryEditor_StoryBackground = 'g2b4';
const _clsClicker = 'g2b5';
const _clsGroupResizeRightBot = 'g2b6';
const _clsColumn = 'g2b7';
const _clsTeamItem_Edit = 'g2b8';
const _clsModalDialog = 'g2b9';
const _clsTaskSchedule_Wrapper = 'g2ba';
const _clsTaskSchedule_Body = 'g2bb';
const _clsTaskSchedule_Editor = 'g2bc';
const _clsTaskSchedule_InitialLabel = 'g2bd';
const _clsTaskSchedule_Editor_Header = 'g2be';
const _clsDuration_Editor = 'g2bf';
const _idSymbolBackButton = 'g2bg';
const _bndPagerNext = 'g2bh';
const _bndPagerPrev = 'g2bi';
const _ienEmailForWSItem = 'g2bj';
const _ienTaskTemplate = 'g2bk';
const _ienSubscriptionTPL = 'g2bl';
const _ienProjectMember = 'g2bm';
const _ienGetUserFLName = 'g2bn';
const _ienOverviewUpcomingPostprocessing = 'g2bo';
const _ienInputRadio = 'g2bp';
const _ienTranslateLang = 'g2bq';
const _ienDeadlineBlockClass = 'g2br';
const _ienNumber = 'g2bs';
const _ienDefUserEmail = 'g2bt';
const _bndCurrentWorkspaceUsers = 'g2bu';
const _rWorkspaceUserInfo = 'g2bv';
const _rCurrentWorkspaceUserInfo = 'g2bw';
const _rCreateWorkspace = 'g2bx';
const _rListProjectsPaging = 'g2by';
const _rCreateTutorialProject = 'g2bz';
const _rProjectMemberPack = 'g2c0';
const _rMultipleTaskInfos = 'g2c1';
const _cMultipleTaskInfosResponse = 'g2c2';
const _rTeamPack = 'g2c3';
const _rClearClipboard = 'g2c4';
const _rProductsList = 'g2c5';
const _rCreateSubscription = 'g2c6';
const _rUpdateSubscriptionCard = 'g2c7';
const _rChangeSubscriptionPlan = 'g2c8';
const _rCancelSubscription = 'g2c9';
const _rRemoveContentFromSubscription = 'g2ca';
const _rFeatureAuth = 'g2cb';
const _rListProjectTags = 'g2cc';
const _rWorkspaceDigest = 'g2cd';
const _cWorkspaceDigestRequest = 'g2ce';
const _urChangeUserInfo = 'g2cf';
const _urDetachWorkspaceUser = 'g2cg';
const _urChangeWorkspaceUserImage = 'g2ch';
const _urListWorkspaceUsers = 'g2ci';
const _urAttachWorkspaceUser = 'g2cj';
const _urChangeWorkspaceUserInfo = 'g2ck';
const _urChangeWorkspaceUserRole = 'g2cl';
const _urChangeWorkspaceUserRoleToOwner = 'g2cm';
const _urChangeWorkspaceUserRoleToLeader = 'g2cn';
const _urChangeWorkspaceUserRoleToParticipant = 'g2co';
const _urChangeWorkspaceUserRoleToSpectator = 'g2cp';
const _urLeaveWorkspace = 'g2cq';
const _urRemoveWorkspaceUser = 'g2cr';
const _urArchiveProject = 'g2cs';
const _urReactivateProject = 'g2ct';
const _urDeleteProject = 'g2cu';
const _urChangeProjectImage = 'g2cv';
const _urChangeProjectInfo = 'g2cw';
const _urChangeProjectState = 'g2cx';
const _urListProjectMembers = 'g2cy';
const _urAddWorkspaceUserToProject = 'g2cz';
const _urAddExecutor = 'g2d0';
const _urBecomeExecutor = 'g2d1';
const _urChangeTaskType = 'g2d2';
const _urSetCompletionTime = 'g2d3';
const _urRemoveDeadline = 'g2d4';
const _urRemoveExecutor = 'g2d5';
const _urRemoveSelfAsExecutor = 'g2d6';
const _urSetDeadline = 'g2d7';
const _urSetTaskStartDate = 'g2d8';
const _urRemoveTaskStartDate = 'g2d9';
const _urStartTask = 'g2da';
const _urUndoTask = 'g2db';
const _urCreateTransition = 'g2dc';
const _urRemoveTransition = 'g2dd';
const _urUpdateTransition = 'g2de';
const _urChangeState = 'g2df';
const _urListMembers = 'g2dg';
const _urReadFiles = 'g2dh';
const _urReadHistory = 'g2di';
const _urReadState = 'g2dj';
const _urPublishShare = 'g2dk';
const _urReadShares = 'g2dl';
const _urRemoveShare = 'g2dm';
const _urDeleteSomeonesComment = 'g2dn';
const _urAddMemberToTeam = 'g2do';
const _urUpdateProjectTag = 'g2dp';
const _urManageProjectTags = 'g2dq';
const _urProjectReporting = 'g2dr';
const _clsSlideShow = 'g2ds';
const _clsSlideShowItem = 'g2dt';
const _ienAllProjectsLink = 'g2du';
const _cProtocolMessage = 'g2dv';
const _cClientConfig = 'g2dw';
const _cClientInfo = 'g2dx';
const _cUserOriginInfo = 'g2dy';
const _cAlertSetting = 'g2dz';
const _cContactInfoInfo = 'g2e0';
const _cWorkspaceLimitsInfo = 'g2e1';
const _cWorkspaceUsageInfo = 'g2e2';
const _cWorkspaceUserProfileRequest = 'g2e3';
const _cWorkspaceUserProfileResponse = 'g2e4';
const _cMessageToProject = 'g2e5';
const _cProjectStateRequest = 'g2e6';
const _cChangeProjectStateRequest = 'g2e7';
const _cProjectMembershipInfo = 'g2e8';
const _cListProjectMembershipsRequest = 'g2e9';
const _cListOfProjectMembershipsResponse = 'g2ea';
const _cStateChange = 'g2eb';
const _cNotification = 'g2ec';
const _cGetUpdates = 'g2ed';
const _cStandardTeamInfo = 'g2ee';
const _cStandardTeamMembershipInfo = 'g2ef';
const _cStandardTeamMessages = 'g2eg';
const _cStandardTeamStatusResponse = 'g2eh';
const _cAddToStandardTeamRequest = 'g2ei';
const _cAddedToStandardTeamResponse = 'g2ej';
const _cCreateStandardTeamRequest = 'g2ek';
const _cListStandardTeamsRequest = 'g2el';
const _cListOfStandardTeamsResponse = 'g2em';
const _cRemoveFromStandardTeamRequest = 'g2en';
const _cRemoveStandardTeamRequest = 'g2eo';
const _cStandardTeamDoneResponse = 'g2ep';
const _cUpdateStandardTeamRequest = 'g2eq';
const _cProjectTeamsAndMembers = 'g2er';
const _cProjectMemberPack = 'g2es';
const _cTeamPack = 'g2et';
const _cMutlipleTaskInfosResponse = 'g2eu';
const _cMessageToTeam = 'g2ev';
const _cSnapshotStats = 'g2ew';
const _cTaskPastePosition = 'g2ex';
const _cSharedContentInfo = 'g2ey';
const _cSharedSnapshotData = 'g2ez';
const _cOverviewForUserInWorkspace = 'g2f0';
const _cOverviewForUserInProject = 'g2f1';
const _cListOfBlocks = 'g2f2';
const _cDigestsForProjects = 'g2f3';
const _cOverviewForWorkspace = 'g2f4';
const _cDigestForProject = 'g2f5';
const _cDigestForUserInProject = 'g2f6';
const _cProductInfo = 'g2f7';
const _cLimitsDefinition = 'g2f8';
const _cProductDefinition = 'g2f9';
const _cBillableResource = 'g2fa';
const _cProductFeature = 'g2fb';
const _cBillableResourceLimit = 'g2fc';
const _cSubscriptionInfo = 'g2fd';
const _cSubscriptionContentInfo = 'g2fe';
const _cFeatureAuthData = 'g2ff';
const _cFeatureData = 'g2fg';
const _cFeatureAuthChallenge = 'g2fh';
const _cFeatureQuestion = 'g2fi';
const _cEvernoteError = 'g2fj';
const _cEvernoteNotebookInfo = 'g2fk';
const _cEvernoteNotebookRestrictions = 'g2fl';
const _cListOfNotebooks = 'g2fm';
const _cListOfLinkedNotebooks = 'g2fn';
const _cEvernoteUserInfo = 'g2fo';
const _cEvernoteBusinessUserInfo = 'g2fp';
const _cEvernoteLinkedNotebookInfo = 'g2fq';
const _cProjectTagStatusResponse = 'g2fr';
const _cAnswer = 'g2fs';
const _cStoryPage = 'g2ft';
const _cStoryPageSocial = 'g2fu';
const _cStoryPageInfo = 'g2fv';
const _cStoryMedia = 'g2fw';
const _cReportEntry = 'g2fx';