__USE_MIXPANEL && window.mixpanel.init(window.appConfig.config.mixpaneltoken, { autotrack: true });
__USE_MIXPANEL &&
    window.mixpanel.init(window.appConfig.config.mixpanelacctoken, { autotrack: false }, _gcMixPanelAccount);
if (__DEV__) {
    __USE_MIXPANEL && (window.mixpanel[_gcMixPanelAccount] = mixpanel);
}

/** namespace */
const core_stat = {};

/**
 * Track event
 * @param {String} ev
 * @param {Object=} obj
 */
core_stat.track = (ev, obj) => {
    const wsu = $scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id;
    if (!__STATISTIC) {
        console.info('core::stat::track', ev, obj);
        // console.info('core::stat::track-' + _gcMixPanelAccount, ev, {'WorkspaceUser': wsu});
    } else {
        if (!core.skip_stats) {
            __USE_MIXPANEL && window.mixpanel.track(ev, obj);
            if (obj && wsu) {
                obj['WorkspaceUser'] = wsu;
            }
            __USE_MIXPANEL &&
                window.mixpanel[_gcMixPanelAccount].track(ev, obj || (wsu && { WorkspaceUser: wsu }) || {});
        }
    }
};

/**
 * Increment event
 * @param {String} ev
 * @param {number?} qty
 */
core_stat.inc = (ev, qty) => {
    if (!__STATISTIC) {
        console.info('core::stat::increment', ev, qty);
        // console.info('core::stat::increment-' + _gcMixPanelAccount, ev, qty);
    } else {
        if (!core.skip_stats) {
            __USE_MIXPANEL && window.mixpanel.people.increment(ev, qty || 1);
            __USE_MIXPANEL && window.mixpanel[_gcMixPanelAccount].people.increment(ev, qty || 1);
        }
    }
};

/**
 * Track and Increment
 * @param ev
 * @param {number?} qty
 */
core_stat.incAndTrack = (ev, qty) => {
    const wsu = $scope[_bndCurrentWorkspaceUser].id;
    if (!__STATISTIC) {
        console.info('core::stat::incAndTrack', ev, qty);
        // console.info('core::stat::incAndTrack-' + _gcMixPanelAccount, ev, qty, {'WorkspaceUser': wsu});
    } else {
        if (!core.skip_stats) {
            __USE_MIXPANEL && window.mixpanel.track(ev);
            __USE_MIXPANEL && window.mixpanel.people.increment(ev, qty || 1);
            __USE_MIXPANEL && window.mixpanel[_gcMixPanelAccount].track(ev, (wsu && { WorkspaceUser: wsu }) || {});
            __USE_MIXPANEL && window.mixpanel[_gcMixPanelAccount].people.increment(ev, qty || 1);
            try {
                __USE_INTERCOM && ev.match(_mpcTaskCreated) && Intercom('trackEvent', 'created-task');
            } catch (e) {
                __USE_ROLLBAR && Rollbar.debug('Intercom::trackEvent', { e });
            }
        }
    }
};

/**
 * Track links
 * @param {String} sel
 * @param {String} ev
 */
core_stat.track_links = (sel, ev) => {
    const wsu = $scope[_bndCurrentWorkspaceUser].id;
    if (!__STATISTIC) {
        console.info('core::stat::track_link', ev);
        // console.info('core::stat::track_link-' + _gcMixPanelAccount, ev, {'WorkspaceUser': wsu});
    } else {
        if (!core.skip_stats) {
            __USE_MIXPANEL && window.mixpanel.track_links(sel, ev);
            __USE_MIXPANEL &&
                window.mixpanel[_gcMixPanelAccount].track_links(sel, ev, (wsu && { WorkspaceUser: wsu }) || {});
        }
    }
};
