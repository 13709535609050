/** @namespace */ const $methods = {};
/** @namespace */ const $filters = {};
/** @namespace */ const $computed = {};
/** @namespace */ const $ACL = {};

/** @type {Vue} */
let $vue;

/** @type Object.<string, *> */
const $scope = {};

/** @type {Armap<cWorkspaceInfo>} */
$scope[_bndWorkspaces] = Armap();
/** @type {cWorkspaceInfo} */
$scope[_bndCurrentWorkspace] = null;
/** @type {cWorkspaceUserInfo} */
$scope[_bndCurrentWorkspaceUser] = {};
/** @type {cWorkspaceUserInfo} */
$scope[_bndWorkspaceOverviewActiveUser] = {};
/** @type {cWorkspaceUserInfo} */
$scope[_bndIAM] = {};
/** @type {boolean} */
$scope[_bndAllowedToExtendTrial] = false;
/** @type {boolean} */
$scope[_bndExtendTrialProcessed] = false;
/** @type {cWorkspaceSettingsInfo} */
$scope[_bndCurrentWorkspaceSettings] = {};

/** @type {Armap.<cProjectInfo>} */
$scope[_bndProjects] = Armap('id', ['workspaceId']);
/**
 * @typedef ProjectStatsType
 * @property {number} Blocked
 * @property {number} Unblocked
 * @property {number} Completed
 * @property {number} Overdue
 * @property {number} Deadline
 */
/** @type {Armap<ProjectStatsType>} */
$scope[_bndProjectStats] = Armap();
/** @type {Armap.<cWorkspaceUserInfo>} */
$scope[_bndWorkspaceUsers] = Armap('id', ['workspaceId']);
/** @type {cProjectInfo} */
$scope[_bndCurrentProject] = null;
/** @type {Armap.<cWorkspaceUserInfo} */
$scope[_bndProjectUsers] = [];
/** @type {Armap.<cTeamInfo>} */
$scope[_bndProjectTeams] = [];
$scope[_bndProjectTags] = [];
// $scope[_bndProjectTeamMembers] = [];
$scope[_bndTaskDebugMode] = !!TASK_DEBUG;

/**
 * @type {Object<string,Array<string>>}
 */
let $teamMembersHash = {};

/**
 * @param {Array.<string>} execs
 * @returns {Array.<string>|string}
 */
function preProcExecs(execs) {
    // switch (true) {
    //     case execs.length == 0 && !d.teamId: return _dcNobodys;
    //     case execs.length == 0 && !!d.teamId: return [d.teamId];
    //     case !!d.teamId: return [].concat(execs, [d.teamId]);
    //     default: return execs;
    // }
    return (execs.length && execs) || _dcNobodys;
}

/** @type {Armap<сTaskInfo>} */
$scope[_bndProjectTasks] = Armap('id', {
    // projectId: _gcEmbedProjectId,
    groupId: _dcNoGroup,
    state: undefined,
    execs: preProcExecs,
    type: undefined,
    teamId: _dcNoTeam,
    /**
     * @param {*} ign
     * @param {cTaskInfo} item
     * @returns {boolean}
     */
    alerted(ign, /* cTaskInfo */ item) {
        // return item.isDeadlineAlert();
        return isDeadlineAlert(item);
    }
});

/** @type {string} */
$scope[_bndActiveUser] = null;

$scope[_bndHasFeature] = {};

$scope[_mtdACL] = $ACL;

/**
 * @private
 * @type {Object}
 */

let cached = {};
/**
 * @private
 * @type {Object}
 */
const storage = {};
/**
 * @private
 * @type {?Array<string>|string}
 */
let ctask = null;
/**
 * @private
 * @type {Object<string,boolean>}
 */
const endhistory = {};
/**
 * @private
 * @type {Object<string,string|number|boolean>}
 */
let settings = {};
/**
 * @private
 * @type {Object<string, cSnapshotStats>}
 */
const clipboard = {};
/**
 * @private
 * @type {Object<string,string>} */
const clipboardMeta = {};
/**
 * @private
 * @type {?string} */
let actualClipboardEntry = null;
/**
 * @private
 * @type {Observer} */
const onWorkspaceUpdate = new $classes.Observer();
/** @private
 * @type {Observer} */

const onCWSChange = new $classes.Observer();
/**
 * @private
 * @type {Observer} */

const onProjectUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */

const onCPRChange = new $classes.Observer();
/**
 * @private
 * @type {Observer} */

const onUsersUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */

const onMembersUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */

const onTasksUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onDraftsUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */

const onTransitionsUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onHistoryUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onProjectStatsUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onFilesUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onNotificationsUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onChangeCTID = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onChangeAU = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onUserInfoUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onClipboardUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onWorkspaceStatusChange = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onOwnershipChanged = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onTeamsUpdate = new $classes.Observer();
/**
 * @private
 * @type {Observer} */
const onTeamMembersUpdate = new $classes.Observer();
/**
 * @private
 * @type {Function} */
const getFromObj = core_utils.getFromObj;
/**
 * @private
 * @type {Function} */
const mkNS = core_utils.mkNS;
/**
 * @private
 * @type {Object.<string, boolean>} */
const removedTasksAndTransitions = {};

/** @type {Armap.<cWorkspaceUserInfo>} */
const $members = Armap('id', ['projectId', 'workspaceUserId']);

/** @type {Armap.<cTeamInfo>} */
const $teams = Armap('id', ['projectId']);

/** @type {Armap.<cTeamMembershipInfo>} */
const $teamMembers = Armap(
    /** @param {cTeamMembershipInfo} item */ item => `${item['teamId']}:${item['workspaceUserId']}`,
    ['projectId', 'teamId', 'workspaceUserId']
);
/** @type {Array.<cTeamMembershipInfo>} */
// $scope[_bndProjectTeamMembers] = $teamMembers;

/** @type {Armap.<cTransitionInfo>} */
const $transitions = Armap('id', {
    // projectId: _gcEmbedProjectId,
    fromId: undefined,
    toId: undefined,
    groupId: _dcNoGroup
});

/**
 * @type {Object<string, boolean>}
 */
const $projectAuthorizedAtGoogle = {};
/** @type {Object<string, string>} */
const $googleAuthToken = {};
/** @type {Object<string,string|undefined>} */
const $googleCalendars = {};

/** @type {Armap<ProjectStatsType>} */
const $groupStats = Armap();

/** @type {Armap.<cHistoryEntryInfo>} */
const $history = Armap('id', ['workspaceId', 'projectId', 'event', 'stream', 'objectId']);

/** @type {Armap.<cFileInfo>} */
const $files = Armap('id', ['workspaceId', 'projectId', 'objectId']);

/** @type {Armap.<cUserAlertEntryInfo>} */
const $notifications = Armap('id', ['workspaceId', 'projectId', 'objectId']);

/** @type {Object.<string,AxisGrid>} */
let $axis = {};

/**
 * Tasks sorting function
 * @param {cTaskInfo} a
 * @param {cTaskInfo} b
 * @return {number}
 */
function tasksSortFn(a, b) {
    const ay = (typeof a == 'string' && $scope[_bndProjectTasks].$item(a).rowId) || a.rowId;
    const by = (typeof b == 'string' && $scope[_bndProjectTasks].$item(b).rowId) || b.rowId;
    switch (true) {
        case ay > by:
            return 1;
        case ay < by:
            return -1;
        default:
            const ax = (typeof a == 'string' && $scope[_bndProjectTasks].$item(a).colId) || a.colId;
            const bx = (typeof a == 'string' && $scope[_bndProjectTasks].$item(b).colId) || b.colId;
            switch (true) {
                case ax > bx:
                    return 1;
                case ax < bx:
                    return -1;
                default:
                    return 0;
            }
    }
}

/**
 * @param {cWorkspaceUserInfo} a
 * @param {cWorkspaceUserInfo} b
 */
function usersSort(a, b) {
    var _an=((a.lastName || '') + (a.firstName || '')).toLowerCase();
    var _bn=((b.lastName || '') + (b.firstName || '')).toLowerCase();
    return _an > _bn ? 1 : _an==_bn ? 0 : -1;
};

// core_DO.tasksSortFn = tasksSortFn;

/**
 * Tasks sorting by status + priorities
 * @param {cTaskInfo} a
 * @param {cTaskInfo} b
 * @return {number}
 */
function tasksSortByStatusFn(a, b) {
    const aa = (typeof a == 'string' && $scope[_bndProjectTasks].$item(a)) || a;
    const bb = (typeof b == 'string' && $scope[_bndProjectTasks].$item(b)) || b;
    const asi = enmTaskInfoStates.indexOf(aa.state);
    const abi = enmTaskInfoStates.indexOf(bb.state);
    switch (true) {
        case asi > abi:
            return -1;
        case asi < abi:
            return 1;
        default:
            const ay = aa.rowId;
            const by = bb.rowId;
            switch (true) {
                case ay > by:
                    return 1;
                case ay < by:
                    return -1;
                default:
                    const ax = aa.colId;
                    const bx = bb.colId;
                    switch (true) {
                        case ax > bx:
                            return 1;
                        case ax < bx:
                            return -1;
                        default:
                            return 0;
                    }
            }
            break;
    }
}

function sortProjectByLTA() {
    /**
     * @param {cProjectInfo} a
     * @param {cProjectInfo} b
     */
    const f = (a, b) => {
        switch (true) {
            case a.lta_ < b.lta_:
                return 1;
            case a.lta_ > b.lta_:
                return -1;
            default:
                return sortProjectsByName(a, b);
        }
    };
    return f;
}

/**
 * @param {!string} id
 * @param {!string} newState
 * @return void
 */
function updateOwnerStateStatistic(id, newState) {
    const t = getFromObj(storage, [_dmdTasks, $scope[_bndCurrentProject], id], {});
    if (t['groupId']) {
        const o = getFromObj(storage, [_dmdTasks, $scope[_bndCurrentProject], t['id']], {});
        const tbs = o.tasksByState || (o.tasksByState = {});
        (tbs[t['state']] == undefined && (tbs[t['state']] = 0)) || tbs[t['state']]--;
        (tbs[newState] == undefined && (tbs[newState] = 1)) || tbs[newState]++;
    }
}

/**
 * @param {string} pid
 * @param {?string} gid
 * @param {?Array<string>} aids
 */
function createAxis(pid, gid, aids) {
    const aid = [pid || $scope[_bndCurrentProject].id, gid || _dcNoGroup].join(':');
    if (!$axis[aid]) {
        $axis[aid] = new AxisGrid(
            50,
            50,
            _trINIT_COL,
            _trINIT_ROW,
            _trCOL_WIDTH,
            _trROW_HEIGHT,
            1000,
            1000,
            _trCOL_INC,
            _trROW_INC
        );
        aids && aids.push(aid);
    }
    return aid;
}

/**
 * @param {*} list
 * @return {number}
 */
function deepSort(list) {
    list.sort(tasksSortFn);
    let qty = list.length;
    for (let i = list.length; --i >= 0; ) {
        if (list[i].childs) {
            qty += deepSort(list[i].childs);
        }
    }
    return qty;
}

/**
 * @param {string=} groupId
 */
function updateGroupStats(groupId) {
    DEBUG && console.time('updateGroupStats');
    const obj = {
        [enmTaskInfoStates.Completed]: 0,
        [enmTaskInfoStates.Unblocked]: 0,
        [enmTaskInfoStates.Started]: 0,
        [_gcTaskStateAlerted]: 0,
        [enmTaskInfoStates.Blocked]: 0,
        tasksQty: 0
    };

    $scope[_bndProjectTasks].$keysByAggregateKey({ type: enmTaskInfoTypes.Group }).forEach(
        /** @param {string} id */ id => {
            const s = { ...obj };
            const $list = $scope[_bndProjectTasks].$valuesByAggregateKeys(
                { groupId: getChildGroups(id), type: enmTaskInfoTypes.Task },
                []
            );

            for (let t, i = $list.length; --i >= 0; ) {
                t = $list[i];
                if (t.isDeadlineAlert()) {
                    s[_gcTaskStateAlerted]++;
                } else {
                    s[t.state]++;
                }
            }

            s.tasksQty = $list.length;
            // s[enmTaskInfoStates.Unblocked] += s[enmTaskInfoStates.Started];
            $groupStats.$push(s, id);
        }
    );
    DEBUG && console.timeEnd('updateGroupStats');
}

/**
 * @param {string} gid
 * @param {boolean} removeOwner
 * @return {Array<string>}
 */
function getChildGroups(gid, removeOwner = false) {
    let c = [gid];
    for (let i = 0; i < c.length; i++) {
        c = [...c, ...$scope[_bndProjectTasks].$keysByAggregateKey({ type: enmTaskInfoTypes.Group, groupId: c[i] })];
        // c = core_utils.concatArrays(
        //     c,
        //     $scope[_bndProjectTasks].$keysByAggregateKey({ type: enmTaskInfoTypes.Group, groupId: c[i] })
        // );
    }
    return (removeOwner && c.slice(1)) || c;
}

const core_DO = {
    onWorkspaceUpdate,
    onWorkspaceStatusChange,
    onCWSChange,
    onProjectUpdate,
    onCPRChange,
    onUsersUpdate,
    onMembersUpdate,
    onTasksUpdate,
    onDraftsUpdate,
    onTransitionsUpdate,
    onHistoryUpdate,
    onProjectStatsUpdate,
    onFilesUpdate,
    onNotificationsUpdate,
    onChangeCTID,
    onChangeAU,
    onUserInfoUpdate,
    onClipboardUpdate,
    onOwnershipChanged,
    onTeamsUpdate,
    onTeamMembersUpdate,

    /**
     * Cleanup the storages
     * @returns void
     */
    cleanup() {
        $scope[_bndWorkspaces].$empty();
        $scope[_bndProjects].$empty();
        $scope[_bndProjectStats].$empty();
        $scope[_bndWorkspaceUsers].$empty();
        $members.$empty();
        $scope[_bndProjectTasks].$empty();
        $transitions.$empty();
        $groupStats.$empty();
        $history.$empty();
        $files.$empty();
        $notifications.$empty();
        $axis = {};
    },
    /**
     * User settings
     * @param {*} $set
     * @param {*} value
     * @return {*}
     */
    settings($set = undefined, value = undefined) {
        switch (arguments.length) {
            case 1:
                const v = settings[$set];
                switch ($set) {
                    case _skeyPagingFeature:
                    case _skeyMOTDStatus:
                        if (v === undefined) {
                            return false;
                        } else {
                            // @ts-ignore
                            return /true/.test(v);
                        }
                        break;
                }
                return v;
            case 2:
                if ($set === null) {
                    settings = value || {};
                } else {
                    settings[$set] = value;
                    if (value === null) {
                        return core.moses.announce(_rRemoveUserProperties, { keys: [$set] });
                    } else {
                        const obj = {};
                        // @ts-ignore
                        obj[$set] = value;
                        return core.moses.announce(_rSetUserProperties, { properties: obj });
                    }
                }
                break;
            default:
                return settings;
        }
    },

    /**
     * Set current workspace
     * @param {!string|boolean} id
     * @param {string=} npr (current project id)
     * @return {boolean}
     */
    setCWS(id, npr) {
        DEBUG && console.info('DO::setCWS', id);

        if (id == null) {
            $scope[_bndCurrentWorkspace] = null;
            $scope[_bndCurrentWorkspaceUser] = null;
            $scope[_bndCurrentProject] = null;
            return false;
        } else if (id === false && $scope[_bndCurrentWorkspace]) {
            $scope[_bndCurrentProject] = null;
            return false;
        }

        $scope[_bndCurrentWorkspace] = $scope[_bndWorkspaces].$item(id);
        DEBUG && console.assert($scope[_bndCurrentWorkspace], 'no workspace found');
        if (!$scope[_bndCurrentWorkspace]) {
            return false;
        }

        $scope[_bndCurrentWorkspaceUser] = $scope[_bndCurrentWorkspace]['workspaceUser'];
        $scope[_bndCurrentWorkspaceUser]['flname'] = core.getMsg(_msgUserFLName, {
            firstName: $scope[_bndCurrentWorkspaceUser]['firstName'],
            lastName: $scope[_bndCurrentWorkspaceUser]['lastName']
        });

        $scope[_bndWorkspaceOverviewActiveUser] = $scope[_bndCurrentWorkspaceUser];

        const limits = $scope[_bndCurrentWorkspace].workspaceLimits;
        let intercom_obj;
        let mpo;
        if (__STATISTIC) {
            intercom_obj = {
                user_id: core.iam.id,
                company: {
                    id,
                    name: $scope[_bndCurrentWorkspace].name,
                    plan: undefined,
                    inTrial: 'false',
                    trialUntil: undefined,
                    created_at: 0
                }
            };
            mpo = {
                Plan: '',
                name: $scope[_bndCurrentWorkspace].name,
                'In Trial': 'false',
                'Trial Until': undefined,
                'Current Term Until': undefined
            };
        }

        if (limits) {
            if (intercom_obj && mpo) {
                limits.productCode && (mpo.Plan = intercom_obj.company.plan = limits.productCode);
                limits.inTrial != undefined &&
                    (mpo['In Trial'] = intercom_obj.company.inTrial = (limits.inTrial && 'true') || 'false');
                limits.inTrial != true &&
                    limits.trialEnds &&
                    // @ts-ignore
                    (mpo['Trial Until'] = intercom_obj['company']['trialUntil'] = moment(limits.trialEnds).format(
                        _gcMixPanelDateFormat
                    ));
                limits.currentTermEnds &&
                    // @ts-ignore
                    (mpo['Current Term Until'] = moment(limits.currentTermEnds).format(_gcMixPanelDateFormat));
            }
            if (
                // @ts-ignore
                (limits.inTrial && moment(limits.trialEnds).isBefore(Date.now())) ||
                $scope[_bndCurrentWorkspace].writable == false
            ) {
                $scope[_bndExtendTrialProcessed] = false;
                core.moses.announce(_rCheckTrialEligibility).then(
                    /** @param {cUserBillingManagementResponse} r */ r => {
                        if (r.status == enmBillingResponseStatus.Allowed) {
                            $scope[_bndAllowedToExtendTrial] = true;
                        } else {
                            $scope[_bndAllowedToExtendTrial] = false;
                        }
                    }
                );
            }
        }

        if (__STATISTIC && __USE_MIXPANEL && window.mixpanel) {
            const mp = window.mixpanel[_gcMixPanelAccount];
            mp.identify($scope[_bndCurrentWorkspace].id);
            mp.people.set(mpo);
            mp.name_tag($scope[_bndCurrentWorkspace].name);
        }

        if (__STATISTIC && __USE_INTERCOM) {
            if (IntercomBootData) {
                IntercomBootData.push(intercom_obj);
            } else {
                try {
                    DEBUG && console.info('Intercom::update', intercom_obj);
                    Intercom('update', intercom_obj);
                } catch (e) {
                    DEBUG && console.error('Intercom::update fail', e);
                    __USE_ROLLBAR && Rollbar.debug('Intercom::update fail', { e, obj: intercom_obj });
                }
            }
        }

        intercom_obj &&
            $scope[_bndCurrentWorkspace].createdAt &&
            (intercom_obj['company']['created_at'] = $scope[_bndCurrentWorkspace].createdAt / 1000);

        $scope[_bndHasFeature] = {};
        limits &&
            limits.features &&
            limits.features.forEach(
                /** @param {string} id */
                id => {
                    $scope[_bndHasFeature][id] = true;
                }
            );
        // enabling labs features for VUE dataset
        (core_DO.settings('labs.features') || '').split(' ').forEach(
            /** @param {string} id */
            id => {
                $scope[_bndHasFeature][id] = true;
            }
        );
        // $scope[_bndHasFeature][_fndTaskDuration] = false;

        $scope[_bndCurrentWorkspaceSettings] = $scope[_bndCurrentWorkspace].settings;

        if (core_DO.hasFeature(_fndProjectCategories)) {
            // balancing categories order if required
            const l = $scope[_bndCurrentWorkspace].categories;

            if (l.length > 0 && l.length > l.unique(/** @param {cProjectTagInfo} i */ i => i.order).length) {
                let idx = 10;
                const step = 1000;
                $scope[_bndCurrentWorkspace].categories = $scope[_bndCurrentWorkspace].categories.sort(tagsSort).map(
                    /** @param {cProjectTagInfo} i */ i => {
                        i.order = idx++ * step;
                        const nd = i.$export();
                        nd.tagId = i.id;
                        delete nd.id;
                        core.moses.announce(_rUpdateProjectTag, nd);
                        return i;
                    }
                );
                DEBUG && console.info('>> Categories rebalanced');
            }

            $scope[_bndProjectTags] = Armap($scope[_bndCurrentWorkspace].categories || [], 'id');
            $scope[_bndProjectTags].sort(tagsSort);
        }

        const us = core_DO.users();
        let q = true;

        if (us.length == 0 && core.moses) {
            $scope[_bndCurrentWorkspaceUsers] = [];
            q = core.moses.announce(_rListWorkspaceUsers, { workspaceId: id }).then(
                /** @param {cListOfWorkspaceUsersResponse} obj */ obj => {
                    core_DO.setUsers(obj.workspaceUsers);
                    npr && core_DO.setCPR(npr);
                }
            );
        } else {
            onUsersUpdate.debouncedPublish();
        }

        if (npr) {
            $scope[_bndCurrentProject] = $scope[_bndProjects].$item(npr);
            DEBUG && console.assert($scope[_bndCurrentProject]);
        } else {
            $scope[_bndCurrentProject] = null;
        }

        onCWSChange.publish();
        return q;
    },

    /**
     * Check feature in workspace
     * @param {string} featureName
     * @return {boolean}
     */
    hasFeature(featureName) {
        // if (featureName == _fndConsultingTools) {
        //     return true;
        // }
        /** @type {Array.<string>} */
        let labs = core_DO.settings('labs.features')?.split(' ') || [];
        /** @type {Array.<string>} */
        const f =
            ($scope[_bndCurrentWorkspace] &&
                $scope[_bndCurrentWorkspace].workspaceLimits &&
                $scope[_bndCurrentWorkspace].workspaceLimits.features) ||
            [];
        return [...f, ...labs].indexOf(featureName) > -1 || false;
    },

    /**
     * Add workspace feature
     * @param {!string} name
     * @return {void}
     */
    pushFeature(name) {
        $scope[_bndHasFeature][name] = true;
        const f =
            ($scope[_bndCurrentWorkspace] &&
                $scope[_bndCurrentWorkspace].workspaceLimits &&
                $scope[_bndCurrentWorkspace].workspaceLimits.features) ||
            [];
        f.push(name);
    },

    /**
     * Set Active User in project
     * @param {!string} id
     * @param {string=} caller
     * @return {void}
     */
    setAU(id, caller) {
        DEBUG && console.log('core::DO::setAU', [id, caller]);
        $scope[_bndActiveUser] = id != undefined ? id : $scope[_bndCurrentWorkspaceUser].id;
        onChangeAU.publish($scope[_bndActiveUser], caller);
    },

    /**
     * Get Active User ID
     * @return {string}
     */
    getAU() {
        return $scope[_bndActiveUser];
    },

    /**
     * Set/Get workspaces list
     * @param {Array.<cWorkspaceInfo>=} data
     * @returns {void|Array.<cWorkspaceInfo>}
     */
    $workspaces(data) {
        if (data && Object.isArray(data)) {
            cached = {};

            data.forEach(v => {
                // const ws = v.id;

                if (v.projects) {
                    v.projects.forEach(
                        /**
                         * @param {cProjectInfo} p
                         */
                        p => {
                            $scope[_bndProjects].$push($classes.$check(_cProjectInfo, p));
                            imgs.projectImage.add(p.id, p.imageId);
                        }
                    );
                    delete v.projects;
                }

                if (v.projectStats) {
                    for (const [id, str] of Object.entries(v.projectStats)) {
                        const { stats } = JSON.parse(str);
                        $scope[_bndProjectStats].$push(stats, id);
                    }
                    delete v.projectStats;
                }

                $scope[_bndWorkspaces].$push($classes.$check(_cWorkspaceInfo, v));
                imgs.workspaceImage.add(v.id, v.imageId);

                const wu = v.workspaceUser;
                wu && imgs.usersImage.add(wu.id, wu.imageId, undefined, [wu.firstName, wu.lastName]);
            });

            onWorkspaceUpdate.debouncedPublish();
        } else {
            if (data) {
                return $scope[_bndWorkspaces].$item(data);
            } else {
                return $scope[_bndWorkspaces].sort(
                    /**
                     * @param {cWorkspaceInfo} a
                     * @param {cWorkspaceInfo} b
                     */
                    (a, b) => {
                        const u =
                            enmWorkspaceUserRole.indexOf(b.workspaceUser?.role) -
                            enmWorkspaceUserRole.indexOf(a.workspaceUser?.role);

                        if (a.name == null || b.name == null) {
                            return u;
                        }

                        switch (true) {
                            case u == 0:
                                switch (true) {
                                    case a.name.toLowerCase() > b.name.toLowerCase():
                                        return 1;
                                    case a.name.toLowerCase() < b.name.toLowerCase():
                                        return -1;
                                    default:
                                        return 0;
                                }
                            default:
                                return u;
                        }
                    }
                );
            }
        }
    },

    /**
     * Get workspace info by id
     * @param {!string} id
     * @returns {cWorkspaceInfo|undefined}
     */
    workspace(id) {
        return $scope[_bndWorkspaces].$item(id);
    },

    /**
     * Update Workspace info
     * @param {!string} id
     * @param {cWorkspaceInfo} data
     * @returns {void}
     */
    updateWorkspace(id, data) {
        /** @type {cWorkspaceInfo} */
        const item = $scope[_bndWorkspaces].$item(id);
        let flags = 0;

        if (!item) {
            return;
        }

        if (data.name != item.name) {
            flags |= _dupNameChanged;
        } else {
            flags |= _dupDataChanged;
        }

        if (item) {
            item.$update(data);
            imgs.workspaceImage.add(id, (item.imageId = data.imageId));
            onWorkspaceUpdate.publish(id, flags);
        }
    },

    /**
     * Change workspace owner
     * @param {string} id
     * @param {string} uid
     * @returns {void}
     */
    changeWorkspaceOwner(id, uid) {
        /** @type {cWorkspaceInfo} */
        const ws = $scope[_bndWorkspaces].$item(id);
        /** @type {cWorkspaceUserInfo} */
        const wsu = $scope[_bndWorkspaceUsers].$item(uid);

        if (!ws) {
            return;
        }
        if (!wsu) {
            console.warn('no such user', uid);
            return;
        }
        ws.workspaceOwner = wsu;
        onOwnershipChanged.publish(id);
    },

    /**
     * Update Workspace state for writable flag
     * @param {string} id
     * @param {boolean} state
     * @returns {void}
     */
    updateWritableState4Workspace(id, state) {
        /** @type {cWorkspaceInfo} */
        const $item = $scope[_bndWorkspaces].$item(id);
        if ($item) {
            $item.writable = state;
            onWorkspaceUpdate.publish(id, _dupWritableState);
            onWorkspaceStatusChange.publish(id);
        }
    },

    /**
     * Update Workspace Limits
     * @param {string} id
     * @param {cWorkspaceLimitsInfo} obj
     * @returns {void}
     */
    updateWorkspaceLimits(id, obj) {
        /** @type {cWorkspaceInfo} */
        const $item = $scope[_bndWorkspaces].$item(id);
        if ($item) {
            if ($item.workspaceLimits instanceof cWorkspaceLimitsInfo) {
                $item.workspaceLimits.$update(obj);
            } else {
                $item.workspaceLimits = new cWorkspaceLimitsInfo(obj);
            }
            onWorkspaceUpdate.publish(id, _dupDataChanged);
        }
    },

    /**
     * Update Workspace settings
     * @param  {string} id
     * @param  {cWorkspaceSettingsInfo} obj
     * @returns {void}
     */
    updateWorkspaceSettings(id, obj) {
        /** @type {cWorkspaceInfo} */
        const $item = $scope[_bndWorkspaces].$item(id);
        if ($item) {
            $item.settings = new cWorkspaceSettingsInfo(obj);
            onWorkspaceUpdate.publish(id, _dupDataChanged);
        }
    },

    /**
     * Add new workspace record
     * @param {cWorkspaceInfo} data
     * @returns {void}
     */
    addWorkspace(data) {
        $scope[_bndWorkspaces].$push($classes.$check(_cWorkspaceInfo, data));
        imgs.workspaceImage.add(data.id, data.imageId);
        onWorkspaceUpdate.publish(data.id, _dupCreated);
    },

    /**
     * Remove Workspace
     * @param {!string} id
     * @return {void}
     */
    removeWorkspace(id) {
        $scope[_bndWorkspaces].$remove(id);
        imgs.workspaceImage.remove(id);
        if ($scope[_bndCurrentWorkspace] && id == $scope[_bndCurrentWorkspace].id) {
            alert(core.getMsg(_msgWorkspaceBeenRemoved));
            core.uriHandler.setUri({ id: _umWorkspaces });
        }
        onWorkspaceUpdate.publish(id, _dupRemoved);
    },

    /**
     * Get workspaces belong to current workspace user
     * @return {Array.<cWorkspaceInfo>}
     */
    myWorkspaces() {
        return $scope[_bndWorkspaces]
            .filter(
                /** @param {cWorkspaceInfo} d */
                d => d.ownerId == core.iam.id
            )
            .sortBy(
                /** @param {cWorkspaceInfo} d */
                d => d.name
            );
    },

    /**
     * Set active project
     * @param {!string} id
     * @return {boolean|Promise<boolean>}
     */
    setCPR(id) {
        DEBUG && console.info('DO::setCPR', id);

        const p = core_DO.projectInfo(id);
        DEBUG && console.assert(p, 'no project found');

        switch (true) {
            case !!p && $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id != p.workspaceId && !__EMBED:
            case !$scope[_bndCurrentWorkspace] && !!p:
                return core_DO.setCWS(p['workspaceId'], id);
            case !p:
                return Promise.reject();
        }

        $scope[_bndCurrentProject] = p;
        $scope[_bndCurrentWorkspaceUser].active = true;
        ctask = null;
        onCPRChange.publish(id);

        $scope[_bndProjectUsers] && $scope[_bndProjectUsers].$release && $scope[_bndProjectUsers].$release();
        // @ts-ignore
        $scope[_bndProjectUsers] = $members.$valuesByAggregateKeys(
            { projectId: $scope[_bndCurrentProject].id },
            Armap(),
            true
        );

        if (!core_DO.hasFeature(_fndProjectTeams)) {
            $scope[_bndProjectUsers].push(new cWorkspaceUserInfo({ id: _clsAddUser }));
        }

        $scope[_bndProjectTeams] && $scope[_bndProjectTeams].$release && $scope[_bndProjectTeams].$release();
        // @ts-ignore
        $scope[_bndProjectTeams] = $teams.$valuesByAggregateKeys(
            { projectId: $scope[_bndCurrentProject].id },
            Armap(),
            true
        );

        $scope[_bndProjectTasks].$empty();
        // @ts-ignore
        $transitions.$empty();
        // @ts-ignore
        $groupStats.$empty();

        return true;
    },

    /**
     * Set projects
     * @param {Array<cProjectInfo>} data
     * @param {boolean=} skipAnnounce
     * @return {void}
     */
    setProjects(data, skipAnnounce = false) {
        data.forEach(p => {
            $scope[_bndProjects].$push($classes.$check(_cProjectInfo, p));
            imgs.projectImage.add(p['id'], p['imageId']);
        });

        !skipAnnounce && onProjectUpdate.debouncedPublish();
    },

    /**
     * Get projects
     * @param {boolean=} _sortBy
     * @return {Armap<cProjectInfo>}
     */
    projects(_sortBy = false) {
        const id = $scope[_bndCurrentWorkspace]?.id;
        const sortBy = _sortBy || core_DO.settings(_skeyProjectSortBy) || _prsbLTA;
        let sortFunc;

        switch (sortBy) {
            case _prsbAlphabet:
                sortFunc = sortProjectsByName;
                break;
            case _prsbCreationTime:
                sortFunc = sortProjectsByCreationTime;
                break;
            default:
            case _prsbLTA:
                sortFunc = sortProjectByLTA();
                break;
        }

        if (
            core_DO.hasFeature(_fndProjectCategories) &&
            parseInt(core_DO.settings(_skeyProjectGroupByCategories) || 0, 10)
        ) {
            const max = Number.MAX_SAFE_INTEGER;
            const bSortFunc = sortFunc;
            /**
             * @param {cProjectInfo} a
             * @param {cProjectInfo} b
             */
            sortFunc = (a, b) => {
                let ta = max;
                if ([null, 'null'].includes(a.tagId) == false) {
                    const tmp = $scope[_bndProjectTags].$item(a.tagId);
                    ta = tmp?.order ?? max;
                }
                let tb = max;
                if ([null, 'null'].includes(b.tagId) == false) {
                    const tmp = $scope[_bndProjectTags].$item(b.tagId);
                    tb = tmp?.order ?? max;
                }
                switch (true) {
                    case ta > tb:
                        return 1;
                    case ta < tb:
                        return -1;
                    default:
                        let sa = max;
                        if ([null, 'null'].includes(a.tag2Id) == false) {
                            const tmp = $scope[_bndProjectTags].$item(a.tag2Id);
                            sa = tmp?.order ?? max;
                        }
                        let sb = max;
                        if ([null, 'null'].includes(b.tag2Id) == false) {
                            const tmp = $scope[_bndProjectTags].$item(b.tag2Id);
                            sb = tmp?.order ?? max;
                        }
                        switch (true) {
                            case sa > sb:
                                return 1;
                            case sa < sb:
                                return -1;
                            default:
                                return bSortFunc(a, b);
                        }
                }
            };
        }
        return (
            (id &&
                $scope[_bndProjects]
                    .$valuesByAggregateKeys({ workspaceId: id })
                    .$map(
                        /** @param {cProjectInfo} v */
                        v => {
                            const lta = core_DO.settings(`${_skeyProjectIdPrefix}:${v.id}`);
                            v.stats_ = $scope[_bndProjectStats].$item(v.id) || {};
                            v.lta_ = lta ? core_utils.unpackLTA(lta) : 0;
                            return v;
                        }
                    )
                    .sort(sortFunc)) ||
            Armap()
        );
    },

    /**
     * Get project info by id
     * @param {!string} id
     * @return {cProjectInfo|undefined}
     */
    projectInfo(id) {
        return $scope[_bndProjects].$item(id);
    },

    /**
     * Add project record
     * @param {cProjectInfo} data
     * @return {void}
     */
    addProject(data) {
        $scope[_bndProjects].$push($classes.$check(_cProjectInfo, data));
        imgs.projectImage.add(data.id, data.imageId);
        data.workspaceId == ($scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id) &&
            onProjectUpdate.publish(_dupCreated);
    },

    /**
     * Update project LTA
     * @param {string} id
     * @return {void}
     */
    updateProjectLTA(id) {
        // core_DO.settings(`${_skeyProjectIdPrefix}:${id}`, (~~(Date.now() / 1000) - _gcProjectTimeOffset).toString(36));
        // V5
        core_DO.settings(`${_skeyProjectIdPrefix}:${id}`, core_utils.packLTA(Date.now()));
    },

    /**
     * Remove project data
     * @param {!string} id
     * @return {void}
     */
    removeProject(id) {
        /** @type {cProjectInfo} */
        const p = $scope[_bndProjects].$item(id);

        if (p) {
            $scope[_bndProjects].$remove(id);
            $scope[_bndProjectStats] && $scope[_bndProjectStats].$remove(id);
            imgs.projectImage.remove(id);

            const ws = p.workspaceId;

            const vs = parseInt(core_DO.settings(_skeyProjectsAccessVersion), 10) || 0;

            if (vs < 3) {
                let lta = core_DO.settings(_skeyProjectsAccess);
                if (lta) {
                    lta = core_utils.decData(lta);
                    if (lta[id]) {
                        delete lta[id];
                        core_DO.settings(_skeyProjectsAccess, core_utils.encData(lta));
                    }
                }
            } else {
                core_DO.settings(`${_skeyProjectIdPrefix}:${id}`, null);
            }
            ws == ($scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id) &&
                onProjectUpdate.publish(id, _dupRemoved);
        }
    },

    /**
     * Update project info
     * @param {!string} id
     * @param {!cProjectInfo} data
     * @return {void}
     */
    updateProject(id, data) {
        let flags = 0;

        /** @type {cProjectInfo} */
        const p = $scope[_bndProjects].$item(id);

        if (!p || data.name != p.name) {
            flags = _dupNameChanged;
        } else {
            flags |= _dupDataChanged;
        }

        DEBUG && console.assert(p, 'dataManager::updateProject');

        if (p) {
            const o = p.$export();
            p.$update(data, true);
            // FIXME: hack to prevent loosing data
            p.callerRole = p.callerRole || o.callerRole;
            imgs.projectImage.add(id, data.imageId);
            p.workspaceId == ($scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id) &&
                onProjectUpdate.publish(id, flags);
        }
    },

    /**
     * @param {Array<cWorkspaceUserInfo>} data
     */
    setUsers(data) {
        data.forEach(v => {
            $scope[_bndWorkspaceUsers].$push($classes.$check(_cWorkspaceUserInfo, v));
            imgs.usersImage.add(v.id, v.imageId, undefined, [v.firstName, v.lastName, v.middleName, '']);
        });
        onUsersUpdate.debouncedPublish();
    },

    /**
     * Set/Get users info
     * @return {Array<cWorkspaceUserInfo>}
     */
    users() {
        return $scope[_bndWorkspaceUsers]
            .$valuesByAggregateKeys({
                workspaceId: $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id
            })
            .sort(usersSort
                // /**
                //  * @param {cWorkspaceUserInfo} a
                //  * @param {cWorkspaceUserInfo} b
                //  */
                // (a, b) => {
                //     var _an=((a.lastName || '') + (a.firstName || '')).toLowerCase();
                //     var _bn=((b.lastName || '') + (b.firstName || '')).toLowerCase();
                //     return _an > _bn ? 1 : _an==_bn ? 0 : -1;
                // }
            );
    },

    /**
     * Get workspace user info by id
     * @param {!string} id
     * @return {cWorkspaceUserInfo|undefined}
     */
    user(id) {
        return $scope[_bndWorkspaceUsers].$item(id);
    },

    /**
     * Get workspace user primary email assigned
     * @return {string|null}
     */
    userPrimaryEmail() {
        const em = core.iam.contacts.find(d => d.id == core.iam.primaryContactId);
        return em?.value || null;
    },

    /**
     * Add new user
     * @param {cWorkspaceUserInfo} data
     * @return {void}
     */
    addUser(data) {
        $scope[_bndWorkspaceUsers].$push($classes.$check(_cWorkspaceUserInfo, data));
        imgs.usersImage.add(data.id, data.imageId, undefined, [data.firstName, data.lastName, data.middleName, '']);
        $scope[_bndCurrentWorkspace] &&
            data.workspaceId == $scope[_bndCurrentWorkspace].id &&
            onUsersUpdate.publish(data.id, _dupCreated);
    },

    /**
     * Update user info
     * @param {cWorkspaceUserInfo} data
     * @return {void}
     */
    updateUser(data) {
        const ws = data.workspaceId;
        const wso = core_DO.workspace(data.workspaceId);

        if (!wso) {
            return;
        }

        /** @type {cWorkspaceUserInfo} */
        const _wsu = wso.workspaceUser;
        /** @type {cWorkspaceUserInfo} */
        const u = $scope[_bndWorkspaceUsers].$item(data.id);

        if (data.id == _wsu.id) {
            _wsu.$update(data);
            onWorkspaceUpdate.debouncedPublish();
        }

        if (u) {
            $classes.$check(_cWorkspaceUserInfo, u).$update(data);
            imgs.usersImage.add(data.id, data.imageId, undefined, [data.firstName, data.lastName, data.middleName, '']);

            $scope[_bndCurrentWorkspace] &&
                ws == $scope[_bndCurrentWorkspace].id &&
                onUsersUpdate.publish(data.id, _dupDataChanged);
        } else {
            $scope[_bndWorkspaceUsers].$push($classes.$check(_cWorkspaceUserInfo, data));
            $scope[_bndCurrentWorkspace] &&
                ws == $scope[_bndCurrentWorkspace].id &&
                onUsersUpdate.publish(data.id, _dupCreated);
        }

        const m = $members.$valuesByAggregateKeys({ workspaceUserId: data.id });
        if (m.length) {
            cWorkspaceUserInfo.prototype.$update.call(m[0], data);
        }
    },

    /**
     * Remove user info
     * @param {!string} id
     * @return {void}
     */
    removeUser(id) {
        /** @type {cWorkspaceUserInfo} */
        const ui = $scope[_bndWorkspaceUsers].$item(id);

        if (ui) {
            imgs.usersImage.remove(id);
            $scope[_bndWorkspaceUsers].$remove(id);
            $scope[_bndCurrentWorkspace] &&
                ui.workspaceId == $scope[_bndCurrentWorkspace].id &&
                onUsersUpdate.publish(id, _dupRemoved);
            // sounds like user leave workspace
            const ws = core_DO.workspace(ui.workspaceId);
            if (ws && ws.workspaceOwner != ui.id && ws.workspaceUser.id == id) {
                core_DO.removeWorkspace(ws.id);
            }
        } else {
            // sounds like user was removed from participated workspace
            const ws = $scope[_bndWorkspaces].find(
                /** @param {cWorkspaceInfo} d */
                d => d.workspaceUser?.id == id
            );
            ws && core_DO.removeWorkspace(ws.id);
        }
    },

    /**
     * Set/Get project members info
     * @param {?Object<string,cProjectMembershipInfo>} mbm
     * @param {?Array<cProjectMembershipInfo>} memberShip
     * @return {Array<cWorkspaceUserInfo>|void}
     */
    members(mbm = null, memberShip = null) {
        if (arguments.length) {
            const map = Armap(mbm);

            (memberShip || []).forEach(v => {
                /** @type {cWorkspaceUserInfo} */
                const i = map.$item(v.workspaceUserId);
                DEBUG && console.assert(i, `workspace user to member map. no user found${JSON.stringify(v)}`);

                if (i) {
                    i.wsrole = i.role;
                    i.role = v.role;
                    i.projectId = v.projectId;
                    // @ts-ignore
                    i.workspaceUserId = v.workspaceUserId;
                    $members.$push(i);
                }
            });

            onMembersUpdate.debouncedPublish();
        } else {
            return (
                ($scope[_bndCurrentProject] &&
                    $members.$valuesByAggregateKeys({ projectId: $scope[_bndCurrentProject].id }).sort(
                        /**
                         * @param {cWorkspaceUserInfo} a
                         * @param {cWorkspaceUserInfo} b
                         */
                        usersSort
//                        (a, b) => a.lastName + a.firstName > b.lastName + b.firstName
                    )) ||
                []
            );
        }
    },

    /**
     * Get project member info
     * @param {!string} id
     * @return {cWorkspaceUserInfo|undefined}
     */
    member(id) {
        return $members.$item(id || $scope[_bndCurrentWorkspaceUser]?.id);
    },

    /**
     * Get members hash
     * @return {Array.<string>}
     */
    membersHash() {
        return $members.$keysByAggregateKey({ projectId: $scope[_bndCurrentProject].id });
    },

    /**
     * Get members hased
     * @param {string} id
     * @return {Object<string, cWorkspaceUserInfo>}
     */
    membersHashed(id) {
        return (
            ($scope[_bndCurrentProject] &&
                $members.$valuesByAggregateKeys({ projectId: $scope[_bndCurrentProject].id })) ||
            []
        );
    },

    /**
     * Add new member to project
     * @param {!string} id
     * @param {!enmWorkspaceUserRole} role
     * @param {!string} pr
     * @return {void}
     */
    addMember(id, role, pr) {
        const p = core_DO.projectInfo(pr || $scope[_bndCurrentProject].id);

        if (!p) {
            core.moses.announce(_rProjectInfo, { projectId: pr || $scope[_bndCurrentProject].id }).then(
                /** @param {cProjectInfoBundle} obj */
                obj => {
                    core_DO.addProject(obj.project);
                    core_DO.addMember(id, role, pr);
                }
            );
            return;
        }

        const item = new cWorkspaceUserInfo(core_DO.user(id) || {});

        item.wsrole = item.role;
        item.role = role;
        item.projectId = p.id;

        $members.$push(item);

        onMembersUpdate.debouncedPublish();
    },

    /**
     * Remove member from project
     * @param {!string} id
     * @return {void}
     */
    removeMember(id) {
        /** @type {cWorkspaceUserInfo} */
        const m = $members.$item(id);
        if (m?.role != enmWorkspaceUserRole.Owner && $scope[_bndCurrentWorkspaceUser].id == m?.id) {
            $scope[_bndProjects].$remove(m.projectId);
            onProjectUpdate.debouncedPublish();
        }
        $members.$remove(id);
        onMembersUpdate.debouncedPublish();
    },

    /**
     * @param {!string} id
     * @param {!enmWorkspaceUserRole} role
     * @return {void}
     */
    changeMemberRole(id, role) {
        /** @type {cWorkspaceUserInfo} */
        const item = $members.$item(id);
        DEBUG && console.assert(item, `no member with such ID ${id}`);

        item && (item.role = role);
        if ($scope[_bndCurrentWorkspaceUser] && id == $scope[_bndCurrentWorkspaceUser].id) {
            /** @type {cProjectInfo} */
            const p = core_DO.projectInfo(item.projectId);
            if (!p) {
                return;
            }
            p.callerRole = role;
            //             onProjectUpdate.publish(item.projectId);
            onMembersUpdate.debouncedPublish();
        }
    },

    /**
     * @param {Array<cTeamInfo>} members
     * @param {Array<cTeamMembershipInfo>} membership
     * @return {void}
     */
    setTeams(members, membership) {
        $teamMembersHash = {};
        (membership || []).forEach(d => {
            $teamMembers.$push($classes.$check(_cTeamMembershipInfo, d));
            !$teamMembersHash[d.workspaceUserId] && ($teamMembersHash[d.workspaceUserId] = []);
            $teamMembersHash[d.workspaceUserId].push(d.teamId);
        });
        (members || []).forEach(d => {
            $teams.$push($classes.$check(_cTeamInfo, d));
            d.members_ = $teamMembers.$valuesByAggregateKeys(
                { teamId: d.id },
                [],
                true /*, function (d) { return $scope[_bndWorkspaceUsers].$item(d.workspaceUserId); }*/
            );
            $teamMembersHash[d.id] = d.members_.map(d => d.workspaceUserId);
        });
        // updateTeamsScope();
    },

    /**
     * @param {!(string|cTeamInfo)} _id
     * @param {string?} _name
     * @param {string?} _symbol
     * @param {string?} _description
     * @param {string?} _color
     * @param {string?} _projectId
     * @param {boolean} isNew
     * @return {cTeamInfo}
     */
    addTeam(_id, _name, _symbol, _description, _color, _projectId, isNew) {
        /** @type {cTeamInfo} */
        let obj;
        if (typeof _id == 'object') {
            obj = $classes.$check(_cTeamInfo, _id);
        } else {
            obj = new cTeamInfo({
                id: _id || (_id = core_utils.uuid()),
                name: _name || core.getMsg(_msgDefaultTeamName),
                symbol: _symbol,
                description: _description,
                color: _color,
                projectId: _projectId || $scope[_bndCurrentProject].id
            });
        }
        obj.isNew_ = isNew || false;
        obj.members_ = /*$scope[_bndProjectTeamMembers]*/ $teamMembers.$valuesByAggregateKeys(
            { teamId: obj.id },
            [],
            true /*, function (d) { return $scope[_bndWorkspaceUsers].$item(d.workspaceUserId); }*/
        );

        $teamMembersHash[obj.id] = [];

        $teams.$push(obj);
        // imgs.users.add(obj.id, undefined, undefined, undefined, obj.symbol, obj.color);

        return obj;

        // if (obj.projectId == ($scope[_bndCurrentProject] && $scope[_bndCurrentProject].id)) {
        //     updateTeamsScope();
        // }
    },

    /**
     * @param {boolean=} skipCreateLink
     * @return {Array<cTeamInfo>}
     */
    getTeams(skipCreateLink) {
        return $teams.$valuesByAggregateKeys({ projectId: $scope[_bndCurrentProject].id }, Armap(), !skipCreateLink);
    },

    /**
     * @return {Armap<cTeamInfo>}
     */
    $teams() {
        return $teams;
    },

    /**
     * @param {!string} id
     * @return {void}
     */
    removeTeam(id) {
        const item = $teams.$item(id);
        if (item && item.members_ && item.members_$release) {
            item.members_$release();
        }
        $teams.$remove(id);
        // imgs.users.remove(id);
        $teamMembers.$removeByIndex({ teamId: id });
        // $scope.$teams.remove(function (d) { return d.id == id; });
        // onTeamsUpdate.publish(id, _dupRemoved);
    },

    /**
     * @param {cTeamInfo} obj
     */
    updateTeam(obj) {
        const d = $teams.$item(obj.id);
        cTeamInfo.prototype.$update.call(d, obj);
        // imgs.users.update(d.id, undefined, undefined, d.symbol, d.color);
        if (!d.members_.$release) {
            d.members_ = $scope[_bndProjectTeamMembers].$valuesByAggregateKeys(
                { teamId: d.id },
                [],
                true /*, function (d) { return $scope[_bndWorkspaceUsers].$item(d.workspaceUserId); }*/
            );
        }
    },

    /**
     * @param {string?} id
     * @return {Array<cWorkspaceUserInfo>}
     */
    projectTeamsMembers(id) {
        return $teamMembers.$valuesByAggregateKeys({ projectId: id || $scope[_bndCurrentProject]?.id }, []).map(
            /** @param {cTeamMembershipInfo} d */
            d => {
                const u = $scope[_bndWorkspaceUsers].$item(d.workspaceUserId);
                console.assert(u, `CDO::projectTeamsMembers no user found for ${u.workspaceUserId}`);
                // u.image_ = imgs.usersImage40(u.id);
                return u;
            }
        );
    },

    /**
     * @param {string?} id
     * @return {Object<string, string>}
     */
    projectTeamsMembersHash(id) {
        /** @type {Object<string, string>} */
        const hash = {};

        $teamMembers.$valuesByAggregateKeys({ projectId: id || $scope[_bndCurrentProject]?.id }, []).forEach(
            /** @param {cTeamMembershipInfo} d */
            d => {
                hash[d.workspaceUserId] = d.teamId;
            }
        );

        return hash;
    },

    /**
     * return teams where user is a member
     * @param {string} uid
     * @return {Array.<string>}
     */
    memberOfTeams(uid) {
        return $teamMembers
            .$valuesByAggregateKeys({
                projectId: $scope[_bndCurrentProject] && $scope[_bndCurrentProject].id,
                workspaceUserId: uid
            })
            .map(/** @param {cTeamMembershipInfo} d */ d => d.teamId);
    },

    /**
     * @param {string|Object} _projectId
     * @param {string?} _teamId
     * @param {string?} _workspaceUserId
     * @param {string?} _role
     * @return {void}
     */
    addMemberToTeam(_projectId, _teamId, _workspaceUserId, _role) {
        /** @type {cTeamMembershipInfo} */
        let obj;
        if (typeof _projectId == 'object') {
            // @ts-ignore
            obj = _projectId;
        } else {
            // @ts-ignore
            obj = {
                projectId: _projectId,
                teamId: _teamId || '',
                workspaceUserId: _workspaceUserId || ''
                // 'role': _role
            };
        }
        $teamMembers.$push(new cTeamMembershipInfo(obj));
        !$teamMembersHash[obj.teamId] && ($teamMembersHash[obj.teamId] = []);
        $teamMembersHash[obj.teamId].push(obj.workspaceUserId);
        // @ts-ignore
        $teamMembersHash[obj.teamId] = $teamMembersHash[obj.teamId].unique();
        // onTeamMembersUpdate.publish({uid: obj.workspaceUserId, tid: obj.teamId}, _dupCreated);
    },

    /**
     * @param {!string} uid
     * @param {!string} teamId
     * @return {void}
     */
    removeMemberFromTeam(uid, teamId) {
        $teamMembers.$removeByIndex({ workspaceUserId: uid, teamId });
        // @ts-ignore
        $teamMembersHash[teamId].remove(uid);
        // onTeamMembersUpdate.publish({uid: uid, tid: teamId}, _dupRemoved);
    },

    /**
     * @param {Array<cTaskInfo>} tasks
     * @param {Array<cTransitionInfo>} transitions
     * @return {Array<string>|undefined}
     */
    setTasks(tasks, transitions) {
        if (!$scope[_bndCurrentProject]) {
            return;
        }

        DEBUG && console.time('CDO::setTasks');
        /** @type {Array<string>} */
        const aids = [];

        DEBUG && console.time('cdo::setTasks::tasks');
        (tasks || []).forEach(d => {
            const task = $classes.$check(_cTaskInfo, d);
            let aid;

            $scope[_bndProjectTasks].$push(task);

            task.type == enmTaskInfoTypes.Group && createAxis(task.projectId, task.id, aids);

            aid = createAxis(task.projectId, task.groupId, aids);
            task.type !== enmTaskInfoTypes.Draft && $axis[aid].add(task.rowId, task.colId, false);
        });
        DEBUG && console.timeEnd('cdo::setTasks::tasks');

        DEBUG && console.time('cdo::setTasks::transitions');
        (transitions || []).forEach(d => {
            /** @type {cTransitionInfo} */
            const p = $classes.$check(_cTransitionInfo, d);
            p.groupId = $scope[_bndProjectTasks].$item(d.fromId || d.toId).groupId || _dcNoGroup;
            $transitions.$push(p);
        });
        DEBUG && console.timeEnd('cdo::setTasks::transitions');

        // we gotta be sure there is no lost tasks and groups
        DEBUG && console.time('cdo::setTasks::lostGroups');

        const wholeIds = Object.keys($scope[_bndProjectTasks].$hash());
        const lostGroups = $scope[_bndProjectTasks]
            .filter(/** @param {cTaskInfo} p */ p => !!p.groupId)
            .map(/** @param {cTaskInfo} p */ p => p.groupId)
            .filter(/** @param {string} id */ id => !wholeIds.includes(id))
            .unique();
        // var groups = $scope[_bndProjectTasks].$valuesByAggregateKeys(
        //     { /*'projectId': $scope[_bndCurrentProject].id,  type: enmTaskInfoTypes.Task */},
        //     Armap('id', ['groupId'])
        // );
        // var ids = groups.$aggregatedKeys('groupId');
        //
        // ids.forEach(function(id) {
        //     if (id != _dcNoGroup && $scope[_bndProjectTasks].$item(id) == undefined) {
        //         lostGroups.push(id);
        //     }
        // });

        if (lostGroups.length) {
            const rootAxis = $axis[createAxis($scope[_bndCurrentProject].id, null, null)];
            const mic = rootAxis.x.first() - ((_trTaskWidth / _trCOL_WIDTH) * _trCOL_INC + _trCOL_INC * 2);
            let mir = rootAxis.y.first();
            const pckt = new cStateChangesEnvelope({ projectId: $scope[_bndCurrentProject].id });

            lostGroups.forEach(
                /**
                 * @param {string} id
                 * @param {number} i
                 */
                (id, i) => {
                    const d = new cTaskInfo({
                        id,
                        name: `${core.getMsg(_msgLostAndFoundGroupName)} ${i + 1}`,
                        colId: mic,
                        rowId: mir,
                        projectId: $scope[_bndCurrentProject].id,
                        type: enmTaskInfoTypes.Group,
                        description: 'Auto recovered group',
                        creatorId: $scope[_bndCurrentWorkspaceUser].id
                    });

                    $scope[_bndProjectTasks].$push(d);

                    pckt.changes.push(
                        new cStateChange({
                            type: enmStateChangeTypes.CreateTask,
                            id,
                            data: d
                        })
                    );

                    mir += (70 / _trROW_HEIGHT) * _trROW_INC + _trROW_INC;
                }
            );

            core.moses.announce(_rProjectStatePush, pckt);
        }
        DEBUG && console.timeEnd('cdo::setTasks::lostGroups');

        updateGroupStats();

        DEBUG && console.timeEnd('CDO::setTasks');

        onTasksUpdate.publish();
        onTransitionsUpdate.debouncedPublish();
        return (lostGroups.length && lostGroups) || undefined;
    },

    /**
     * @typedef GroupStatType
     * @property {number} Blocked
     * @property {number} Unblocked
     * @property {number} Completed
     * @property {number} Overdue
     * @property {number} tasksQty
     * Return group tasks count
     * @param {string=} groupId
     * @return {GroupStatType}
     */
    groupStat(groupId) {
        return $groupStats.$item(groupId) || {};
    },

    /**
     * @param {Array<string>} ids
     * @return {Array<string>}
     */
    getChildIds(ids) {
        let c = Array.prototype.slice.call(ids || []);

        for (let i = 0; i < c.length; i++) {
            const d = $scope[_bndProjectTasks].$item(c[i]);
            if (d?.type == enmTaskInfoTypes.Group) {
                c = [...c, ...$scope[_bndProjectTasks].$keysByAggregateKey({ groupId: c[i] })];
                // c = core_utils.concatArrays(
                //     c,
                //     $scope[_bndProjectTasks].$keysByAggregateKey({groupId: c[i]})
                // );
            }
        }
        return c;
    },

    /**
     * Access to Axis object
     * @param {string} groupId
     * @return {AxisGrid}
     */
    axis(groupId) {
        return (
            ($scope[_bndCurrentProject].id &&
                $axis[[$scope[_bndCurrentProject].id, groupId || _dcNoGroup].join(':')]) ||
            {}
        );
    },

    /**
     * request axis updates
     * @param {Array<string>} aids
     */
    axisUpdate(aids) {
        ((aids && aids.length && aids) || Object.keys($axis)).forEach(id => {
            $axis[id].update(true, true, true);
        });
    },

    /**
     * Returns list of tasks of active project
     * @param {string=} groupId
     * @return {{$tasks: Array<cTaskInfo>, $transitions: Array<cTransitionInfo>}}
     */
    tasks(groupId) {
        DEBUG && console.time('CDO::tasks');
        const aid = createAxis($scope[_bndCurrentProject].id, groupId, null);
        /** @type {Array<cTaskInfo>} */
        const list = $scope[_bndProjectTasks].$valuesByAggregateKeys({
            groupId: groupId || _dcNoGroup,
            type: [enmTaskInfoTypes.Group, enmTaskInfoTypes.Task]
        });
        const trans = $transitions.$valuesByAggregateKeys({ groupId: groupId || _dcNoGroup });

        $axis[aid].$updated == false && $axis[aid].update(true);

        list.forEach(d => {
            // @ts-ignore
            d.x = $axis[aid].x.axisById(d['colId']);
            // @ts-ignore
            d.y = $axis[aid].y.axisById(d['rowId']);
        });

        //if ($scope[_bndProjectTasks].lastUpdate() > $groupStats.lastUpdate()) { updateGroupStats(); }

        DEBUG && console.timeEnd('CDO::tasks');

        return {
            $tasks: list,
            $transitions: trans
        };
    },

    /**
     * @param {!string} id
     * @return {cTaskInfo|undefined}
     */
    task(id) {
        return $scope[_bndProjectTasks].$item(id);
    },

    /**
     * @param {?string} id
     * @param {?string} uid
     * @param {boolean} teamsIncluded
     * @param {?cTaskInfo} $task
     * @return {boolean}
     */
    // FIXME: in case there is no team data loaded, we cannot determine which team contain the user!!!
    isTaskMine(id, uid = null, teamsIncluded = false, $task = null) {
        const d = $task || $scope[_bndProjectTasks].$item(id);
        if (!d) {
            return false;
        }
        !uid && (uid = $scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id);
        if (teamsIncluded) {
            return (
                (d.execs.length == 0 && !d.teamId) || // no executers assigned to the task, assuming is mine
                d.execs.indexOf(uid) > -1 || // I was assigned to task
                (uid && d.teamId && $teamMembersHash[d.teamId] && $teamMembersHash[d.teamId].indexOf(uid) > -1) || // task assigned to team and I'm member of this team
                false
            ); // oops, fallback
        } else {
            return (
                (d.execs.length == 0 && !d.teamId) || // no executers assigned to the task, assuming is mine
                d.execs.indexOf(uid) > -1 || // I was assigned to task
                false
            ); // oops, fallback
        }
    },

    /**
     * @param {string|Array<string>|undefined} id
     * @param {string=} caller
     * @return {void}
     */
    setCTID(id = undefined, caller = undefined) {
        DEBUG && console.log('core::DO::setCTID', [id, caller]);
        ctask = (id instanceof Array && id) || (id !== undefined && [id]) || [];
        onChangeCTID.publish(id, caller);
    },

    /**
     * @return {string|Array.<string>|undefined}
     */
    getCTID() {
        return ctask || [];
    },

    /**
     * @param {!string} id
     * @return {Array<cTransitionInfo>}
     */
    tasksByLinkFollow(id) {
        return $transitions.$valuesByAggregateKeys({
            // 'projectId': $scope[_bndCurrentProject].id,
            fromId: id
        });
    },

    /**
     * @param {!string} id
     * @return {Array<cTransitionInfo>}
     */
    tasksByLinkPred(id) {
        return $transitions.$valuesByAggregateKeys({
            // 'projectId': $scope[_bndCurrentProject].id,
            toId: id
        });
    },

    /**
     * @param {!string} id
     * @return {{blocked: Array<cTaskInfo>, blocks: Array<cTaskInfo>}}
     */
    taskBlocksAndBlockedBy(id) {
        const res = {
            blocks: [],
            blocked: []
        };
        const tt = $scope[_bndProjectTasks].$item(id);

        if (id == null || !tt) {
            return res;
        }

        switch (tt['state']) {
            case enmTaskInfoStates.Blocked:
                res.blocked = $transitions
                    .$valuesByAggregateKeys({ /*'projectId': $scope[_bndCurrentProject].id, */ toId: id })
                    .map(/** @param {cTransitionInfo} t */ t => $scope[_bndProjectTasks].$item(t['fromId']))
                    .filter(/** @param {cTaskInfo} d */ d => d['state'] != enmTaskInfoStates.Completed)
                    .sort(tasksSortFn);

                res.blocks = $transitions
                    .$valuesByAggregateKeys({ /*'projectId': $scope[_bndCurrentProject].id, */ fromId: id })
                    .map(/** @param {cTransitionInfo} t */ t => $scope[_bndProjectTasks].$item(t['toId']))
                    .sort(tasksSortFn);
                break;
            case enmTaskInfoStates.Unblocked:
            case enmTaskInfoStates.Started:
                res.blocks = $transitions
                    .$valuesByAggregateKeys({ /*'projectId': $scope[_bndCurrentProject].id, */ fromId: id })
                    .map(/** @param {cTransitionInfo} t */ t => $scope[_bndProjectTasks].$item(t['toId']))
                    .sort(tasksSortFn);
                break;
        }

        return res;
    },

    /**
     * @param {!string|number} id
     * @param {boolean=} includeFree
     * @param {string=} $teamId
     * @param {enmTaskInfoStates=} $state
     * @param {enmTaskInfoTypes=} $type
     * @param {?function} filterCb
     * @return {{$tasks: Array<cTaskInfo>, completed: number, $alerted: number}}
     */
    tasksOfTheUser(id, includeFree, $teamId, $state, $type, filterCb) {
        const key = {};
        const rules = [];
        let klist = [];

        $state && (key['state'] = $state);
        $type && (key['type'] = $type);

        switch (true) {
            case id && !$teamId:
                rules.push({ ...key, ...{ execs: (includeFree && [_dcNobodys, id]) || id } });
                rules.push({ ...key, ...{ teamId: core_DO.memberOfTeams(id) } });
                break;
            case id && !!$teamId:
                rules.push({ ...key, ...{ execs: [_dcNobodys, id], teamId: $teamId } });
                break;
            case !id && !!$teamId:
                rules.push({ ...key, ...{ teamId: $teamId } });
                break;
            case id === 0:
                // id = $scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id || 0;
                if (!$state && !$type) {
                    key.type = [enmTaskInfoTypes.Task, enmTaskInfoTypes.Group];
                }
                rules.push(key);
                break;
        }
        klist = rules
            .map(r => $scope[_bndProjectTasks].$keysByAggregateKey(r))
            // @ts-ignore
            .flatten()
            .unique();

        let alerts = 0;
        let qty = 0;

        const r = klist
            .map(
                /** @param {string} tid */ tid => {
                    const d = $scope[_bndProjectTasks].$item(tid);
                    if (filterCb && !filterCb.call(null, d)) {
                        return undefined;
                    }
                    if (id !== 0 && !core_DO.isTaskMine(undefined, id, true, d)) {
                        return undefined;
                    }
                    if (
                        (d.type == enmTaskInfoTypes.Group && core_DO.groupStat(d.id).tasksQty == 0) ||
                        d.type == enmTaskInfoTypes.Task
                    ) {
                        d.isDeadlineAlert() && alerts++;
                        d.state == enmTaskInfoStates.Completed && qty++;
                        return d;
                    } else {
                        return undefined;
                    }
                }
            )
            .compact();

        return {
            $tasks: r,
            completed: qty,
            $alerted: alerts
        };
    },

    /**
     * @param {string} id
     * @return {Array<string>}
     */
    groupPath(id) {
        const r = [id];
        const $tasks = $scope[_bndProjectTasks];
        let gid;
        while ((gid = $tasks.$item(id).groupId)) {
            r.push((id = gid));
        }
        return r;
    },

    /**
     * Tasks list by status
     * @param {!string} id
     * @param {!boolean} includeFree
     * @param {enmTaskInfoStates} state
     * @param {enmTaskInfoTypes} ttype
     * @param {?function} filterCb
     * @return {{$list: Armap<cTaskInfo>, qty: number}}
     */
    tasksListByStatus(id, includeFree, state, ttype, filterCb) {
        if (id == undefined) {
            return {
                $list: [],
                qty: 0
            };
        }

        const root = { id: _dcNoGroup };
        const cache = {};

        const fullfillPath = v => {
            let p;
            const id = (p = v.groupId);
            const r = [];

            if (cache[p]) {
                return cache[p];
            }

            while (p != undefined) {
                const g = $scope[_bndProjectTasks].$item(p);
                r.push(g.id);
                p = g.groupId;
            }

            let i = r.length,
                d = root;

            while (--i > -1) {
                if (d.childs) {
                    if (!(r[i] in d.childs.$hash())) {
                        d.childs.$push(new cTaskInfo($scope[_bndProjectTasks].$item(r[i])));
                    }
                } else {
                    d.childs = Armap();
                    d.childs.$push(new cTaskInfo($scope[_bndProjectTasks].$item(r[i])));
                }

                d = d.childs.$item(r[i]);
            }

            return (cache[id] = d);
        };

        root.childs = new Armap();

        const $list = core_DO.tasksOfTheUser(
            id[0] || (id[0] == '' && id.length == 1 && 0),
            includeFree,
            id[1],
            state,
            undefined,
            filterCb
        ).$tasks;
        $list.forEach(v => {
            const p = fullfillPath(v);
            !p.childs && (p.childs = Armap());
            p.childs.$push(new cTaskInfo(v));
        });

        deepSort(root.childs);

        return {
            $list: root.childs,
            qty: $list.length
        };
    },

    /**
     * @param {!string} id
     * @return {Array<cTransitionInfo>}
     */
    taskTransitions(id) {
        if (!$scope[_bndCurrentProject]) {
            return [];
        }
        return [...$transitions.$keysByAggregateKey({ toId: id }), ...$transitions.$keysByAggregateKey({ fromId: id })];
    },

    /**
     * @param {!string} groupId
     * @param {boolean} map
     * @return {Array<string|cTaskInfo>}
     */
    groupTasks(groupId, map = false) {
        const $list = $scope[_bndProjectTasks].$keysByAggregateKey({ groupId });
        return (!map && $list) || $list.map(d => $scope[_bndProjectTasks].$item(d));
    },

    /**
     * @param {!string} id
     * @param {boolean=} includeFree
     * @param {string=} groupId
     * @param {string|boolean=} type
     * @return {cTaskInfo|boolean}
     */
    upperTaskOfTheUser(id, includeFree, groupId, type) {
        let found = false;

        [
            enmTaskInfoStates.Unblocked,
            enmTaskInfoStates.Started,
            enmTaskInfoStates.Blocked,
            enmTaskInfoStates.Completed
        ].forEach(s => {
            if (found) {
                return;
            }

            const key = { /*'projectId': $scope[_bndCurrentProject].id, 'groupId': groupId || _dcNoGroup, */ state: s };
            groupId && (key['groupId'] = groupId);

            id && (key['execs'] = (includeFree && [id, _dcNobodys]) || id);
            (type == undefined && (key['type'] = enmTaskInfoTypes.Task)) ||
                (typeof type === 'string' && (key['type'] = type));

            found = $scope[_bndProjectTasks]
                .$keysByAggregateKey(key)
                .sort(tasksSortFn)
                .first();
        });

        return found && $scope[_bndProjectTasks].$item(found);
    },

    /**
     * Return upper task
     * @param {string=} groupId
     * @return {cTaskInfo|boolean}
     */
    upperTask(groupId) {
        const list = $scope[_bndProjectTasks].$keysByAggregateKey({
            /*'projectId': $scope[_bndCurrentProject].id, */ groupId,
            type: enmTaskInfoTypes.Task
        });
        list.sort(tasksSortFn);
        return (list.length && list[0]) || false;
    },

    /**
     * @param {!string} id
     * @param {!cTaskInfo} data
     * @param {boolean} resetDefaults
     * @return {void}
     */
    updateTask(id, data, resetDefaults = false) {
        let flags = 0;

        const nd = new cTaskInfo($scope[_bndProjectTasks].$item(id));

        const t = new cTaskInfo(nd);

        const gids = [];

        if (!t) {
            return;
        }

        nd.$update(data, resetDefaults);

        !nd.id && (nd.id = id);

        if (nd.name && nd.name != t.name) {
            flags |= _dupNameChanged;
        }
        if (nd.state && nd.state != t.state) {
            flags |= _dupStateChanged;
            const gid = (t.type == enmTaskInfoTypes.Group && t.id) || (t.type == enmTaskInfoTypes.Task && t.groupId);
            gid && gids.push(gid);
        }
        if ((nd.groupId || nd.groupId === null) && nd.groupId != t.groupId) {
            flags |= _dupGroupChanged;
            $axis[createAxis(nd.projectId, nd.groupId, [])].add(nd.rowId, nd.colId, false);
        }
        if (nd.deadline != t.deadline && !(nd.deadline == undefined && t.deadline == 0)) {
            flags |= _dupDeadlineChanged;
            const gid = (t.type == enmTaskInfoTypes.Group && t.id) || (t.type == enmTaskInfoTypes.Task && t.groupId);
            gid && gids.push(gid);
        }
        if ((nd.colId * 1 && nd.colId * 1 != t.colId) || (nd.rowId * 1 && nd.rowId * 1 != t.rowId)) {
            flags |= _dupCoordChanged;
            const aid = createAxis(nd.projectId, nd.groupId, []);
            nd.x = $axis[aid].x.axisById(nd.colId);
            nd.y = $axis[aid].y.axisById(nd.rowId);
        }

        $scope[_bndProjectTasks].$push(nd);

        if (flags & _dupDeadlineChanged || flags & _dupStateChanged || flags & _dupGroupChanged) {
            updateGroupStats();
        }

        nd.type == enmTaskInfoTypes.Draft ? onDraftsUpdate.publish(id, flags) : onTasksUpdate.publish(id, flags);

        if (flags & _dupGroupChanged) {
            const links = $transitions
                .$keysByAggregateKey({ /*'projectId': $scope[_bndCurrentProject].id, */ fromId: id })
                .concat(
                    $transitions.$keysByAggregateKey({ /*'projectId': $scope[_bndCurrentProject].id, */ toId: id })
                );
            links.forEach(id => {
                core_DO.updateTransition(id, { groupId: nd.groupId || _dcNoGroup });
            });
        }

        (flags & _dupCoordChanged ||
            flags & _dupDeadlineChanged ||
            flags & _dupStateChanged ||
            flags & _dupGroupChanged) &&
            onTransitionsUpdate.publish();
    },

    /**
     * @param {!string} id
     * @param {!string} name
     * @return {void}
     */
    updateTaskName(id, name) {
        const t = $scope[_bndProjectTasks].$item(id);
        if (!t) {
            return;
        }
        t.name = name;
        onTasksUpdate.publish(id, _dupNameChanged);
    },

    /**
     * @param {!string} id
     * @param {cTaskInfo} data
     * @return {void}
     */
    changeTaskType(id, data) {
        let t = $scope[_bndProjectTasks].$item(id);
        if (t) {
            const ot = t.type;
            t = new cTaskInfo(t).$update(data);
            $scope[_bndProjectTasks].$push(t);
            if (t.type == enmTaskInfoTypes.Group) {
                const aid = createAxis(t.projectId, t.id, []);
                $axis[aid].update(true);
                onTransitionsUpdate.publish();
            }

            updateGroupStats();

            switch (ot) {
                case enmTaskInfoTypes.Draft:
                    onDraftsUpdate.debouncedPublish();
                case enmTaskInfoTypes.Group:
                    onTransitionsUpdate.publish();
                // var aid = createAxis(ot['projectId'], ot['id'], []);
                // $axis[aid].remove && $axis[aid].remove();
                case enmTaskInfoTypes.Task:
                    onTasksUpdate.publish(id, _dupDataChanged);
                    break;
                // case enmTaskInfoTypes.Swimline:
            }
        }
    },

    /**
     * @param {cTaskInfo} data
     * @return {void}
     */
    changeTaskState(data) {
        let ot = $scope[_bndProjectTasks].$item(data.id);
        if (!ot) {
            return;
        }
        const os = ot?.state;
        const ns = data.state;

        if (os && os != ns) {
            ot = new cTaskInfo(ot);
            ot.state = data.state;
            $scope[_bndProjectTasks].$push(ot);
        }
        updateGroupStats();
        onTasksUpdate.publish(data.id, _dupStateChanged);
    },

    /**
     * Update task timing info (startDate/deadline/etc)
     * @param  {string} id
     * @param  {Object<string, *>} data
     * @return {void}
     */
    updateTaskTiming(id, data) {
        const task = core_DO.task(id);
        if (task) {
            task.$update(data);
            onTasksUpdate.publish(id, _dupDeadlineChanged);
            updateGroupStats();
        }
    },

    /**
     * Update task timing values
     * @param  {!string} id
     * @param  {Object<string, *>} values
     * @return {void}
     */
    updateTaskValues(id, values) {
        const task = core_DO.task(id);
        if (task) {
            task.values = values;
            onTasksUpdate.publish(id, _dupDataChanged);
        }
    },

    /**
     * @param {cTaskInfo} data
     * @param {boolean} skipAnnounce
     * @param {?Array<string>} aids
     * @return {void}
     */
    addTask(data, skipAnnounce = false, aids = null) {
        /** @type {cTaskInfo} */
        const ot = $scope[_bndProjectTasks].$item(data.id);
        /** @type {cTaskInfo} */
        const d = $classes.$check(_cTaskInfo, data);

        $scope[_bndProjectTasks].$push(d);

        const aid = createAxis(d.projectId, d.groupId, aids);

        d.type !== enmTaskInfoTypes.Draft && $axis[aid].add(d.rowId, d.colId, false);
        d.type == enmTaskInfoTypes.Group && createAxis(d.projectId, d.id, []);

        updateGroupStats();

        if (!skipAnnounce) {
            onTasksUpdate.publish(d.id, _dupCreated);
            if ((ot && ot.type == enmTaskInfoTypes.Draft) || d.type == enmTaskInfoTypes.Draft) {
                onDraftsUpdate.debouncedPublish();
            } else {
                onTransitionsUpdate.publish();
            }
        }
    },

    /**
     * @param {!string} id
     * @param {!string} uid
     * @return {void}
     */
    addExecutor(id, uid) {
        const d = new cTaskInfo($scope[_bndProjectTasks].$item(id));

        $scope[_bndProjectTasks].$remove(id);

        d.execs.push(uid);
        d.execs = d.execs.unique();
        delete d._execsHash;

        $scope[_bndProjectTasks].$push(d);
        onTasksUpdate.publish(id);
    },

    /**
     * @param {!string} id
     * @param {!string} tid
     * @return {void}
     */
    assignTeam(id, tid) {
        /** @type {cTaskInfo} */
        const d = cTaskInfo($scope[_bndProjectTasks].$item(id));

        $scope[_bndProjectTasks].$remove(id);

        delete d._execsHash;

        d.teamId = tid;
        // imgs.tasks.add(d.execsHash(), d.execIds());

        $scope[_bndProjectTasks].$push(d);
        onTasksUpdate.publish(id);
    },

    /**
     * @param {!string} id
     * @param {!string} uid
     * @return {void}
     */
    removeExecutor(id, uid) {
        /** @type {cTaskInfo} */
        const d = new cTaskInfo($scope[_bndProjectTasks].$item(id));
        if (!d) {
            return;
        }

        $scope[_bndProjectTasks].$remove(id);

        delete d._execsHash;

        d.execs = d.execs.remove(uid).unique();

        $scope[_bndProjectTasks].$push(d);
        onTasksUpdate.publish(id);
    },

    /**
     * @param {!string} id
     * @param {boolean} silent
     * @return {void}
     */
    removeTask(id, silent = false) {
        removedTasksAndTransitions[id] = true;
        $scope[_bndProjectTasks].$remove(id);
        onTransitionsUpdate.suspend();
        core_DO.taskTransitions(id).forEach(id => {
            core_DO.removeTransition(id);
            removedTasksAndTransitions[id] = true;
        });
        onTransitionsUpdate.resume();
        updateGroupStats();
        !silent && onTasksUpdate.publish(id, _dupRemoved);
    },

    /**
     * @return {Array.<cTaskInfo>}
     */
    drafts() {
        return (
            ($scope[_bndCurrentProject] &&
                $scope[_bndProjectTasks]
                    .$valuesByAggregateKeys({
                        /*'projectId': $scope[_bndCurrentProject].id, */ type: enmTaskInfoTypes.Draft
                    })
                    .filter(d => d.creatorId == $scope[_bndCurrentWorkspaceUser].id)
                    .sortBy(d => d.rowId * 1, true)) ||
            []
        );
    },

    /**
     * @param {!string} id
     * @return {boolean}
     */
    isDraft(id) {
        return $scope[_bndProjectTasks].$item(id).type == enmTaskInfoTypes.Draft;
    },

    /**
     * @param {Array<cHistoryEntryInfo>}
     * @return {void}
     */
    history(coms) {
        $history.$concat(coms);
        onHistoryUpdate.debouncedPublish();
    },

    /**
     * @param {string} id
     * @return {void}
     */
    removeComment(id) {
        const item = $history.$item(id);
        if (item) {
            $history.$remove(id);
            if (
                $scope[_bndCurrentWorkspace] &&
                $scope[_bndCurrentWorkspace].id == item.workspaceId &&
                $scope[_bndCurrentProject] &&
                $scope[_bndCurrentProject].id == item.projectId &&
                ctask &&
                ctask[0] == item.objectId
            ) {
                onHistoryUpdate.debouncedPublish();
            }
        }
    },

    /**
     * @param {!string} ws
     * @param {string=} objId
     * @param {enmHistoryEventType=} eType
     * @param {enmStreamTypes=} stream
     * @return {Array<cHistoryEntryInfo>}
     */
    getHistory(ws, objId = undefined, eType = undefined, stream = undefined) {
        const key = { workspaceId: $scope[_bndCurrentWorkspace].id };

        eType !== undefined && (key.event = eType);
        stream !== undefined && (key.stream = stream);
        objId !== undefined && (key.objectId = objId);

        return $history
            .$valuesByAggregateKeys(key)
            .map(
                /** @param {cHistoryEntryInfo} d */ d => {
                    const u = core_DO.user(d['subjectId']);
                    return {
                        ...new cHistoryEntryInfo(d),
                        ...{
                            ui:
                                (u && {
                                    firstName: u.firstName,
                                    lastName: u.lastName,
                                    id: u.id
                                }) ||
                                {},
                            content:
                                (typeof d.content == 'string' &&
                                    linkifyStr(/*core_utils.string.proc_entity(*/ d.content /*)*/)) ||
                                d.content
                        }
                    };
                }
            )
            .sortBy(/** @param {cHistoryEntryInfo} d */ d => d.eventTime, true);
    },

    /**
     * @param {!string} id
     * @return {void}
     */
    setHistoryEnd(id) {
        endhistory[id] = true;
    },

    /**
     * @param {!string} id
     * @return {boolean}
     */
    isEndOfHistory(id) {
        return !!endhistory[id];
    },

    /**
     * @param {!string} id
     * @param {} data
     * @return {void}
     */
    projectStats(id, data) {
        $scope[_bndProjectStats].$push(data, id);
        onProjectStatsUpdate.debouncedPublish(id);
    },

    /**
     * @param {!string} id
     * @return {Object}
     */
    getProjectStats(id) {
        return $scope[_bndProjectStats].$item(id) || {};
    },
    /**
     * @param {!string} groupId
     * @return {Array<cTransitionInfo>}
     */
    transitions(groupId) {
        return $transitions.$valuesByAggregateKeys({
            projectId: $scope[_bndCurrentProject].id,
            groupId: groupId || _dcNoGroup
        });
    },

    /**
     * @param {!string}
     * @return {cTransitionInfo}
     */
    transition(id) {
        return $transitions.$item(id);
    },

    /**
     * @param {cTransitionInfo} data
     * @return {void}
     */
    addTransition(data) {
        if (data.projectId != ($scope[_bndCurrentProject] && $scope[_bndCurrentProject].id)) {
            return;
        }
        /** @type {cTransitionInfo} */
        const p = $classes.$check(_cTransitionInfo, data);
        const ti = $scope[_bndProjectTasks].$item(p.fromId || p.toId);

        if (ti) {
            p.groupId = ti.groupId;
            $transitions.$push(p);
            onTransitionsUpdate.publish();
        }
    },

    /**
     * @param {!string} id
     * @return {void}
     */
    removeTransition(id) {
        removedTasksAndTransitions[id] = true;
        $transitions.$remove(id);
        onTransitionsUpdate.publish();
    },

    /**
     * @param {!string} id
     * @param {{groupId: string | undefined, fromId: string, toId: string}} data
     * @return {cTransitionInfo}
     */
    updateTransition(id, data) {
        const l = $transitions.$item(id);
        let p;
        if (l) {
            p = new cTransitionInfo(l).$update(data);
            !data.groupId && (p.groupId = $scope[_bndProjectTasks].$item(p.fromId || p.toId).groupId);
            $transitions.$push(p);
            onTransitionsUpdate.publish();
        }

        return p || l;
    },

    /**
     * @param {!string} tid
     * @param {string=} pr
     * @param {string=} ws
     * @return {Array<cFileInfo>}
     */
    files(tid, pr = undefined, ws = undefined) {
        const key = {
            projectId: pr || ($scope[_bndCurrentProject] && $scope[_bndCurrentProject].id),
            workspaceId: ws || ($scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id)
        };

        tid && (key.objectId = tid);

        return $files.$valuesByAggregateKeys(key).sortBy(v => v.timeUploaded);
    },

    /**
     * @param {Array<cFileInfo>} data
     * @return {void}
     */
    addFile(data) {
        data.forEach(d => {
            $files.$push($classes.$check(_cFileInfo, d));
        });

        onFilesUpdate.debouncedPublish();
    },

    /**
     * @param {!string} id
     * @param {string=} ws
     * @param {string=} pr
     * @return {void}
     */
    removeFile(id, ws = undefined, pr = undefined) {
        $files.$remove(id);
        onFilesUpdate.debouncedPublish();
    },

    /**
     * @return {Array<cUserAlertEntryInfo>}
     */
    notifications() {
        return $notifications.sortBy(d => ((d.read == false && 'z') || 'a') + d.eventTime, true);
    },

    /**
     * @param {cUserAlertEntryInfo} data
     * @return {void}
     */
    addNotification(data) {
        // fabricated read flag
        // data.read = false;

        $notifications.$push($classes.$check(_cUserAlertEntryInfo, data));
        onNotificationsUpdate.debouncedPublish();
    },

    /**
     * @param {!number} start
     * @param {!number} qty
     * @return {void}
     */
    markNotificationsAsRead(start, qty) {
        do {
            $notifications[start + p] && ($notifications[start + p].read_ = true);
        } while (++p < qty);

        onNotificationsUpdate.debouncedPublish();
    },

    /**
     * Adds clipboard entry
     * @param {cClipboardEntryInfo} obj
     * @return {void}
     */
    addClipboardEntry(obj) {
        actualClipboardEntry = obj.id;
        clipboard[obj.id] = obj.info;
        // @ts-ignore
        clipboard[obj.id].workspaceId = obj.workspaceId;
        onClipboardUpdate.debouncedPublish();
    },

    /**
     * Removes clipboard entry
     * @param {Array<string>} ids
     * @return {void}
     */
    clearClipboardEntry(ids) {
        ids.forEach(id => {
            delete clipboard[id];
            delete clipboardMeta[id];
        });
        onClipboardUpdate.debouncedPublish();
    },

    /**
     * Returns clipboard entries for current workspace or specified entry by ID
     * @param {string=} id
     * @return {cSnapshotStats|Array<cSnapshotStats>}
     */
    clipboard(id) {
        return id
            ? clipboard[id]
            : Object.values(clipboard).filter(
                  /** @param {cSnapshotStats} v */
                  v =>
                      $scope[_bndCurrentWorkspace] &&
                      // @ts-ignore
                      v.workspaceId == $scope[_bndCurrentWorkspace].id
              );
    },
    /**
     * Sets/Returns clipboard meta info
     * @param {string} id
     * @param {string=} data
     * @return {string}
     */
    clipboardMeta(id, data = undefined) {
        if (data) {
            clipboardMeta[id] = data;
            onClipboardUpdate.debouncedPublish();
        }
        return clipboardMeta[id];
    },

    /**
     * Retrieve actual clipboard
     * @return {{id: string, info: cSnapshotStats, meta: string}|null}
     */
    actualClipboard() {
        // @ts-ignore
        if (actualClipboardEntry && clipboard[actualClipboardEntry].workspaceId == $scope[_bndCurrentWorkspace].id) {
            return {
                id: actualClipboardEntry,
                info: clipboard[actualClipboardEntry],
                meta: clipboardMeta[actualClipboardEntry]
            };
        } else {
            return null;
        }
    },

    /**
     * Indicate is task or transition been removed so should not be recreated and/or updated by notification
     * @param {string} id
     * @return {Boolean}
     */
    isRemoved(id) {
        return !!removedTasksAndTransitions[id];
    },

    /**
     * @param {string} prid
     * @param {boolean=} authorized
     * @returns {boolean|void}
     */
    projectAuthorizedAtGoogle(prid, authorized) {
        if (authorized === undefined) {
            return $projectAuthorizedAtGoogle[prid || $scope[_bndCurrentProject]?.id];
        } else {
            $projectAuthorizedAtGoogle[prid || $scope[_bndCurrentProject]?.id] = authorized;
        }
    },

    /**
     * @param {string} prid
     * @param {string=} token
     * @param {string|void}
     */
    googleAuthToken(prid, token = undefined) {
        if (token === undefined) {
            return $googleAuthToken[prid || $scope[_bndCurrentProject]?.id];
        } else {
            $googleAuthToken[prid || $scope[_bndCurrentProject]?.id] = token;
        }
    },

    // core_DO.setGoogleCalendars = (prid, calId) => {};

    /**
     * @param {string} prid
     * @param {string=} calId
     * @return {string|undefined}
     */
    googleCalendars(prid, calId = undefined) {
        if (calId === undefined) {
            return $googleCalendars[prid || $scope[_bndCurrentProject]?.id];
        } else {
            $googleCalendars[prid || $scope[_bndCurrentProject]?.id] = calId == 'null' ? undefined : calId;
        }
    }
};
