/**
 * Class to implement enumerable data type
 * @return {Enumerate}
 */
class Enumerate {
    $hashed = {};
    $values = [];

    /**
     * Convert values into hashed object to speedup checks
     */
    $gen() {
        const values = [];
        const hashed = {};

        Object.keys(this).forEach(i => {
            if (!this.hasOwnProperty(i) || this[i] instanceof Function || /^\$/.test(i)) {
                return;
            }
            hashed[this[i]] = values.length;
            values.push(this[i]);
        });

        this.$values = values;
        this.$hashed = hashed;
    }
    /**
     * create serialized string
     * @param {string|null} delimiter
     * @return {string}
     */
    toString(delimiter) {
        return this.$values.join(delimiter || '|');
    }
    valueOf(delimiter) {
        return this.toString(delimiter);
    }
    /**
     * check if value exists
     * @param {string} value
     * @return {boolean}
     */
    has(value) {
        // return this.values().indexOf(value) > -1;
        return this.$hashed[value] != undefined;
    }

    values() {
        return this.$values;
    }

    /**
     * convert enumerate to integer
     * @param {string} value
     * @return {number}
     */
    indexOf(value) {
        // return this.values().indexOf(value);
        var v = this.$hashed[value];
        return v || -1;
    }
}
