/**
 * internal communication object
 * @author Jacky Shikerya <shikerya@me.com>
 */

var uid = 0;
const defOpts = {
    async: true,
    broadcast: true,
    callback: null
};

class Mediator {
    options_;
    events_;

    constructor(options) {
        this.options_ = { ...defOpts, ...(options || {}) };
        this.events_ = {};
    }

    subscribe(evType, callback) {
        switch (true) {
            case Object.isString(evType) && /,/.test(evType):
                const s = evType.split(',');
                s.forEach(v => this.subscribe(v, callback));
                return;
            case Object.isString(evType):
                !this.events_[evType] && (this.events_[evType] = {});
                this.events_[evType][++uid] = callback;
                callback.__lid = uid;
                break;
            case Object.isArray(evType):
                if (arguments.length == 1) {
                    evType.forEach(v => this.subscribe(v[0], v[1]));
                } else {
                    evType.forEach(v => this.subscribe(v, callback));
                }
                break;
            case Object.isObject(evType):
                Object.keys(evType).forEach(id => this.subscribe(id, evType[id]));
                break;
        }
    }

    unsubscribe(evType, callback) {
        switch (true) {
            case Object.isString(evType) && /,/.test(evType):
                const s = evType.split(',');
                s.forEach(id => this.unsubscribe(id, callback));
                return;
            case Object.isString(evType):
                var e = this.events_[evType];
                if (e) {
                    if (callback && callback.__lid != undefined) {
                        delete e[callback.__lid];
                    } else if (callback) {
                        Object.keys(e).forEach(p => {
                            if (p == callback) {
                                delete e[i];
                            }
                        });
                    } else {
                        delete this.events_[evType];
                    }
                    if (Object.isEmpty(e)) {
                        delete this.events_[evType];
                    }
                }
                break;
            case Object.isArray(evType):
                if (arguments.length == 1) {
                    evType.forEach(v => this.unsubscribe(v[0], v[1]));
                } else {
                    evType.forEach(v => this.unsubscribe(v, callback));
                }
                break;
            case Object.isObject(evType):
                Object.keys(evType).forEach(id => this.unsubscribe(id, evType[id]));
                break;
        }
    }

    announce(evType, args, opts, publishedBy) {
        // DEBUG && console.log('announcing:', JSON.stringify(evType), args);

        let p, _opts;

        if (Object.isFunction(opts)) {
            _opts = this.options_;
            if (args instanceof Array) {
                args.push(opts);
            } else {
                args = [opts];
            }
        } else {
            _opts = { ...this.options_, ...(opts || {}) };
        }

        if ((p = this.events_[evType]) != undefined) {
            const res = Object.keys(p)
                .map(id => p[id].apply(null, args))
                .compact();

            switch (res.length) {
                case 0:
                    return undefined;
                case 1:
                    return res[0];
                default:
                    return res;
            }
        }
    }
}
$classes.Mediator = Mediator;
