const /** @const */ _CONF_BASEURL = `${appConfig.config.api.replace(/\/$/, '')}/client`;
const /** @const */ _CONF_SHARE = appConfig.config.viewshare;
const /** @const */ _CONF_SHARE_DATA = `${appConfig.config.shares}s/`;

const _CONF_SSEURL = `${
    /^localhost/i.test(window.location.host) ? 'http://localhost:8500' : '@host.sse@'
}/client/user/stream?sessionKey=`;
const _CONF_SOCKET = appConfig.config.socket;
//(/^localhost/i.test(window.location.host) ? 'http://localhost' : '@host.sse@') + ':' + (/https/i.test(location.protocol) && '@host.sse.https.port@' || '@host.sse.http.port@') +'/client/socket');

// var /** @const */ _CONF_ROOTURL = CSL_HOST_APP; //'@host.app@';
const /** @const */ _CONF_APPROOTURL = '/app';
const /** @const */ _CONF_VISITORS_ROOTURL = '/guest';
const /** @const */ _CONF_AV_HOSTER = appConfig.config.files.replace(/\/$/, ''); //'@host.images@';
const /** @const */ _CONF_IMAGE_PATH = `${__DEV__ ? '' : '../../s'}/images/v2.1`;
const /** @const */ _CONF_GOOGLE_REGISTER = `${_CONF_BASEURL}/g/register`;
const /** @const */ _CONF_GOOGLE_LOGIN = `${_CONF_BASEURL}/g/login`;
// var /** @const */ _CONF_HELP_SITE = CSL_HOST_HELP; //'@host.help@';
const /** @const */ _CONF_PUBLIC_RES = appConfig.config.files.replace(/\/$/, ''); //'@host.public@';
const /** @const */ _CONF_EXPORT_PATH = `${_CONF_BASEURL}/export/convert/{format}/form/save/{session}`;
// var /** @const */ _CODE_PATH = __DEV__ ? '/development/js/vendor/svg2pdf' : '@path.assets@';
// var /** @const */ _CONF_WEBFONT = CSL_WEBFONT; //'@host.webfont@';
const /** @const */ _fpUploadPath = `${_CONF_BASEURL}/files/upload`;
// var /** @const */ _CONF_EMBED_STORY_URL = CSL_EMBED_STORY_URL; //'@embed.story.url@';
const /** @const */ _avUploadUrl = `${_CONF_BASEURL}/files/picture/upload`;

/**
 * URL map
 * @namespace
 */
const _UM = {
    [_umLogin]: {
        handle: 'login',
        pcre: /^(login|\/)$/
    },
    [_umSignup]: {
        handle: 'signup',
        pcre: /^(signup)$/
    },
    [_umForgotPassword]: {
        handle: 'forgot-password',
        pcre: /^(forgot\-password)$/
    },
    [_umReRequestVerification]: {
        handle: 're-request-verification',
        pcre: /^(re-request-verification)$/
    },
    [_umConfirmEmail]: {
        handle: 'confirm-email',
        pcre: /^(confirm\-email)(?:!|:)(.*)$/
    },
    [_umInvite]: {
        handle: 'invite',
        pcre: /^(invite)$/
    },
    [_umRecoverPassword]: {
        handle: 'recover-password',
        pcre: /^(recover\-password)(?:!|:)(.*)$/
    },
    [_umNotifications]: {
        pcre: /^(managenotifications):([\w\-]+)$/
    },

    [_umGoogleRegister]: {
        pcre: /^(gregister)\-(after|error)(?:!|:)(.*)$/
    },
    [_umGoogleLogin]: {
        pcre: /^(glogin)\-(after|register|error)((?:!|:)(.*))?$/
    },
    [_umGoogleAuth]: {
        pcre: /^gauth\-(success|error)$/
    },
    [_umFeatureAuth]: {
        pcre: /^auth-(success|error)$/
    },

    [_umWorkspaces]: {
        handle: '',
        pcre: /^$/,
        make: __USE_HASH ? '#' : ''
    },
    [_umManageBillingWorkspaces]: {
        handle: _pidManageBillingWorkspaces,
        pcre: /^(manage\-billing)(?:\/(manage|reactivate))?(?::([\w\d\-]+))?$/,
        make(obj, data) {
            return ['{uri}', (obj['mode'] && `/${obj['mode']}`) || '', (obj['wsId'] && ':{wsId}') || ''].join('');
        }
    },
    [_umWorkspace]: {
        handle: _pidWorkspace,
        //pcre: /^(workspace):([\w\d\-]+)\/?(people|projects|overview)?(?::([\w\d\-]+))?$/,
        pcre: /^(workspace):([\w\d\-]+)\/?(people|projects|overview)?(?::([\w\d\-]+))?(\/page:(\d+))?$/,
        make(obj, data) {
            switch (obj['link']) {
                case 'projects':
                    obj['link'] = '/projects';
                    obj['archived'] && (obj['link'] += ':archived');
                    obj['page'] && (obj['link'] += `/page:${obj['page']}`);
                    break;
                case 'people':
                    obj['link'] = `/people:${obj['user'] ||
                        ($scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id) ||
                        ''}`;
                    break;
                case 'overview':
                    obj['link'] = '/overview';
                    if (obj['tuser']) {
                        obj['link'] += `:${obj['tuser']}`;
                    }
                    break;
                default:
                    obj['link'] = '';
                    break;
            }
            return ['{uri}:', (obj['wsId'] && '{wsId}') || '{CWS}', '{link}'].join('');
        }
    },

    [_umProject]: {
        handle: _pidProject,
        pcre: /^(project):([\w\d\-]+)\/?(?:m:(e|t|f|u|l))?\/?(?:u:([\w\d\-:]+))?\/?(?:g:([\w\d\-]+))?\/?(?:id:([\w\d\-,]+))?$/,
        make(obj, data) {
            return [
                '{uri}:',
                (obj['prId'] && '{prId}') || '{CPR}',
                (obj['mode'] && '/m:{mode}') || '',
                (obj['filter'] && '/u:{filter}') || '',
                (obj['gid'] && '/g:{gid}') || '',
                (obj['tid'] && '/id:{tid}') || ''
            ].join('');
        }
    }
};

if (typeof window['Vue'] != 'undefined') {
    Vue.config['delimiters'] = ['[', ']'];
    Vue.config.debug = true;
}
