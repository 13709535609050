var skipPoolCheck = false;

function AbstractDM() {}

(function(p) {
    var handlers_ = {};
    var nhandlers_ = {};
    var subscribeIssueFlag;

    p.init = function() {};

    function data2Class(rid, data) {
        return (
            (data instanceof $classes[_cAbstractMessage] && data) ||
            ($classes[rid.sendClass] && new $classes[rid.sendClass](data)) ||
            data.toString()
        );
    }

    function response2Class(data, def) {
        return new $classes[(data && data.error && _cErrorMessage) || def.expectClass](data);
    }

    (__USEAJAX &&
        // use regular AJAX & SSE
        (function() {
            p.handleRequest = function(rid, data) {
                var so = data2Class(rid, data),
                    self = this;

                if (!so) throw new Error('::DM::handleRequest - no such message type defined', rid.sendClass);

                DEBUG == 3 && console.info('::DM::handleRequest', rid, so);

                return $.ajax({
                    url: [_CONF_BASEURL, rid.uri].join('/'),
                    data: data + '',
                    dataType: 'json',
                    type: 'POST',
                    contentType: 'application/json'
                });
            };

            p.processError = function(ec) {
                // TODO: implement error handling
                debugger;
                console.log('XHR error:', arguments);
            };

            return true;
        })()) ||
        // use SockJS to communicate with server
        (function() {
            var sockjs,
                pool = {},
                pid = 0,
                watchDog,
                lid = 0,
                sid = null,
                wd,
                isOnline = true,
                offTimer;

            wd = setInterval(function() {
                if (sockjs && sockjs.readyState == SockJS.OPEN) {
                    var now = new Date().getTime();
                    Object.keys(pool).forEach(function(i) {
                        var v = pool[i];
                        if (v) {
                            switch (v.state) {
                                case _PackageStatusAwaiting:
                                    if (now - v.stamp > _gcPackageResponseTimeout) {
                                        pool[i].state = _PackageStatusInRetry;
                                    }
                                    break;
                                case _PackageStatusDone:
                                    pool[i].$dfr = null;
                                    delete pool[i];
                                    break;
                            }
                        } else {
                            delete pool[i];
                        }
                    });
                }
                if (!isOnline && sockjs && sockjs.readyState == SockJS.OPEN) {
                    DEBUG && console.warn('network interface goes online');
                    isOnline = true;
                    // __USE_ROLLBAR && Rollbar.debug('::networkStatus goes online', new Date().getTime - offTimer);
                    core.moses.announce(_evNetworkStatusChange, _nsOnline);
                }
                if (isOnline && ((sockjs && sockjs.readyState != SockJS.OPEN) || sockjs == null)) {
                    DEBUG && console.warn('network interface goes offline');
                    offTimer = new Date().getTime();
                    // __USE_ROLLBAR && Rollbar.debug('::networkStatus goes offline');
                    isOnline = false;
                    core.moses.announce(_evNetworkStatusChange, _nsOffline);
                }
            }, _gcWatchDogTimeout);

            function subscribe() {
                if (!sid) {
                    DEBUG && console.info('::DM->subscribe', 'session does not exists yet, no subscribe either');
                    return;
                }
                if (core.visitorsInterface) {
                    DEBUG && console.info('::DM->subscribe', 'visitors interface, no subscribe available');
                    return;
                }
                var s = [_gcSubscribePrefix, lid, sid].join(' ');
                DEBUG && console.info('::DM->subscribe', s);
                if (sockjs && sockjs.readyState == SockJS.OPEN) {
                    try {
                        sockjs.send(s);
                        if (__USE_ROLLBAR && subscribeIssueFlag) {
                            subscribeIssueFlag = null;
                            // __USE_ROLLBAR && Rollbar.info('::DM::subscribe channel connection is restored and session subscribed');
                        }
                    } catch (e) {
                        DEBUG &&
                            console.warn('::DM::subscribe', 'error while sending data, restart for channel issued', e);
                        // __USE_ROLLBAR && Rollbar.warning('::DM::subscribe error while sending data, restart for channel issued', e);
                        (sockjs && (sockjs.close(), sockjs['onclose']())) || start();
                    }
                } else {
                    DEBUG && console.error('::DM::subscribe channel is down. subscribe initiated');
                    // __USE_ROLLBAR && Rollbar.critical('::DM::subscribe channel is down but subscribe initiated. restart for channel issued');
                    subscribeIssueFlag = true;
                    (sockjs && (sockjs.close(), sockjs['onclose']())) || start();
                }
            }

            function sendQueued() {
                if (sockjs && sockjs.readyState == SockJS.OPEN) {
                    Object.keys(pool).forEach(function(k) {
                        var v = pool[k];
                        if (v.state == _PackageStatusQueued || v.state == _PackageStatusInRetry) {
                            var s = preparePkg(k);
                            DEBUG == 3 && console.warn('::DM->sendQueued', s, pool[k]);
                            try {
                                sockjs.send(s);
                            } catch (e) {
                                DEBUG &&
                                    console.warn(
                                        '::DM::sendQueued',
                                        'error while sending data, restart for channel issued',
                                        e
                                    );
                                // __USE_ROLLBAR && Rollbar.warning('::DM::sendQueued error while sending data, restart for channel issued', e);
                                (sockjs && (sockjs.close(), sockjs['onclose']())) || start();
                            }
                        }
                    });
                }
            }

            function processNotify(ntype, data) {
                var nh = nhandlers_[ntype];
                if (nh) {
                    var c = response2Class(data, nh),
                        res = undefined;

                    if (c && nh.nhandler) {
                        res = nh.nhandler.call(this, c);
                    }

                    switch (res) {
                        case false:
                            return;
                        case undefined:
                            return c;
                        default:
                            return res;
                    }
                } else {
                    return data;
                }
            }

            window.addEventListener('online', function() {
                DEBUG && console.warn('network interface goes online');
                core.moses.announce(_evNetworkStatusChange, _nsOnline);
                // __USE_ROLLBAR && Rollbar.debug('::networkStatus goes online', new Date().getTime - offTimer);
                setTimeout(function() {
                    start();
                }, 1000);
            });

            window.addEventListener('offline', function() {
                DEBUG && console.warn('network interface goes offline');
                core.moses.announce(_evNetworkStatusChange, _nsOffline);
                // __USE_ROLLBAR && Rollbar.debug('::networkStatus goes offline');
                p.close();
            });

            function start() {
                if (sockjs) {
                    sockjs = null;
                }

                sockjs = new SockJS(_CONF_SOCKET, null, { debug: !!DEBUG });

                sockjs['onopen'] = function(ev) {
                    DEBUG == 3 && console.info('::DM->start::open', 'socket channel open', ev);
                    sid && subscribe();
                    sendQueued();
                    core.sessionWatchDog && core.sessionWatchDog.start();
                };

                sockjs['onmessage'] = function(ev) {
                    DEBUG && console.info('::DM->start::message', '<<i>>', ev.data);
                    DEBUG == 3 && console.time('sockjs::onmessage');
                    var data,
                        s = ev.data.split(' '),
                        pid = s[1],
                        dataStr = s.slice(2).join(' ');

                    core.sessionWatchDog && core.sessionWatchDog.start();

                    switch (s[0]) {
                        case _gcNotificationPrefix:
                            try {
                                data = JSON.parse(dataStr);
                            } catch (e) {
                                DEBUG && console.error('::DM->start::message', 'package contain broken data');
                                __USE_ROLLBAR &&
                                    Rollbar.warning('::DM->start::message package contain broken data', dataStr);
                                return;
                            }

                            lid = data['sn'];

                            /** @type {cNotificationsEnvelope} */
                            var o = new $classes[_cNotificationsEnvelope](data);
                            var ntf = o.notifications;

                            if (core.timeOffset === null) {
                                core.timeOffset = Date.now() - o.time;
                                DEBUG && console.info('Local timeOffset', core.timeOffset);
                            }

                            DEBUG &&
                                __DEV__ &&
                                console.log('::DM->start::message <<i>>', s[0], s[1], __DEV__ ? o : JSON.stringify(o));

                            if (ntf) {
                                ntf.forEach(function(ntfi) {
                                    if (ntfi['targets'].length) {
                                        Object.keys(ntfi['targets']).forEach(function(i) {
                                            var v = ntfi['targets'][i];
                                            var res = processNotify(ntfi['type'], v);
                                            res && !res['id'] && (res = $.extend({ id: ntfi['targetIds'][i] }, res));
                                            res && core.moses.announce(ntfi['type'], res);
                                        });
                                    } else {
                                        var tmp;
                                        if (ntfi['values'] && !Object.isEmpty(ntfi['values'])) {
                                            if (!ntfi['values']['id']) {
                                                tmp = $.extend({ id: ntfi['targetIds'][0] }, ntfi['values']);
                                            } else {
                                                tmp = { id: ntfi['targetIds'][0], values: ntfi['values'] };
                                            }
                                        } else {
                                            tmp = ntfi['targetIds'];
                                        }
                                        core.moses.announce(ntfi['type'], tmp);
                                    }
                                });
                            }
                            break;
                        case _gcSubscribePrefix:
                            DEBUG == 3 &&
                                console.info('::DM->start::message', 'subscribe response, ignoring on this stage');
                            break;
                        default:
                            if (pid) {
                                var d = pool[pid];

                                if (!d) {
                                    DEBUG &&
                                        console.warn('::DM->state::message', 'socket got not registered package', s);
                                    // __USE_ROLLBAR && Rollbar.warning('::DM->start::message socket got not registered package', s);
                                    break;
                                }

                                switch (d.state) {
                                    case _PackageStatusQueued:
                                        if (d.id == _rSessionPing) {
                                            d.state = _PackageStatusDone;
                                            return;
                                        }
                                        DEBUG &&
                                            console.warn(
                                                '::DM->start::message',
                                                s[0],
                                                s[1],
                                                'package in Queued state but response retrieved, process anyway'
                                            );
                                    // __USE_ROLLBAR && Rollbar.warning('::DM->start::message package in Queued state but response retrieved, process anyway', s);
                                    case _PackageStatusInRetry:
                                        DEBUG &&
                                            console.info(
                                                '::DM->start::message',
                                                s[0],
                                                s[1],
                                                'got response for message in retry'
                                            );
                                    case _PackageStatusAwaiting:
                                        d.state = _PackageStatusProcessing;

                                        try {
                                            data = JSON.parse(dataStr);
                                        } catch (e) {
                                            DEBUG &&
                                                console.error(
                                                    '::DM->start::message package contain broken data',
                                                    s[0],
                                                    s[1]
                                                );
                                            __USE_ROLLBAR &&
                                                Rollbar.warning(
                                                    '::DM->start::message package contain broken data',
                                                    dataStr
                                                );
                                            d.q.rjct();
                                        }

                                        DEBUG &&
                                            __DEV__ &&
                                            console.log(
                                                '::DM->start::message <<i>>',
                                                s[0],
                                                s[1],
                                                __DEV__ ? data : JSON.stringify(data)
                                            );

                                        if (data['error']) {
                                            d.q.rjct(data);
                                        } else {
                                            d.q.rslv(data);
                                        }

                                        d.state = _PackageStatusDone;

                                        if (!sid) {
                                            // __USE_ROLLBAR && Rollbar.debug('::DM->start::message got package but no session is set, resubscribe');
                                            DEBUG &&
                                                console.debug(
                                                    '::DM->start::message got package but no session is set, resubscribe'
                                                );
                                            sid = data[_gcSESSION_FIELD];
                                            subscribe();
                                        }
                                        break;
                                    case _PackageStatusDone:
                                        DEBUG &&
                                            console.debug(
                                                '::DM->start::message',
                                                'got response for processed package. sounds like package was in retry mode?',
                                                s[0],
                                                s[1],
                                                d,
                                                __DEV__ ? data : JSON.stringify(data)
                                            );
                                        // __USE_ROLLBAR && Rollbar.debug('::DM->start::message', 'got response for processed package. sounds like package was in retry mode?', {'req':d, 'resp':data});
                                        break;
                                    default:
                                        DEBUG &&
                                            console.warn(
                                                '::DM->start::message',
                                                'unexpected package',
                                                d,
                                                s[0],
                                                s[1],
                                                __DEV__ ? data : JSON.stringify(data)
                                            );
                                        // __USE_ROLLBAR && Rollbar.warning('::DM->start::message unexpected package', {'req':d, 'resp':data});
                                        break;
                                }
                            }
                    }

                    sendQueued();
                    DEBUG == 3 && console.timeEnd('sockjs::onmessage');
                };

                sockjs['onclose'] = function() {
                    DEBUG == 3 && console.warn('::DM::->start::close', '!socket closed');
                    sockjs = sid = null;

                    if (wd == null) {
                        DEBUG == 3 && console.log('::DM->start::close', 'sounds like socket been asked to close');
                        return;
                    }

                    for (var i in pool) {
                        if ([_PackageStatusAwaiting, _PackageStatusInRetry].indexOf(pool[i].state) > -1) {
                            pool[i].state = _PackageStatusQueued;
                        }
                    }

                    core.sessionWatchDog && core.sessionWatchDog.suspend();

                    setTimeout(function() {
                        // DEBUG && console.info('::DM->start::close', '!socket restart forced');
                        start();
                    }, _gcSocketReconnectTimeout);
                };
            }

            function preparePkg(pid, rid, data, rslv, rjct) {
                var d;
                if (arguments.length == 1) {
                    d = pool[pid];
                    d.state = _PackageStatusAwaiting;
                    d.stamp = new Date().getTime();
                } else {
                    d = pool[pid] = {
                        id: rid.id,
                        uri: rid.uri,
                        msg: data + '',
                        stamp: new Date().getTime(),
                        state: _PackageStatusQueued,
                        pid: pid,
                        q: {
                            rslv: rslv,
                            rjct: rjct
                        }
                    };
                }
                return [d.uri, pid, d.msg].join(_gcSocketStringDelimiter);
            }

            p.handleRequest = function(rid, data) {
                return core.q(function(rslv, rjct) {
                    if (rid.id == _rSessionPing) {
                        for (var k in pool) {
                            if (pool[k].uri == rid.uri) {
                                DEBUG && console.info('::DM::handleRequest', 'ping request skipped', pool);
                                rslv({});
                                return;
                            }
                        }
                    }
                    var s = preparePkg(++pid, rid, data2Class(rid, data), rslv, rjct);
                    if (sockjs && sockjs.readyState == SockJS.OPEN) {
                        DEBUG && console.info('::DM::handleRequest', '<<o>>', s);
                        try {
                            sockjs.send(s);
                            pool[pid].state = _PackageStatusAwaiting;
                        } catch (e) {
                            DEBUG && console.warn('::DM::handleRequest', 'Error while sending package data', e);
                            // __USE_ROLLBAR && Rollbar.warning('::DM::handleRequest Error while sending package data', {'data': s, 'errorCatched': e});
                            (sockjs && (sockjs.close(), sockjs['onclose']())) || start();
                        }
                    } else {
                        DEBUG == 3 && console.warn('::DM::handleRequest', 'socket not ready, requesting to start');
                        start();
                    }
                });
            };

            p.stopWatchdog = function() {
                DEBUG == 3 && console.log('::DM::stopWatchdog');
                if (wd) {
                    clearInterval(wd);
                    wd = null;
                }
            };

            p.close = function() {
                this.stopWatchdog();
                DEBUG == 3 && console.debug('::DM::close');
                sockjs && sockjs.close();
                sockjs = null;
            };

            p.isPoolEmpty = function() {
                if (skipPoolCheck === true) {
                    return true;
                }
                for (var k in pool) {
                    if (pool[k].id != _rSessionPing && pool[k].state != _PackageStatusDone) {
                        return false;
                    }
                }
                return true;
            };
        })();

    p.registerHandler = function(id, uri, sendClass, expectClass, dmType, handler, tables) {
        switch (true) {
            /* many handlers as array
             *     ::registerHandler([
             *        [id, uri, sendClass, expectClass, dmType, handler, tables],
             *        [id, uri, sendClass, expectClass, dmType, handler, tables],
             *        ...
             *     ])
             */
            case id instanceof Array && arguments.length == 1:
                var self = this;
                id.forEach(function(v) {
                    self.registerHandler.apply(self, v);
                });
                return;
            /* many handlers as array with shared handler
             *     ::registerHandler([
             *            [id, uri, sendClass, expectClass, dmType, tables],
             *            [id, uri, sendClass, expectClass, dmType, tables],
             *            ...
             *         ], {
             *            ... handler object
             *        }
             *    )
             */
            case id instanceof Array && arguments.length == 2 && arguments[1] instanceof Object:
                var self = this,
                    h = arguments[1];

                id.forEach(function(v) {
                    v.splice(5, 0, h);
                    self.registerHandler.apply(self, v);
                });
                return;
        }

        DEBUG &&
            console.assert(
                id || uri || sendClass || expectClass,
                '::DM::registerHandler Cannot register handler for undefined'
            );

        handlers_[id] = {
            id: id,
            uri: uri,
            sendClass: sendClass,
            expectClass: expectClass,
            handler: handler,
            tables: tables,
            dmType: dmType
        };

        // DEBUG == 3 && console.log('::DM::registerHandler', handlers_[id]);

        core.moses.subscribe(
            id,
            function(data, opts) {
                DEBUG && console.info('::DM::pickUpHandler', id, data, opts);
                var callback,
                    self = this;

                switch (true) {
                    case Object.isFunction(data) && opts == undefined:
                        callback = data;
                        data = null;
                        break;
                    case opts && Object.isFunction(opts):
                        callback = opts;
                        break;
                    case opts && Object.isObject(opts) && opts.cb && Object.isFunction(opts.cb):
                        console.error('callback is depricated!');
                        callback = opts.cb;
                        break;
                }

                var h = this.getHandler_(id);

                return this.handleRequest(h, data)
                    .then(function(r) {
                        return response2Class(r, h);
                    })
                    .then(function(ro) {
                        core.moses.announce(h.expectClass, ro);
                        if (callback) {
                            callback.call(null, ro);
                        }
                        return ro;
                    });
                // .catch(function (r) {
                //     debugger;
                //     DEBUG && console.error(JSON.stringify(arguments));
                //     throw
                // });
            }.bind(this)
        );
    };

    p.registerNHandler = function(id, expectClass, nhandler) {
        switch (true) {
            case id instanceof Array && arguments.length == 2 && expectClass instanceof Function:
                for (var i in id) {
                    this.registerNHandler.apply(this, $.merge(id[i], [expectClass]));
                }
                break;
            case id instanceof Array:
                for (var i in id) {
                    this.registerNHandler.apply(this, id[i]);
                }
                break;
            default:
                nhandlers_[id] = {
                    expectClass: expectClass,
                    nhandler: nhandler
                };
        }
    };

    p.getHandler_ = function(id) {
        return handlers_[id];
    };

    p.getHandlers_ = function() {
        return handlers_;
    };

    p.killUserSession = function() {
        skipPoolCheck = true;
    };
})(AbstractDM.prototype);

core.DM = new AbstractDM();
