/**
 * DOM element events default handler
 * @author Jacky Shikerya
 */
function mosesEventHandler(event) {
    const $el = $(this);
    let ev = $el.data('event') || $el.data('moses');
    let data;
    let m;
    let obj;

    if ($el.hasClass('disabled') || $el.hasClass(_clsDisabled)) {
        return false;
    }

    if (!ev && $el[0].tagName == 'BUTTON') {
        ev = $el.parents('form').data('event');
    }

    data = $el.data('value');
    DEBUG && console.log('moses click', ev, data, event);

    switch (true) {
        case data instanceof $classes[_cAbstractMessage]:
            core.moses.announce(obj);
            break;
        default:
            core.moses.announce(ev, (data != undefined && data) || this, data !== undefined ? undefined : $el.data());
            break;
    }

    // event.stopPropagation();
    // event.stopImmidiatePropogation();
    return false;
}

function setCaretPosition(elem, caretPos) {
    if (elem.createTextRange) {
        const range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
    } else {
        if (elem.selectionStart) {
            elem.focus();
            elem.setSelectionRange(caretPos, caretPos);
        } else {
            elem.focus();
        }
    }
}

function getCursorPosition(input) {
    if ('selectionStart' in input) {
        // Standard-compliant browsers
        let r = false;
        try {
            r = input.selectionStart;
        } catch (e) {}
        return r;
    } else if (document.selection) {
        // IE
        input.focus();
        const sel = document.selection.createRange();
        const selLen = document.selection.createRange().text.length;
        sel.moveStart('character', -input.value.length);
        return sel.text.length - selLen;
    }
}

function getSelectionFromEl(input) {
    if ('selectionStart' in input) {
        // Standard-compliant browsers
        const r = [];
        try {
            r.push(input.selectionStart, input.selectionEnd);
        } catch (e) {
            r.push(0, 0);
        }
        return r;
    } else if (document.selection) {
        // IE
        input.focus();
        const sel = document.selection.createRange();
        const selLen = document.selection.createRange().text.length;
        sel.moveStart('character', -input.value.length);
        return sel.text.length - selLen;
    }
}

$(document)
    .on(
        'click',
        `.${_gcMosesWatchClass}:not(select,form), form.${_gcMosesWatchClass} button[data-role]:not([type="submit"])`,
        mosesEventHandler
    )
    .on('dblclick', `.${_gcMosesWatchClass}:not(select,form)`, mosesEventHandler)
    .on('change', `select.${_gcMosesWatchClass}`, mosesEventHandler)
    .on('submit', `form.${_gcMosesWatchClass}`, mosesEventHandler)
    .on('click', '[data-href], [href]:not(a,link,image,use)', function(ev) {
        const d = $(this).data('href'),
            a = $(this).attr('href');

        core.uriHandler.setUri(d != undefined ? d : a);
        return false;
    })
    // .on('focusin', 'input:not(.jcrop-keymgr), input:not([type="checkbox"]), textarea', function () {
    //     var $el = $(this),
    //         tmp = $el.val(),
    //         sel = getSelectionFromEl(this);
    //
    //     if (getCursorPosition(this) != 0 || sel[1] > 0 && sel[0] == 0) {
    //         this.focus();
    //         return false;
    //     }
    //
    //     $el.val('');
    //     setTimeout(function () {
    //         $el.val(tmp);
    //     });
    // })
    .on('keypress', ev => {
        if (ev.which == 97 && ev.metaKey == true && !/INPUT|TEXTAREA/.test(ev.target.tagName)) {
            ev.stopPropagation();
            return false;
        }
    })
    .on('click', '[data-mp-event]', function() {
        const $el = $(this);
        const ev = $el.data('mp-event');
        const type = $el.data('mp-type') || _mpctTrack;
        let d = $el.data('mp-data') || undefined;

        DEBUG && console.assert(ev);

        d && /^\{/.test(d) && (d = JSON.parse(d.replace(/'/g, '"')));

        switch (type) {
            case _mpctTrack:
                core_stat.track(ev, d);
                break;
            case _mpctInc:
                core_stat.inc(ev, d);
                break;
            case _mpctIncAndTrack:
                core_stat.incAndTrack(ev, d);
                break;
        }

        return true;
    })
    .on('keypress', 'textarea[data-max-length]', function(ev) {
        const $el = $(this),
            mx = $el.data('max-length');

        if (ev.originalEvent.isChar && mx < $el.val().length - 1) {
            ev.stopPropagation();
            return false;
        }
    })
    .on('paste', 'textarea[data-max-length]', function(ev) {
        setTimeout(
            el => {
                const $el = $(el),
                    mx = $el.data('max-length');

                if ($el.val().length - 1 > mx) {
                    $el.val($el.val().substr(0, mx));
                }
            },
            0,
            this
        );
    })
    .on('drop dragover', e => {
        e.preventDefault();
    });

$('body').on('keydown', ev => {
    switch (ev.keyCode) {
        case _keyBACKSPACE:
            if (/INPUT|TEXTAREA/.test(ev.target.tagName) || ev.target.contentEditable) {
                return true;
            }
            return false;
        case _keyESC:
            $(ev.target).trigger(_evEscPressed);
            ev.stopPropagation();
            return false;
        case _keyTab:
            if (/INPUT|TEXTAREA/.test(ev.target.tagName)) {
                return true;
            }
            return false;
    }
});
