// login

/** @type {enmLoginStatus} */
const enmLoginStatus = new Enumerate;
/** @const */ enmLoginStatus.OK = 'OK';
/** @const */ enmLoginStatus.Fail = 'Fail';
/** @const */ enmLoginStatus.UserNotFound = 'UserNotFound';
/** @const */ enmLoginStatus.NotConfirmed = 'NotConfirmed';
/** @const */ enmLoginStatus.WrongPassword = 'WrongPassword';
/** @const */ enmLoginStatus.BadRequest = 'BadRequest';
/** @const */ enmLoginStatus.GeneralError = 'GeneralError';
enmLoginStatus.$gen();
// registration

/** @type {enmRegistrationResponseSource} */
const enmRegistrationResponseSource = new Enumerate;
/** @const */ enmRegistrationResponseSource.Direct = 'Direct';
/** @const */ enmRegistrationResponseSource.ExternalVerified = 'ExternalVerified';
/** @const */ enmRegistrationResponseSource.Invitation = 'Invitation';
enmRegistrationResponseSource.$gen();

/** @type {enmRegistrationResponseStatus} */
const enmRegistrationResponseStatus = new Enumerate;
/** @const */ enmRegistrationResponseStatus.EmailAlreadyInSystem = 'EmailAlreadyInSystem';
/** @const */ enmRegistrationResponseStatus.EmailVerificationSent = 'EmailVerificationSent';
/** @const */ enmRegistrationResponseStatus.EmailVerificationPending = 'EmailVerificationPending';
/** @const */ enmRegistrationResponseStatus.UserCreated = 'UserCreated';
/** @const */ enmRegistrationResponseStatus.Completed = 'Completed';
/** @const */ enmRegistrationResponseStatus.BadInput = 'BadInput';
/** @const */ enmRegistrationResponseStatus.BadPassword = 'BadPassword';
/** @const */ enmRegistrationResponseStatus.AlreadyCompleted = 'AlreadyCompleted';
/** @const */ enmRegistrationResponseStatus.GeneralError = 'GeneralError';
/** @const */ enmRegistrationResponseStatus.RegistrationClosed = 'RegistrationClosed';
enmRegistrationResponseStatus.$gen();

/** @type {enmVerifyEmailTokenResponseStatus} */
const enmVerifyEmailTokenResponseStatus = new Enumerate;
/** @const */ enmVerifyEmailTokenResponseStatus.Verified = 'Verified';
/** @const */ enmVerifyEmailTokenResponseStatus.BadInput = 'BadInput';
/** @const */ enmVerifyEmailTokenResponseStatus.Error = 'Error';
enmVerifyEmailTokenResponseStatus.$gen();

/** @type {enmVerifyEmailTokenResponseCause} */
const enmVerifyEmailTokenResponseCause = new Enumerate;
/** @const */ enmVerifyEmailTokenResponseCause.Registration = 'Registration';
/** @const */ enmVerifyEmailTokenResponseCause.Invitation = 'Invitation';
/** @const */ enmVerifyEmailTokenResponseCause.NewEmail = 'NewEmail';
enmVerifyEmailTokenResponseCause.$gen();

/** @type {enmResendEmailVerificationResponseStatus} */
const enmResendEmailVerificationResponseStatus = new Enumerate;
/** @const */ enmResendEmailVerificationResponseStatus.BadInput = 'BadInput';
/** @const */ enmResendEmailVerificationResponseStatus.VerificationSent = 'VerificationSent';
enmResendEmailVerificationResponseStatus.$gen();

/** @type {enmPasswordRecoveryResponseStatus} */
const enmPasswordRecoveryResponseStatus = new Enumerate;
/** @const */ enmPasswordRecoveryResponseStatus.NoSuchEmail = 'NoSuchEmail';
/** @const */ enmPasswordRecoveryResponseStatus.MessageSent = 'MessageSent';
/** @const */ enmPasswordRecoveryResponseStatus.PasswordChanged = 'PasswordChanged';
/** @const */ enmPasswordRecoveryResponseStatus.BadInput = 'BadInput';
enmPasswordRecoveryResponseStatus.$gen();
// user info

/** @type {enmContactInfoInfoStatus} */
const enmContactInfoInfoStatus = new Enumerate;
/** @const */ enmContactInfoInfoStatus.Pending = 'Pending';
/** @const */ enmContactInfoInfoStatus.Confirmed = 'Confirmed';
/** @const */ enmContactInfoInfoStatus.Disabled = 'Disabled';
enmContactInfoInfoStatus.$gen();

/** @type {enmAddNewEmailResponseStatus} */
const enmAddNewEmailResponseStatus = new Enumerate;
/** @const */ enmAddNewEmailResponseStatus.EmailAdded = 'EmailAdded';
/** @const */ enmAddNewEmailResponseStatus.EmailVerificationPending = 'EmailVerificationPending';
/** @const */ enmAddNewEmailResponseStatus.EmailVerificationSent = 'EmailVerificationSent';
/** @const */ enmAddNewEmailResponseStatus.EmailAlreadyInSystem = 'EmailAlreadyInSystem';
enmAddNewEmailResponseStatus.$gen();
// workspace

/** @type {enmWorkspaceInfoRequestDetails} */
const enmWorkspaceInfoRequestDetails = new Enumerate;
/** @const */ enmWorkspaceInfoRequestDetails.WorkspaceUser = 'WorkspaceUser';
/** @const */ enmWorkspaceInfoRequestDetails.WorkspaceOwner = 'WorkspaceOwner';
/** @const */ enmWorkspaceInfoRequestDetails.WorkspaceLimits = 'WorkspaceLimits';
/** @const */ enmWorkspaceInfoRequestDetails.WorkspaceUsage = 'WorkspaceUsage';
/** @const */ enmWorkspaceInfoRequestDetails.ProjectInfos = 'ProjectInfos';
/** @const */ enmWorkspaceInfoRequestDetails.ProjectStats = 'ProjectStats';
/** @const */ enmWorkspaceInfoRequestDetails.ProjectCategories = 'ProjectCategories';
/** @const */ enmWorkspaceInfoRequestDetails.WorkspaceSettings = 'WorkspaceSettings';
enmWorkspaceInfoRequestDetails.$gen();

/** @type {enmWorkspaceUserRole} */
const enmWorkspaceUserRole = new Enumerate;
/** @const */ enmWorkspaceUserRole.Nobody = 'Nobody';
/** @const */ enmWorkspaceUserRole.Resource = 'Resource';
/** @const */ enmWorkspaceUserRole.Spectator = 'Spectator';
/** @const */ enmWorkspaceUserRole.Participant = 'Participant';
/** @const */ enmWorkspaceUserRole.Leader = 'Leader';
/** @const */ enmWorkspaceUserRole.Owner = 'Owner';
enmWorkspaceUserRole.$gen();

/** @type {enmActiveWorkspaceUserRole} */
const enmActiveWorkspaceUserRole = new Enumerate;
/** @const */ enmActiveWorkspaceUserRole.Owner = 'Owner';
/** @const */ enmActiveWorkspaceUserRole.Leader = 'Leader';
/** @const */ enmActiveWorkspaceUserRole.Participant = 'Participant';
enmActiveWorkspaceUserRole.$gen();

/** @type {enmLeaderWorkspaceUserRole} */
const enmLeaderWorkspaceUserRole = new Enumerate;
/** @const */ enmLeaderWorkspaceUserRole.Owner = 'Owner';
/** @const */ enmLeaderWorkspaceUserRole.Leader = 'Leader';
enmLeaderWorkspaceUserRole.$gen();

/** @type {enmPassiveWorkspaceUserRole} */
const enmPassiveWorkspaceUserRole = new Enumerate;
/** @const */ enmPassiveWorkspaceUserRole.Spectator = 'Spectator';
/** @const */ enmPassiveWorkspaceUserRole.Resource = 'Resource';
/** @const */ enmPassiveWorkspaceUserRole.Nobody = 'Nobody';
enmPassiveWorkspaceUserRole.$gen();

/** @type {enmWorkspaceUserStatus} */
const enmWorkspaceUserStatus = new Enumerate;
/** @const */ enmWorkspaceUserStatus.Pending = 'Pending';
/** @const */ enmWorkspaceUserStatus.Confirmed = 'Confirmed';
/** @const */ enmWorkspaceUserStatus.Detached = 'Detached';
/** @const */ enmWorkspaceUserStatus.Disabled = 'Disabled';
/** @const */ enmWorkspaceUserStatus.Deleted = 'Deleted';
enmWorkspaceUserStatus.$gen();

/** @type {enmAddUserToWorkspaceResponseStatus} */
const enmAddUserToWorkspaceResponseStatus = new Enumerate;
/** @const */ enmAddUserToWorkspaceResponseStatus.Added = 'Added';
/** @const */ enmAddUserToWorkspaceResponseStatus.InvitationSent = 'InvitationSent';
/** @const */ enmAddUserToWorkspaceResponseStatus.UserExists = 'UserExists';
/** @const */ enmAddUserToWorkspaceResponseStatus.NoSuchUser = 'NoSuchUser';
/** @const */ enmAddUserToWorkspaceResponseStatus.NotAllowed = 'NotAllowed';
enmAddUserToWorkspaceResponseStatus.$gen();

/** @type {enmRemoveUserFromWorkspaceResult} */
const enmRemoveUserFromWorkspaceResult = new Enumerate;
/** @const */ enmRemoveUserFromWorkspaceResult.Removed = 'Removed';
/** @const */ enmRemoveUserFromWorkspaceResult.NotAllowed = 'NotAllowed';
enmRemoveUserFromWorkspaceResult.$gen();

/** @type {enmAttachUserToWorkspaceUserStatus} */
const enmAttachUserToWorkspaceUserStatus = new Enumerate;
/** @const */ enmAttachUserToWorkspaceUserStatus.Attached = 'Attached';
/** @const */ enmAttachUserToWorkspaceUserStatus.InvitationSent = 'InvitationSent';
/** @const */ enmAttachUserToWorkspaceUserStatus.UserExists = 'UserExists';
/** @const */ enmAttachUserToWorkspaceUserStatus.NotAllowed = 'NotAllowed';
enmAttachUserToWorkspaceUserStatus.$gen();

/** @type {enmDetachUserFromWorkspaceUserStatus} */
const enmDetachUserFromWorkspaceUserStatus = new Enumerate;
/** @const */ enmDetachUserFromWorkspaceUserStatus.Detached = 'Detached';
/** @const */ enmDetachUserFromWorkspaceUserStatus.NotAllowed = 'NotAllowed';
enmDetachUserFromWorkspaceUserStatus.$gen();

/** @type {enmListWorkspacesContents} */
const enmListWorkspacesContents = new Enumerate;
/** @const */ enmListWorkspacesContents.All = 'All';
/** @const */ enmListWorkspacesContents.Owned = 'Owned';
/** @const */ enmListWorkspacesContents.Participated = 'Participated';
enmListWorkspacesContents.$gen();

/** @type {enmResendWorkspaceInvitationResult} */
const enmResendWorkspaceInvitationResult = new Enumerate;
/** @const */ enmResendWorkspaceInvitationResult.Sent = 'Sent';
/** @const */ enmResendWorkspaceInvitationResult.NotPending = 'NotPending';
enmResendWorkspaceInvitationResult.$gen();
// projects

/** @type {enmProjectStatus} */
const enmProjectStatus = new Enumerate;
/** @const */ enmProjectStatus.Active = 'Active';
/** @const */ enmProjectStatus.Deleted = 'Deleted';
/** @const */ enmProjectStatus.Archived = 'Archived';
enmProjectStatus.$gen();

/** @type {enmProjectsSort} */
const enmProjectsSort = new Enumerate;
/** @const */ enmProjectsSort.Name = 'Name';
/** @const */ enmProjectsSort.Creation = 'Creation';
enmProjectsSort.$gen();

/** @type {enmStateChangeTypes} */
const enmStateChangeTypes = new Enumerate;
/** @const */ enmStateChangeTypes.CreateTask = 'CreateTask';
/** @const */ enmStateChangeTypes.UpdateTask = 'UpdateTask';
/** @const */ enmStateChangeTypes.ChangeTaskState = 'ChangeTaskState';
/** @const */ enmStateChangeTypes.ChangeTaskType = 'ChangeTaskType';
/** @const */ enmStateChangeTypes.DeleteTask = 'DeleteTask';
/** @const */ enmStateChangeTypes.CreateTransition = 'CreateTransition';
/** @const */ enmStateChangeTypes.UpdateTransition = 'UpdateTransition';
/** @const */ enmStateChangeTypes.DeleteTransition = 'DeleteTransition';
/** @const */ enmStateChangeTypes.AddTaskExecutor = 'AddTaskExecutor';
/** @const */ enmStateChangeTypes.RemoveTaskExecutor = 'RemoveTaskExecutor';
/** @const */ enmStateChangeTypes.ChangeDenied = 'ChangeDenied';
/** @const */ enmStateChangeTypes.UpdateTaskTiming = 'UpdateTaskTiming';
/** @const */ enmStateChangeTypes.UpdateTaskValues = 'UpdateTaskValues';
/** @const */ enmStateChangeTypes.ReassignTasks = 'ReassignTasks';
enmStateChangeTypes.$gen();

/** @type {enmTaskInfoTypes} */
const enmTaskInfoTypes = new Enumerate;
/** @const */ enmTaskInfoTypes.Task = 'Task';
/** @const */ enmTaskInfoTypes.Draft = 'Draft';
/** @const */ enmTaskInfoTypes.Swimline = 'Swimline';
/** @const */ enmTaskInfoTypes.Edge = 'Edge';
/** @const */ enmTaskInfoTypes.Group = 'Group';
enmTaskInfoTypes.$gen();

/** @type {enmTaskInfoStates} */
const enmTaskInfoStates = new Enumerate;
/** @const */ enmTaskInfoStates.Blocked = 'Blocked';
/** @const */ enmTaskInfoStates.Unblocked = 'Unblocked';
/** @const */ enmTaskInfoStates.Started = 'Started';
/** @const */ enmTaskInfoStates.Completed = 'Completed';
/** @const */ enmTaskInfoStates.Skipped = 'Skipped';
enmTaskInfoStates.$gen();

/** @type {enmTaskInfoDurations} */
const enmTaskInfoDurations = new Enumerate;
/** @const */ enmTaskInfoDurations.Minutes = 'Minutes';
/** @const */ enmTaskInfoDurations.Hours = 'Hours';
/** @const */ enmTaskInfoDurations.Days = 'Days';
/** @const */ enmTaskInfoDurations.Weeks = 'Weeks';
enmTaskInfoDurations.$gen();

/** @type {enmCloneProjectRequestsOption} */
const enmCloneProjectRequestsOption = new Enumerate;
/** @const */ enmCloneProjectRequestsOption.SameExecutors = 'SameExecutors';
/** @const */ enmCloneProjectRequestsOption.RelativeDeadlines = 'RelativeDeadlines';
enmCloneProjectRequestsOption.$gen();
// feedback types

/** @type {enmFeedbackTypes} */
const enmFeedbackTypes = new Enumerate;
/** @const */ enmFeedbackTypes.Feedback = 'Feedback';
/** @const */ enmFeedbackTypes.Bug = 'Bug';
/** @const */ enmFeedbackTypes.FeatureRequest = 'FeatureRequest';
enmFeedbackTypes.$gen();
// comments

/** @type {enmHistoryEntryType} */
const enmHistoryEntryType = new Enumerate;
/** @const */ enmHistoryEntryType.Comment = 'Comment';
/** @const */ enmHistoryEntryType.Status = 'Status';
/** @const */ enmHistoryEntryType.Event = 'Event';
/** @const */ enmHistoryEntryType.Alert = 'Alert';
enmHistoryEntryType.$gen();

/** @type {enmHistoryEventType} */
const enmHistoryEventType = new Enumerate;
/** @const */ enmHistoryEventType.ExecutorAdded = 'ExecutorAdded';
/** @const */ enmHistoryEventType.ExecutorRemoved = 'ExecutorRemoved';
/** @const */ enmHistoryEventType.Comment = 'Comment';
/** @const */ enmHistoryEventType.DeadlineSet = 'DeadlineSet';
/** @const */ enmHistoryEventType.TaskCompleted = 'TaskCompleted';
/** @const */ enmHistoryEventType.TaskUndone = 'TaskUndone';
/** @const */ enmHistoryEventType.TaskCreated = 'TaskCreated';
/** @const */ enmHistoryEventType.FileAdded = 'FileAdded';
/** @const */ enmHistoryEventType.FileRemoved = 'FileRemoved';
/** @const */ enmHistoryEventType.TeamAssigned = 'TeamAssigned';
/** @const */ enmHistoryEventType.TimeCompletedSet = 'TimeCompletedSet';
/** @const */ enmHistoryEventType.StartDateSet = 'StartDateSet';
/** @const */ enmHistoryEventType.DurationSet = 'DurationSet';
/** @const */ enmHistoryEventType.TaskStarted = 'TaskStarted';
/** @const */ enmHistoryEventType.TaskStartCanceled = 'TaskStartCanceled';
enmHistoryEventType.$gen();

/** @type {enmStreamTypes} */
const enmStreamTypes = new Enumerate;
/** @const */ enmStreamTypes.Task = 'Task';
/** @const */ enmStreamTypes.Project = 'Project';
/** @const */ enmStreamTypes.Workspace = 'Workspace';
enmStreamTypes.$gen();
// alerts

/** @type {enmAlertTypes} */
const enmAlertTypes = new Enumerate;
/** @const */ enmAlertTypes.DigestEmail = 'DigestEmail';
/** @const */ enmAlertTypes.InstantEmail = 'InstantEmail';
/** @const */ enmAlertTypes.OnlineAlert = 'OnlineAlert';
enmAlertTypes.$gen();

/** @type {enmAlertEvents} */
const enmAlertEvents = new Enumerate;
/** @const */ enmAlertEvents.AddedToProject = 'AddedToProject';
/** @const */ enmAlertEvents.AddedAsExecutor = 'AddedAsExecutor';
/** @const */ enmAlertEvents.RemovedAsExecutor = 'RemovedAsExecutor';
/** @const */ enmAlertEvents.OtherUserCompletedTask = 'OtherUserCompletedTask';
/** @const */ enmAlertEvents.OtherUserUndoneTask = 'OtherUserUndoneTask';
/** @const */ enmAlertEvents.OtherUserCommentedTask = 'OtherUserCommentedTask';
/** @const */ enmAlertEvents.OtherUserAddedFile = 'OtherUserAddedFile';
enmAlertEvents.$gen();
// files

/** @type {enmFileStreams} */
const enmFileStreams = new Enumerate;
/** @const */ enmFileStreams.Task = 'Task';
/** @const */ enmFileStreams.Project = 'Project';
enmFileStreams.$gen();

/** @type {enmFileType} */
const enmFileType = new Enumerate;
/** @const */ enmFileType.Upload = 'Upload';
/** @const */ enmFileType.Dropbox = 'Dropbox';
/** @const */ enmFileType.Box = 'Box';
/** @const */ enmFileType.GoogleDrive = 'GoogleDrive';
/** @const */ enmFileType.GoogleMaps = 'GoogleMaps';
/** @const */ enmFileType.OneDrive = 'OneDrive';
enmFileType.$gen();

/** @type {enmErrorMessage} */
const enmErrorMessage = new Enumerate;
/** @const */ enmErrorMessage.AccessDenied = 'AccessDenied';
/** @const */ enmErrorMessage.Error = 'Error';
/** @const */ enmErrorMessage.IllegalMessage = 'IllegalMessage';
/** @const */ enmErrorMessage.ReadOnly = 'ReadOnly';
/** @const */ enmErrorMessage.NoSuchSession = 'NoSuchSession';
enmErrorMessage.$gen();
// clipboard

/** @type {enmClipboardEntryInfoType} */
const enmClipboardEntryInfoType = new Enumerate;
/** @const */ enmClipboardEntryInfoType.StateSnapshot = 'StateSnapshot';
enmClipboardEntryInfoType.$gen();

/** @type {enmClipboardPreviewType} */
const enmClipboardPreviewType = new Enumerate;
/** @const */ enmClipboardPreviewType.metaData = 'metaData';
enmClipboardPreviewType.$gen();
// share

/** @type {enmSharedContentType} */
const enmSharedContentType = new Enumerate;
/** @const */ enmSharedContentType.Snapshot = 'Snapshot';
/** @const */ enmSharedContentType.ActualState = 'ActualState';
/** @const */ enmSharedContentType.LiveState = 'LiveState';
enmSharedContentType.$gen();

/** @type {enmSnapshotContents} */
const enmSnapshotContents = new Enumerate;
/** @const */ enmSnapshotContents.Progress = 'Progress';
/** @const */ enmSnapshotContents.UserInfo = 'UserInfo';
/** @const */ enmSnapshotContents.UserBrief = 'UserBrief';
/** @const */ enmSnapshotContents.Teams = 'Teams';
enmSnapshotContents.$gen();
// Billing

/** @type {enmBillingResponseStatus} */
const enmBillingResponseStatus = new Enumerate;
/** @const */ enmBillingResponseStatus.Allowed = 'Allowed';
/** @const */ enmBillingResponseStatus.Denied = 'Denied';
/** @const */ enmBillingResponseStatus.OutOfLimits = 'OutOfLimits';
/** @const */ enmBillingResponseStatus.NoCard = 'NoCard';
/** @const */ enmBillingResponseStatus.Unknown = 'Unknown';
enmBillingResponseStatus.$gen();

/** @type {enmCurrencies} */
const enmCurrencies = new Enumerate;
/** @const */ enmCurrencies.USD = 'USD';
enmCurrencies.$gen();

/** @type {enmPriceUnit} */
const enmPriceUnit = new Enumerate;
/** @const */ enmPriceUnit.PerPeriod = 'PerPeriod';
/** @const */ enmPriceUnit.PerUse = 'PerUse';
enmPriceUnit.$gen();

/** @type {enmTimePeriod} */
const enmTimePeriod = new Enumerate;
/** @const */ enmTimePeriod.Month = 'Month';
enmTimePeriod.$gen();

/** @type {enmProductTypes} */
const enmProductTypes = new Enumerate;
/** @const */ enmProductTypes.Plan = 'Plan';
/** @const */ enmProductTypes.Content = 'Content';
enmProductTypes.$gen();

/** @type {enmBillableResources} */
const enmBillableResources = new Enumerate;
/** @const */ enmBillableResources.workspace = 'workspace';
/** @const */ enmBillableResources.project = 'project';
/** @const */ enmBillableResources.projectActive = 'projectActive';
/** @const */ enmBillableResources.projectInactive = 'projectInactive';
/** @const */ enmBillableResources.user = 'user';
/** @const */ enmBillableResources.userActive = 'userActive';
/** @const */ enmBillableResources.userSpectator = 'userSpectator';
/** @const */ enmBillableResources.storage = 'storage';
enmBillableResources.$gen();

/** @type {enmSubscriptionStatus} */
const enmSubscriptionStatus = new Enumerate;
/** @const */ enmSubscriptionStatus.Future = 'Future';
/** @const */ enmSubscriptionStatus.Trial = 'Trial';
/** @const */ enmSubscriptionStatus.Active = 'Active';
/** @const */ enmSubscriptionStatus.NonRenewing = 'NonRenewing';
/** @const */ enmSubscriptionStatus.Cancelled = 'Cancelled';
enmSubscriptionStatus.$gen();

/** @type {enmSubscriptionCancelReason} */
const enmSubscriptionCancelReason = new Enumerate;
/** @const */ enmSubscriptionCancelReason.UserCancelled = 'UserCancelled';
/** @const */ enmSubscriptionCancelReason.Fraud = 'Fraud';
/** @const */ enmSubscriptionCancelReason.NotPaid = 'NotPaid';
/** @const */ enmSubscriptionCancelReason.NoCard = 'NoCard';
/** @const */ enmSubscriptionCancelReason.Unknown = 'Unknown';
enmSubscriptionCancelReason.$gen();

/** @type {enmGoogleCalendarStatus} */
const enmGoogleCalendarStatus = new Enumerate;
/** @const */ enmGoogleCalendarStatus.WorkspaceUserUnlinked = 'WorkspaceUserUnlinked';
/** @const */ enmGoogleCalendarStatus.CalendarLinked = 'CalendarLinked';
/** @const */ enmGoogleCalendarStatus.CalendarUnlinked = 'CalendarUnlinked';
enmGoogleCalendarStatus.$gen();

/** @type {enmEvernoteClientQuestions} */
const enmEvernoteClientQuestions = new Enumerate;
/** @const */ enmEvernoteClientQuestions.ListNotebooks = 'ListNotebooks';
/** @const */ enmEvernoteClientQuestions.ListBusinessNotebooks = 'ListBusinessNotebooks';
/** @const */ enmEvernoteClientQuestions.GetEvernoteUser = 'GetEvernoteUser';
enmEvernoteClientQuestions.$gen();

/** @type {enmEvernoteErrorType} */
const enmEvernoteErrorType = new Enumerate;
/** @const */ enmEvernoteErrorType.Unauthorized = 'Unauthorized';
/** @const */ enmEvernoteErrorType.NotFound = 'NotFound';
/** @const */ enmEvernoteErrorType.GeneralError = 'GeneralError';
enmEvernoteErrorType.$gen();

/** @type {enmEvernoteEventRequest} */
const enmEvernoteEventRequest = new Enumerate;
/** @const */ enmEvernoteEventRequest.PushWSOverview = 'PushWSOverview';
/** @const */ enmEvernoteEventRequest.PushWSProjectStats = 'PushWSProjectStats';
/** @const */ enmEvernoteEventRequest.PushProjectTaskList = 'PushProjectTaskList';
/** @const */ enmEvernoteEventRequest.PushProjectTeamOverview = 'PushProjectTeamOverview';
enmEvernoteEventRequest.$gen();

/** @type {enmAnswerStatus} */
const enmAnswerStatus = new Enumerate;
/** @const */ enmAnswerStatus.ok = 'ok';
/** @const */ enmAnswerStatus.error = 'error';
enmAnswerStatus.$gen();

/** @type {enmStoryPartType} */
const enmStoryPartType = new Enumerate;
/** @const */ enmStoryPartType.Section = 'Section';
enmStoryPartType.$gen();

/** @type {enmStoryPartFlow} */
const enmStoryPartFlow = new Enumerate;
/** @const */ enmStoryPartFlow.Normal = 'Normal';
/** @const */ enmStoryPartFlow.Branch = 'Branch';
enmStoryPartFlow.$gen();

/** @type {enmStoryPageTemplate} */
const enmStoryPageTemplate = new Enumerate;
/** @const */ enmStoryPageTemplate.story = 'story';
/** @const */ enmStoryPageTemplate.casualstory = 'casualstory';
enmStoryPageTemplate.$gen();

/** @type {enmStoryMediaType} */
const enmStoryMediaType = new Enumerate;
/** @const */ enmStoryMediaType.Slideshow = 'Slideshow';
/** @const */ enmStoryMediaType.Document = 'Document';
/** @const */ enmStoryMediaType.Hidden = 'Hidden';
enmStoryMediaType.$gen();

/** @type {enmFeatureQuestion} */
const enmFeatureQuestion = new Enumerate;
/** @const */ enmFeatureQuestion.StoryEditor = 'StoryEditor';
enmFeatureQuestion.$gen();

/** @type {enmFeatureQuestionEvent} */
const enmFeatureQuestionEvent = new Enumerate;
/** @const */ enmFeatureQuestionEvent.QueryStorySettings = 'QueryStorySettings';
/** @const */ enmFeatureQuestionEvent.UpdateStorySettings = 'UpdateStorySettings';
/** @const */ enmFeatureQuestionEvent.QueryStoryChapter = 'QueryStoryChapter';
/** @const */ enmFeatureQuestionEvent.UpdateStoryChapter = 'UpdateStoryChapter';
/** @const */ enmFeatureQuestionEvent.DeleteStoryChapter = 'DeleteStoryChapter';
enmFeatureQuestionEvent.$gen();
// Workspace Reporting

/** @type {enmReportProjectsScope} */
const enmReportProjectsScope = new Enumerate;
/** @const */ enmReportProjectsScope.WholeWorkspace = 'WholeWorkspace';
/** @const */ enmReportProjectsScope.ForWorkspaceUser = 'ForWorkspaceUser';
enmReportProjectsScope.$gen();