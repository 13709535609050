/**
 * @fileoverview
 * Wrapper for google code functions
 */

'use strict';

var goog = goog || {};

core.getMsg = function (id, output) {
    typeof(id) == 'object' && (id = id.id);
    var ij;
    if (output instanceof soy.StringBuilder) {
        ij = null;
    } else {
        ij = output;
        output = undefined;
    }

    var s = tpls.jsMsg({type: id}, output, ij);
    return s;
}

core_utils.intExport(_ienGetMsg, core.getMsg);