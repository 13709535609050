// roles
const /** @const */ _srFrm = 'frm';
const /** @const */ _srDialog = 'dialog';
const /** @const */ _srLogin = 'login';
const /** @const */ _srSignUp = 'signUp';
const /** @const */ _srCompleteRegistration = 'completeRegistration';
const /** @const */ _srReRequest = 'reRequest';
const /** @const */ _srForgotPassword = 'forgotPassword';
const /** @const */ _srForgotPassword2 = 'forgotPassword2';
const /** @const */ _srInvite = 'invite';
const /** @const */ _srProcessing = 'processing';
const /** @const */ _srCancel = 'cnsl';
const /** @const */ _srFeedback = 'fdbk';
const /** @const */ _srNotifications = 'notifications';
// moved to constants.js-dist to support classes variable
// /** @const */ _srEmailConfirmation = 'srEmailConfirmation';

// /** @namespace */
// _SPLinks = {};
// _SPLinks.login = '/';
// _SPLinks.signup = 'signup';
// _SPLinks.forgotPassword = 'forgot-password';
// _SPLinks.rerequest = 're-request-verification';
// _SPLinks.confirmEmail = 'confirm-email';
// _SPLinks.workspace = 'workspace';

(core => {
    core.visitorsInterface = true;

    const dialogs = {};
    const postHandlers = {};
    const receiver = {};
    let remember = false;
    let lastLogin;
    let emailSent;

    DEBUG && (core.dialog = dialog);
    DEBUG && (core.frmDialog = frmDialog);

    dialogs[_srFrm] = tpls.frm;
    dialogs[_srDialog] = tpls.dialog;
    dialogs[_srLogin] = tpls.login;
    dialogs[_srSignUp] = tpls.signUp;
    dialogs[_srCompleteRegistration] = tpls.completeRegistration;
    dialogs[_srReRequest] = tpls.reRequest;
    dialogs[_srForgotPassword] = tpls.forgotPassword;
    dialogs[_srForgotPassword2] = tpls.forgotPassword2;
    dialogs[_srInvite] = tpls.invite;
    dialogs[_srProcessing] = tpls.processing;
    dialogs[_srFeedback] = tpls.feedback;
    dialogs[_srNotifications] = tpls.notifications;
    dialogs[_srEmailConfirmation] = tpls.emailConfirmationDone;

    postHandlers[_srSignUp] = data => {
        core_stat.track(_mpcSignupEMailSubmited);
        try {
            emailSent = data['email'];
            core.storage.$set('emailSent', data['email']);
        } catch (e) {}
        return data;
    };

    postHandlers[_srForgotPassword] = data => {
        core_stat.track(_mpcPasswordResetSubmited);
        try {
            emailSent = data['email'];
            core.storage.$set('emailSent', data['email']);
        } catch (e) {}
        return data;
    };

    postHandlers[_srCompleteRegistration] = postHandlers[_srInvite] = data => {
        if (data['password'] != data['prepeat']) {
            $('<div></div>')
                .addClass('error')
                .html(tpls.messages({ cat: _srCompleteRegistration, type: 'pwd' }))
                .insertAfter('form input[name="prepeat"]');
            return false;
        } else {
            $('form div.error').remove();
        }
        delete data['prepeat'];
        return data;
    };

    postHandlers[_srFeedback] = data => {
        data['userAgent'] = window.navigator.userAgent;
        data['uiPath'] = window.location.toString();
        data['data'] = 'Screen:{width}x{height}x{color}/{vwidth}x{vheight}'.format({
            width: screen.width,
            height: screen.height,
            color: screen.colorDepth,
            vwidth: window.innerWidth,
            vheight: window.innerHeight
        });

        data['type'] == enmFeedbackTypes.Bug && console.getMessages && (data['logs'] = console.getMessages());
        return data;
    };

    postHandlers[_srLogin] = data => {
        if (data['remember'] == '1') {
            remember = true;
            delete data['remember'];
            core_stat.track(_mpcRemeberMeChecked);
        }

        lastLogin = data['email'];

        return data;
    };

    postHandlers[_srNotifications] = data => {
        const pd = {};
        const all = [];
        let id;
        let sd;

        (data || []).forEach(v => {
            !pd[v['name']] && (pd[v['name']] = []);
            pd[v['name']].push(v['value']);
        });

        sd = (pd['sendDigest'] && pd['sendDigest'][0]) || false;
        delete pd['sendDigest'];
        id = pd['id'][0];
        delete pd['id'];

        $.each(pd, (i, v) => {
            all.push({
                name: i,
                options: v
            });
        });

        dialog(_srProcessing);
        const d = {
            sendDigest: !!sd,
            all
        };

        $.ajax({
            url: `${_CONF_BASEURL}/user/settings/notifications/${id}`,
            data: `${new $classes[_cAlertsSettings](d)}`,
            dataType: 'json',
            type: 'POST',
            contentType: 'application/json'
        })
            .done(data => {
                frmDialog({ header: tpls.jsMsg({ type: _msgNotificationsUpdated }) });
            })
            .fail(data => {
                debugger;
            });
        return false;
    };

    receiver[_srFeedback] = obj => {
        frmDialog({ status: 'fdbk' });
    };

    function dialog(name, data, $ij) {
        const tpl =
                (name == _srFrm && dialogs[name](data, null, $ij || {})) ||
                dialogs[_srDialog]({ id: name, bd: dialogs[name](data || {}) }, null, $ij || {}),
            $el = $(tpl);

        $('section[data-role="main"]')
            .empty()
            .append($el);

        $('form')
            .on('submit', function(ev) {
                const $form = $(this),
                    $button = $form.find('button:submit'),
                    role = $button.data('role'),
                    c = $button.data('post');

                if (name == _srCompleteRegistration) {
                    core_stat.track(_mpcAppSignup, { formValid: $form.get(0).checkValidity?.() });
                }
                if ($form.get(0).checkValidity?.() === false) {
                    alert(tpls.jsMsg({ type: _msgCheckInputField }));
                    return;
                }

                $button.addClass('disabled process');

                let data = {};

                if ($button.data('noser') != undefined) {
                    data = $form.serializeArray();
                } else {
                    data = core_utils.serializeForm($form);
                }

                if (postHandlers[role]) {
                    data = postHandlers[role].call($button[0], data);
                }

                if (data != false) {
                    // console.log('POST', data);
                    core.moses.announce(c, data, receiver[role]);
                } else {
                    $button.removeClass('disabled process');
                }

                ev.preventDefault();
                return false;
            })
            .on('click', 'button[type="button"]', function(ev) {
                const $el = $(this);
                core.uriHandler.setUri($el.data('uri'));
            });

        return $el;
    }

    function frmDialog(id, msg) {
        const $dlg = dialog(_srFrm, null, id);
        const $header = $dlg.find('header');
        let $li = [];
        let t;

        $('#message > ul > li')
            .hide()
            .removeClass('error warning progress');

        switch (typeof id) {
            case 'string':
                $li = $dlg.find(`li.${id}`);
                const h = $li.data('header');
                h && $header.html(h);
                break;
            case 'object':
                if (id['status']) {
                    $li = $dlg.find(`li.${id['status']}`);
                    // if (!$li.length && id instanceof $classes[_cAbstractMessage]) {
                    //     $li = $dlg.find('li.' + id.Class.className + '-' + id['status']);
                    // }
                }
                $li.length == 0 && ($li = $dlg.find('li.custom'));
                $header.html(id['header'] || $li.data('header'));
                id['message'] || (id['msg'] && $li.html(id['message'] || id['msg']));
                $li.css('display', 'block');
                id['type'] && $li.addClass(id['type']);
                break;
        }

        $li && $li.css('display', 'block') && (t = $li.data('message-type')) && $li.addClass(t);

        $dlg.find('button').click(function() {
            const data = $(this).data();
            switch (true) {
                case !!data['uri']:
                    core.uriHandler.setUri(data['uri']);
                    break;
                case !!data['reset']:
                    core.uriHandler.setUri();
                    break;
            }
        });
    }

    function rset(data) {
        const d = {};
        data.forEach(v => {
            !d[v['name']] && (d[v['name']] = {});
            v['options'].forEach(o => {
                d[v['name']][o] = true;
            });
        });

        return d;
    }

    core.uriHandler
        .registerDefault(() => {
            core_stat.track(_mpcLoginPageVisit);
            if (core.isSessionActive()) {
                core.moses.announce(_rCheckSession).then(obj => {
                    if (obj['status'] != enmLoginStatus.OK) {
                        core.storage.$kill(_gcSESSION_ID_NAME);
                        core.storage.$kill(_gcSESSION_ID_NAME, true);
                        core_stat.track(_mpcLoginFailed);
                        dialog(_srLogin, { error: tpls.messages({ cat: _srLogin, type: obj['status'] }) });
                    }
                });
            } else {
                const {
                    config: { signupoptions = '', signup = '' }
                } = appConfig;
                let signupUri;
                switch (true) {
                    default:
                        signupUri = core.uriHandler.mkLink(_umSignup);
                        break;
                    case /app/.test(signupoptions) == false && !!signup:
                        signupUri = signup;
                        break;
                }
                dialog(_srLogin, { signupUri });
            }
        }, _umLogin)
        .registerHandler(_umSignup, () => {
            core_stat.track(_mpcSignupPageVisit);
            dialog(_srSignUp);
            receiver[_srSignUp] = obj => {
                core_stat.track(_mpcAppSignupStart);
                switch (obj['status']) {
                    case enmRegistrationResponseStatus.UserCreated:
                        DEBUG && console.log(obj);
                        break;
                    case enmRegistrationResponseStatus.EmailVerificationSent:
                    case enmRegistrationResponseStatus.Completed:
                    case enmRegistrationResponseStatus.RegistrationClosed:
                        frmDialog(obj);
                        break;
                    case enmRegistrationResponseStatus.EmailAlreadyInSystem:
                        dialog(_srLogin, {
                            errorHead: tpls.jsMsg({ type: _msgEmailAlreadyRegistered }),
                            headText: tpls.messages({ cat: _srSignUp, type: obj['status'] })
                        });
                        break;
                    case enmRegistrationResponseStatus.EmailVerificationPending:
                        dialog(_srSignUp, {
                            notify: tpls.messages({ cat: _srSignUp, type: obj['status'] })
                        });
                        break;
                    case enmRegistrationResponseStatus.BadInput:
                    case enmRegistrationResponseStatus.GeneralError:
                        dialog(_srSignUp, {
                            error: tpls.messages({ cat: 'common', type: obj['status'] })
                        });
                        break;
                    case enmRegistrationResponseStatus.BadPassword:
                        dialog(_srSignUp, {
                            error: tpls.messages({ cat: _srSignUp, type: obj['status'] })
                        });
                        break;
                    case enmRegistrationResponseStatus.AlreadyCompleted:
                        dialog(_srLogin, {
                            notify: tpls.messages({ cat: _srSignUp, type: obj['status'] }),
                            errorHead: tpls.jsMsg({ type: _msgRegistrationDoneMessage })
                        });
                        break;
                }
            };
        })
        .registerHandler(_umReRequestVerification, () => {
            dialog(_srReRequest, { email: emailSent || core.storage.$get('emailSent') });
            receiver[_srReRequest] = obj => {
                frmDialog(obj);
            };
        })
        .registerHandler(_umForgotPassword, () => {
            core_stat.track(_mpcPasswordResetVisited);
            dialog(_srForgotPassword);
        })
        .registerHandler(_umRecoverPassword, (nav, c) => {
            core_stat.track(_mpcPasswordResetLinkVisited);
            dialog(_srForgotPassword2, { token: c[1], email: emailSent || core.storage.$get('emailSent') });
            receiver[_srForgotPassword2] = obj => {
                core_stat.track(_mpcPasswordResetCompleted);
                frmDialog(obj);
            };
        })
        .registerHandler(_umConfirmEmail, (nav, c) => {
            dialog(_srProcessing);
            core.moses.announce(_rVerifyEmailToken, { token: c[1] }).then(data => {
                if (data['cause'] == 'NewEmail' || data['cause'] == 'Signup') {
                    switch (data.status) {
                        case enmVerifyEmailTokenResponseStatus.Verified:
                            core_stat.track(_mpcAppSignupStart);
                            dialog(_srEmailConfirmation, { sessionAlive: core.isSessionActive() });
                            break;
                        case enmVerifyEmailTokenResponseStatus.Error:
                            frmDialog({
                                header: tpls.jsMsg({ type: _msgErrorMessage }),
                                msg: tpls.jsMsg({ type: _msgWrongTokenMessage })
                            });
                            break;
                    }
                } else {
                    switch (data.status) {
                        case enmVerifyEmailTokenResponseStatus.Verified:
                            if (
                                data['registrationResponse']['status'] == enmRegistrationResponseStatus.AlreadyCompleted
                            ) {
                                core_stat.track(_mpcSignupEMailConfirmFailed);
                                dialog(_srLogin, {
                                    error: tpls.messages({
                                        cat: _srSignUp,
                                        type: enmRegistrationResponseStatus.AlreadyCompleted
                                    })
                                });
                            } else {
                                core_stat.track(_mpcSignupEMailConfirmed);
                                dialog(_srCompleteRegistration, {
                                    regId: data['registrationResponse']['registrationId'],
                                    source: data['registrationResponse']['source']
                                });
                            }
                            break;
                        case enmVerifyEmailTokenResponseStatus.Error:
                            core_stat.track(_mpcSignupEMailConfirmFailed);
                            frmDialog({
                                header: tpls.jsMsg({ type: _msgErrorMessage }),
                                msg: tpls.jsMsg({ type: _msgWrongTokenMessage })
                            });
                            break;
                    }
                }
            });
        })
        .registerHandler(_umInvite, () => {
            dialog(_srInvite, { invite: token });
        })
        .registerHandler(_umGoogleRegister, (nav, c) => {
            switch (c[1]) {
                case 'after':
                    core.moses.announce(_rContinueRegistration, { registrationId: c[2] }).then(res => {
                        if (!!res['registrationId']) {
                            dialog(_srCompleteRegistration, {
                                regId: res['registrationId'],
                                firstName: res['userInfo']['firstName'],
                                lastName: res['userInfo']['lastName']
                            });
                        } else {
                            frmDialog(res);
                        }
                    });
                    break;
                case 'error':
                    switch (c[2]) {
                        case enmRegistrationResponseStatus.EmailAlreadyInSystem:
                        case enmRegistrationResponseStatus.AlreadyCompleted:
                            dialog(_srLogin, { error: tpls.messages({ cat: _srSignUp, type: c[2] }) });
                            break;
                        default:
                            frmDialog({ status: c[2] });
                    }
                    break;
            }
        })
        .registerHandler(_umGoogleLogin, (nav, c) => {
            switch (c[1]) {
                case 'after':
                    core_stat.track(_mpcLoginWithGoogleSuccess);
                    try {
                        core.storage.$set(_gcSESSION_ID_NAME, c[3]);
                    } catch (e) {
                        alert(
                            'Cannot store session in the browser storage. Interface will not work properly. Please free some space.'
                        );
                    }
                    // $.cookie(_gcLanguageCookie, obj['language'].match(/^(\w{2})/)[1]);
                    setTimeout(() => {
                        window.location = _CONF_APPROOTURL;
                    }, _mpcSyncTimeout);
                    break;
                case 'register':
                    frmDialog({ status: 'signUpForGoogle' });
                    break;
                case 'error':
                    console.error('visitors::umGoogleLogin', c);
                    break;
            }
        })
        .registerHandler(_umGoogleAuth, (nav, c) => {
            window.opener[_gcGoogleAuthCallback].call(null, c[0] == 'success');
            window.close();
        })
        .registerHandler(_umFeatureAuth, () => {
            window.close();
        })
        .registerHandler(_umNotifications, (nav, c) => {
            dialog(_srProcessing);
            $.getJSON(`${_CONF_BASEURL}/user/settings/notifications/${c[1]}`).then(data => {
                if (data['error']) {
                    frmDialog({ status: data['error'] });
                    return;
                }
                const d = new $classes[_cGetUserAlertsSettingsResponse](data);

                const settings = d['settings'];
                settings['all'] = rset(settings['all']);

                const defValues = d['defaults'];
                defValues['all'] = rset(defValues['all']);

                dialog(_srNotifications, {
                    events: enmAlertEvents.values(),
                    data: settings,
                    id: c[1],
                    defs: JSON.stringify(defValues)
                });

                $(`form button[data-role="${_srRevert2Def}"]`).on('click', function() {
                    const $f = $(this).parents('form'),
                        def = $(this).data('value');

                    $f.find('input[type="checkbox"]').attr('checked', false);

                    for (const k in def['all']) {
                        for (const i in def['all'][k]) {
                            $f.find(`input[name="${k}"][value="${i}"]`)[0].checked = true;
                        }
                    }
                    $f.find('input[name="sendDigest"]')[0].checked = def['sendDigest'] != undefined;
                });
            });
        });

    core.moses.subscribe(_eReRequestVerification, () => {
        if (!core.storage.$get('emailSent') && !emailSent) {
            core.uriHandler.setUri(_umReRequestVerification);
        } else {
            core.moses.announce(
                _rResendEmailVerification,
                { email: emailSent || core.storage.$get('emailSent') },
                obj => {
                    frmDialog(obj);
                }
            );
        }
    });

    core.moses.subscribe(_eSignup4Waitlist, () => {
        const $f = $('<form />')
            .attr('method', 'POST')
            .attr('action', 'http://casual.io/signup')
            .css('display', 'none');

        $('<input />')
            .attr('name', 'email')
            .attr('type', 'text')
            .val(emailSent || core.storage.$get('emailSent'))
            .appendTo($f);

        $f.appendTo('body');

        $f.trigger('submit');
    });

    core.moses.subscribe(_cLoginResponse, obj => {
        switch (obj['status']) {
            case enmLoginStatus.OK:
                core_stat.track(_mpcLoginSuccess);
                try {
                    core.storage.$set(_gcSESSION_ID_NAME, obj['sessionKey']);
                } catch (e) {
                    __USE_ROLLBAR &&
                        Rollbar.warning('visitors::cRegistrationResponse cannot store sessionID in session storage');
                    alert(
                        'Cannot store session in the browser storage. Interface will not work properly. Please free some space.'
                    );
                }

                obj['language'] && $.cookie(_gcLanguageCookie, obj['language'].match(/^(\w{2})/)[1]);
                const url = core.storage.$get('url2return');
                url && core.storage.$kill('url2return');

                if (remember) {
                    try {
                        core.storage.$set(_gcSESSION_ID_NAME, obj['sessionKey'], true);
                    } catch (e) {}
                    DEBUG && console.info('store sessionId for future logins');
                }

                setTimeout(() => {
                    window.location.href = url || _CONF_APPROOTURL;
                }, _mpcSyncTimeout);
                break;
            case enmLoginStatus.Fail:
                core_stat.track(_mpcLoginFailed);
                core.storage.$kill(_gcSESSION_ID_NAME);
                // core.uriHandler.setUri('');
                break;
            default:
                dialog(_srLogin, {
                    error: tpls.messages({ cat: _srLogin, type: obj['status'] }),
                    lastLogin
                });
                break;
        }
    });

    core.moses.subscribe(_cRegistrationResponse, obj => {
        switch (obj['status']) {
            case enmRegistrationResponseStatus.Completed:
                try {
                    core.storage.$set(_gcSESSION_ID_NAME, obj['sessionKey']);
                } catch (e) {
                    __USE_ROLLBAR &&
                        Rollbar.warning('visitors::cRegistrationResponse cannot store sessionID in session storage');
                    alert(
                        'Cannot store session in the browser storage. Interface will not work properly. Please free some space.'
                    );
                }
                core_stat.track(_mpcLoginSuccess);
                setTimeout(() => {
                    window.location = `${_CONF_APPROOTURL}/${core.uriHandler.mkLink({
                        id: _umWorkspace,
                        wsId: obj['destination']
                    })}`;
                }, _mpcSyncTimeout);
                break;
            default:
                frmDialog(obj);
                break;
        }
    });

    core.moses.subscribe(_cPasswordRecoveryResponse, frmDialog);

    // force start up core interface
    core.moses.announce(_MOSES_IFACE_READY);
    core.uriHandler.init();

    const uid = getURLParameter('uid');
    if (__USE_MIXPANEL && uid) {
        window.mixpanel.alias(uid);
    }

    const wsid = getURLParameter('wsid');
    if (__USE_MIXPANEL && wsid) {
        wsid.replace(/\|$/, '')
            .split('|')
            .forEach(id => {
                window.mixpanel.alias(id);
            });
    }

    (() => {
        // ua	"Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; SLCC2; .NET CLR 2.0.50727; .NET CLR 3.5.30729; .NET CLR 3.0.30729; Media Center PC 6.0; rv:11.0) like Gecko"
        //      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.10240"
        // var ua = navigator.userAgent,
        //     name = ua.match(/Edge|Safari|Chrome|Firefox|MSIE|Trident/),
        //     v = (tmp = ua.match(/EDGE\/([\d\.])|AppleWebKit\/([\d\.]+)|Firefox\/([\d\.]+)|MSIE\s([\d\.]+)/)) && Number(tmp.slice(1).compact()[0].split('.', 1)[0]);
        //
        // var v;
        // switch (true) {
        //     case !!(v = ua.match(/EDGE\/([\d\.]+)/i)):
        //     case !!(v = ua.match(/MSIE|Trident/i)):
        //     case !!(v = ua.match(/))
        // }
        $(document)
            .on('click', 'div.feedback-link', () => {
                dialog(_srFeedback);
            })
            .on('click', '[href]', ev => {
                const href = $(ev.target).attr('href');
                if (/^http/.test(href) == false) {
                    core.uriHandler.setUri(href);
                    return false;
                }
            });
        // .on('change', 'div.rt-wrapper select', function () {
        //     $.cookie('lang', $(this).val());
        //     window.location.reload();
        // })
        core_stat.track_links('.google-login', _mpcLoginWithGoogleClick);
        core_stat.track_links('.google-signup', _mpcSignup, { where: 'App login form', via: 'Google' });

        const browser = detectBrowser(navigator.userAgent);
        DEBUG && console.info('browser', browser);
        const v = browser && browser.version.replace(/\..*$/, '');
        const bn = browser && browser.name;

        switch (true) {
            default:
                $('<div class="ua-message" />')
                    .html(core.getMsg(_msgUAInfo))
                    .insertBefore('section.wlcm');
                break;
            case bn == 'firefox' && v > 12:
            case bn == 'chrome' && v > 13:
            case bn == 'safari' && v > 5:
            case bn == 'opera' && v > 40:
                $('section.wlcm').html(tpls.logo());
                break;
        }

        if (/causal\.io/i.test(document.referrer.split('/')[2])) {
            core_stat.track(_mpcCameFromLanding);
        }
    })();

    $('body')
        .on(
            'keydown',
            '#signUp input[name="email"]',
            (() => {
                core_stat.track(_mpcInteractWithSignupForm, { where: 'App', field: 'email' });
                return true;
            }).once()
        )
        .on('click', '#signUp button[data-role="cnsl"]', () => {
            core_stat.track(_mpcAppSignupCancel, { step: 1 });
            return true;
        })
        .on(
            'keydown',
            '#completeRegistration input',
            function() {
                core_stat.track(_mpcInteractWithSignupForm, { where: 'App step 2', field: $(this).attr('name') });
                return true;
            }.once()
        )
        .on('click', '#completeRegistration button[data-role="cnsl"]', () => {
            core_stat.track(_mpcAppSignupCancel, { step: 2 });
            return true;
        });
})(core);
