/**
 * core implementation of Casual
 * @author <a href="mailto:shikerya@me.com">Jacky Shikerya</a>
 */

/**
 * @namespace
 * @property {cWorkspaceUserInfo} iam
 */
const core = {};

core.skip_stats = false;

/**
 * Session ID cache holder
 * @type {string|null}
 */
core.__session = null;

/**
 * Local time offset
 * difference between server time and local time
 * @type {number|null}
 */
core.timeOffset = null;

/**
 * Promise shortcat
 * @param {Function} func
 * @return {Promise}
 */
core.q = function(func) {
    return new Promise(func);
};

/**
 * @type {cUserInfo}
 */
core.iam;
/**
 * core initiation procedure
 * @public
 * @return Pointer to core object
 */
core.init = function() {
    core.moses.announce(_MOSES_CORE_INIT);
    return core;
};

/**
 * Start procedure of core object
 * @public
 * @return Pointer for core object
 */
core.run = function() {
    core.moses.announce(_MOSES_CORE_START);
    if (__BUILD_APP) {
        core.isSessionActive() ? core.moses.announce(_rCheckSession) : core.moses.announce(_MOSES_NO_SESSION);
    }
    return core;
};

function getURLParameter(name) {
    return (
        decodeURIComponent(
            (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ''])[1].replace(
                /\+/g,
                '%20'
            )
        ) || null
    );
}

function checkValue(val) {
    return [null, 'null', '', undefined].indexOf(val) == -1 ? val : null;
}

/**
 * Session status
 * @return {boolean} session status
 */
core.isSessionActive = function() {
    if (!core.__session) {
        var ls = checkValue(core.storage.$get(_gcSESSION_ID_NAME, true));
        var ss = checkValue(core.storage.$get(_gcSESSION_ID_NAME));
        core.__session =
            checkValue(getURLParameter(_gcSESSION_ID_NAME)) ||
            checkValue($.cookie(_gcSESSION_ID_NAME)) ||
            ss || // from sessionStorage
            ls || // from localStorage
            null;
        core.__session && !(ls || ss) && core.storage.$set(_gcSESSION_ID_NAME, core.__session, true);
    }
    return core.__session;
};

/**
 * Session ID
 * @return {string|null}
 */
core.sessionId = function() {
    return (core.isSessionActive() && core.__session) || null;
};

core.setMainNav = function(moduleId, tabId) {
    $('#' + _clsHeadMenu + ' div.' + moduleId + ' li.' + _clsToggle)
        .removeClass(_clsActive)
        .parent()
        .find('[data-el="' + tabId + '"]')
        .addClass(_clsActive);
};
