/**
 * @author Jacky Shikerya <shikerya@me.com>
 */

/**
 * Object<string, any>
 */
const $classes = {};

/**
 * Class factory
 * @param {string} cName
 * @return {Class}
 * @public
 */
$classes.$factory = function(cName) {
    let cl;

    const proto = $classes[cName];

    const args = Array.prototype.slice.call(arguments, 1);

    if (proto == undefined) {
        console.assert(cName, `Class ${cName} is not defined`);
        throw Error(`Class ${cName} is not defined`);
    }

    cl = new proto();

    cl._create && cl._create(...args);
    cl.init && cl.init(...args);
    return cl;
};

/**
 * Class constructor
 * @param {string=} className Name of class to be defined
 * @param {(string|Object)=} parentClassName Class name to be iheritated or class definition
 * @param {Object=} props Class definition
 * @public
 */
$classes.Class = function(className, parentClassName, props) {
    var props = arguments[arguments.length - 1];

    if (props instanceof Function) props = props();

    if (!props || props instanceof $classes.Class || typeof props != 'object') props = {};

    DEBUG && console.assert(className);

    var className = arguments[0];
    if (!className || typeof className != 'string') className = '';
    var parentClassName = className ? arguments[1] : arguments[0];
    if (parentClassName && typeof parentClassName != 'string' && !(parentClassName.prototype instanceof $classes.Class))
        parentClassName = null;

    let parentClass;
    if (parentClassName) {
        if (typeof parentClassName == 'string') {
            parentClass = $classes[parentClassName];
        } else {
            parentClass = parentClassName;
        }
        DEBUG && console.assert(parentClass);
    }

    /** @private */
    function Class() {
        reset(this);
        return this;
    }

    $classes[className] = Class;

    if (parentClass) {
        for (var p in parentClass) {
            Class[p] = parentClass[p];
        }
        Class.prototype = new parentClass();
    } else {
        Class.prototype = this;
    }

    Class.prototype.Class = Class;
    Class.prototype.constructor = Class;

    if (props.PropertiesList) {
        const p = props.PropertiesList,
            o = new RegExp(`^(${(props.Optional && props.Optional.join('|')) || ''})$`);

        for (let i = p.length; --i >= 0; ) {
            if (!props.hasOwnProperty(p[i])) {
                props[p[i]] = o.test(p[i]) ? undefined : null;
            }
        }

        delete props.PropertiesList;
    }

    for (p in props) {
        if (!props.hasOwnProperty(p)) continue;
        extend(Class.prototype, parentClass ? parentClass.prototype : null, p, props[p]);
    }

    Class['className'] = className;

    /** @private */
    function extend(object, parent, p, v) {
        switch (true) {
            case v instanceof Function:
                if (!object[p] || typeof object[p] != 'function' || !parent || !parent.hasOwnProperty(p)) {
                    object[p] = v;
                } else {
                    object[p] = ((p, v) =>
                        function(...args) {
                            this._super = parent[p];
                            return v.apply(this, args);
                        })(p, v);
                }
                break;
            case Object.isObject(v) && parent && Object.isObject(parent[p]) && !(v instanceof RegExp):
                object[p] = $.extend(true, parent[p], v);
                break;
            default:
                object[p] = v;
                break;
        }
    }

    /** @private */
    function reset(props) {
        for (const p in props) {
            const v = props[p];
            const toString = Object.prototype.toString.call(v);
            if (toString == '[object Array]') {
                props[p] = Object.merge([], v);
            } else if (toString == '[object Object]') {
                props[p] = $.extend({}, v);
            } else {
                props[p] = v;
            }
        }
    }
};

$classes.$typeCheck = $typeCheck;

function $typeCheck(value, type, subtype, def) {
    /** @const */ const undef = undefined;
    switch (true) {
        case (type & _dtpArray) == _dtpArray:
            var r = [];

            // if (value == undef && (type & _dtpOptional) == _dtpOptional) {
            //     return undef;
            // } else
            if (value == undef && def != undef) {
                value = def;
            } else if (value == undef && def == undef) {
                return def || r;
            } else if (value != undef && value instanceof Array == false) {
                value = [value];
            }

            switch (true) {
                case (type & _dtpClass) == _dtpClass:
                    r = value.map(d => new $classes[subtype](d));
                    break;
                case (type & _dtpEnum) == _dtpEnum:
                    r = value.map(d => (subtype.has(d) && d) || undefined).filter(d => d !== undefined);
                    break;
                case subtype == null && value instanceof Array:
                    return value;
            }
            return r;
        case (type & _dtpAnyType) == _dtpAnyType:
            return value || def || null; //((type & _dtpOptional) == _dtpOptional ? undef : null);
        case (type & _dtpID) == _dtpID: {
            if (value && value === 'null') {
                return null;
            }
            return value || def || null;
        }
        case (type & _dtpNumber) == _dtpNumber:
            switch (true) {
                case value === undef:
                    return /*(type & _dtpOptional) == _dtpOptional ? undef : */ def || 0;
                case value == 'null':
                    return def || 0;
                case typeof value == 'string':
                    switch (true) {
                        case /^\d+$/.test(value || def):
                            return Number(value || def);
                        case /^[\dabcdef]+$/.test(value || def):
                            return parseInt(value || def, 16);
                        default:
                            return parseInt(value || def, 10) || 0;
                    }
                    break;
                case typeof value == 'number':
                    return value;
            }
        case (type & _dtpBoolean) == _dtpBoolean:
            switch (typeof value) {
                case 'boolean':
                    return value;
                default:
                    // if ((type & _dtpOptional) == _dtpOptional) {
                    //     return undef;
                    // }
                    switch (true) {
                        case /false|true/i.test(value):
                            return value.toLowerCase() == 'true';
                        case value !== undef:
                            return !!value;
                        default:
                            return value === undef && def === null ? undefined : value || def;
                    }
                    break;
            }
        case (type & (_dtpJSON | _dtpString)) == (_dtpJSON | _dtpString):
            return $typeCheck(
                value,
                (value && /^\{/.test(value) && value.length > 0 && _dtpJSON) || _dtpString,
                subtype,
                def
            );
        case (type & _dtpJSON) == _dtpJSON:
            switch (value) {
                case undef:
                // if ((type & _dtpOptional) == _dtpOptional) {
                //     return undef;
                // }
                case 'null':
                    return def || {};
                default:
                    switch (true) {
                        case Object.isString(value):
                            try {
                                return JSON.parse(value) || def || {};
                            } catch (e) {
                                if (/^\{/.test(value) == false && value != '' && /\w+=\d+/.test(value)) {
                                    var r = {};
                                    value.split(/\s/).forEach(v => {
                                        const m = v.match(/(\w+)=(\d+)/);
                                        if (m) {
                                            r[m[1]] = m[2];
                                        }
                                    });
                                    return r;
                                }
                            }
                            break;
                        case Object.isObject(value):
                            return value;
                        default:
                            return def || {};
                    }
                    break;
            }
        case (type & _dtpString) == _dtpString:
            return (value != undef && value.toString()) || def || '';
        case (type & _dtpTimestamp) == _dtpTimestamp:
            return (value && Number(value)) || def || 0;
        case (type & _dtpDate) == _dtpDate:
            return (value && moment(value).format('YYYY-MM-DD')) || def || 0;
        case (type & _dtpColor) == _dtpColor:
            switch (value) {
                case undef:
                // if ((type & _dtpOptional) == _dtpOptional) {
                //     return undef;
                // }
                case 'null':
                    return def || '#000';
                default:
                    switch (true) {
                        case /^rgb/i.test(value):
                            return colorLib.RGB2HEX(value);
                        case /^[A-F0-9]+$/i.test(value):
                            return `#${value}`;
                        default:
                            return value || def || '#000';
                    }
            }
        case (type & _dtpObject) == _dtpObject:
            return value || def || {}; //((type & _dtpOptional) == _dtpOptional ? undef : {});
        case (type & _dtpEnum) == _dtpEnum:
            return (subtype.has(value) && value) || def || undef;
        case (type & _dtpMap) == _dtpMap:
            var r = {};
            if (value === undef) {
                // return (type & _dtpOptional) == _dtpOptional ? undef : r
                return r;
            }

            for (const k in value) {
                r[k] = new $classes[subtype](value[k]);
            }
            return r;
        case (type & _dtpClass) == _dtpClass:
            return new $classes[subtype](value);
        // value === undef
        //     ? ((type & _dtpOptional) == _dtpOptional ? undef : new subtype())
        //     : new subtype(value);
    }
}

// $classes.$factory = function (cName, data) {
//     return new $classes[cName](data);
// }

$classes.$check = (type, obj) => {
    const p = $classes[type] || type;
    return (obj instanceof p && obj) || new p(obj);
};

/**
 * @constructor
 * @ typedef {Object} cBasePrototype
 * @ property {function} $update
 * @ property {function} $export
 */
class cBasePrototype {
    static __fieldsDef = {};
    $update() {}
    $export() {}
}
$classes[_cBasePrototype] = cBasePrototype;

// $classes[_cBasePrototype] = cBasePrototype;
// $classes[_cBasePrototype].
// $classes[_cBasePrototype].prototype.
// $classes[_cBasePrototype].prototype.

/**
 * @param {Object} r
 * @param {string} $className
 * @param {boolean=} setOnly
 * @this cBasePrototype
 */
function exportFields(r, $className, setOnly) {
    !r && (r = {});
    const def = $classes[$className]?.__fieldsDef || {};

    DEBUG && console.assert(def, `class ${$className} fields definitions not found`);

    for (const k in def) {
        const f = def[k];
        const av = this[k];

        switch (true) {
            case !!(f[0] & _dtpOptional) && setOnly:
                const vdef = f[2] instanceof Function ? f[2].call(this) : f[2];
                let sdef = undefined;

                switch (true) {
                    case !!(f[0] & _dtpAnyType):
                        sdef = undefined;
                        break;
                    case !!(f[0] & _dtpString):
                        sdef = '';
                        break;
                    case !!(f[0] & _dtpID):
                        sdef = null;
                        break;
                    case !!(f[0] & _dtpNumber):
                        sdef = 0;
                        break;
                    case !!(f[0] & _dtpBoolean):
                        sdef = undefined;
                        break;
                    case !!(f[0] & _dtpJSON):
                    case !!(f[0] & _dtpObject):
                        sdef = {};
                        break;
                    case !!(f[0] & _dtpTimestamp):
                    case !!(f[0] & _dtpDate):
                        sdef = 0;
                        break;
                    case !!(f[0] & _dtpColor):
                        sdef = '#000';
                        break;
                    case !!(f[0] & _dtpMap):
                        sdef = {};
                        break;
                    case !!(f[0] & _dtpArray):
                        sdef = [];
                        break;
                    case !!(f[0] & _dtpEnum):
                        sdef = undefined;
                        break;
                    case !!(f[0] & _dtpClass):
                        sdef = new $classes[f[1]]();
                        break;
                }

                if (f[0] & _dtpArray) {
                    av && sdef.toString() !== (av || []).toString() && (r[k] = av);
                } else if (f[0] & (_dtpClass | _dtpObject | _dtpAnyType | _dtpJSON | _dtpMap)) {
                    if (!isObjectEqual(av, sdef)) {
                        if (av instanceof $classes[_cBasePrototype]) {
                            r[k] = av.$export(undefined, true);
                        } else {
                            r[k] = av;
                        }
                    }
                } else if (sdef !== av) {
                    if ((f[0] & _dtpDate) == _dtpDate) {
                        r[k] = core_utils.dateStr2Timestamp(av);
                    } else {
                        r[k] = av;
                    }
                }
                break;
            case !!(f[0] & _dtpInternal):
                break;
            default:
                switch (true) {
                    case !!(f[0] & _dtpMap):
                        var r1 = {};
                        Object.keys(av).forEach(v => {
                            r1[v] = av[v].$export(undefined, setOnly);
                        });
                        r[k] = r1;
                        break;
                    case !!(f[0] & _dtpArray):
                        var r1 = [];
                        av.forEach(v => {
                            r1.push((v instanceof $classes[_cBasePrototype] && v.$export(undefined, setOnly)) || v);
                        });
                        r[k] = r1;
                        break;
                    case !!(f[0] & _dtpClass):
                        r[k] = av.$export(undefined, setOnly);
                        break;
                    default:
                        r[k] = av;
                        break;
                }
                break;
        }
    }

    return r; //JSON.stringify(r);
}

/**
 * @param {Object} obj
 * @param {string} $className
 * @param {boolean} resetDefaults
 * @this cBasePrototype
 * @return {void}
 */
function updateFields(obj, $className, resetDefaults) {
    const def = $classes[$className].__fieldsDef,
        src = (resetDefaults && def) || obj,
        p = this;

    (Object.keys(src) || []).forEach(k => {
        if (def[k] === undefined) {
            return;
        }
        const f = def[k];
        p[k] = $typeCheck(obj && obj[k], f[0], f[1], f[2] instanceof Function ? f[2].call(p, obj) : f[2]);
    });
}

function isObjectEqual(x, y) {
    for (const p in x) {
        if (typeof x[p] !== typeof y[p]) {
            return false;
        }
        if ((x[p] === null) !== (y[p] === null)) {
            return false;
        }
        switch (typeof x[p]) {
            case 'undefined':
                if (typeof y[p] != 'undefined') {
                    return false;
                }
                break;
            case 'object':
                if (
                    x[p] !== null &&
                    y[p] !== null &&
                    (x[p].constructor.toString() !== y[p].constructor.toString() || isObjectEqual(!x[p], y[p]))
                ) {
                    return false;
                }
                break;
            case 'function':
                if (p != 'equals' && x[p].toString() != y[p].toString()) {
                    return false;
                }
                break;
            default:
                if (x[p] !== y[p]) {
                    return false;
                }
                break;
        }
    }
    return true;
}
