/**
 * external synchronous communication object
 * @author Jacky Shikerya <shikerya@me.com>
 */

core.moses = (() => {
    const p = {},
        defOpts = {
            bc: true,
            cb: undefined,
            cbError: undefined
        },
        mediator = new $classes.Mediator(),
        observer = new $classes.Observer(),
        watcher = new $classes.WatchQueue();

    observer.subscribe((ev, data) => {
        watcher.fire(ev, data);
    });

    p.watch = function(events, id, callback) {
        if (arguments.length == 2) {
            callback = id;
            id = undefined;
        }
        return watcher.queue(events, id, callback);
    };

    p.cancelWatch = id => {
        watcher.cancel(id);
    };

    p.subscribe = mediator.subscribe.bind(mediator);

    p.unsubscribe = mediator.unsubscribe.bind(mediator);

    p.announce = function(method, data, opts) {
        DEBUG && console.assert(method);

        if (method instanceof Array && arguments.length == 1) {
            for (let i = 0; i < method.length; i++) {
                if (method[i] instanceof Array) {
                    this.announce(...method[i]);
                } else {
                    this.announce.call(this, method[i]);
                }
            }
            return;
        }

        if (method instanceof $classes[_cMessage]) {
            opts = data;
            data = method;
            method = data.Class.className;
        }

        const r = mediator.announce(method, (data && [data]) || undefined, opts);
        observer.publish(method, data, opts, this);

        return r;
    };

    return p;
})();
