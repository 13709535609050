core.moses.subscribe(_rLogout, () => {
    $('<div id="processShield" />').appendTo('body');
    DEBUG && console.log('logout processing');

    if (core.channel) {
        core.channel.stop();
    }

    core.DM.killUserSession();
    core.DM.stopWatchdog();

    if (__USE_INTERCOM) {
        try {
            Intercom('shutdown');
        } catch (e) {
            __USE_ROLLBAR && Rollbar.debug('Intercom::shutdown fail', e);
        }
    }

    setTimeout(() => {
        core.storage.$kill(_gcSESSION_ID_NAME);
        core.storage.$kill(_gcSESSION_ID_NAME, true);
        $.cookie(_gcSESSION_ID_NAME, null);
        core.DM.close();
        core_DO.cleanup();
        window.location.href = _CONF_ROOTURL;
    }, 1000);
});

core.DM.registerHandler([
    [_rLogin, 'user/session/login', _cLoginRequest, _cLoginResponse],
    [_rLogout, 'user/session/logout', _cLogoutRequest, _cLogoutResponse],
    [_rCheckSession, 'user/session/check', _cCheckSessionRequest, _cLoginResponse],
    [_rInvalidateSessions, 'user/session/invalidate', _cInvalidateSessionsRequest, _cLoginResponse],
    [_rDirectUserRegistration, 'user/register/begin/direct', _cDirectRegistrationRequest, _cRegistrationResponse],
    [_rVerifyEmailToken, 'user/register/verify/token', _cVerifyEmailTokenRequest, _cVerifyEmailTokenResponse],
    [
        _rResendEmailVerification,
        'user/register/verify/resend',
        _cResendEmailVerificationRequest,
        _cResendEmailVerificationResponse
    ],
    [_rContinueRegistration, 'user/register/continue', _cContinueRegistrationRequest, _cRegistrationResponse],
    [_rCompleteRegistration, 'user/register/complete', _cCompleteRegistrationRequest, _cRegistrationResponse],
    [_rPasswordRecovery, 'user/register/recovery/request', _cPasswordRecoveryRequest, _cPasswordRecoveryResponse],
    [
        _rPasswordRecoveryStep2,
        'user/register/recovery/password',
        _cPasswordRecoveryStep2Request,
        _cPasswordRecoveryResponse
    ],
    [_rChangePassword, 'user/info/password/update', _cChangePasswordRequest, _cErrorMessage],
    [_rAddUserFeedback, 'feedback/add', _cAddUserFeedback, _cSessionMessage]
]);

if (__BUILD_APP) {
    core.DM.registerHandler([
        [_rUserInfo, 'user/info/self', _cUserInfoRequest, _cUserInfoResponse],
        [_rValidatePassword, 'user/info/password/validate', _cValidatePasswordRequest, _cSessionMessage],
        [_rRemoveUserAccount, 'user/info/remove', _cRemoveUserAccountRequest, _cSessionMessage],
        [_rUpdateUserInfo, 'user/info/self/update', _cUpdateUserInfoRequest, _cUserInfoResponse],
        [_rSessionPing, 'user/session/ping', _cSessionMessage, _cSessionMessage],
        [_rListUserProperties, 'user/properties/list', _cListUserPropertiesRequest, _cUserPropertiesResponse],
        [_rSetUserProperties, 'user/properties/set', _cSetUserPropertiesRequest, _cUserPropertiesDoneResponse],
        [_rRemoveUserProperties, 'user/properties/remove', _cRemoveUserPropertiesRequest, _cUserPropertiesDoneResponse],
        [_rRemovePicture, 'files/picture/remove', _cRemovePictureRequest, _cSessionMessage],
        [_rListWorkspaceUsers, 'workspace/members/list', _cListWorkspaceUsersRequest, _cListOfWorkspaceUsersResponse],
        [_rAddUserToWorkspace, 'workspace/members/add', _cAddUserToWorkspaceRequest, _cAddUserToWorkspaceResponse],
        [
            _rRemoveUserFromWorkspace,
            'workspace/members/remove',
            _cRemoveUserFromWorkspaceRequest,
            _cRemoveUserFromWorkspaceResponse
        ],
        [
            _rAttachUserToWorkspaceUser,
            'workspace/members/attach',
            _cAttachUserToWorkspaceUserRequest,
            _cAttachUserToWorkspaceUserResponse
        ],
        [
            _rDetachUserFromWorksaceUser,
            'workspace/members/detach',
            _cDetachUserFromWorksaceUserRequest,
            _cDetachUserFromWorkspaceUserResponse
        ],
        [_rWorkspaceUserInfo, 'workspace/members/info', _cWorkspaceUserInfoRequest, _cWorkspaceUserInfoResponse],
        [
            _rUpdateWorkspaceUserInfo,
            'workspace/members/info/update',
            _cUpdateWorkspaceUserInfoRequest,
            _cUpdateWorkspaceUserInfoResponse
        ],
        [_rAddNewEmail, 'user/contact/addemail', _cAddNewEmailRequest, _cAddNewEmailResponse],
        [_rRemoveContactInfo, 'user/contact/remove', _cRemoveContactInfoRequest, _cSessionMessage],
        [
            _rAssignContactInfoToWorkspaceUser,
            'user/contact/assign',
            _cAssignContactInfoToWorkspaceUser,
            _cSessionMessage
        ],
        [_rSetPrimaryContactInfo, 'user/contact/primary', _cSetPrimaryContactInfoRequest, _cSessionMessage],
        [
            _rCurrentWorkspaceUserInfo,
            'workspace/members/self',
            _cWorkspaceUserInfoRequest,
            _cUpdateWorkspaceUserInfoResponse
        ],
        [_rListWorkspaces, 'workspace/list', _cListWorkspacesRequest, _cListOfWorkspacesResponse],
        [_rCreateWorkspace, 'workspace/create', _cCreateWorkspaceRequest, _cCreateWorkspaceResponse],
        [_rUpdateWorkspace, 'workspace/update', _cUpdateWorkspaceRequest, _cWorkspaceStatusResponse],
        [_rTransferOwnership, 'workspace/transfer', _cTransferOwnershipRequest, _cWorkspaceStatusResponse],
        [_rRemoveWorkspace, 'workspace/remove', _cRemoveWorkspaceRequest, _cSessionMessage],
        [_rWorkspaceInfo, 'workspace/info', _cWorkspaceInfoRequest, _cWorkspaceStatusResponse],
        [_rProcessUploadedPicture, 'files/picture/process', _cProcessUploadedPictureRequest, _cSessionMessage],
        [_rListProject, 'workspace/projects/list', _cListProjectsRequest, _cListOfProjectsResponse],
        [_rCreateProject, 'workspace/projects/create', _cCreateProjectRequest, _cCreateProjectResponse],
        [_rUpdateProject, 'project/update', _cUpdateProjectRequest, _cProjectInfoPackage],
        [_rCloneProject, 'workspace/projects/clone', _cCloneProjectRequest, _cSessionMessage],
        [
            _rListProjectsPaging,
            'workspace/projects/listpage',
            _cListProjectsPagingRequest,
            _cListOfProjectsPagingResponse
        ],
        [
            _rConsultantCloneProject,
            'workspace/projects/cloneoutside',
            _cConsultantCloneProjectRequest,
            _cSessionMessage
        ],
        [_rRemoveProject, 'workspace/projects/remove', _cRemoveProjectRequest, _cSessionMessage],
        [_rArchiveProject, 'workspace/projects/archive', _cArchiveProjectRequest, _cSessionMessage],
        [_rReactivateProject, 'workspace/projects/reactivate', _cReactivateProjectRequest, _cSessionMessage],
        [_rCreateTutorialProject, 'workspace/projects/tutorial', _cTutorialProjectRequest, _cSessionMessage],
        [_rPurgeRemovedProjects, 'workspace/projects/purge', _cPurgeRemovedProjectsRequest, _cSessionMessage],
        [_rProjectInfo, 'project/state/infobundle', _cProjectInfoBundleRequest, _cProjectInfoBundle],
        [_rProjectMap, 'project/state/map', _cProjectMapRequest, _cProjectMapResponse],
        [
            _rProjectExecutionBundle,
            'project/state/execbundle',
            _cProjectExecutionBundleRequest,
            _cProjectExecutionBundle
        ],
        [_rProjectMemberPack, 'project/state/memberpack', _cProjectMemberPackRequest, _cProjectMemberPackResponse],
        [_rMultipleTaskInfos, 'project/state/tasks', _cMultipleTaskInfosRequest, _cMultipleTaskInfosResponse],
        [_rTeamPack, 'project/state/teampack', _cTeamPackRequest, _cTeamPackResponse],
        [_rCreateTeam, 'project/team/create', _cCreateTeamRequest, _cTeamStatusResponse],
        [_rUpdateTeam, 'project/team/update', _cUpdateTeamRequest, _cTeamStatusResponse],
        [_rRemoveTeam, 'project/team/remove', _cRemoveTeamRequest, _cSessionMessage],
        [_rTeamMembership, 'project/team/become', _cTeamMembershipRequest, _cTeamMembershipResponse],
        [_rProjectMembership, 'project/members/change', _cProjectMembershipRequest, _cProjectMembershipResponse],
        [_rProjectStatePush, 'project/state/change', _cStateChangesEnvelope, _cStateChangeAccepted],
        [_rGetMessageOfTheDay, 'feedback/motd', _cGetMessageOfTheDay, _cMessageOfTheDayResponse],
        [_rHistoryStream, 'project/history/query', _cHistoryStreamRequest, _cSessionMessage],
        [_rPostComment, 'project/postcomment', _cPostCommentRequest, _cSessionMessage],
        [_rRemoveComment, 'project/removecomment', _cRemoveCommentRequest, _cSessionMessage],
        [
            _rGetUserAlertsSettings,
            'user/settings/alerts/get',
            _cGetUserAlertsSettingsRequest,
            _cGetUserAlertsSettingsResponse
        ],
        [_rUpdateUserAlertsSettings, 'user/settings/alerts/set', _cUpdateUserAlertsSettingsRequest, _cSessionMessage],
        [_rNewFile, 'project/files/add', _cNewFileRequest, _cSessionMessage],
        [_rListFiles, 'project/files/list', _cListFilesRequest, _cSessionMessage],
        [_rRemoveFile, 'project/files/remove', _cRemoveFileRequest, _cSessionMessage],
        [_rUploadTicket, 'project/files/upload', _cUploadTicketRequest, _cUploadTicketResponse],
        [_rCopyToClipboard, 'project/state/copy', _cCopyToClipboardRequest, _cCopyToClipboardResponse],
        [_rClipboardPreview, 'user/clipboard/preview', _cClipboardPreview, _cSessionMessage],
        [_rClearClipboard, 'user/clipboard/clear', _cClearClipboardRequest, _cSessionMessage],
        [_rPasteFromClipboard, 'project/state/paste', _cPasteFromClipboardRequest, _cSessionMessage],
        [_rCreateShare, 'project/share/create', _cCreateShareRequest, _cCreateShareResponse],
        [_rListShares, 'project/share/list', _cListSharesRequest, _cListSharesResponse],
        [_rRemoveShare, 'project/share/remove', _cRemoveShareRequest, _cSessionMessage],
        [
            _rWorkspaceOverviewForUser,
            'workspace/overview',
            _cWorkspaceOverviewForUserRequest,
            _cWorkspaceOverviewForUserResponse
        ],
        [_rProductsList, 'products/list', _cProductsListRequest, _cProductsListResponse],
        [
            _rListActiveSubscriptions,
            'user/subscriptions/list',
            _cListActiveSubscriptionsRequest,
            _cListOfActiveSubscriptionsResponse
        ],
        [
            _rCreateSubscription,
            'user/subscriptions/create',
            _cCreateSubscriptionRequest,
            _cUserBillingManagementResponse
        ],
        [
            _rUpdateSubscriptionCard,
            'user/subscriptions/card',
            _cUpdateSubscriptionCardRequest,
            _cUserBillingManagementResponse
        ],
        [
            _rChangeSubscriptionPlan,
            'user/subscriptions/change',
            _cChangeSubscriptionPlanRequest,
            _cUserBillingManagementResponse
        ],
        [
            _rCancelSubscription,
            'user/subscriptions/cancel',
            _cCancelSubscriptionRequest,
            _cUserBillingManagementResponse
        ],
        [
            _rRemoveContentFromSubscription,
            'user/subscriptions/removecontent',
            _cRemoveContentFromSubscriptionRequest,
            _cUserBillingManagementResponse
        ],
        [_rFeatureAuth, 'workspace/features/auth', _cFeatureAuthRequest, _cSessionMessage],
        [_rFeatureData, 'workspace/features/data', _cFeatureDataRequest, _cSessionMessage],
        [_rFeatureQuestion, 'workspace/features/question', _cFeatureQuestionRequest, _cFeatureQuestionResponse],
        [_rListProjectTags, 'workspace/tags/list', _cListProjectTagsRequest, _cListOfProjectTagsResponse],
        [_rCreateProjectTag, 'workspace/tags/create', _cCreateProjectTagRequest, _cSessionMessage],
        [_rUpdateProjectTag, 'workspace/tags/update', _cUpdateProjectTagRequest, _cSessionMessage],
        [_rRemoveProjectTag, 'workspace/tags/remove', _cRemoveProjectTagRequest, _cSessionMessage],
        [_rTagProject, 'workspace/projects/tag', _cTagProjectRequest, _cSessionMessage],
        [
            _rResendWorkspaceInvitation,
            'workspace/members/resend',
            _cResendWorkspaceInvitationRequest,
            _cResendWorkspaceInvitationResponse
        ],

        /** Workspace Reporting */
        [
            _rReportProjectsAndStats,
            'workspace/report/projectstatuses',
            _cReportProjectsAndStatsRequest,
            _cReportProjectsAndStatsResponse
        ],

        /** Workspace Overview Messages */
        [_rWorkspaceOverview, 'workspace/overview/full', _cWorkspaceOverviewRequest, _cWorkspaceOverviewResponse],
        [_rWorkspaceDigest, 'workspace/overview/digest', _cWorkspaceDigestRequest, _cSessionMessage],

        /** Workspace settings */
        [_rUpdateWorkspaceSettings, 'workspace/settings/update', _cUpdateWorkspaceSettingsRequest, _cSessionMessage],

        /** check for ability to extend trial */
        [
            _rCheckTrialEligibility,
            'user/subscriptions/checktrial',
            _cCheckTrialEligibilityRequest,
            _cUserBillingManagementResponse
        ],
        [_rRedeemTrialOffer, 'user/subscriptions/usetrial', _cRedeemTrialOfferRequest, _cUserBillingManagementResponse]
    ]);
}
