/**
 * Storage manager for Casual CORE
 * @author Jacky Shikerya <shikerya@me.com>
 */

core.storage = {
    /**
     * @param {string} key
     * @param {boolean=} persistent
     * @returns {string|number|boolean|Object|null}
     */
    $get(key, persistent = false) {
        try {
            const _ = ((persistent && window.localStorage) || window.sessionStorage).getItem(key);
            const [, t, v] = (_ && /^☞(J|N|B):(.+)$/.exec(_)) || [];
            switch (t) {
                case undefined:
                    return _;
                case 'J':
                    return JSON.parse(v);
                case 'N':
                    return parseFloat(v);
                case 'B':
                    return v == 'true';
            }
        } catch (e) {
            switch (e.name) {
                case 'NS_ERROR_FILE_CORRUPTED':
                    alert(core.getMsg(_msgGetNS_ERROR_FILE_CORRUPTED));
                    break;
                default:
                    __USE_ROLLBAR && Rollbar.debug('storage::get', { e });
                    break;
            }
        }
        return null;
    },
    /**
     * @param {string} key
     * @param {string|number|boolean|Object} value
     * @param {boolean=} persistent
     * @returns {void}
     */
    $set(key, value, persistent = false) {
        try {
            let v;
            switch (typeof value) {
                case 'object':
                    v = '☞J:' + JSON.stringify(value);
                    break;
                case 'number':
                    v = '☞N:' + JSON.stringify(value);
                    break;
                case 'boolean':
                    v = '☞B:' + JSON.stringify(value);
                    break;
                default:
                    v = value.toString();
                    break;
            }
            return ((persistent && window.localStorage) || window.sessionStorage).setItem(key, v);
        } catch (e) {
            switch (e.name) {
                case 'NS_ERROR_FILE_CORRUPTED':
                    alert(core.getMsg(_msgPutNS_ERROR_FILE_CORRUPTED));
                    break;
                case 'QuotaExceededError':
                    alert(core.getMsg(_msgPutmsgPutQuotaExceededError));
                    break;
                default:
                    __USE_ROLLBAR && Rollbar.debug('storage::put', { e });
                    break;
            }
        }
    },
    /**
     * @param {string} key
     * @param {boolean=} persistent
     */
    $kill(key, persistent = false) {
        try {
            ((persistent && window.localStorage) || window.sessionStorage).removeItem(key);
        } catch (e) {
            switch (e.name) {
                case 'NS_ERROR_FILE_CORRUPTED':
                    alert(core.getMsg(_msgRemoveNS_ERROR_FILE_CORRUPTED));
                    break;
                default:
                    __USE_ROLLBAR && Rollbar.debug('storage::remove', { e });
                    break;
            }
        }
    }
};

// core.storage = (() => {
//     let $get, $set, $kill;

//     switch (!!window.sessionStorage) {
//         case true:
//             /**
//              * @param {string} key
//              * @param {boolean=} persistent
//              */
//             $get = (key, persistent) => getFromStorage(key, persistent) || $.cookie(key);
//             /**
//              * @param {string} key
//              * @param {string|number|boolean|Object|null} val
//              * @param {boolean=} persistent
//              */
//             $set = (key, val, persistent) => {
//                 if (val === null) {
//                     $kill(key, persistent);
//                     return;
//                 }
//                 let rval;
//                 try {
//                     rval = putToStorage(key, val, persistent);
//                 } catch (e) {
//                     rval = $.cookie(key, val);
//                 }
//             };
//             /**
//              * @param {string} key
//              * @param {boolean=} persistent
//              */
//             $kill = (key, persistent) => {
//                 removeFromStorage(key, persistent);
//                 $.cookie(key, '', { expire: -1 });
//             };
//             break;
//         default:
//             /**
//              * @param {string} key
//              * @param {boolean=} persistent
//              */
//             $get = (key, persistent) => $.cookie(key);
//             $set = (key, value, persistent) => $.cookie(key, value);
//             $kill = (key, persistent) => {
//                 $.cookie(key, '', { expire: -1 });
//             };
//             break;
//     }

//     return {
//         $get,
//         $set,
//         $isDefined(key) {
//             return !!$get(key);
//         },
//         $kill
//     };
// })();
